import { Alert } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as lineupActions from '../../actions/lineup'
import * as accountActions from '../../actions/account'
import getDefaultDateInfo from '../../utils/get-default-date-info'
import { EXP_SETTINGS_KEY } from '../../constants/local-storage-keys'

import OnOff from './onOff'
import TopStacks from './topStacks'

import ReactGA from 'react-ga'

ReactGA.initialize('UA-146608705-1')

const AnalysisContainer = styled.div`
  padding: 100px 2% 76px;
  min-height: 100vh;
  border-radius: 10px;
  background-color: #F1F1F1;
`

class Analysis extends Component {

  componentDidMount() {
    const { sport, site, slate, type } = this.props.match.params
    let [_counter, _season] = getDefaultDateInfo(sport)

    ReactGA.pageview(`/analytics`)

    if (!this.props.players.data || !this.props.players.data.length) {
      this.props.fetchPlayers({slate, site, sport, counter: _counter, season: _season})
    }

    this.props.fetchSubscription()
  }

  updateSlate(slate) {
    const { site, sport } = this.props.match.params
    window.location = window.location = window.location.protocol + "//" + window.location.host + "/optimizer/" + sport + "/" + site + '/' + slate
  }

  refreshPlayerTable(_site) {
    const { sport, site, slate, type } = this.props.match.params
    let [_counter, _season] = getDefaultDateInfo(sport)

    this.props.fetchPlayers({slate, site: _site || site, sport, counter: _counter, season: _season})
  }

  render() {
    const { players, subscription } = this.props

    if (!players.data) return false

    const { site, slate, sport, type } = this.props.match.params

    let body

    switch (sport) {
      case 'nba':
        switch(type) {
          case 'on-off':
            body = (
              <OnOff players={players.data} updatedAt={players.updatedAt} loading={players.loading || subscription.loading} refreshPlayerTable={this.refreshPlayerTable.bind(this)} />
            )
            break
          default:
            body = false
        }
        break
      case 'nfl':
        switch(type) {
          case 'stacks':
            body = (
              <TopStacks site={site} updatedAt={players.updatedAt} players={players.data} loading={players.loading} />
            )
            break
          default:
            body = false
        }
        break
      default:
        body = false
    }

    return (
      <AnalysisContainer>
        { body }
      </AnalysisContainer>
    )
  }
}

export default connect(
  state => ({
    players: state.lineup.players,
    analysis: state.analysis,
    subscription: state.account.subscription
  }),
  {
    fetchPlayers: lineupActions.fetchPlayers,
    fetchSubscription: accountActions.fetchSubscription
  }
)(Analysis)
