import { createActions } from 'lessdux'

/* Actions */

// LINEUP
export const opt = {
  ...createActions('OPT'),
  CANCEL: 'OPT_CANCEL',
  NEW_LINEUP: 'OPT_NEW_LINEUP',
  SAVE_LINEUPS: 'OPT_SAVE_LINEUPS',
  REPLACE_LINEUP: 'OPT_REPLACE_LINEUP'
}
export const players = {
  ...createActions('PLAYERS', { withUpdate: true }),
  REMOVE: 'PLAYERS_REMOVE'
}
export const teamStacks = {
  ...createActions('TEAM_STACKS', { withUpdate: true }),
  REMOVE: 'PLAYERS_TEAM_STACKS'
}
export const settings = {
  ...createActions('SETTINGS', { withUpdate: true }),
  RESET: 'RESET_SETTINGS',
  OVERRIDE: 'OVERRIDE_SETTINGS'
}
export const presets = {
  ...createActions('PRESETS')
}
export const exposureStats = createActions('EXPOSURE_STATS')
export const lineupStats = createActions('LINEUP_STATS')
export const results = createActions('RESULTS')
export const slates = {
  ...createActions('SLATES'),
  FETCH_ALL: 'SLATES_FETCH_ALL',
  RECEIVE_ALL: 'SLATES_RECEIVE_ALL'
}
export const savedLineups = createActions('SAVED_LINEUPS', { withUpdate: true })

/* Action Creators */

// OPT
export const fetchOpt = params => ({
  type: opt.FETCH,
  payload: { params }
})
export const fetchExposureStats = ({ lineups, players, sport, luSize }) => ({
  type: exposureStats.FETCH,
  lineups,
  players,
  sport,
  luSize
})
export const cancelOpt = () => {
  return {
    type: opt.CANCEL
  }
}
export const replaceLineups = ({ payload, site, slate }) => {
  return {
    type: opt.RECEIVE,
    payload,
    site,
    slate
  }
}
export const fetchLineupStats = (payload) => ({
  type: lineupStats.FETCH,
  ...payload
})
// PLAYERS
export const fetchPlayers = ({ slate, site, sport, showdown, free, counter, season }) => ({
  type: players.FETCH,
  slate,
  site,
  sport,
  showdown,
  free,
  counter,
  season
})
export const updatePlayersTable = ({payload, rowData, slate, site, sport, counter, season})  => {
  return {
    type: players.UPDATE,
    payload,
    rowData,
    slate,
    site,
    sport,
    counter,
    season
  }
}

export const removePlayers = ({ slate, site, sport, counter, season }) => ({
  type: players.REMOVE,
  slate,
  site,
  sport,
  counter,
  season
})

// SETTINGS
export const fetchSettings = ({site, sport, counter, season, showdown=false, slate="Main"}) => ({
  type: settings.FETCH,
  site,
  sport,
  showdown,
  slate,
  counter,
  season
})
export const updateSettings = ({site, slate, sport, counter, season, ...args}) => {
  return {
    type: settings.UPDATE,
    site,
    slate,
    sport,
    counter,
    season,
    payload: args
  }
}
export const resetSiteSettings = ({site, slate, showdown, sport, counter, season}) => ({
  type: settings.RESET,
  site,
  slate,
  showdown,
  sport,
  counter,
  season
})
// PRESETS
export const fetchPresets = ({ contestType, numTeams, site, sport }) => ({
  type: presets.FETCH,
  numTeams,
  site,
  sport,
  contestType
})

// STACKS
export const fetchTeamStacks = ({players, slate, site, sport, counter, season}) => ({
  type: teamStacks.FETCH,
  players,
  slate,
  site,
  sport,
  counter,
  season
})
export const updateTeamStacks = ({_teamStacks, rowData, slate, site, sport, counter, season}) => {
  return ({
    type: teamStacks.UPDATE,
    payload: _teamStacks,
    rowData,
    slate,
    site,
    sport,
    counter,
    season
  })
}
export const removeTeamStacks = ({ slate, site, sport, counter, season }) => ({
  type: teamStacks.REMOVE,
  slate,
  site,
  sport,
  counter,
  season
})

// RESULTS
export const fetchResultsCSV = (payload = {}) => ({
  type: results.FETCH,
  payload
})

// SAVED LINEUPS
export const saveLineups = ({ lineups, site, slate, sport, counter, season, silent=false }) => ({
  type: savedLineups.UPDATE,
  lineups,
  site,
  slate,
  sport,
  silent,
  counter,
  season
})

export const fetchSavedLineups = ({ site, slate, sport, counter, season }) => ({
  type: savedLineups.FETCH,
  site,
  slate,
  sport,
  counter,
  season
})

// Slates
export const fetchSlates = ({ site, season, counter }) => ({
  type: slates.FETCH,
  site,
  season,
  counter
})

export const fetchAllSlates = ({ season, counter, sites=['dk', 'fd', 'ya'] }) => ({
  type: slates.FETCH_ALL,
  sites,
  season,
  counter
})
