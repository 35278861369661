import React, { Component } from 'react'
import { Row, Col, Card, Input, Tooltip, Checkbox, Switch, Button, Popconfirm } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  CrownOutlined
} from '@ant-design/icons'

import * as lineupActions from '../../../../actions/lineup'

import TeamStacksTable from './stack-table'
import mergeOrderTeamStacks from '../../../../utils/merge-order-team-stacks'
import { nbaTeamNames } from '../../../../utils/team-name-to-abbrev'
import { initialRow, getEmptyStack, getDefaultSpecificStacks, getMaxFreq } from '../../../../utils/specific-stacks'
import FakurianDesign from '../../../../assets/images/backgrounds/fakurian-design-light.png'
import { SectionHeaderTitle } from '../../../../components/section-header-title'

import './Settings.css'


const StyledSwitch = styled(Switch)`
  margin-top: 20px !important;
`
const SwitchCol = styled(Col)`
  .ant-switch {
    background-color: #1890ff;
    background-image: none;
  }
  .ant-switch-checked {
    background-color: #ffa500;
  }
`
const SettingsContainer = styled.div`
`
const HeadingCard = styled(Card)`
  &.ant-card {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }
`
const InputLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const SettingsInput = styled(Input)`
  .ant-input-group-addon {
    @media (max-width: 1280px) {
      font-size: 12px;
    }
  }
`
const InputContainer = styled.div`
  padding: 20px;
`
const StyledEnableButton = styled(Button)`
  float: right;
`
const EnableDiv = styled.div`
  float: right;
`

class Settings extends Component {
  static propTypes = {
    // Redux State goes here
    fetchOpt: PropTypes.func.isRequired,
    updateSettings: PropTypes.func.isRequired,
    updateTeamStacks: PropTypes.func.isRequired
  }

  state = {
    stackSettingMode: this.props.settings.data.stackSettingMode
  }

  onTeamStacksChange(rowData) {
    const teamStacks = this.props.teamStacks.data

    const _teamStacks = teamStacks.map(stack => {
      if (stack.TeamAbbrev === rowData.TeamAbbrev) {
        return rowData
      }
      if (stack.TeamAbbrev === rowData.Opp) {
        if (stack.AllowSecondaryStack !== rowData.AllowSecondaryStack) {
          const _stack = {
            ...stack
          }
          _stack.AllowSecondaryStack = rowData.AllowSecondaryStack
          return _stack
        }
      }
      return stack
    })

    this.props.updateTeamStacks({
      _teamStacks,
      rowData,
      slate: this.props.slate,
      site: this.props.site,
      sport: 'nba',
      counter: this.props.counter
    })
  }

  async onChangeAllTeamStackOption(rowKey, val) {
    const teamStacks = this.props.teamStacks.data

    const _teamStacks = await Promise.all(teamStacks.map(stack => {
      return {
        ...stack,
        [rowKey]: val
      }
    }))

    this.props.updateTeamStacks({
      _teamStacks,
      rowData: [],
      slate: this.props.slate,
      site: this.props.site,
      sport: 'nba',
      counter: this.props.counter
    })
  }

  uncheckStackingOptions() {
    const booleanKeys = ['AllowNakedQB', 'AllowQBStack', 'AllowRBStack', 'AllowWRStack', 'AllowTEStack', 'AllowGameStack', 'AllowRBOppStack', 'AllowWROppStack', 'AllowTEOppStack', 'AllowSecondaryStack']

    const _teamStacks = this.props.teamStacks.data
    const teamStacks = _teamStacks.map(stack => {
      const _newStack = { ...stack }
      booleanKeys.forEach(_key => {
        _newStack[_key] = false
      })

      return _newStack
    })

    this.props.updateTeamStacks({
      _teamStacks: teamStacks,
      rowData: teamStacks, // Batch update
      slate: this.props.slate,
      site: this.props.site,
      sport: 'nba',
      counter: this.props.counter
    })
  }

  updateInputValue = (key, val) => {
    this.props.updateSettings( {site: this.props.site, slate: this.props.slate, [key]: val, sport: 'nba', counter: this.props.counter } )

    // cache_settings('nba', this.props.site, this.props.slate, {
    //   [key]: val
    // })
  }

  resetStackSettings = () => {
    const _specificStacks = getDefaultSpecificStacks()

    this.props.updateSettings( { site: this.props.site, slate: this.props.slate, specificStacks: _specificStacks, sport: 'nba', counter: this.props.counter } )

    // cache_settings('nba', this.props.site, this.props.slate, {
    //   specificStacks: _specificStacks
    // })
  }

  render() {
    const { settings, teamStacks, players, showdown } = this.props
    let _teamStacksSettings = []
    if (teamStacks.data && players.data && !players.loading && !teamStacks.loading)
      _teamStacksSettings = mergeOrderTeamStacks(teamStacks.data, players.data, nbaTeamNames)

    return (
      <div className="Settings">
        <SettingsContainer>
          <HeadingCard style={{backgroundImage: `url(${FakurianDesign})`, backgroundSize: '100% auto'}}>
            <Row>
              <Col lg={12}>
                <h1 style={{paddingTop: '15px'}}>Stack Settings</h1>
                <SectionHeaderTitle sport={'nba'} site={this.props.site} counter={this.props.counter} />
              </Col>
            </Row>
          </HeadingCard>
          <TeamStacksTable
            rows={_teamStacksSettings}
            className={'team-stacks-table'}
            onChangeHandler={this.onTeamStacksChange.bind(this)}
            loading={teamStacks.loading}
            clearStackSettings={this.props.clearStackSettings}
            uncheckStackingOptions={this.uncheckStackingOptions.bind(this)}
            onChangeAllTeamStackOption={this.onChangeAllTeamStackOption.bind(this)}
            showdown={this.props.showdown}
            rowKey={(_row, i) => {
              return `team-stacks-custom-${String(i)}`
            }}
          />
        </SettingsContainer>
      </div>
    )
  }
}

export default connect(
  state => ({
    settings: state.lineup.settings,
    teamStacks: state.lineup.teamStacks,
    players: state.lineup.players,
    loggedin: state.auth.loggedin,
    subscription: state.account.subscription,
    savedLineups: state.lineup.savedLineups
  }),
  {
    fetchOpt: lineupActions.fetchOpt,
    updateSettings: lineupActions.updateSettings,
    updateTeamStacks: lineupActions.updateTeamStacks
  }
)(Settings)
