import {
  Row,
  Col,
  Card,
  Button,
  Switch
} from 'antd'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { CheckOutlined, CrownOutlined } from '@ant-design/icons'

import * as lineupActions from '../../../../actions/lineup'
import FakurianDesign from '../../../../assets/images/backgrounds/fakurian-design-light.png'

import { SectionHeaderTitle } from '../../../../components/section-header-title'

import Players from './players'
import Analysis from './analysis'
import SlatesSelect from '../../../../components/slates-select'
import BetaBanner from '../../../../components/beta-banner'
import NewBanner from '../../../../components/new-banner'
import { cache_settings } from '../../../../utils/sport-settings'

import './Players.css'

const CSV_HEADINGS = ["Id","Name","Position","Team","ProjPts","ProjOwn","MinExp","MaxExp"]

const StyledSearch = styled.div`
  margin-top: 25px;
  font-size: 18px;

  input {
    height: 40px;
  }
`
const HeadingCard = styled(Card)`
  &.ant-card {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }
`
const ScrollableContainer = styled.div`
  height: 700px;
  overflow-y: scroll;
  width: 100%;
`
const StyledSwitch = styled(Switch)`
  margin-top: 20px !important;
`
const SwitchCol = styled(Col)`
  .ant-switch {
    background-color: #1890ff;
    background-image: none;
  }
  .ant-switch-checked {
    background-color: #ffa500;
  }
`
const PlayersBox = styled.div`
  margin-top: 25px;
`

const siteToNameMap = {
  'dk': 'DraftKings',
  'fd': 'FanDuel',
  'ya': 'Yahoo'
}

class PlayersContainer extends PureComponent {
  state = {
    toggle: 'players',
    showUpdateBanner: false,
    showPresetsActive: false
  }

  exposureChangeValid(rowData) {
    // must be inbetween 0-100
    if (rowData.MaxExp > 100 || rowData.MaxExp < 0 || rowData.MinExp > 100 || rowData.MinExp < 0) return false

    return true
  }

  onExposureChange(rowData) {
    // turn into array
    if (!Array.isArray(rowData))
      rowData = [rowData]

    const _players = [
      ...this.props.players.data
    ]

    rowData.forEach(r => {
      // Skip rows that have invalid changes
      if (!this.exposureChangeValid(r)) return

      const _playerIndex = _players.findIndex(p => p.Id === r.Id)
      if (_playerIndex < 0) {
        throw Error('No player with id ' + r.Id)
      }

      _players[_playerIndex] = {
        ...r
      }
    })

    this.props.updatePlayersTable({
      payload: _players,
      rowData,
      slate: this.props.slate,
      site: this.props.site,
      sport: 'nfl',
      season: this.props.season,
      counter: this.props.week
    })
  }

  setPreset = (index) => {
    const _settings = this.props.presets.data[index]

    this.props.updateSettings( { 
      site: this.props.site, 
      slate: this.props.slate, 
      ..._settings, 
      sport: 'nfl', 
      season: this.props.season,
      counter: this.props.week
    } )
    this.setState({
      showPresetsActive: true
    })
  }

  render() {
    return (
      <PlayersBox>
        <HeadingCard style={{backgroundImage: `url(${FakurianDesign})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% auto'}}>
          <Row>
            <Col lg={8}>
                {
                  this.props.showdown ? (
                    <h3>Showdown
                    </h3>
                  ) : (
                    <div>
                      <SectionHeaderTitle sport={'nfl'} site={this.props.site} counter={this.props.week} season={this.props.season} changeDateInfo={this.props.changeDateInfo} />
                      <h3><br/><span style={{fontWeight: 400, fontSize: '16px'}}>Slate</span></h3>
                    </div>
                    
                  )
                }

              <SlatesSelect
                updateSlate={this.props.updateSlate}
                slate={this.props.slate}
                site={this.props.site}
                sport={'nfl'}
                counter={this.props.week}
                season={this.props.season}
              />
            </Col>
            <Col lg={6} sm={24} style={{'textAlign': 'center', paddingTop: '20px'}}>
              <Row>
                <Button
                  style={{marginTop: '18px'}}
                  onClick={() => this.setPreset(0)}
                  disabled={(this.props.showdown && (this.props.site !== 'dk')) || this.props.subscription.loading || (this.props.subscription.permissions && !this.props.subscription.permissions.opt.nfl.historical_data)}
                >
                  Use Presets
                  <span style={{fontSize: '12px', marginLeft: '4px', color: '#ffa500'}}><CrownOutlined /></span>
                </Button>
              </Row>
              <Row>
                {
                  this.state.showPresetsActive ? (
                    <div style={{margin: '5px 10px 10px 30px'}}>
                      Active <CheckOutlined style={{color: '#50b547'}} />
                    </div>
                  ) : ""
                }
              </Row>
            </Col>
            <Col lg={8}>
            </Col>
            <SwitchCol lg={2}>
              <StyledSwitch
                disabled={this.props.players.loading}
                checkedChildren="Analysis"
                unCheckedChildren="Players"
                onClick={(e) => {this.setState({
                  toggle: e ? 'analysis' : 'players'
                })}}/>
            </SwitchCol>
          </Row>
        </HeadingCard>
        {
          this.state.toggle === 'players' ? (
            <Players
              clearPlayerData={this.props.clearPlayerData}
              zeroExposures={this.props.zeroExposures}
              site={this.props.site}
              slate={this.props.slate}
              onExposureChange={this.onExposureChange.bind(this)}
              showdown={this.props.showdown}
              week={this.props.week}
              season={this.props.season}
            />
          ) : (
            <Analysis
              players={this.props.players.data}
              onExposureChange={this.onExposureChange.bind(this)}
              settings={this.props.settings.data}
              teamStacks={this.props.teamStacks.data}
              permissions={this.props.subscription.permissions}
              positionCounts={this.props.positionCounts.data}
              showdown={this.props.showdown}
              site={this.props.site}
              week={this.props.week}
              season={this.props.season}
            />
          )
        }
      </PlayersBox>
    )
  }
}

export default connect(
  state => ({
    players: state.lineup.players,
    loggedin: state.auth.loggedin,
    teamStacks: state.lineup.teamStacks,
    subscription: state.account.subscription,
    settings: state.lineup.settings,
    positionCounts: state.analysis.positionCounts,
    presets: state.lineup.presets
  }),
  {
    updatePlayersTable: lineupActions.updatePlayersTable,
    updateSettings: lineupActions.updateSettings,
  }
)(PlayersContainer)
