import React, { useState } from 'react'
import { Table, Radio, Modal, Button, Input } from 'antd'
import styled from 'styled-components'
import { nbaTeamNames as TeamToAbbrev } from '../../../../../utils/team-name-to-abbrev'
import { CPTDisplay } from '../../../../../utils/showdown'

const HoverableDiv = styled.div`
  text-align: center;

  #default {
    display: inline;
  }
  #onHover {
    display: none;
  }

  &:hover {
    #default {
      display: none;
    }
    #onHover {
      display: inline;
    }
  }
`
const StyledTable = styled(Table)`
  &.blurry-text {
    tr:not(:first-child) {
      color: transparent;
      text-shadow: 0 0 10px rgba(0,0,0,0.5);

      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;

      /*
       Introduced in IE 10.
       See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
      */
      -ms-user-select: none;
      user-select: none;
    }
  }
`

const TransparentButton = styled(Button)`
  background-color: transparent !important;
  border: none !important;
`

const StyledModal = styled(Modal)`
  width: 70%;
`

import {
  HEAT_MAP_POSITIVE_COLOR_SCALE,
  HEAT_MAP_NEGATIVE_COLOR_SCALE,
  getColorForValue
} from './utils/color'


const TopOwnedPlayers = ({ players, onExposureChange, permissioned, showdown, site }) => {
  const [ position, setPosition ] = useState(showdown ? 'FLEX' : '')
  const [ showModal, setShowModal ] = useState(false)
  const [ selectedPlayer, setSelectedPlayer ] = useState()
  const [ minExp, setMinExp ] = useState()
  const [ maxExp, setMaxExp ] = useState()
  const [ tempMinExp, setTempMinExp ] = useState(0)
  const [ tempMaxExp, setTempMaxExp ] = useState(0)
  const formattedPlayers = formatTopPlayers(players, position, showdown)

  return (
    <div>
      <Radio.Group
        onChange={(e) => {setPosition(e.target.value)}}
        value={position}
      >
      {
        showdown ? (
          <>
            <Radio.Button value={'FLEX'}>FLEX</Radio.Button>
            <Radio.Button value={"CPT"}>{CPTDisplay[site]}</Radio.Button>
          </>
        ) : (
          <>
            <Radio.Button value={''}>ALL</Radio.Button>
            <Radio.Button value={"PG"}>PG</Radio.Button>
            <Radio.Button value={"SG"}>SG</Radio.Button>
            <Radio.Button value={"SF"}>SF</Radio.Button>
            <Radio.Button value={"PF"}>PF</Radio.Button>
            <Radio.Button value={"C"}>C</Radio.Button>
          </>
        )
      }
      </Radio.Group>
      <StyledTable
        bordered
        columns={cols(setShowModal, setSelectedPlayer, permissioned, setTempMinExp, setTempMaxExp)}
        dataSource={formattedPlayers}
        size={'small'}
        className={ (!permissioned) ? 'blurry-text' : '' }
        rowKey ={(_row, i) => {
          return `top-owmed-players-${i}`
        }}
      />
      <StyledModal
        title={'Adjust Exposure'}
        visible={showModal}
        onOk={() => {
          const _p = {
            ...players.filter(p => p.Id === selectedPlayer)[0]
          }
          if (tempMinExp) _p.MinExp = Number(tempMinExp)
          if (tempMaxExp) _p.MaxExp = Number(tempMaxExp)
          // Dispatch Change

          onExposureChange(_p)

          // Reset all fields
          setSelectedPlayer(null)
          setMinExp(null)
          setMaxExp(null)
          setShowModal(false)
        }}
        onCancel={() => {
          setShowModal(false)
          setSelectedPlayer(null)
          setMinExp(null)
          setMaxExp(null)
        }}
        style={{top: '30%'}}
        width={'50%'}
      >
        <PlayerExposureTable
          player={
            players.filter(p => p.Id === selectedPlayer)[0]
          }
          tempMinExp={tempMinExp}
          setTempMinExp={setTempMinExp}
          tempMaxExp={tempMaxExp}
          setTempMaxExp={setTempMaxExp}
          permissioned={permissioned}
        />
      </StyledModal>
  </div>
  )
}

const PlayerExposureTable = ({ player, permissioned, tempMinExp, tempMaxExp, setTempMinExp, setTempMaxExp }) => {

  const _cols = [
    {
      title: 'Player',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Proj. Own',
      dataIndex: 'ProjOwn',
      key: 'ProjOwn',
    },
    {
      title: 'Min. Exp',
      key: 'MinExp',
      render: () => {
        return (
          <Input style={{width: '80px'}} value={tempMinExp} onChange={(v) => {
              setTempMinExp(v.target.value)
            }}
          />
        )
      }
    },
    {
      title: 'Max. Exp',
      dataIndex: 'MaxExp',
      render: () => {
        return (
          <Input style={{width: '80px'}} value={tempMaxExp} onChange={(v) => {
              setTempMaxExp(v.target.value)
            }}
          />
        )
      }
    },
    {
      title: 'Avg. Exp',
      key: 'avgExp',
      render: (v) => {
        return (
          <span>{((Number(tempMinExp) + Number(tempMaxExp)) / 2).toFixed(2)}</span>
        )
      }
    },
    {
      title: 'Leverage',
      key: 'leverage',
      render: (v) => {
        const _l = (((Number(tempMinExp) + Number(tempMaxExp)) / 2) - Number(v.ProjOwn)).toFixed(2)
        const _hex = getColorForValue(_l)

        return (
          <div style={{background: _hex, width: '100%', textAlign: 'center'}}>{_l}%</div>
        )
      }
    }
  ]

  if (!player) return (
    <div />
  )

  return (
    <StyledTable
      key={'player-exp-table'}
      bordered
      columns={_cols}
      dataSource={[player]}
      size={'small'}
      pagination={false}
      className={ (!permissioned) ? 'blurry-text' : '' }
      scroll={{x: '150px'}}
      rowKey ={(_row, i) => {
        return `player-exposure-table-pre-analysis-${i}`
      }}
    />
  )
}

const formatTopPlayers = (players, position, showdown) => {
  const _formattedPlayersData = []
  const FLEX_POSITIONS = ['RB', 'WR', 'TE']

  if (showdown) {
    players.forEach((p, i) => {
      if (p.MaxExp > 0 || Number(p.ProjOwn) !== 0) {
        if (p.RosterPosition === position) {
          const avgExp = ((Number(p.MinExp) + Number(p.MaxExp)) / 2).toFixed(2)

          _formattedPlayersData.push({
            ...p,
            avgExp,
            leverage: (Number(avgExp - p.ProjOwn)).toFixed(2)
          })
        }
      }
    })
  } else {
    players.forEach((p, i) => {
      if (p.MaxExp > 0 || Number(p.ProjOwn) !== 0) {
        let _add = false

        if (p.Position.toUpperCase() === position.toUpperCase()) {_add = true}
        else if (position === 'FLEX' && FLEX_POSITIONS.indexOf(p.Position.toUpperCase()) >= 0) {_add = true}
        else if (!position) {_add = true}

        if (_add) {
          const avgExp = ((Number(p.MinExp) + Number(p.MaxExp)) / 2).toFixed(2)

          _formattedPlayersData.push({
            ...p,
            avgExp,
            leverage: (Number(avgExp - p.ProjOwn)).toFixed(2)
          })
        }
      }
    })
  }


  return _formattedPlayersData
}

const cols = (setShowModal, setSelectedPlayer, permissioned, setTempMinExp, setTempMaxExp) => ([
  {
    'title': 'Player',
    'dataIndex': 'Name',
    'key': 'name'
  },
  {
    'title': 'Pos.',
    'dataIndex': 'Position',
    'key': 'position'
  },
  {
    'title': 'Team',
    'dataIndex': 'TeamAbbrev',
    'key': 'team',
    render: (text) => (
      <img src={require(`../../../../../assets/images/nba/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
    ),
  },
  {
    'title': 'Avg. Exposure',
    'dataIndex': 'avgExp',
    'key': 'avgExp',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => Number(b.avgExp - a.avgExp),
  },
  {
    'title': 'Proj. Own',
    'dataIndex': 'ProjOwn',
    'key': 'projOwn',
    sorter: (a, b) => Number(b.ProjOwn - a.ProjOwn),
  },
  {
    'title': 'Leverage',
    'dataIndex': 'leverage',
    'key': 'leverage',
    sorter: (a, b) => Number(b.leverage - a.leverage),
    render: (v, row) => {
      const _hex = getColorForValue(v)
      if (!permissioned) {
        return (
          <span id="default">{v}%</span>
        )
      } else {
        return (
          <HoverableDiv
            style={{background: _hex}}
          >
            <span id="default">{v}%</span>
            <span id="onHover">
              <TransparentButton
                size="small"
                onClick={() => {
                  setShowModal(true)
                  setSelectedPlayer(row.Id)
                  setTempMinExp(row.MinExp)
                  setTempMaxExp(row.MaxExp)
                }}
              >
                Adjust
              </TransparentButton>
            </span>
          </HoverableDiv>
        )
      }
    }
  }
])

export default TopOwnedPlayers
