import { Tooltip, Row, Col, Input, Checkbox } from 'antd'
import styled from 'styled-components'

const InputLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const SettingsInput = styled(Input)`
  .ant-input-group-addon {
    @media (max-width: 1280px) {
      font-size: 12px;
    }
  }
`
const InputContainer = styled.div`
  padding: 20px;
`
// warning div red text
const WarningDiv = styled.div`
  color: red;
  font-size: 12px;
  font-weight: 300;
  margin-lef: 5px;
`

export default ({ updateInputValue, settings, showdown, disabled }) => {
  return (
    <Row>
      <Col lg={10}>
        {
          !showdown ? (
            <div>
              {
                showdown ? '' : (
                  <div>
                    
                  </div>
                )
              }
              
          </div>
          ) : ''
        }
      </Col>
      <Col lg={10} offset={4}>
        {
          !showdown ? (
            <div>
              <InputContainer>
                <Row>
                  
                </Row>
              </InputContainer>
            </div>
          ) : (
            <div>
              <InputContainer>
                
              </InputContainer>
            </div>
          )
        }
      </Col>
    </Row>
  )
}
