const ERROR_THRESHOLD = 2.5

export const validateBuild = (players) => {
  let valid = true

  let lines = {
    'PG': {
      minBelow: 100,
      maxAbove: 100
    },
    'SG': {
      minBelow: 300,
      maxAbove: 200
    },
    'SF': {
      minBelow: 400,
      maxAbove: 300
    },
    'PF': {
      minBelow: 200,
      maxAbove: 100
    },
    'C': {
      minBelow: 700,
      maxAbove: 700
    }
  }
  let totalExposures = {
    'PG': {
      "MinExp": 0,
      "MaxExp": 0
    },
    'SG': {
      "MinExp": 0,
      "MaxExp": 0
    },
    'SF': {
      "MinExp": 0,
      "MaxExp": 0
    },
    'PF': {
      "MinExp": 0,
      "MaxExp": 0
    },
    'C': {
      "MinExp": 0,
      "MaxExp": 0
    }
  }
  const errors = []

  players.forEach(_player => {
    const _minExp = Number(_player.MinExp)
    const _maxExp = Number(_player.MaxExp)
    // if any player has a greater MinExp than MaxExp not valid
    if (_minExp > _maxExp) {
      errors.push(
        `${_player.Name} has a greater Min Exp than Max Exp`
      )
      valid = false
    }

    _player.Positions.forEach(pos => {
      totalExposures[pos].MinExp += _minExp
      totalExposures[pos].MaxExp += _maxExp
    })
  })

  Object.keys(totalExposures).forEach(_position => {
    if (totalExposures[_position].MaxExp < lines[_position].maxAbove) {
      errors.push(
        `${_position} exposure does not meet lineup minimums (${lines[_position].maxAbove})`
      )
      valid = false
    }
    if (totalExposures[_position].MinExp > lines[_position].minBelow) {
      errors.push(
        `${_position} exposure exceeds lineup maximums (${lines[_position].minBelow})`
      )
      valid = false
    }
  })

  return [
    valid,
    errors
  ]
}

export const validateSettings = (players, settings) => {
  const errors = []
  let valid = true

  // Check to ensure that forced stack + forced no stack >= 100
  if (Number(settings.noStackPct) + Number(settings.teamStackPct) > 1) {
    errors.push(`Team Stack Percentage and Forced No Stack Percentage is greater than 100% combined. This may cause the build to fail or produce unexpected results. Go to Team Stack Settings to change.`)
    valid = false
  }

  // Check to see if flex pct > 100
  if ((settings.teFlexPct * 100 + settings.wrFlexPct * 100 + settings.rbFlexPct * 100) < 100) {
    errors.push(`Limit Flex Pct. must be >= 100%. Currently it is ${(settings.teFlexPct * 100 + settings.wrFlexPct * 100 + settings.rbFlexPct * 100).toFixed(0)}%. Go to Build Settings to change.`)
    valid = false
  }

  // Check bracket ownership
  const minRangeInformation = {
    [2.5]: 0,
    [5]: 0,
    [10]: 0,
    [18]: 0,
    [25]: 0
  }
  const maxRangeInformation = {
    [2.5]: 0,
    [5]: 0,
    [10]: 0,
    [18]: 0,
    [25]: 0
  }

  const FLEX_POSITIONS = ['QB', 'RB', 'WR', 'TE']
  const RANGE_KEYS = [2.5, 5, 10, 18, 25]
  players.forEach((p, i) => {
    const _minExp = Number(p.MinExp)
    const _maxExp = Number(p.MaxExp)
    // Should have exposure and be a non defense position
    if (_maxExp > 0 && FLEX_POSITIONS.indexOf(p.Position) >= 0) {
      RANGE_KEYS.forEach(r => {
        if (Number(p.ProjOwn) <= Number(r)) {
          minRangeInformation[r] = minRangeInformation[r] + _minExp
          maxRangeInformation[r] = maxRangeInformation[r] + _maxExp
        }
      })
    }
  })

  // Not enough exposure low end
  const minSettingsKeys = ['minUnderOwn_1', 'minUnderOwn_2', 'minUnderOwn_3', 'minUnderOwn_4', 'minUnderOwn_5']
  minSettingsKeys.forEach((k, i) => {
    const minUnderN = settings[k]

    if (maxRangeInformation[RANGE_KEYS[i]] < (minUnderN * 100)) {
      valid = false
      errors.push((
        <span>
          <b>Bracket Ownership {RANGE_KEYS[i]}% Under-Exposed: </b>
            Need <b>{(minUnderN * 100) - maxRangeInformation[RANGE_KEYS[i]]}%</b> more exposure to players under {RANGE_KEYS[i]}% ownership. May result in inability to have enough lineups within Bracket Ownership ranges, exposure outside of boundaries, or in some cases failed builds.
        </span>
      ))
    }
  })

  // Too much exposure upper end
  const maxSettingsKeys = ['maxUnderOwn_1', 'maxUnderOwn_2', 'maxUnderOwn_3', 'maxUnderOwn_4', 'maxUnderOwn_5']
  maxSettingsKeys.forEach((k, i) => {
    const maxUnderN = settings[k]

    if (minRangeInformation[RANGE_KEYS[i]] > (maxUnderN * 100)) {
      valid = false
      errors.push((
        <span>
          <b>Bracket Ownership {RANGE_KEYS[i]}% Over-Exposed: </b>
            Decrease exposure by <b>{minRangeInformation[RANGE_KEYS[i]] - (maxUnderN * 100)}%</b> for players under {RANGE_KEYS[i]}% ownership. May result in inability to have enough lineups within Bracket Ownership ranges, exposure outside of boundaries, or in some cases failed builds.
        </span>
      ))
    }
  })


  return [
    valid,
    errors
  ]
}

// export const validateTeamStacks = (players, settings, teamStacks) => {
//   let valid = true
//   let errors = []

//   // Setup lookup objects
//   const teamStacksLookup = {}
//   teamStacks.forEach(ts => {
//     teamStacksLookup[ts.TeamAbbrev] = ts
//   })

//   // Player lookups
//   const playerLookupByPosition = {}
//   const playerLookupByTeam = {}
//   players.forEach(p => {
//     if (!playerLookupByTeam[p.TeamAbbrev])
//       playerLookupByTeam[p.TeamAbbrev] = []
//     if (!playerLookupByPosition[p.Position])
//       playerLookupByPosition[p.Position] = []

//     playerLookupByPosition[p.Position].push(p)
//     playerLookupByTeam[p.TeamAbbrev].push(p)
//   })

//   // There should be enough FLEX for stacks and game stacks
//   const teamStackPct = Number(settings.teamStackPct)
//   const gameStackPct = Number(settings.gameStackPct)

//   // game stacks
//   const gameStackWarningGenerated = {}

//   playerLookupByPosition["QB"].forEach(qb => {
//     if (Number(qb.MaxExp) === 0)
//       return
//     // team stacks
//     if (teamStacksLookup[qb.TeamAbbrev].AllowQBStack) {
//       let RBMaxExp = 0
//       let WRMaxExp = 0
//       let TEMaxExp = 0
//       playerLookupByTeam[qb.TeamAbbrev].forEach(p => {
//         const _maxExp = Number(p.MaxExp)

//         if (p.Position === 'RB') {
//           if (teamStacksLookup[qb.TeamAbbrev].AllowRBStack) {
//             RBMaxExp = RBMaxExp + _maxExp
//           }
//         }
//         if (p.Position === 'WR') {
//           if (teamStacksLookup[qb.TeamAbbrev].AllowWRStack) {
//             WRMaxExp = WRMaxExp + _maxExp
//           }
//         }
//         if (p.Position === 'TE') {
//           if (teamStacksLookup[qb.TeamAbbrev].AllowTEStack) {
//             TEMaxExp = TEMaxExp + _maxExp
//           }
//         }
//       })

//       const maxStackedPlayers = RBMaxExp + WRMaxExp + TEMaxExp
//       if ((Number(qb.MinExp) * teamStackPct - maxStackedPlayers).toFixed(1) > ERROR_THRESHOLD) {
//         if (valid)
//           valid = false

//         errors.push(
//           <span>
//             <b>{qb.TeamAbbrev} Under-Exposed Stacks</b>
//             : Need an additional <b>{(Number(qb.MinExp) * teamStackPct - maxStackedPlayers).toFixed(1)}%</b> exposure to stackable players. May lead to fewer stacks than expected, lower exposure to {qb.Name}, or cause the build to fail in some circumstances.
//           </span>
//         )
//       }
//     }

//     if (teamStacksLookup[qb.TeamAbbrev].AllowGameStack && !gameStackWarningGenerated[qb.Opp]) {
//       let OppRBMaxExp = 0
//       let OppWRMaxExp = 0
//       let OppTEMaxExp = 0
//       let OppQBMinExp = 0

//       playerLookupByTeam[qb.Opp].forEach(p => {
//         const _minExp = Number(p.MinExp)
//         const _maxExp = Number(p.MaxExp)

//         if (p.Position === 'QB') {
//           if (teamStacksLookup[p.TeamAbbrev].AllowQBStack) {
//             OppQBMinExp = OppQBMinExp + _minExp
//           }
//         }
//         if (p.Position === 'RB') {
//           if (teamStacksLookup[qb.TeamAbbrev].AllowRBOppStack) {
//             OppRBMaxExp = OppRBMaxExp + _maxExp
//           }
//         }
//         if (p.Position === 'WR') {
//           if (teamStacksLookup[qb.TeamAbbrev].AllowWROppStack) {
//             OppWRMaxExp = OppWRMaxExp + _maxExp
//           }
//         }
//         if (p.Position === 'TE') {
//           if (teamStacksLookup[qb.TeamAbbrev].AllowTEOppStack) {
//             OppTEMaxExp = OppTEMaxExp + _maxExp
//           }
//         }
//       })

//       const maxOppStackedPlayers = OppRBMaxExp + OppWRMaxExp + OppTEMaxExp
//       const minExp = (Number(qb.MinExp) * gameStackPct * teamStackPct) + (OppQBMinExp * teamStackPct)
//       if (minExp - maxOppStackedPlayers > ERROR_THRESHOLD) {
//         if (valid)
//           valid = false

//         gameStackWarningGenerated[qb.Opp] = true

//         errors.push(
//           <span>
//             <b>{qb.TeamAbbrev} Under-Exposed Game Stacks</b>
//             : Need an additional <b>{(minExp - maxOppStackedPlayers).toFixed(1)}%</b> exposure to stackable players from the <b>{qb.Opp}</b>. May lead to fewer game stacks than expected, or cause the build to fail in some circumstances.
//           </span>
//         )
//       }
//     }
//   })

//   // Too many/few players per settings
//   const settingsKeys = ['TotalMaxFlex', 'TotalMaxPlayers', 'TotalMaxRB', 'TotalMaxTE', 'TotalMaxWR']
//   Object.keys(playerLookupByTeam).forEach(teamAbbrev => {
//     const players = playerLookupByTeam[teamAbbrev]
//     const stackSettings = teamStacksLookup[teamAbbrev]

//     // Min exp for team over max settings
//     let minExpFlex = 0
//     let minExpAllPlayers = 0
//     let minExpRB = 0
//     let minExpTE = 0
//     let minExpWR = 0

//     // Max exp over min settings
//     let maxExpFlex = 0
//     let maxExpAllPlayers = 0
//     let maxExpRB = 0
//     let maxExpTE = 0
//     let maxExpWR = 0

//     const FLEX_POSITIONS = ['RB', 'WR', 'TE']
//     players.forEach(p => {
//       const _minExp = Number(p.MinExp)
//       const _maxExp = Number(p.MaxExp)

//       if (FLEX_POSITIONS.indexOf(p.Position) !== -1) {
//         minExpFlex = minExpFlex + _minExp
//         maxExpFlex = maxExpFlex + _maxExp
//       }

//       switch (p.Position) {
//         case 'RB':
//           minExpRB = minExpRB + _minExp
//           maxExpRB = maxExpRB + _maxExp
//           break
//         case 'WR':
//           minExpWR = minExpWR + _minExp
//           maxExpWR = maxExpWR + _maxExp
//           break
//         case 'TE':
//           minExpTE = minExpTE + _minExp
//           maxExpTE = maxExpTE + _maxExp
//           break
//         default:
//           break
//       }

//       // Increment totals
//       minExpAllPlayers = minExpAllPlayers + _minExp
//       maxExpAllPlayers = maxExpAllPlayers + _maxExp
//     })

//     if (stackSettings.TotalMaxFlex * 100 < minExpFlex) {
//       valid = false

//       errors.push(
//         <span>
//           <b>{teamAbbrev} Over-Exposed Flex</b>
//           : Lower FLEX Min. Exposure by {minExpFlex - stackSettings.TotalMaxFlex * 100}% or allow for more FLEX per lineup for this team in the Team Stack Settings. <b>This will cause the build to fail.</b>
//         </span>
//       )
//     }
//     if (stackSettings.TotalMaxPlayers * 100 < minExpAllPlayers) {
//       valid = false

//       errors.push(
//         <span>
//           <b>{teamAbbrev} Over-Exposed Total Players</b>
//           : Lower team exposure by {minExpAllPlayers - stackSettings.TotalMaxPlayers * 100}% or allow for more players per lineup for this team in the Team Stack Settings. <b>This will cause the build to fail.</b>
//         </span>
//       )
//     }
//     if (stackSettings.TotalMaxRBs * 100 < minExpRB) {
//       valid = false

//       errors.push(
//         <span>
//           <b>{teamAbbrev} Over-Exposed RB</b>
//           : Lower RB Min. Exposure by {minExpRB - stackSettings.TotalMaxRBs * 100}% or allow for more RBs per lineup for this team in the Team Stack Settings. <b>This will cause the build to fail.</b>
//         </span>
//       )
//     }
//     if (stackSettings.TotalMaxWRs * 100 < minExpWR) {
//       valid = false

//       errors.push(
//         <span>
//           <b>{teamAbbrev} Over-Exposed WR</b>
//           : Lower WR Min. Exposure by {minExpWR - stackSettings.TotalMaxWRs * 100}% or allow for more WRs per lineup for this team in the Team Stack Settings. <b>This will cause the build to fail.</b>
//         </span>
//       )
//     }
//     if (stackSettings.TotalMaxTEs * 100 < minExpTE) {
//       valid = false

//       errors.push(
//         <span>
//           <b>{teamAbbrev} Over-Exposed TE</b>
//           : Lower TE Min. Exposure by {minExpTE - stackSettings.TotalMaxTEs * 100}% or allow for more TEs per lineup for this team in the Team Stack Settings. <b>This will cause the build to fail.</b>
//         </span>
//       )
//     }

//     // Check if any max is over the min exposure
//     if (stackSettings.TotalMinFlex * 100 > maxExpFlex) {
//       valid = false

//       errors.push(
//         <span>
//           <b>{teamAbbrev} Under-Exposed Flex</b>
//           : Raise FLEX Max Exposure by {stackSettings.TotalMinFlex * 100 - maxExpFlex}% or allow for less FLEX per lineup for this team in the Team Stack Settings. <b>This will cause the build to fail.</b>
//         </span>
//       )
//     }
//     if (stackSettings.TotalMinPlayers * 100 > maxExpAllPlayers) {
//       valid = false

//       errors.push(
//         <span>
//           <b>{teamAbbrev} Under-Exposed Total Players</b>
//           : Raise team exposure by {stackSettings.TotalMinPlayers * 100 - maxExpAllPlayers}% or allow for less players per lineup for this team in the Team Stack Settings. <b>This will cause the build to fail.</b>
//         </span>
//       )
//     }
//     if (stackSettings.TotalMinRBs * 100 > maxExpRB) {
//       valid = false

//       errors.push(
//         <span>
//           <b>{teamAbbrev} Under-Exposed RB</b>
//           : Raise RB Max Exposure by {stackSettings.TotalMinRBs * 100 - maxExpRB}% or allow for less RBs per lineup for this team in the Team Stack Settings. <b>This will cause the build to fail.</b>
//         </span>
//       )
//     }
//     if (stackSettings.TotalMinWRs * 100 > maxExpWR) {
//       valid = false

//       errors.push(
//         <span>
//           <b>{teamAbbrev} Under-Exposed WR</b>
//           : Raise WR Max Exposure by {stackSettings.TotalMinWRs * 100 - maxExpWR}% or allow for less WRs per lineup for this team in the Team Stack Settings. <b>This will cause the build to fail.</b>
//         </span>
//       )
//     }
//     if (stackSettings.TotalMinTEs * 100 > maxExpTE) {
//       valid = false

//       errors.push(
//         <span>
//           <b>{teamAbbrev} Under-Exposed TE</b>
//           : Raise TE Max Exposure by {stackSettings.TotalMinTEs * 100 - maxExpTE}% or allow for less TEs per lineup for this team in the Team Stack Settings. <b>This will cause the build to fail.</b>
//         </span>
//       )
//     }
//   })

//   return [
//     valid,
//     errors
//   ]
// }
