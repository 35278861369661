import React, { Component } from 'react'
import { Row, Col, Card, Menu, Dropdown, Button, Alert, Popconfirm } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CrownOutlined } from '@ant-design/icons'

import * as lineupActions from '../../../../actions/lineup'
import FakurianDesign from '../../../../assets/images/backgrounds/fakurian-design-light.png'

import GeneralSettingsCard from './general-settings-card'
import { cache_settings } from '../../../../utils/sport-settings'
import { SectionHeaderTitle } from '../../../../components/section-header-title'
import { week } from '../../../../utils/nfl-week'

import './Settings.css'

const SettingsContainer = styled.div`
`
const HeadingCard = styled(Card)`
  &.ant-card {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }
`

class Settings extends Component {
  static propTypes = {
    // Redux State goes here
    fetchOpt: PropTypes.func.isRequired,
    updateSettings: PropTypes.func.isRequired,
  }

  state = {
    showUpdateBanner: false
  }

  updateInputValue = (val, key) => {
    this.props.updateSettings( { 
      site: this.props.site, 
      slate: this.props.slate, 
      [key]: val, 
      sport: 'nfl',
      counter: this.props.week, 
      season: this.props.season
     } )
  }

  setPreset = (index) => {
    const _settings = this.props.presets.data[index]

    this.props.updateSettings( { sport: 'nfl', site: this.props.site, slate: this.props.slate, counter: this.props.week, season: this.props.season, ..._settings } )
    cache_settings('nfl', this.props.site, this.props.slate, {
      ..._settings
    })
    this.setState({
      showUpdateBanner: true
    })

    setTimeout(() => {
      this.setState({
        showUpdateBanner: false
      })
    }, 2000)
  }

  render() {
    const { settings } = this.props

    // Check to see if we have no ownership
    let ownership = false
    this.props.players.data.forEach(p => {
      if (Number(p.ProjOwn || 0) !== 0 || Number(p.UserOwn || 0) !== 0) {
        ownership = true
        return
      }
    })

    const noProjOwn = !ownership

    return (
      <div className="Settings">
        <SettingsContainer>
          <HeadingCard style={{backgroundImage: `url(${FakurianDesign})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% auto'}}>
            <Row>
              <Col lg={5}>
                <h1>General Settings</h1>
                <SectionHeaderTitle sport={'nfl'} site={this.props.site} counter={this.props.week} season={this.props.season} changeDateInfo={this.props.changeDateInfo} />
              </Col>
              <Col lg={4}>
                <Button
                  style={{marginTop: '8px'}}
                  onClick={() => this.setPreset(0)}
                  disabled={(this.props.showdown && !this.props.site === 'dk') || (this.props.subscription.permissions && !this.props.subscription.permissions.opt.nfl.historical_data)}
                >
                  Use Presets
                  <span style={{fontSize: '12px', marginLeft: '4px', color: '#ffa500'}}><CrownOutlined /></span>
                </Button>
              </Col>
              <Col lg={4}>
                <Popconfirm
                  cancelText="No"
                  okText="Yes"
                  onClick={(e) => e.stopPropagation()}
                  onCancel={(e) => e.stopPropagation()}
                  onConfirm={
                    (e) => {
                      e.stopPropagation()
                      console.log(this.props.week)
                      this.props.resetSiteSettings({site: this.props.site, slate: this.props.slate, showdown: this.props.showdown, counter: this.props.week, season: this.props.season})

                      this.setState({
                        showUpdateBanner: true
                      })

                      setTimeout(() => {
                        this.setState({
                          showUpdateBanner: false
                        })
                      }, 2000)
                    }
                  }
                  title={`Permenantly clear all saved settings for the ${this.props.slate} slate?`}
                >
                  <Button
                    style={{marginTop: '8px'}}
                  >
                    Reset Settings
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </HeadingCard>
          {
            this.state.showUpdateBanner ? (
              <Alert
                message="Settings updated"
                banner={true}
                type="success"
                style={{textAlign: 'center'}}
              />
            ) : ''
          }
          <GeneralSettingsCard
            settings={settings.data}
            inputOnChange={this.updateInputValue}
            loggedin={this.props.loggedin}
            savedLineupsNumber={this.props.savedLineups.data ? this.props.savedLineups.data.length / 9 : 0}
            permissions={this.props.subscription.permissions}
            site={this.props.site}
            noProjOwn={noProjOwn}
            showdown={this.props.showdown}
          />
        </SettingsContainer>
      </div>
    )
  }
}

export default connect(
  state => ({
    settings: state.lineup.settings,
    loggedin: state.auth.loggedin,
    savedLineups: state.lineup.savedLineups,
    subscription: state.account.subscription,
    presets: state.lineup.presets,
  }),
  {
    fetchOpt: lineupActions.fetchOpt,
    updateSettings: lineupActions.updateSettings,
    resetSiteSettings: lineupActions.resetSiteSettings
  }
)(Settings)
