import { EXP_SETTINGS_KEY } from '../constants/local-storage-keys'
import isNull from './isNull'

const mergePlayerTableDbProjections = (playerTable, dbProj, noPosition=false) => {
    let DEFAULT_MIN_EXP = 0
    let DEFAULT_MAX_EXP = 100
    const exposureDefaults = JSON.parse(localStorage.getItem(EXP_SETTINGS_KEY))

    if (exposureDefaults && exposureDefaults.zeroed)
      DEFAULT_MAX_EXP = 0

    const projectionsMap = {}
    dbProj.forEach((projRow, i) => {
        const [ playerName, rosterPosition ] = projRow.player.name.split("|")

        // FIXME: use IDs for player table
        projectionsMap[projMapKey(
          playerName,
          projRow.player.position,
          projRow.player.team.name,
          rosterPosition || '',
          noPosition
        )] = {
          MinExp: projRow.min_exp,
          MaxExp: projRow.max_exp,
          UserProj: projRow.proj_pts,
          UserOwn: projRow.proj_own,
          UserMins: projRow.minutes
        }
    })

    return playerTable.map(player => {
      const playerKey = projMapKey(player.Name, player.Position, player.TeamAbbrev, player.RosterPosition || '', noPosition)

      if (projectionsMap[playerKey]) {

        return {
          ...player,
          MinExp: (!isNull(projectionsMap[playerKey].MinExp)) ? projectionsMap[playerKey].MinExp : DEFAULT_MIN_EXP,
          MaxExp: (!isNull(projectionsMap[playerKey].MaxExp)) ? projectionsMap[playerKey].MaxExp : DEFAULT_MAX_EXP,
          UserProj: (!isNull(projectionsMap[playerKey].UserProj)) ? projectionsMap[playerKey].UserProj : player.UserProj,
          UserOwn: (!isNull(projectionsMap[playerKey].UserOwn)) ? projectionsMap[playerKey].UserOwn : player.UserOwn,
          HouseProjPts: player.HouseProjPts ? player.HouseProjPts : player.ProjPts,
          HouseOwn: player.HouseOwn ? player.HouseOwn : player.ProjOwn,
          ProjPts: (!isNull(projectionsMap[playerKey].UserProj)) ? projectionsMap[playerKey].UserProj : player.ProjPts,
          ProjOwn: (!isNull(projectionsMap[playerKey].UserOwn)) ? projectionsMap[playerKey].UserOwn : player.ProjOwn,
          UserMins: (!isNull(projectionsMap[playerKey].UserMins)) ? projectionsMap[playerKey].UserMins : player.UserMins,
          HouseMin: player.HouseMin ? player.HouseMin : player.Min,
          Min: (!isNull(projectionsMap[playerKey].UserMins)) ? projectionsMap[playerKey].UserMins : player.Min
        }
      } else {
        return {
          ...player,
          MinExp: DEFAULT_MIN_EXP,
          MaxExp: DEFAULT_MAX_EXP,
        }
      }
    })
}

const projMapKey = (playerName, playerPosition, playerTeamName, rosterPosition, noPosition) => {
  if (noPosition) {
    return `${playerName}-${playerTeamName}-${rosterPosition}`
  } else {
    return `${playerName}-${playerPosition}-${playerTeamName}-${rosterPosition}`
  }
}

export default mergePlayerTableDbProjections
