const mergeOrderTeamStacks = (teamStacks, playersTable, TeamAbbrevMap, sorted=true) => {
  const vegasKeys = ["Opp","OU","Spread","ImpPts"]

  let _updatedTeamStacks = []
  teamStacks.forEach(team => {
    const _team = { ...team }
    const teamRow = playersTable.find((player) => {
      return player.TeamAbbrev === _team.TeamAbbrev
    })
    vegasKeys.forEach(_vk => {
      _team[_vk] = teamRow[_vk]
    })

    _updatedTeamStacks.push(_team)
  })
   if (sorted) {
      _updatedTeamStacks.sort((a,b) => {
        try {
          return TeamAbbrevMap[a.TeamAbbrev].localeCompare(TeamAbbrevMap[b.TeamAbbrev])
        } catch(e) {
          console.log(a.TeamAbbrev)
          throw Error()
        }
      })
   }

  return _updatedTeamStacks
}

export default mergeOrderTeamStacks
