import React, { Component } from 'react'
import { Table, Tooltip, Input, Checkbox, Menu, Popconfirm, Button, Row, Col } from 'antd'
import styled from 'styled-components'

import { nbaTeamNames as TeamToAbbrev } from '../../../../utils/team-name-to-abbrev'

const TeamStackOptions = styled.div`
`

const ToggleHeader = styled.div`
  background: white;
  width: 100%;
`

const StyledInputNumber = styled(Input)`
  &.ant-input {
    width: 40px !important;
  }
`
const StyledInputNumberLong = styled(Input)`
  &.ant-input {
    width: 70px !important;
  }
`

const StyledCheckbox = styled(Checkbox)`
  > .ant-checkbox-wrapper + span, .ant-checkbox + span {
    padding-right: 0;
    padding-left: 5px;
  }

  input {
    appearance: none;
  }

  &:checked {
    background: #ffa500;
  }
`
const StyledCheckboxWhite = styled(StyledCheckbox)`
  color: white !important;
`

const StyledTable = styled(Table)`
  td {
    text-align: center;
  }

  tr th {
    text-align: center !important;
  }

  tr:first-child {
    th {
      color: white !important;
      background: #2B404F !important;
    }
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  tr.ant-table-row-level-0:hover > td.team-col {
    background-color: rgba(0,0,0,0.05) !important;
  }

  td.team-col {
    background-color: rgba(0,0,0,0.05) !important;
  }
`
const StyledButtonCol = styled(Col)`
  height: 48px;
  background: white;

  button {
    margin-top: 8px;
  }
`

const CheckBoxInput = ({row, _var, _onChange, children}) => (
  <StyledCheckbox
    checked={row[_var]}
    onClick={(e) => _onStackingChange(row, _var, e.target.checked, _onChange)}
  >{children}</StyledCheckbox>
)

const NumberInput = ({row, _var, _onChange, children}) => (
  <StyledInputNumber
    placeholder="0"
    value={Number(row[_var]) > 0 ? row[_var] : ''}
    onChange={(e) => _onStackingChange(row, _var, Number(e.target.value), _onChange)}
  >{children}</StyledInputNumber>
)

const NumberInputLong = ({row, _var, _onChange, children}) => (
  <StyledInputNumberLong
    placeholder="0"
    value={Number(row[_var]) > 0 ? row[_var] : ''}
    onChange={(e) => _onStackingChange(row, _var, Number(e.target.value), _onChange)}
  >{children}</StyledInputNumberLong>
)

const _onStackingChange = (row, key, value, _onChange) => {
  const _row = {
    ...row,
    [key]: value
  }
  _onChange(_row)
}

const teamGameData = [
  {
    title: 'Team',
    dataIndex: 'TeamAbbrev',
    key: 'TeamAbbrev',
    className: 'team-col',
    width: 80,
    render: (text) => (
      <img src={require(`../../../../assets/images/nba/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
    ),
  },
  {
    title: 'Opp',
    dataIndex: 'Opp',
    key: 'opp',
    width: 80,
    render: (text) => (
      <img src={require(`../../../../assets/images/nba/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
    ),
  },
  {
    title: (
      <Tooltip placement="top" title={"Projected total points for the game"}>
        <span>OU</span>
      </Tooltip>
    ),
    dataIndex: 'OU',
    key: 'ou',
    width: 60,
  },
  {
    title: (
      <Tooltip placement="top" title={"Projected amount team will win or lose by (favored team is negative)"}>
        <span>Sprd</span>
      </Tooltip>
    ),
    dataIndex: 'Spread',
    key: 'spread',
    width: 60,
  },
  {
    title: (
      <Tooltip placement="top" title={"Projected total points for the team"}>
        <span>Pts</span>
      </Tooltip>
    ),
    dataIndex: 'ImpPts',
    key: 'currImpPt',
    width: 60,
  }
]

const teamContraints = (onTeamStacksChange, onChangeAllTeamStackOption) => [
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Min/Max total players from this team in the same lineup"}>
          <span>Total Players</span>
        </Tooltip>
        <div>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMinPlayers', Number(e.target.value))}
          ></StyledInputNumber>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMaxPlayers', Number(e.target.value))}
          ></StyledInputNumber>
        </div>
      </div>
    ),
    key: 'totalPlayers',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInput row={row} _var={'TotalMinPlayers'} _onChange={onTeamStacksChange} />
          <NumberInput row={row} _var={'TotalMaxPlayers'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  },
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Salary range used for this team"}>
          <span>Total Salary</span>
        </Tooltip>
        <div>
          <StyledInputNumberLong
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('MinTeamSal', Number(e.target.value))}
          ></StyledInputNumberLong>
          <StyledInputNumberLong
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('MaxTeamSal', Number(e.target.value))}
          ></StyledInputNumberLong>
        </div>
      </div>
    ),
    key: 'totalSalary',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInputLong row={row} _var={'MinTeamSal'} _onChange={onTeamStacksChange} />
          <NumberInputLong row={row} _var={'MaxTeamSal'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  },
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Salary range used for this game"}>
          <span>Game Salary</span>
        </Tooltip>
        <div>
          <StyledInputNumberLong
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('MinGameSal', Number(e.target.value))}
          ></StyledInputNumberLong>
          <StyledInputNumberLong
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('MaxGameSal', Number(e.target.value))}
          ></StyledInputNumberLong>
        </div>
      </div>
    ),
    key: 'gameSalary',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInputLong row={row} _var={'MinGameSal'} _onChange={onTeamStacksChange} />
          <NumberInputLong row={row} _var={'MaxGameSal'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  }
]

class StackTable extends Component {
  state = {
    toggle: this.props.showdown ? 1 : 0
  }

  render() {
    const extraCols = [
      teamContraints(this.props.onChangeHandler, this.props.onChangeAllTeamStackOption),
    ][this.state.toggle]

    return (
      <TeamStackOptions>
        <ToggleHeader>
          <Row>
            <Col lg={12}>
              <Menu onClick={(e) => this.setState({
                  toggle: e.key
                })} selectedKeys={[String(this.state.toggle)]} mode="horizontal">
                <Menu.Item key={0}><Tooltip placement={'top'} title={'Set global Min/Max settings for an entire team'}>Team Constraints</Tooltip></Menu.Item>
              </Menu>
            </Col>
            <StyledButtonCol lg={12}>
              <div style={{float: 'right'}}>
                <Popconfirm
                  cancelText="No"
                  okText="Yes"
                  onClick={(e) => e.stopPropagation()}
                  onCancel={(e) => e.stopPropagation()}
                  onConfirm={
                    (e) => {
                      e.stopPropagation()
                      this.props.clearStackSettings()
                    }
                  }
                  title={`Permenantly clear all settings for this slate?`}
                >
                  <Button>Reset to Defaults</Button>
                </Popconfirm>
              </div>
            </StyledButtonCol>
          </Row>
        </ToggleHeader>
        <StyledTable
          columns={teamGameData.concat(extraCols)}
          dataSource={this.props.rows}
          pagination={{ pageSize: 50 }}
          bordered
          loading={this.props.loading}
          size='small'
          rowKey={_row => String(_row.TeamAbbrev)}
          scroll={{ y: 700, x: '90%' }}
        />
    </TeamStackOptions>
    )
  }
}

export default StackTable
