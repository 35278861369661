import { getLineupSize } from './contests'

export const positions = ['QB', 'RB1', 'RB2', 'WR1', 'WR2', 'WR3', 'TE', 'FLEX', 'DST']

export const getPositionsClassic = (site, sport) => {
  if (sport === 'nfl')
    return positions
  else if (sport === 'nba') {
    if (site === 'dk')
      return ['pg1', 'sg1', 'sf1', 'pf1', 'c', 'g', 'f', 'util']
    else if (site === 'fd')
      return ['pg1', 'pg2', 'sg1', 'sg2', 'sf1', 'sf2', 'pf1', 'pf2', 'c']
  } else if (sport === 'mlb') {
    if (site === 'dk') {
      return ['p1', 'p2', 'c', '1b', '2b', 'ss', '3b', 'of1', 'of2', 'of3']
    }
  }

  throw Error(`no positions for ${site} : ${sport}`)
}

export const getPositionsShowdown = (site, sport) => {
  const positions = ['CPT']
  const lineupSize = getLineupSize(site, 'showdown', sport)
  for (let i = 1; i < lineupSize; i++) {
    positions.push(`FLEX${i}`)
  }

  return positions
}

export const normalizeLineup = (lu, site="dk", showdown=false, sport='nfl') => {
  if (!lu) return {}
  const _normalizedLU = {}
  const lineupSize = getLineupSize(site, showdown ? 'showdown' : 'classic', sport)
  const _positions = getPositionsClassic(site, sport)

  if (showdown) {
    const numFlex = lineupSize - 1
    for (let i = 0; i < lineupSize; i++) {
      if (i === 0)
        _normalizedLU['CPT'] = lu[i]
      else
        _normalizedLU[`FLEX${i}`] = lu[i]
    }
  } else {
    for (let i = 0; i < lineupSize; i++) {
      _normalizedLU[_positions[i]] = lu[i]
    }
  }

  return _normalizedLU
}