import { Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'

const NewBanner = styled.div`
  color: #fff;
  background-color: #ff595e;
  padding: 2px;
  font-size: 7px;
  border-radius: 2px;
`
const NewBannerContainer = styled.div`
  display: inline-block;
  margin-right: 4px;
  line-height: 10px;
`

export default ({helpText}) => (
  <NewBannerContainer>
    <Tooltip title={helpText}>
      <NewBanner>NEW</NewBanner>
    </Tooltip>
  </NewBannerContainer>
)
