/**
 * Make sure all user inputs are correctly typed
 */
export const sanitizePlayerTable = (playerTable) => {
  return playerTable.map(playerRow => {
    return sanitizePlayerTableRow(playerRow, false, true)
  })
}

export const sanitizePlayerTableRow = (tableRow, showdown=false, skipNameConcat=false) => {
  const _tableRow = { ...tableRow }
  if (_tableRow.UserProj === '') _tableRow.UserProj = null
  if (_tableRow.UserOwn === '') _tableRow.UserOwn = null
  _tableRow.DKPts = Number(_tableRow.ProjPts)
  _tableRow.MinExp = Number(_tableRow.MinExp)
  _tableRow.MaxExp = Number(_tableRow.MaxExp)
  _tableRow.ProjOwn = Number(_tableRow.ProjOwn)
  _tableRow.Min = Number(_tableRow.Min)
  _tableRow.Salary = Number(_tableRow.Salary)
  _tableRow.TimeRank = Number(_tableRow.TimeRank || 1)
  _tableRow.Ceiling = Number(_tableRow.Ceiling || 0)

  // differentiate players by Roster Position
  if (_tableRow.RosterPosition && !skipNameConcat)
    _tableRow.Name = `${_tableRow.Name}|${_tableRow.RosterPosition}`

  return _tableRow
}

const intToPct = (i) => {
  return Number(i) / 100
}

/**
 * Make sure General settings are within bounds and correctly typed
 */
const nonNumberSettings = ['slate', 'site', 'sport', 'projOwnRandInput', 'projPtsRandInput', 'projPtsRandQB', 'projPtsRandRB', 'projPtsRandWR', 'projPtsRandTE', 'projPtsRandDST', 'OverMax_Sensitivity', 'contestType', 'specificStacks', 'stackSettingMode', 'projPtsRandHitter', 'projPtsRandPitcher']
const nonStandardSettings = ['ceilingPct', 'allowDefenseVsTeam']

const nonStandardHandler = {
  'ceilingPct': intToPct,
  'allowDefenseVsTeam': (v) => Boolean(v)
}

export const sanitizeGeneralSettings = (generalSettingsObject) => {
  const keys = Object.keys(generalSettingsObject)

  keys.forEach(_key => {
    if (nonStandardSettings.indexOf(_key) !== -1)
      generalSettingsObject[_key] = nonStandardHandler[_key](generalSettingsObject[_key])
    else if (nonNumberSettings.indexOf(_key) === -1)
      generalSettingsObject[_key] = Number(generalSettingsObject[_key])
  })

  return generalSettingsObject
}

// export const normalizeLineup = (lu) => {
//   const _lu = []
//   const keys = Object.keys(lu)
//   for (let i = 0; i < Object.keys(lu[keys[0]]).length; i++) {
//     const _player = {}
//     for (let key of keys) {
//       _player[key] = lu[key][i]
//     }
//
//     _lu.push(_player)
//   }
//
//   return _lu
// }

/**
 * 'Id', 'Name', 'Position', 'Team', 'Group', 'minGroup', 'maxGroup'
 */
export const sanitizePlayerGroups = (players, groups) => {
  const _groupPlayers = []
    Object.keys(groups).forEach(groupNumber => {
    // Skip inactive groups
    if (groups[groupNumber].active) {
      const _group = groups[groupNumber]
      groups[groupNumber].players.forEach(id => {
        const _player = {}
        // Get player data
        const p = players.filter(player => player.Id === id)[0]

        _player["Id"] = id
        _player["Name"] = p.Name
        _player["Team"] = p.TeamAbbrev
        _player["Group"] = Number(_group.ID) + 1
        _player["minGroup"] = Number(_group.minGroup)
        _player["maxGroup"] = Number(_group.maxGroup)
        _player["Pct"] = Number(_group.pctGroup || 1)
        _player["KeyPlayer"] = _group.anchorPlayerID === p.Id ? 1 : 0

        _groupPlayers.push(_player)
      })
    }
  })

  return _groupPlayers
}
