import moment from 'moment-timezone'

export function calcESTDay() {
  const d = new Date()
  const utc = d.getTime() + (d.getTimezoneOffset() * 60000)
  const nd = new Date(utc + (3600000*-5)) // Timezone EST is -5 (or not depending on daylight savings but close enough)
  return nd
}

export const defaultDate = () => {
  return '2023-01-13'
  // const timeInEST = moment().tz('America/Los_Angeles').format('YYYY-MM-DD')

  // return timeInEST
  // const offset = d.getTimezoneOffset()
  // console.log(new Date(d.getTime() - (offset*60*1000)))
  // return d.toISOString().split('T')[0]
}