import { Card, Row, Col, Spin, Divider, Radio, Space, Input } from 'antd'
import {
  CheckOutlined,
  LeftOutlined
} from '@ant-design/icons'
import React, { Component } from 'react'
import styled from 'styled-components'
import { CardElement, injectStripe } from 'react-stripe-elements'
import PlanCard from '../../components/plan-card'
import TOS from '../../assets/agreements/tos.pdf'
import Privacy from '../../assets/agreements/privacy.pdf'

const Terms = styled.a`
  text-decoration: none;
  margin: 0px 2px;
`
const StyledButton = styled.button`
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  cursor: pointer;
  margin-top: 50px;
  width: 100%;
  height: 30px;

  :disabled {
    cursor: auto;
    background-color: #DCDCDC;
    border: none;
  }
`
const StyledError = styled.div`
  color: red;
  margin-top: 10px;
`
const StyledHeading = styled.div`
  color: #1d3557;
  font-size: 36px;
  margin: 10px 0px 20px 0px;
`
const TextBlock = styled.div`
  font-size: 14px;
  margin: 10px 0px;
`
const ErrorText = styled.div`
  color: #bb0000;
`
const BackDiv = styled.div`
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  color: #808080;
`

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
}

class CheckoutForm extends Component {
  state = {
    errorMessage: '',
    yearly: false,
    promo: ''
  }

  constructor(props) {
    super(props)
  }

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  }

  handleSubmit = (evt) => {
    evt.preventDefault()
    if (this.props.plan.name === 'Free' || this.props.account.subscription.last4)
      this.props.handleResult(null, this.state.yearly, this.state.promo)
    else if (this.props.stripe) {
      this.props.stripe.createToken().then((sub) => {this.props.handleResult(sub, this.state.yearly, this.state.promo)})
    } else {
      console.log("Stripe.js hasn't loaded yet.")
    }
  }

  handleBack = () => {
    window.location = window.location.protocol + "//" + window.location.host + "/plans"
  }

  render() {
    return (
      <div>
        <BackDiv onClick={() => {
          this.handleBack()
        }}>
          <LeftOutlined /> Back
        </BackDiv>
        <Card>
          <StyledHeading>Checkout</StyledHeading>
          <TextBlock style={{fontSize: '18px'}}>You have chosen the <b>{this.props.plan.title}</b> plan.</TextBlock>
          <Row style={{margin: '15px 0px'}}>
            <Col lg={12}>
              <Row>
                {
                  this.props.plan.name === 'Free' ? (
                    <div>
                      Choosing this plan will cancel your current subscription. Your current plan will run through the end of the current pay period, and you will still be able to access all premium features until the next pay period.
                    </div>
                  ) : (
                    <Col lg={16}>
                      <PlanCard
                        title={this.props.plan.title}
                        basePrice={this.props.plan.basePrice}
                        baseDiscount={this.props.plan.baseDiscount}
                        seasonLongPrice={this.props.plan.seasonLongPrice}
                        seasonLongDiscount={this.props.plan.seasonLongDiscount}
                        details={this.props.plan.details}
                        description={this.props.plan.description}
                        borderColor={this.props.plan.borderColor}
                        sportIcon={this.props.plan.sportIcon}
                      />
                    </Col>
                  )
                }
              </Row>
              <Row>
                {
                  this.props.plan.name === 'Free' ? (
                    <div />
                  ) : (
                    <div>
                      <TextBlock style={{margin: '20px 0px'}}>Plans will be automatically charged every month on the day that the subscription was created (today). You can cancel your subscription at any time by downgrading to the Free plan.</TextBlock>
                      <TextBlock style={{margin: '20px 0px'}}>Plans created in the offseason will not be charged until Sept 1.</TextBlock>
                    </div>
                  )
                }
              </Row>
            </Col>
            {
              this.props.plan.name === 'Free' ? (
                <Col lg={24}>
                  <StyledButton style={{width: '33%'}} onClick={this.handleSubmit}>Cancel Plan</StyledButton>
                </Col>
              ) : (
                <Col lg={11} offset={1}>
                  <Card>
                    <h2>Select Plan</h2>
                    <Radio.Group
                      value={'monthly'}
                      onChange={(v) => {
                        // let yearly = true
                        // if (v.target.value === 'monthly') {
                        //   yearly = false
                        // }
                        // this.setState({
                        //   yearly
                        // })
                      }}
                    >
                      <Space direction="vertical">
                        <Radio disabled={this.props.account.subscription.yearly} value={'monthly'}>Monthly - ${this.props.plan.basePrice - (this.props.plan.basePrice * (this.props.plan.baseDiscount || 0)).toFixed(2)}/mo</Radio>
                      </Space>
                    </Radio.Group>
                  </Card>
                  <div style={{margin: '10px'}} />
                  <Card>
                    <h3>Promo Code</h3>
                    <Input
                      style={{width: '150px'}}
                      type="text"
                      onChange={(v) => {
                        this.setState({
                          promo: v.target.value
                        })
                      }}
                      onBlur={() => {
                        this.props.checkPromoCode(this.state.promo)
                      }}
                    />
                    {
                      this.props.promoCode.valid ? (
                        <CheckOutlined style={{color: 'green', marginLeft: '10px'}} />
                      ) : ''
                    }
                    {
                      this.props.promoCode.valid === false ? (
                        <div style={{color: 'red'}}>Invalid promo code</div>
                      ) : ''
                    }
                  </Card>
                  <div style={{margin: '10px'}} />
                  <form onSubmit={this.handleSubmit.bind(this)}>
                    <Card>
                      <h2 style={{marginBottom: '20px'}}>Card Details</h2>
                      {
                        this.props.account.subscription.last4 ? (
                          <div>
                            <div>
                              **** **** **** { this.props.account.subscription.last4 }
                            </div>
                            <div style={{marginTop: '20px'}}>
                              You can update your billing information in My Account
                            </div>
                          </div>
                        ) : (
                          <CardElement
                            onChange={this.handleChange}
                            {...createOptions()}
                          />
                        )
                      }
                      <StyledError>
                        {this.state.errorMessage}
                      </StyledError>
                    </Card>
                    {
                      this.props.plan.name !== 'Free' ? (
                        <div>
                          <Divider />
                          <div>By subscribing to this service you acknowledge that you have read and understand our <Terms href={Privacy}>Privacy Policy</Terms> and <Terms href={TOS}>Terms of Service</Terms></div>
                        </div>
                      ) : ''
                    }
                    <Col lg={16} offset={4}>
                      <StyledButton disabled={this.props.loading} type="submit">{this.props.loading ? (<Spin />) : 'Pay'}</StyledButton>
                    </Col>
                  </form>
                </Col>
              )
            }
          </Row>
          <Row>
            { this.props.error ? (
              <ErrorText>{this.props.error}</ErrorText>
            ) : ''}
          </Row>
        </Card>
      </div>
    )
  }
}

export default injectStripe(CheckoutForm)
