import { Select } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import * as lineupActions from '../actions/lineup'

const SlatesSelect = ({slates, slate, site, sport, counter, season, fetchSlates, updateSlate}) => {
  useEffect(() => {
    if (sport === 'nfl' && counter && season) {
      fetchSlates({site: site, counter: counter, season: season })
    }
  }, [counter, season])

  let _slates = {
    "classic": ["Main"]
  }
  if (slates.data)
    _slates = slates.data

  return (
    <div>
      <Select style={{width: '200px'}} onChange={(slate) => updateSlate(slate, season, counter)} value={slate}>
        {
          _slates &&
          _slates.classic &&
          _slates.classic.map(_slate => {
            let _displayName = _slate

            return (
              <Select.Option key={_slate} value={`classic|${_slate}`}>{_displayName}</Select.Option>
            )
          })
        }
        {
          _slates &&
          _slates.showdown &&
          _slates.showdown.map(_slate => {
            let _displayName = _slate

            return (
              <Select.Option key={_slate} value={`showdown|${_slate}`}>{_displayName}</Select.Option>
            )
          })
        }
      </Select>
    </div>
  )
}

export default connect(
  state => ({
    slates: state.lineup.slates
  }),
  {
    fetchSlates: lineupActions.fetchSlates,
  }
)(SlatesSelect)
