import { combineReducers } from 'redux'
import { routerReducer as router } from 'react-router-redux'
import { reducer as form } from 'redux-form'

import lineup from './lineup'
import sockets from './sockets'
import auth from './auth'
import account from './account'
import analysis from './analysis'
import groups from './groups'
import afterStart from './after-start'
// Export root reducer
export default combineReducers({
  router,
  form,
  lineup,
  sockets,
  auth,
  account,
  analysis,
  groups,
  afterStart
})
