import { Button, Row, Col, Card, Input, Skeleton, Tooltip, Menu, Select, Switch, Spin } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Plot from 'react-plotly.js'

import TagPlayer from '../../components/tag-player'
import OnOffSummaryTable from '../../components/on-off-summary-table'
import OnOffGamesTable from '../../components/on-off-games-table'
import OnOffValuesTable from '../../components/on-off-values-table'
import OffListTables from './offListTables'
import * as analysisActions from '../../actions/analysis'
import { nbaTeamNames as TeamToAbbrev } from '../../utils/team-name-to-abbrev'
import { fuzzyObjSearch } from '../../utils/search'

const MenuRow = styled(Row)`
  margin: 10px 0px;
`
const Legend = styled.div`
  margin-left: 50px;
`
const LegendTitle = styled.div`
  display: inline-block;
`
const LegendOn = styled.div`
  display: inline-block;
  height: 10px;
  width: 10px;
  background: #9999FF;
  margin: 0px 8px;
`
const LegendOff = styled.div`
  display: inline-block;
  height: 10px;
  width: 10px;
  background: #3D3D66;
  margin: 0px 8px;
`
const BuildButton = styled(Button)`
  background-color: #1890ff !important;
  color: white !important;
  margin: 0px 10px 15px 10px;
  width: 140px;
  display: inline-block;

  &:disabled {
    background-color: #eaecef !important;
    color: gray !important;
  }
`
const StyledSwitch = styled(Switch)`
`
const SwitchCol = styled(Col)`
  .ant-switch {
    background-color: #1890ff;
    background-image: none;
  }
  .ant-switch-checked {
    background-color: #ffa500;
  }
`
const StyledSearch = styled.div`
  font-size: 16px;

  input {
    height: 32px;
  }
`

const GamesTableMenuItems = [
  {
    label: 'Minutes',
    key: 'Mins',
  },
  {
    label: 'DK Pts',
    key: 'DKPts',
  },
  {
    label: 'FD Pts',
    key: 'FDPts',
  },
  {
    label: 'DK PPM',
    key: 'DKPtsPer'
  },
  {
    label: 'FD PPM',
    key: 'FDPtsPer'
  },
  {
    label: 'Usage',
    key: 'Usage'
  }
]

const SummaryTableMenuItems = [
  {
    label: 'DK',
    key: 'DK'
  },
  {
    label: 'FD',
    key: 'FD'
  }
]

const TopMenuItems = [
  {
    label: 'Value Report',
    key: 'all'
  },
  {
    label: 'Team On/Off',
    key: 'team'
  }
]

class OnOff extends Component {
  state = {
    players: [],
    teamAbbrev: null,
    slate: 'Main',
    sameTeam: true,
    showCurTeam: true,
    gameTableDisplayKey: 'Mins',
    summaryTableDisplayKey: 'DK',
    topMenu: 'all',
    masterOffList: [],
    valueSite: 'DK',
    searchInput: '',
    minuteCutoff: 20
  }

  componentDidUpdate(prevProps, prevState) {
    // refresh player table for site
    if (prevState.valueSite !== this.state.valueSite) {
      this.props.refreshPlayerTable(this.state.valueSite.toLowerCase())
    }

    // load values once player off table
    // if (prevProps.players.length === 0 && this.props.players.length > 0) {
    //   let pt = this.props.players
    //   if (!this.props.subscription.permissions || !this.props.subscription.permissions.opt.nfl.projections) {
    //     pt = null
    //   }
    //   this.props.fetchOffList({ playerTable: pt })
    // }

    if ((prevState.valueSite !== this.state.valueSite)) {
      // remove selected players
      this.setState({
        players: []
      })
    }
  }

  fetchTeamOnOff() {
    const _players = this.props.players.filter(p => (this.state.players.indexOf(p.Id) >= 0))
    const playerNames = _players.map(p => p.Name)
    let pt = this.props.players
    if (!this.props.subscription.permissions.opt.nfl.projections) {
      pt = null
    }
    this.props.fetchOnOff({ players: playerNames, team: _players[0].TeamAbbrev, playerTable: pt })
  }

  setMasterOffList(l) {
    this.setState({
      masterOffList: l
    })
  }

  filterData(_playerData) {
    const _sortedData = []

    const result = _playerData.reduce(function (r, a) {
        r[a.PlayerName] = r[a.PlayerName] || [];
        r[a.PlayerName].push(a)
        return r
    }, Object.create(null))

    // Add y entires
    for (let key in result) {
      const _onDataPoints = result[key].filter(r => (r.Status === 'TmPlay'))
      const _offDataPoints = result[key].filter(r => (r.Status === 'TmNoPlay'))
      _sortedData.push({
        x: _onDataPoints.map(r => r.PlayerName),
        y: _onDataPoints.map(r => r.DKPts),
        name: 'On',
        marker: {color: '#9999FF'},
        type: 'box',
        width: 0.4,
      })
      _sortedData.push({
        x: _offDataPoints.map(r => r.PlayerName),
        y: _offDataPoints.map(r => r.DKPts),
        name: 'Off',
        marker: {color: '#3D3D66'},
        type: 'box',
        width: 0.2,
      })
    }

    return _sortedData
  }

  setPlayer(value) {
    const _player = this.props.players.filter(a => a.Name === value)

    this.setState({
      player: _player[0]
    })
  }

  filterPlayersByTeam(data, teamAbbrev) {
    return data.filter(
      a => a.Team === teamAbbrev
    )
  }

  getOnOffSummaryData() {
    if (!this.props.onOff.data.summary) return []

    return this.props.onOff.data.summary.map((player, i) => {
      const _playerCopy = {...player}
      _playerCopy.Name = (
        <div><TagPlayer log={i===0} clickable={true} name={player.PlayerName}/> | {player.PlayerName}</div>
      )
      return _playerCopy
    })
  }

  getSelectOptions() {
    if (this.state.players.length === 0) {
      return this.props.players.map((p, i) => {
        return (
          <Select.Option key={i} value={p.Id}>{p.Name} - {p.Position} | {TeamToAbbrev[p.TeamAbbrev]}</Select.Option>
        )
      })
    } else {
      const _p = this.props.players.filter(p => (p.Id === this.state.players[0]))[0]
      try {
        return this.props.players.filter(p => p.TeamAbbrev === _p.TeamAbbrev).map((p, i) => { 
          return (
            <Select.Option key={i} value={p.Id}>{p.Name} - {p.Position} | {TeamToAbbrev[p.TeamAbbrev]}</Select.Option>
          )
        })
      } catch (e) {
        console.log(_p)
      }
    }
  }

  generateReport() {
    let pt = this.props.players
    let updatedAt = this.props.updatedAt
    if (!this.props.subscription.permissions.opt.nfl.projections) {
      pt = null
      updatedAt = null
    }

    this.props.fetchOnOffValues({ playerTable: pt, updatedAt, offList: this.state.masterOffList, site: this.state.valueSite, minMinutes: this.state.minuteCutoff })
  }

  _filterDataForSearchTerm = (data, searchTerm) => {
    return data.filter(obj => fuzzyObjSearch(searchTerm, obj))
  }

  _search(value) {
    this.setState({
      searchInput: value
    })
  }
  

  render() {
    const _data = this.filterData(this.props.onOff.data.game || [])

    const { searchInput } = this.state

    let valuesData = this.props.onOffValues.data
    if (searchInput && valuesData && valuesData.length)
      valuesData = this._filterDataForSearchTerm(valuesData, searchInput)

    return (
      <div>
        <Card>
          <h1 style={{padding: '20px 0px 10px 0px'}}>NBA On/Off Metrics. Get the Full Picture.</h1>
          <h4>The <i>Value Report</i> incorporates active vs. inactive data and calculates which players have the most value in the current slate. You can then use the <i>Team On/Off</i> to deep dive into every player.</h4>
        </Card>
          {
            this.props.onOff.data ? (
              <Card style={{'backgroundColor': '#fff'}}>
                <Menu mode="horizontal" items={TopMenuItems} selectedKeys={[this.state.topMenu]} onClick={(e) => {
                  this.setState({
                    topMenu: e.key
                  })
                }}/>
                {
                  this.state.topMenu === 'all' ? (
                    <Skeleton loading={!this.props.onOff.data || this.props.subscription.loading} style={{marginTop: '20px'}} >
                      <div style={{padding: '30px 20px 10px 10px'}}>
                        <p>The Value Table shows you which players have the most value in the current slate based on projected points, salary and the characteristics of previous winning GPP lineups. This tool answers a vital question for GPP contests: when a player is ruled out, which of their teammates are the most valuable in the current slate?</p>
                      </div>
                      <Card hoverable={true}>
                        <OffListTables fetchOffList={this.props.fetchOffList} subscription={this.props.subscription} offList={this.props.offList.data} offListLoading={this.props.offList.loading} refreshPlayerTable={this.props.refreshPlayerTable} playerTable={this.props.players} playerTableLoading={this.props.loading} setMasterOffList={this.setMasterOffList.bind(this)} site={this.state.valueSite.toLowerCase()} />
                      </Card>
                      <Card hoverable={true}>
                        <h3 style={{marginBottom: '25px'}}>Today's Top Values</h3>
                        <Row>
                          <SwitchCol lg={4} md={6}>
                            <StyledSwitch
                              checkedChildren="FanDuel"
                              unCheckedChildren="DraftKings"
                              checked={this.state.valueSite === 'FD'}
                              onClick={(e) => {this.setState({
                                valueSite: e ? 'FD' : 'DK'
                              })}}/>
                          </SwitchCol>
                          <Col lg={8} md={10}>
                            <StyledSearch>
                              <Input.Search
                                placeholder="search..."
                                onChange={(e) => this._search(e.target.value)}
                                style={{ width: '80%' }}
                              />
                            </StyledSearch>
                          </Col>
                          <Col lg={8} md={8}>
                            <span>
                              <Tooltip title="Only include players who have played at least X minutes without the Inactive Players on the court.">
                                <QuestionCircleOutlined style={{marginRight: '5px', color: '#ffa500'}} />
                              </Tooltip>
                              Minutes Cutoff 
                              <Input 
                                style={{width: '70px', marginLeft: '10px'}} 
                                type="number" 
                                value={this.state.minuteCutoff}
                                onChange={(e) => {
                                  this.setState({
                                    minuteCutoff: Number(e.target.value)
                                  })
                                }}
                              />
                            </span>
                          </Col>
                          <Col lg={4} md={24}>
                            <div style={{float: 'right'}}>
                              <BuildButton 
                                onClick={() => {
                                  this.generateReport()
                                }}
                                disabled={!this.state.masterOffList || this.state.masterOffList.length === 0}
                              >
                                  Run Report
                              </BuildButton>
                            </div> 
                          </Col>
                        </Row>
                        
                        <OnOffValuesTable onOffValues={valuesData} loading={this.props.onOffValues.loading} site={this.state.valueSite} players={this.props.onOffValues.players} />
                      </Card>
                      
                    </Skeleton>
                  ) : (
                    <Skeleton loading={!this.props.onOff.data || this.props.loading}>
                      <MenuRow>
                        <Col lg={10} md={18}>
                          {
                          //   <AutoComplete
                          //   style={{ width: 200 }}
                          //   dataSource={_playerNames}
                          //   placeholder="Enter Player Name"
                          //   onSelect={this.setPlayer.bind(this)}
                          //   filterOption={(inputValue, option) =>
                          //     option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          //   }
                          // />
                          }
                          <Select
                            showSearch
                            mode="multiple"
                            size="large"
                            style={{ width: '80%', marginTop: '15px' }}
                            placeholder="Search Player"
                            optionFilterProp="children"
                            value={this.state.players}
                            onChange={(v) => {
                              this.setState({
                                players: v
                              })
                            }}
                            filterOption={(input, option) => {
                              return option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            filterSort={(optionA, optionB) => {
                              optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                            }
                            }
                          >
                            { 
                              this.props.players && this.getSelectOptions()
                            }
                          </Select>
                        </Col>
                        <Col lg={6} style={{marginTop: '20px'}}>
                          <BuildButton 
                              onClick={() => {
                                this.fetchTeamOnOff()
                              }}
                              disabled={!this.state.players || this.state.players.length === 0 || this.props.onOff.loading }
                            >
                                {
                                  this.props.onOff.loading ? (
                                    <Spin />
                                  ) : (
                                    <>Run</>
                                  )
                                }
                            </BuildButton>
                        </Col>
                        <div style={{padding: '20px 0px 30px 10px'}}>
                          <p>The Team On/Off tool gives you complete customization and in-depth analysis into every team and influential player. Simply add a Team's inactive players and find your edge.</p>
                        </div>
                      </MenuRow>
                      <Row style={{ margin: '0px 0px', 'minHeight': '550px'}}>
                        <Col lg={13} md={24} sm={24}>
                          <Card>
                          <Legend>
                            <LegendTitle>When Selected Players are Active</LegendTitle>
                            <LegendOn />
                            <div/>
                            <LegendTitle>When Selected Players are Inactive</LegendTitle>
                            <LegendOff />
                          </Legend>
                          <Plot
                            data={_data}
                            style={{ width: '100%', height: '100%' }}
                            layout={ {showlegend: false, boxmode: 'group', autosize: true, title: 'Teammates DK Points', yaxis: { title: { text: 'DK Pts'} } } }
                            config={ {displayModeBar: false, responsive: true} }
                          />
                          </Card>
                        </Col>
                        <Col lg={11} md={24} sm={24}>
                          <Card hoverable={true}>
                            <Menu mode="horizontal" items={SummaryTableMenuItems} selectedKeys={[this.state.summaryTableDisplayKey]} onClick={(e) => {
                              this.setState({
                                summaryTableDisplayKey: e.key
                              })
                            }}/>
                            <OnOffSummaryTable playerName={'Team'} onOffSummaryData={this.props.onOff.data.game || []} loading={this.props.onOff.loading} players={this.props.onOff.data.players} site={this.state.summaryTableDisplayKey}/>
                          </Card>
                          <Card hoverable={true}>
                            <Menu mode="horizontal" items={GamesTableMenuItems} selectedKeys={[this.state.gameTableDisplayKey]} onClick={(e) => {
                              this.setState({
                                gameTableDisplayKey: e.key
                              })
                            }}/>
                            <OnOffGamesTable onOffGameData={this.props.onOff.data ? this.props.onOff.data.game : []} loading={this.props.onOff.loading} displayKey={this.state.gameTableDisplayKey} />
                          </Card>
                        </Col>
                      </Row>
                    </Skeleton>
                  )
                }
              </Card>
            ) : ''
          }
      </div>
    )
  }
}

export default connect(
  state => ({
    onOff: state.analysis.onOff,
    onOffValues: state.analysis.onOffValues,
    offList: state.analysis.offList,
    subscription: state.account.subscription
  }),
  {
    fetchOnOff: analysisActions.fetchOnOff,
    fetchOnOffValues: analysisActions.fetchOnOffValues,
    fetchOffList: analysisActions.fetchOffList
  }
)(OnOff)
