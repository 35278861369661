import React, { useState, useEffect } from 'react'
import { Slider, Tooltip, Row, Col } from 'antd'
import styled from 'styled-components'

const InputLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const InlineColPct = styled(Col)`
  position: relative;
  top: 7px;
`
const SliderValueLeft = styled.div`
  color: rgb(0,0,0,0.4);
  float: left;
`
const SliderValueRight = styled.div`
  color: rgb(0,0,0,0.4);
  float: right;
`

const underOwnedPercentages = [
  '2.5%',
  '5%',
  '10%',
  '18%',
  '25%',
]

const maxForSites = {
  'ya': 5,
  'dk': 6,
  'fd': 5
}

export default ({
  minUnderOwn_1,
  minUnderOwn_2,
  minUnderOwn_3,
  minUnderOwn_4,
  minUnderOwn_5,
  maxUnderOwn_1,
  maxUnderOwn_2,
  maxUnderOwn_3,
  maxUnderOwn_4,
  maxUnderOwn_5,
  inputOnChange,
  noProjOwn,
  showdown,
  site
}) => {
  const [ settings, updateSettings ] = useState({
    minUnderOwn_1,
    minUnderOwn_2,
    minUnderOwn_3,
    minUnderOwn_4,
    minUnderOwn_5,
    maxUnderOwn_1,
    maxUnderOwn_2,
    maxUnderOwn_3,
    maxUnderOwn_4,
    maxUnderOwn_5
  })

  useEffect(()=> {
    updateSettings({
      minUnderOwn_1,
      minUnderOwn_2,
      minUnderOwn_3,
      minUnderOwn_4,
      minUnderOwn_5,
      maxUnderOwn_1,
      maxUnderOwn_2,
      maxUnderOwn_3,
      maxUnderOwn_4,
      maxUnderOwn_5
    })
  },
  [
    minUnderOwn_1,
    minUnderOwn_2,
    minUnderOwn_3,
    minUnderOwn_4,
    minUnderOwn_5,
    maxUnderOwn_1,
    maxUnderOwn_2,
    maxUnderOwn_3,
    maxUnderOwn_4,
    maxUnderOwn_5
  ])

  let max = 9
  if (showdown)
    max = maxForSites[site]

  return (
    <div>
      <InputLabel>
        <Tooltip
          placement={'top'}
          title={'Each lineups total number of players under the given percentages. E.g. To have 3 players under 10% owned in every lineup. move the left 10% slider to 3. To win large-scale tournements, it is imperative to have low-owned players in each lineup'}
        >
          <h2>Bracketed Ownership</h2>
          <div style={{padding: '10px 0px', fontWeight: '500'}}>Number of players under N% owned</div>
        </Tooltip>
      </InputLabel>
      {[1,2,3,4,5].map(num => (
        <Row key={`n-owned-${num}`}>
          <InlineColPct lg={3}>{underOwnedPercentages[num-1]}</InlineColPct>
            {
              noProjOwn ? (
                <Col lg={21}>
                  <Tooltip title="Cannot adjust bracket ownership until there is proj own">
                    <Slider
                      range
                      step={1}
                      min={0}
                      max={max}
                      value={[0,max]}
                      disabled={true}
                      style={{width: '100%', marginBottom: '5px'}}
                    />
                    <div>
                      <SliderValueLeft>{0}</SliderValueLeft>
                      <SliderValueRight>{max}</SliderValueRight>
                    </div>
                  </Tooltip>
                </Col>
              ) : (
                <Col lg={21}>
                  <Slider
                    range
                    step={1}
                    min={0}
                    max={max}
                    value={[settings[`minUnderOwn_${num}`],settings[`maxUnderOwn_${num}`]]}
                    onAfterChange={evt => {
                      inputOnChange(evt[0], `minUnderOwn_${num}`)
                      inputOnChange(evt[1], `maxUnderOwn_${num}`)
                    }}
                    onChange={(evt) => {
                      updateSettings({
                        ...settings,
                        [`minUnderOwn_${num}`]: evt[0],
                        [`maxUnderOwn_${num}`]: evt[1],
                      })
                    }}
                    style={{width: '100%', marginBottom: '5px'}}
                  />
                  <div>
                    <SliderValueLeft>{settings[`minUnderOwn_${num}`]}</SliderValueLeft>
                    <SliderValueRight>{settings[`maxUnderOwn_${num}`]}</SliderValueRight>
                  </div>
                </Col>
              )
            }

        </Row>
      ))}
    </div>
  )
}
