import { week as defaultNFLWeek, season as defaultNFLSeason } from "./nfl-week"
import { defaultDate as defaultNBADate } from "../containers/nba/util/nba-date"

// return [counter, season]
const getDefaultDateInfoForSport = (sport) => {
  switch (sport) {
    case 'nfl':
      return [defaultNFLWeek, defaultNFLSeason]
    case 'nba':
      return [defaultNBADate(), undefined]
    case 'mlb':
      return ['2023-07-25', 2023]
    default:
      return [undefined, undefined]
  }
}

export default getDefaultDateInfoForSport