import { Row, Col, Skeleton, Tooltip, Spin } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import TopStacksTable from '../../components/top-stacks-table'
import LastUpdated from '../../components/last-updated'
import * as analysisActions from '../../actions/analysis'

import { week, season } from '../../utils/nfl-week'

const SpinContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 100px;
`

const siteLookup = {
  'dk': 'DraftKings',
  'fd': 'FanDuel',
  'ya': 'Yahoo'
}

class TopStacks extends Component {
  componentDidMount() {
    this.props.fetchTopStacks({ site: this.props.site, week, season })
  }

  mergeTopStacksWithPlayers() {
    const topStacks = this.props.topStacks.data.top_stacks_table

    if (!topStacks) return []
    const mergedTS = []

    topStacks.forEach(_ts => {
      const ts = { ..._ts }
      const _team = ts.Team

      const _qbs = this.props.players.filter(p => {
        return (
          p.TeamAbbrev === _team && p.Position === 'QB'
        )
      })

      let projOwn = 0
      _qbs.forEach(qb => {
        if (Number(qb.ProjOwn) > projOwn) {
          projOwn = Number(qb.ProjOwn)
        }
      })

      ts.ProjOwn = projOwn
      mergedTS.push(ts)
    })

    return mergedTS
  }

  render() {
    let topStackData = []
    if (this.props.topStacks.data && !this.props.topStacks.loading && !this.props.loading )
       topStackData = this.mergeTopStacksWithPlayers()

    return (
      <div>
        <Row>
          <h1>Stack Analysis Tool -- {siteLookup[this.props.site] || ''}</h1>
          <p>
             The following tables show the likelihood (from 1000 simulations) that each team will be the top stack according to three different metrics:<br/><br/>
             <b>Highest Total</b> shows the likelihood that the given team will have the highest point total from its QB + highest RB/WR/TE.<br/><br/>
             <b>Best Value</b> shows the likelihood that the given team will have the highest combined value from their QB and RB/WR/TE.<br/><br/>
             <b>Milly Maker Winner</b> is a special DFSForecast calculation that blends highest points and highest value. It is our attempt to quantify the likelihood this team's stack will be in the winning Milly Maker lineup.<br/><br/>
             <b>QB Ownership</b> shows the projected ownership for only the QB, it does not include the RB/WR/TE's ownership.
           </p>
        </Row>
        <Skeleton loading={!this.props.topStacks.data}>
          {
            this.props.topStacks.data && !this.props.topStacks.loading && !this.props.loading ? (
              <Row style={{marginTop: '10px'}}>
                <Col offset={3} lg={18}>
                  <LastUpdated timestamp={this.props.updatedAt || null}/>
                  <TopStacksTable data={topStackData || []} />
                </Col>
              </Row>
            ) : (
              <SpinContainer>
                <Spin />
              </SpinContainer>
            )
          }
        </Skeleton>
      </div>
    )
  }
}

export default connect(
  state => ({
    topStacks: state.analysis.topStacks,
  }),
  {
    fetchTopStacks: analysisActions.fetchTopStacks,
  }
)(TopStacks)
