import { Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'

const NewBanner = styled.div`
  color: #fff;
  background-color: #74BCFF;
  padding: 4px;
  font-size: 12px;
  border-radius: 2px;
`
const NewBannerContainer = styled.div`
  display: inline-block;
  margin-left: 6px;
  line-height: 16px;
`

export default ({ helpText, saleText }) => (
  <NewBannerContainer>
    <Tooltip title={helpText}>
      <NewBanner>{saleText}</NewBanner>
    </Tooltip>
  </NewBannerContainer>
)
