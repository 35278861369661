export const downloadCSV = (csvString, filename) => {
  var blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename)
    } else {
        const link = document.createElement("a")
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob)
            link.setAttribute("href", url)
            link.setAttribute("download", filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
}

export const writeRowCSVFromArray = (csvString, rowValuesArray) => {
  rowValuesArray.forEach((col, i) => {
    csvString += String(col)
    if (col !== rowValuesArray.length - 1)
      csvString += ','
  })
  csvString += '\n'
  return csvString
}

export const uploadCSV = (onUpload, onError) => {
  
}