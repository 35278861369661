import { Table } from "antd"
import { nflTeamNames as TeamToAbbrev } from '../../../../../utils/team-name-to-abbrev'
import { CloseOutlined } from "@ant-design/icons"
import StyledTable from "./player-tables"

const cols = (removePlayer) => ([
  {
    title: 'Pos.',
    dataIndex: 'keyPos',
    key: 'keyPos',
    render: (val) => {
      return (val && val.toUpperCase())
    }
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'name'
  },
  {
    title: 'Salary',
    dataIndex: 'Salary',
    key: 'salary',
    width: 70
  },
  {
    title: 'Team',
    dataIndex: 'TeamAbbrev',
    key: 'teamAbbrev',
    width: 50,
    render: (text, row) => {
      if (text)
        return (
          <img src={require(`../../../../../assets/images/mlb/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
        )
    }
  },
  {
    title: 'Opp',
    dataIndex: 'Opp',
    width: 50,
    key: 'opp',
    render: (text, row) => {
      if (text)
        return (
          <img src={require(`../../../../../assets/images/mlb/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
        )
    }
  },
  {
    title: 'ProjPts',
    dataIndex: 'ProjPts',
    key: 'ProjPts',
    render: (v) => {
      return (v && Number(v).toFixed(2))
    }
  },
  {
    title: 'ProjOwn',
    dataIndex: 'ProjOwn',
    key: 'ProjOwn',
    render: (v) => {
      return (v && Number(v).toFixed(2))
    }
  },
  {
    title: 'Remove',
    key: 'remove',
    render: (_, row) => {
      if (!row.Name) return ''
      return (
        <div style={{cursor: 'pointer', color: 'red', textAlign: 'center'}}>
          <CloseOutlined onClick={() => {removePlayer(row.keyPos)}} />
        </div>
      )
    }
  }
])

const SingleLineupTable = ({record, positions, removePlayer}) => {
  const data = []
  positions.forEach(p => {
    const _r = record[p] || {}
    data.push({
      ..._r,
      keyPos: p
    })
  })

  return (
    <StyledTable 
      columns={cols(removePlayer)}
      dataSource={data}
      size="small"
      bordered={true}
      pagination={false}
      rowKey={(r) => r.Id}
    />
  )
}

export default SingleLineupTable