import { Row, Col, Modal, Select, Checkbox, Card, Radio, Button, Input, Popconfirm, Tooltip } from 'antd'
import {
  PlusCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as groupsActions from '../../../../actions/groups'
import { nbaTeamNames as TeamToAbbrev } from '../../../../utils/team-name-to-abbrev'
import { fuzzyObjSearch } from '../../../../utils/search'
import { CPTDisplay } from '../../../../utils/showdown'
import FakurianDesign from '../../../../assets/images/backgrounds/fakurian-design-light.png'
import { SectionHeaderTitle } from '../../../../components/section-header-title'

import PlayersTable from './players-table'
import SelectedPlayersTable from './selected-players-table'

const GroupsPage = styled.div`
  margin: 30px 0px 0px 0px;
`
const GroupsContainer = styled.div`
  padding: 20px;
  min-height: 650px;
`
const GroupsRows = styled(Row)`
  margin-top: 20px;
  height: 100% !important;
`
const BigIconContainer = styled.div`
  color: #9a9a9a;
  width: 100%;
  text-align: center;
  margin-top: 15px;
`
const GroupsRow = styled(Row)`
  padding: 10px;
  line-height: 29px;
  font-weight: 700;
`
const BigIcon = styled(PlusCircleOutlined)`
  cursor: pointer;
  padding-top: 8px;
  margin-left: 2px;
`
const HeadingText = styled.div`
  font-size: 16px;
`
const Divider = styled.div`
  border-top: 1px solid #e8e8e8;
  margin: 25px;
`
const StyledModal = styled(Modal)`
  width: 60%!important;
`
const RightBorderedCol = styled(Col)`
  border-right: 1px solid #1d3557;
  height: 100%;
`
const HeadingCard = styled(Card)`
  &.ant-card {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }
`
const GroupList = styled.div`
  font-size: 18px;
  padding: 8px;

  p {
    display: inline-block;
    line-height: 20px
    margin: 5px 0px 5px 10px;
  }

  &:hover {
    background: #e8e8e8;
    cursor: pointer;
  }

  &.selected {
    background: #1d3557;
    color: white;
    font-weight: 600;
  }
`
const StyledSearch = styled.div`
  font-size: 18px;

  input {
    height: 40px;
  }
`
const PlayerSelectionContainer = styled.div`
  padding: 20px;
`
const StyledNameInput = styled(Input)`
  display: inline-block;
  width: 70% !important;
  margin-left: 5px !important;
`

const defaultInput = {
  name: '',
  minPlayers: 0,
  maxPlayers: 0,
  pctGroup: 1,
  tab: 'players',
  groupIndex: undefined,
  searchInput: '',
  modalSelectedPlayers: []
}

class Groups extends Component {
  state = {
    ...defaultInput,
    selectedGroupIndex: 0,
    editName: false,
    rosterPosition: 'FLEX'
  }

  async componentDidMount() {
    this.props.fetchPlayerGroups({site: this.props.site, slate: this.props.slate, sport: 'nba', counter: this.props.counter})
  }

  changeTab(tab) {
    this.setState({
      tab
    })
  }

  selectGroup(index) {
    this.setState({
      selectedGroupIndex: Number(index)
    })
  }

  setAnchorPlayer(playerID) {
    let _playerID = playerID
    if (playerID === this.props.groups.playerGroups[this.state.selectedGroupIndex].anchorPlayerID) {
      _playerID = null
    }

    this._updateGroup(this.state.selectedGroupIndex, {
      anchorPlayerID: _playerID
    })
  }

  getLastID() {
    return Number(this.props.groups.playerGroups[this.props.groups.playerGroups.length - 1] ? this.props.groups.playerGroups[this.props.groups.playerGroups.length - 1].ID : -1)
  }

  newGroup() {
    const _newGroup = {
      players: [],
      name: `Group ${this.getLastID() + 1}`,
      anchorPlayerID: null,
      minGroup: 1,
      maxGroup: 2,
      pctGroup: 1,
      keyPlayer: null,
      active: true,
      ID: (this.getLastID() + 1)
    }

    this.props.updateGroup({group: _newGroup, site: this.props.site, slate: this.props.slate, sport: 'nba', counter: this.props.counter})
    this.setState({
      selectedGroupIndex: this.props.groups.playerGroups.length
    })
  }

  deleteGroup(groupID) {
    this.props.removeGroup({ groupID, site: this.props.site, slate: this.props.slate, sport: 'nba' })
    this.setState({
      selectedGroupIndex: this.props.groups.playerGroups.length - 2
    })
  }

  addPlayerToGroup(playerId) {
    const _updatedPlayers = [ ...this.props.groups.playerGroups[this.state.selectedGroupIndex].players ]

    // If Player isn't already selected (shouldn't be possible but good check)
    if (!_updatedPlayers.includes(playerId))
      _updatedPlayers.push(playerId)

    this._updateGroup(this.state.selectedGroupIndex, {
      players: _updatedPlayers
    })
  }

  removePlayerFromGroup(playerId) {
    const _updatedPlayers = [ ...this.props.groups.playerGroups[this.state.selectedGroupIndex].players ]

    const _index = _updatedPlayers.indexOf(playerId)
    if (_index > -1)
      _updatedPlayers.splice(_index, 1)

    this._updateGroup(this.state.selectedGroupIndex, {
      players: _updatedPlayers
    })
  }

  addModalPlayers() {
    const _updatedPlayers = [ ...this.props.groups.playerGroups[this.state.selectedGroupIndex].players ]

    this.state.modalSelectedPlayers.forEach(playerId => {
      if (!_updatedPlayers.includes(playerId))
        _updatedPlayers.push(playerId)
    })

    this._updateGroup(this.state.selectedGroupIndex, {
      players: _updatedPlayers
    })

    this.setState({
      visible: false,
      modalSelectedPlayers: []
    })
  }

  _updateGroup(groupIndex, updates) {
    const _updatedGroup = { ...this.props.groups.playerGroups[groupIndex] }

    Object.keys(updates).forEach(k => {
      _updatedGroup[k] = updates[k]
    })

    this.props.updateGroup({group: _updatedGroup, site: this.props.site, slate: this.props.slate, sport: 'nba', counter: this.props.counter})
  }

  _updatePosition(e) {
    const position = e.target.value
    if (position === 'ALL')
      this.setState({
        position: null
      })
    else
      this.setState({
        position
      })
  }

  _updateRosterPosition(e) {
    const rosterPosition = e.target.value
    this.setState({
      rosterPosition
    })
  }

  _sortPlayersByPosition(data) {
    const { position, rosterPosition } = this.state

    if (this.props.showdown) {
      data = data.filter(p => p.RosterPosition === rosterPosition)
    }

    if (!position) return data

    if (position === 'FLEX') return data.filter(player => {
      return (
        ['RB', 'WR', 'TE'].indexOf(player.Position) >= 0
      )
    })

    return data.filter(player => player.Position === position)
  }

  _search(value) {
    this.setState({
      searchInput: value
    })
  }

  _filterDataForSearchTerm = (data, searchTerm) => {
    return data.filter(obj => fuzzyObjSearch(searchTerm, obj))
  }

  _filterOutSelectedPlayers = (data) => {
    return data.filter(player => !this.props.groups.playerGroups[this.state.selectedGroupIndex].players.includes(player.Id))
  }

  _getPlayersFromIds = (idsList) => {
    return this.props.players.data.filter(player => idsList.includes(player.Id))
  }

  render() {
    const { searchInput } = this.state
    const selectedGroup = this.props.groups.playerGroups[this.state.selectedGroupIndex]

    let data = this.props.players.data ? this._sortPlayersByPosition(this.props.players.data) : []
    if (searchInput)
      data = this._filterDataForSearchTerm(data, searchInput)
    if (selectedGroup)
      data = this._filterOutSelectedPlayers(data)

    let selectedPlayers = []
    if (selectedGroup)
      selectedPlayers = this._getPlayersFromIds(selectedGroup.players)

    return (
      <GroupsPage>
        <HeadingCard style={{backgroundImage: `url(${FakurianDesign})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% auto'}}>
          <h1>Groups</h1>
          <SectionHeaderTitle sport={'nba'} site={this.props.site} counter={this.props.counter} />
        </HeadingCard>
        <GroupsContainer>
          <GroupsRows>
            <RightBorderedCol lg={4}>
              <GroupsRow>
                <Col lg={22}>
                  {this.props.counter}
                </Col>
                <Col lg={2}>
                  <BigIcon onClick={this.newGroup.bind(this)}/>
                </Col>
              </GroupsRow>
              {
                this.props.groups.playerGroups.length === 0 ? (
                  <BigIconContainer>
                    <div>You have no groups</div>
                  </BigIconContainer>
                ) : (
                  <div>
                    {
                      this.props.groups.playerGroups.map((g, i) => {
                        return (
                          <GroupList
                            className={i === this.state.selectedGroupIndex ? 'selected' : ''}
                            onClick={(e) => {
                              this.selectGroup(i)
                            }}
                            key={g.ID}
                          >
                            <Row>
                              <Col lg={22}>
                                <Checkbox
                                  checked={g.active}
                                  onClick={() => {
                                    this._updateGroup(i, {
                                      active: !g.active
                                    })
                                  }} />
                                  {
                                    this.state.editName &&  i === this.state.selectedGroupIndex ? (
                                      <StyledNameInput
                                        placeholder={selectedGroup && selectedGroup.name}
                                        type="text"
                                        autoFocus
                                        onChange={(v) => {
                                          this._updateGroup(i, {
                                            name: v.target.value
                                          })
                                        }}
                                        onBlur={() => {
                                          this.setState({
                                            editName: false
                                          })
                                        }}
                                      />
                                    ) : (
                                      <p onDoubleClick={() => this.setState({
                                          editName: true
                                        })}>{g.name}</p>
                                    )
                                  }
                                </Col>
                                <Col lg={2}>
                                  <Popconfirm
                                    cancelText="No"
                                    okText="Yes"
                                    onClick={(e) => e.stopPropagation()}
                                    onCancel={(e) => e.stopPropagation()}
                                    onConfirm={
                                      (e) => {
                                        e.stopPropagation()
                                        this.deleteGroup(g.ID)
                                      }
                                    }
                                    title={`Delete group?`}
                                  >
                                    <DeleteOutlined/>
                                  </Popconfirm>
                                </Col>
                              </Row>
                          </GroupList>
                        )
                      })
                    }
                  </div>
                )
              }
            </RightBorderedCol>
            <Col lg={20}>
              <Row>
                {
                  selectedGroup && (
                    <PlayerSelectionContainer>
                      <Card style={{minHeight: '280px'}}>
                        <Row>
                          <Col lg={4}>
                            <Button type="primary" onClick={() => this.setState({
                              visible: true
                            })}>Quick Add</Button>
                          </Col>
                          <Col lg={2} offset={14}>
                            <Tooltip title="The percentage of lineups in the build that must satisfy the constraints of this group."><div>Pct.</div></Tooltip>
                            <Input
                              type={'number'}
                              placeholder={'Pct'}
                              style={{width: '90%'}}
                              value={selectedGroup && (Number(selectedGroup.pctGroup) * 100).toFixed(0)}
                              onChange={(e) => {
                                this._updateGroup(this.state.selectedGroupIndex, {
                                  pctGroup: (Number(e.target.value) / 100).toFixed(2)
                                })
                              }}
                            />
                          </Col>
                          <Col lg={2}>
                            <Tooltip title="Minimum players from this group that must be in a lineup."><div>Min.</div></Tooltip>
                            <Input
                              type={'number'}
                              placeholder={'Min'}
                              style={{width: '90%'}}
                              value={selectedGroup && selectedGroup.minGroup}
                              onChange={(e) => {
                                this._updateGroup(this.state.selectedGroupIndex, {
                                  minGroup: e.target.value
                                })
                              }}
                            />
                          </Col>
                          <Col lg={2}>
                            <Tooltip title="Maximum players from this group that can be in a lineup"><div>Max.</div></Tooltip>
                            <Input
                              type={'number'}
                              placeholder={'Max'}
                              style={{width: '90%'}}
                              value={selectedGroup && selectedGroup.maxGroup}
                              onChange={(e) => {
                                this._updateGroup(this.state.selectedGroupIndex, {
                                  maxGroup: e.target.value
                                })
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={16} md={24} sm={24}>
                            {
                              selectedGroup && selectedGroup.players && selectedGroup.players.length ? (
                                <div style={{marginTop: '10px'}}>
                                  <SelectedPlayersTable
                                    rows={selectedPlayers}
                                    onRemove={this.removePlayerFromGroup.bind(this)}
                                    showdown={this.props.showdown}
                                    setAnchor={this.setAnchorPlayer.bind(this)}
                                    anchorPlayerID={selectedGroup.anchorPlayerID}
                                  />
                                </div>
                              ) : (
                                <div style={{textAlign: 'center', fontWeight: '300'}}>
                                  No Players Selected
                                </div>
                              )
                            }
                          </Col>
                        </Row>
                      </Card>
                      <Row style={{margin: '10px 0px'}}>
                        <Col lg={6}>
                          <StyledSearch>
                            <Input.Search
                              placeholder="player name, team, opp, salary, etc."
                              onChange={(e) => this._search(e.target.value)}
                              style={{ width: '100%' }}
                            />
                          </StyledSearch>
                        </Col>
                        {
                          this.props.showdown ? (
                            <Col lg={3} offset={1}>
                              <Radio.Group
                                onChange={(e) => this._updateRosterPosition(e)}
                                value={this.state.rosterPosition}
                              >
                                <Radio.Button value='CPT'>{CPTDisplay[this.props.site]}</Radio.Button>
                                <Radio.Button value='FLEX'>FLEX</Radio.Button>
                              </Radio.Group>
                            </Col>
                          ) : ''
                        }

                        <Col lg={8} offset={1}>
                          <Radio.Group
                            onChange={(e) => this._updatePosition(e)}
                            value={this.state.position}
                          >
                            <Radio.Button value={''}>ALL</Radio.Button>
                            <Radio.Button value={"PG"}>PG</Radio.Button>
                            <Radio.Button value={"SG"}>SG</Radio.Button>
                            <Radio.Button value={"SF"}>SF</Radio.Button>
                            <Radio.Button value={"PF"}>PF</Radio.Button>
                            <Radio.Button value={"C"}>C</Radio.Button>
                            {
                              this.props.showdown ? (
                                <Radio.Button value={"K"}>K</Radio.Button>
                              ) : ''
                            }
                          </Radio.Group>
                        </Col>
                      </Row>
                      <div>
                        <PlayersTable
                          rows={data}
                          onAdd={this.addPlayerToGroup.bind(this)}
                          permissions={this.props.subscription.permissions}
                          site={this.props.site}
                          showdown={this.props.showdown}
                        />
                      </div>
                    </PlayerSelectionContainer>
                  )
                }
              </Row>
            </Col>
          </GroupsRows>

          <StyledModal
            centered
            visible={this.state.visible}
            onCancel={() => this.setState({
              visible: false
            })}
            onOk={this.addModalPlayers.bind(this)}
          >
            <Row>
              <div>Add Players:</div>
              <Select
                showSearch
                mode="multiple"
                size="large"
                style={{ width: '80%', marginTop: '15px' }}
                placeholder="Search Player"
                optionFilterProp="children"
                value={this.state.modalSelectedPlayers}
                onChange={(v) => {
                  this.setState({
                    modalSelectedPlayers: v
                  })
                }}
                filterOption={(input, option) => {
                  return option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                filterSort={(optionA, optionB) => {
                  optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                }
                }
              >
                { this.props.players.data.map((p, i) => {
                  return (
                    <Select.Option key={i} value={p.Id}>{p.Name} - {p.Position} | {TeamToAbbrev[p.TeamAbbrev]}</Select.Option>
                  )
                })}
              </Select>
            </Row>
          </StyledModal>
        </GroupsContainer>
      </GroupsPage>
    )
  }
}

export default connect(
  state => ({
    groups: state.groups,
    players: state.lineup.players,
    subscription: state.account.subscription
  }),
  {
    fetchPlayerGroups: groupsActions.fetchGroups,
    updateGroup: groupsActions.updateGroup,
    removeGroup: groupsActions.removeGroup
  }
)(Groups)
