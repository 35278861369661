import React, { Component } from 'react'
import { Table, Tooltip, Input, Checkbox, Menu, Popconfirm, Button, Row, Col } from 'antd'
import styled from 'styled-components'

import { getColorForValueForOU, getColorForValueForImpPts } from '../../../../utils/color'

const TeamStackOptions = styled.div`
`

const ToggleHeader = styled.div`
  background: white;
  width: 100%;
`

const StyledInputNumber = styled(Input)`
  &.ant-input {
    width: 40px !important;
  }
`
const StyledInputNumberLong = styled(Input)`
  &.ant-input {
    width: 70px !important;
  }
`

const StyledCheckbox = styled(Checkbox)`
  > .ant-checkbox-wrapper + span, .ant-checkbox + span {
    padding-right: 0;
    padding-left: 5px;
  }

  input {
    appearance: none;
  }

  &:checked {
    background: #ffa500;
  }
`
const StyledCheckboxWhite = styled(StyledCheckbox)`
  color: white !important;
`

const StyledTable = styled(Table)`
  td {
    text-align: center;
  }

  tr th {
    text-align: center !important;
  }

  tr:first-child {
    th {
      color: white !important;
      background: #2B404F !important;
    }
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  tr.ant-table-row-level-0:hover > td.team-col {
    background-color: rgba(0,0,0,0.05) !important;
  }

  td.team-col {
    background-color: rgba(0,0,0,0.05) !important;
  }
`
const StyledButtonCol = styled(Col)`
  height: 48px;
  background: white;

  button {
    margin-top: 8px;
  }
`

const CheckBoxInput = ({row, _var, _onChange, children}) => (
  <StyledCheckbox
    checked={row[_var]}
    onClick={(e) => _onStackingChange(row, _var, e.target.checked, _onChange)}
  >{children}</StyledCheckbox>
)

const NumberInput = ({row, _var, _onChange, children}) => (
  <StyledInputNumber
    placeholder="0"
    value={Number(row[_var]) > 0 ? row[_var] : ''}
    onChange={(e) => _onStackingChange(row, _var, Number(e.target.value), _onChange)}
  >{children}</StyledInputNumber>
)

const NumberInputLong = ({row, _var, _onChange, children}) => (
  <StyledInputNumberLong
    placeholder="0"
    value={Number(row[_var]) > 0 ? row[_var] : ''}
    onChange={(e) => _onStackingChange(row, _var, Number(e.target.value), _onChange)}
  >{children}</StyledInputNumberLong>
)

const _onStackingChange = (row, key, value, _onChange) => {
  const _row = {
    ...row,
    [key]: value
  }
  _onChange(_row)
}

const teamGameData = [
  {
    title: 'Team',
    dataIndex: 'TeamAbbrev',
    key: 'TeamAbbrev',
    className: 'team-col',
    width: 80,
    render: (text) => (
      <img src={require(`../../../../assets/images/mlb/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={text}/>
    ),
  },
  {
    title: 'Opp',
    dataIndex: 'Opp',
    key: 'opp',
    width: 80,
    render: (text) => (
      <img src={require(`../../../../assets/images/mlb/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={text}/>
    ),
  }
]

const teamContraints = (onTeamStacksChange, onChangeAllTeamStackOption) => [
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Min/Max total hitters from this team in the same lineup"}>
          <span>Min/Max Hitters</span>
        </Tooltip>
        <div>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('MinTeamHitterCnt', Number(e.target.value))}
          ></StyledInputNumber>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('MaxTeamHitterCnt', Number(e.target.value))}
          ></StyledInputNumber>
        </div>
      </div>
    ),
    key: 'totalHitters',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInput row={row} _var={'MinTeamHitterCnt'} _onChange={onTeamStacksChange} />
          <NumberInput row={row} _var={'MaxTeamHitterCnt'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  },
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Allow this team to be the primary stacked team."}>
          <span>Primary Stack</span>
        </Tooltip>
        <div>
          <StyledCheckboxWhite
            defaultChecked={true}
            onClick={(e) =>{onChangeAllTeamStackOption('AllowPrimaryStack', e.target.checked)}}
          ></StyledCheckboxWhite>
        </div>
      </div>
    ),
    key: 'AllowPrimaryStack',
    width: 90,
    render: (_, row) => {
      return (
        <CheckBoxInput row={row} _var={'AllowPrimaryStack'} _onChange={onTeamStacksChange} />
      )
    }
  },
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Allow this team to be the secondary stacked team."}>
          <span>Secondary Stack</span>
        </Tooltip>
        <div>
          <StyledCheckboxWhite
            defaultChecked={true}
            onClick={(e) =>{onChangeAllTeamStackOption('AllowSecondaryStack', e.target.checked)}}
          ></StyledCheckboxWhite>
        </div>
      </div>
    ),
    key: 'AllowSecondaryStack',
    width: 90,
    render: (_, row) => {
      return (
        <CheckBoxInput row={row} _var={'AllowSecondaryStack'} _onChange={onTeamStacksChange} />
      )
    }
  },
  {
    title: (
      <div>
        <Tooltip placement="top" title={""}>
          <span>Max Opp Hitters vs Pitcher</span>
        </Tooltip>
        <div>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('MaxPitcherVsOppHitter', Number(e.target.value))}
          ></StyledInputNumber>
        </div>
      </div>
    ),
    key: 'totalHitters',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInput row={row} _var={'MaxPitcherVsOppHitter'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  },
]

class StackTable extends Component {
  state = {
    toggle: this.props.showdown ? 0 : 0
  }

  render() {
    const extraCols = [
      teamContraints(this.props.onChangeHandler, this.props.onChangeAllTeamStackOption)
    ][this.state.toggle]

    return (
      <TeamStackOptions>
        <ToggleHeader>
          <Row>
            <Col lg={12}>
            </Col>
            <StyledButtonCol lg={12}>
              <div style={{float: 'right'}}>
                <Popconfirm
                  cancelText="No"
                  okText="Yes"
                  onClick={(e) => e.stopPropagation()}
                  onCancel={(e) => e.stopPropagation()}
                  onConfirm={
                    (e) => {
                      e.stopPropagation()
                      this.props.clearStackSettings()
                    }
                  }
                  title={`Permenantly clear all settings for this slate?`}
                >
                  <Button>Reset Team Stacks to Defaults</Button>
                </Popconfirm>
              </div>
            </StyledButtonCol>
          </Row>
        </ToggleHeader>
        <StyledTable
          columns={teamGameData.concat(extraCols)}
          dataSource={this.props.rows}
          pagination={{ pageSize: 50 }}
          scroll={{ y: 700, x: 1400 }}
          bordered
          loading={this.props.loading}
          size='small'
          rowKey={_row => String(_row.TeamAbbrev)}
        />
    </TeamStackOptions>
    )
  }
}

export default StackTable
