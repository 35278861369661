import { Row, Col } from 'antd'
import {
  UpOutlined,
  DownOutlined
} from '@ant-design/icons'
import React, { Component } from 'react'
import styled from 'styled-components'

const CollapsableCard = styled.div`
  background: white;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0;
  margin-bottom: 28px;
`
const StyledHeader = styled.div`
  background: white;
  border-radius: 3px;
  color: #1d3557;
  font-weight: 600;
  padding: 15px 30px;
`
const StyledBody = styled.div`
  padding: 0px 20px;
`

class DetailsArea extends Component {
  state = {
    showInputs: this.props.defaultShow
  }

  render() {
    return (
      <CollapsableCard>
        <StyledHeader>
          <Row>
            <Col lg={16}>{this.props.title}</Col>
            <Col lg={1} offset={6}>
              {
                this.state.showInputs ? (
                  <UpOutlined
                    onClick={() => this.setState({
                      showInputs: !this.state.showInputs
                    })}
                  />
                ) : (
                  <DownOutlined
                    onClick={() => this.setState({
                      showInputs: !this.state.showInputs
                    })}
                  />
                )
              }
            </Col>
          </Row>
        </StyledHeader>
        {this.state.showInputs ? (
          <StyledBody>{this.props.children}</StyledBody>
        ) : ''}
      </CollapsableCard>
    )
  }
}

export default DetailsArea
