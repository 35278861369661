import React, { Component } from 'react'
import { Table, Tooltip, Input, Checkbox, Menu, Popconfirm, Button, Row, Col } from 'antd'
import styled from 'styled-components'

import { nflTeamNames as TeamToAbbrev } from '../../../../utils/team-name-to-abbrev'

import { getColorForValueForOU, getColorForValueForImpPts } from '../../../../utils/color'

const TeamStackOptions = styled.div`
`

const ToggleHeader = styled.div`
  background: white;
  width: 100%;
`

const StyledInputNumber = styled(Input)`
  &.ant-input {
    width: 40px !important;
  }
`
const StyledInputNumberLong = styled(Input)`
  &.ant-input {
    width: 70px !important;
  }
`

const StyledCheckbox = styled(Checkbox)`
  > .ant-checkbox-wrapper + span, .ant-checkbox + span {
    padding-right: 0;
    padding-left: 5px;
  }

  input {
    appearance: none;
  }

  &:checked {
    background: #ffa500;
  }
`
const StyledCheckboxWhite = styled(StyledCheckbox)`
  color: white !important;
`

const StyledTable = styled(Table)`
  td {
    text-align: center;
  }

  tr th {
    text-align: center !important;
  }

  tr:first-child {
    th {
      color: white !important;
      background: #2B404F !important;
    }
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  tr.ant-table-row-level-0:hover > td.team-col {
    background-color: rgba(0,0,0,0.05) !important;
  }

  td.team-col {
    background-color: rgba(0,0,0,0.05) !important;
  }
`
const StyledButtonCol = styled(Col)`
  height: 48px;
  background: white;

  button {
    margin-top: 8px;
  }
`

const CheckBoxInput = ({row, _var, _onChange, children}) => (
  <StyledCheckbox
    checked={row[_var]}
    onClick={(e) => _onStackingChange(row, _var, e.target.checked, _onChange)}
  >{children}</StyledCheckbox>
)

const NumberInput = ({row, _var, _onChange, children}) => (
  <StyledInputNumber
    placeholder="0"
    value={Number(row[_var]) > 0 ? row[_var] : ''}
    onChange={(e) => _onStackingChange(row, _var, Number(e.target.value), _onChange)}
  >{children}</StyledInputNumber>
)

const NumberInputLong = ({row, _var, _onChange, children}) => (
  <StyledInputNumberLong
    placeholder="0"
    value={Number(row[_var]) > 0 ? row[_var] : ''}
    onChange={(e) => _onStackingChange(row, _var, Number(e.target.value), _onChange)}
  >{children}</StyledInputNumberLong>
)

const _onStackingChange = (row, key, value, _onChange) => {
  const _row = {
    ...row,
    [key]: value
  }
  _onChange(_row)
}

const teamGameData = [
  {
    title: 'Team',
    dataIndex: 'TeamAbbrev',
    key: 'TeamAbbrev',
    className: 'team-col',
    width: 80,
    render: (text) => (
      <img src={require(`../../../../assets/images/nfl/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
    ),
  },
  {
    title: 'Opp',
    dataIndex: 'Opp',
    key: 'opp',
    width: 80,
    render: (text) => (
      <img src={require(`../../../../assets/images/nfl/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
    ),
  },
  {
    title: (
      <Tooltip placement="top" title={"Projected total points for the game"}>
        <span>OU</span>
      </Tooltip>
    ),
    dataIndex: 'OU',
    key: 'ou',
    sorter: (a, b) => Number(b.OU) - Number(a.OU),
    width: 60,
    render: (val) => (
      <div
      style=
        {
          {
            backgroundColor: getColorForValueForOU(val),
            textAlign: 'center',
            opacity: '0.75',
            width: '100%'
          }
        }
      >
        { val }
      </div>
    )
  },
  {
    title: (
      <Tooltip placement="top" title={"Projected amount team will win or lose by (favored team is negative)"}>
        <span>Sprd</span>
      </Tooltip>
    ),
    dataIndex: 'Spread',
    key: 'spread',
    width: 60,
  },
  {
    title: (
      <Tooltip placement="top" title={"Projected total points for the team"}>
        <span>Pts</span>
      </Tooltip>
    ),
    dataIndex: 'ImpPts',
    key: 'currImpPt',
    sorter: (a, b) => Number(b.ImpPts) - Number(a.ImpPts),
    width: 60,
    render: (val) => (
      <div
      style=
        {
          {
            backgroundColor: getColorForValueForImpPts(val),
            textAlign: 'center',
            opacity: '0.75',
            width: '100%'
          }
        }
      >
        { val }
      </div>
    )
  }
]

const teamContraints = (onTeamStacksChange, onChangeAllTeamStackOption) => [
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Min/Max total players from this team in the same lineup"}>
          <span>Total Players</span>
        </Tooltip>
        <div>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMinPlayers', Number(e.target.value))}
          ></StyledInputNumber>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMaxPlayers', Number(e.target.value))}
          ></StyledInputNumber>
        </div>
      </div>
    ),
    key: 'totalPlayers',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInput row={row} _var={'TotalMinPlayers'} _onChange={onTeamStacksChange} />
          <NumberInput row={row} _var={'TotalMaxPlayers'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  },
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Min/Max RBs in the same lineup"}>
          <span>Total RBs</span>
        </Tooltip>
        <div>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMinRBs', Number(e.target.value))}
          ></StyledInputNumber>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMaxRBs', Number(e.target.value))}
          ></StyledInputNumber>
        </div>
      </div>
    ),
    key: 'totalRBs',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInput row={row} _var={'TotalMinRBs'} _onChange={onTeamStacksChange} />
          <NumberInput row={row} _var={'TotalMaxRBs'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  },
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Min/Max WRs in the same lineup"}>
          <span>Total WRs</span>
        </Tooltip>
        <div>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMinWRs', Number(e.target.value))}
          ></StyledInputNumber>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMaxWRs', Number(e.target.value))}
          ></StyledInputNumber>
        </div>
      </div>
    ),
    key: 'totalWRs',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInput row={row} _var={'TotalMinWRs'} _onChange={onTeamStacksChange} />
          <NumberInput row={row} _var={'TotalMaxWRs'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  },
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Min/Max TEs in the same lineup"}>
          <span>Total TEs</span>
        </Tooltip>
        <div>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMinTEs', Number(e.target.value))}
          ></StyledInputNumber>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMaxTEs', Number(e.target.value))}
          ></StyledInputNumber>
        </div>
      </div>
    ),
    key: 'totalTEs',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInput row={row} _var={'TotalMinTEs'} _onChange={onTeamStacksChange} />
          <NumberInput row={row} _var={'TotalMaxTEs'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  },
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Min/Max players from this team in the Flex"}>
          <span>Total Flex</span>
        </Tooltip>
        <div>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMinFlex', Number(e.target.value))}
          ></StyledInputNumber>
          <StyledInputNumber
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('TotalMaxFlex', Number(e.target.value))}
          ></StyledInputNumber>
        </div>
      </div>
    ),
    key: 'totalFlex',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInput row={row} _var={'TotalMinFlex'} _onChange={onTeamStacksChange} />
          <NumberInput row={row} _var={'TotalMaxFlex'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  },
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Salary range used for this team"}>
          <span>Total Salary</span>
        </Tooltip>
        <div>
          <StyledInputNumberLong
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('MinTeamSal', Number(e.target.value))}
          ></StyledInputNumberLong>
          <StyledInputNumberLong
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('MaxTeamSal', Number(e.target.value))}
          ></StyledInputNumberLong>
        </div>
      </div>
    ),
    key: 'totalSalary',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInputLong row={row} _var={'MinTeamSal'} _onChange={onTeamStacksChange} />
          <NumberInputLong row={row} _var={'MaxTeamSal'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  },
  {
    title: (
      <div>
        <Tooltip placement="top" title={"Salary range used for Flex spot from this team"}>
          <span>Flex Salary</span>
        </Tooltip>
        <div>
          <StyledInputNumberLong
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('MinFlexSal', Number(e.target.value))}
          ></StyledInputNumberLong>
          <StyledInputNumberLong
            placeholder="0"
            onChange={(e) => onChangeAllTeamStackOption('MaxFlexSal', Number(e.target.value))}
          ></StyledInputNumberLong>
        </div>
      </div>
    ),
    key: 'totalFlexSalary',
    className: 'divide',
    render: (_, row) => {
      return (
        <Input.Group compact>
          <NumberInputLong row={row} _var={'MinFlexSal'} _onChange={onTeamStacksChange} />
          <NumberInputLong row={row} _var={'MaxFlexSal'} _onChange={onTeamStacksChange} />
        </Input.Group>
      )
    }
  },
]

const stackingOptions = (onTeamStacksChange, onChangeAllTeamStackOption) => [
    {
      title: (
        <div>
          <Tooltip placement="top" title={"Set which teams can have a QB with no other flex positions from that team"}>
            <span>Naked QB</span>
          </Tooltip>
          <div>
            <StyledCheckboxWhite
              defaultChecked={true}
              onClick={(e) =>{onChangeAllTeamStackOption('AllowNakedQB', e.target.checked)}}
            ></StyledCheckboxWhite>
          </div>
        </div>
      ),
      key: 'nakedQB',
      width: 90,
      render: (_, row) => {
        return (
          <CheckBoxInput row={row} _var={'AllowNakedQB'} _onChange={onTeamStacksChange} />
        )
      }
    },
    {
      title: (
        <div>
          <Tooltip placement="top" title={"Set which teams can have a QB stacked with their teammates"}>
            <span>Team Stack</span>
          </Tooltip>
          <div>
            <StyledCheckboxWhite
              defaultChecked={true}
              onClick={(e) =>{onChangeAllTeamStackOption('AllowQBStack', e.target.checked)}}
            ></StyledCheckboxWhite>
          </div>
        </div>
      ),
      key: 'qbStack',
      width: 95,
      render: (_, row) => {
        return (
          <CheckBoxInput row={row} _var={'AllowQBStack'} _onChange={onTeamStacksChange} />
        )
      }
    },
    {
      title: (
        <div>
          <Tooltip placement="top" title={"If this team is selected to be stacked, set which positions from the team are allowed to be stacked with the QB"}>
            <span>Stack Pos.</span>
          </Tooltip>
          <div>
            <StyledCheckboxWhite
              defaultChecked={true}
              onClick={(e) =>{onChangeAllTeamStackOption('AllowRBStack', e.target.checked)}}
            >RB</StyledCheckboxWhite>
            <StyledCheckboxWhite
              defaultChecked={true}
              onClick={(e) =>{onChangeAllTeamStackOption('AllowWRStack', e.target.checked)}}
            >WR</StyledCheckboxWhite>
            <StyledCheckboxWhite
              defaultChecked={true}
              onClick={(e) =>{onChangeAllTeamStackOption('AllowTEStack', e.target.checked)}}
            >TE</StyledCheckboxWhite>
          </div>
        </div>
      ),
      key: 'stackPos',
      width: 165,
      render: (_, row) => {
        return (
          <div>
            <CheckBoxInput row={row} _var={'AllowRBStack'} _onChange={onTeamStacksChange}>RB</CheckBoxInput>
            <CheckBoxInput row={row} _var={'AllowWRStack'} _onChange={onTeamStacksChange}>WR</CheckBoxInput>
            <CheckBoxInput row={row} _var={'AllowTEStack'} _onChange={onTeamStacksChange}>TE</CheckBoxInput>
          </div>
        )
      }
    },
    {
      title: (
        <div>
          <Tooltip placement="top" title={"If this team is selected to be stacked, set the range of flex players that are allowed"}>
            <span>Stacked Players</span>
          </Tooltip>
          <div>
            <StyledInputNumber
              placeholder="0"
              onChange={(e) => onChangeAllTeamStackOption('MinStack', Number(e.target.value))}
            ></StyledInputNumber>
            <StyledInputNumber
              placeholder="0"
              onChange={(e) => onChangeAllTeamStackOption('MaxStack', Number(e.target.value))}
            ></StyledInputNumber>
          </div>
        </div>
      ),
      key: 'stackedPlayers',
      render: (_, row) => {
        return (
          <Input.Group compact>
            <NumberInput row={row} _var={'MinStack'} _onChange={onTeamStacksChange} />
            <NumberInput row={row} _var={'MaxStack'} _onChange={onTeamStacksChange} />
          </Input.Group>
        )
      }
    },
    {
      title: (
        <div>
          <Tooltip placement="top" title={"Set the max flex you want for the team if not included in a stack. E.g. If you want to pair two WRs with a QB, but you don't want to play both WRs without the QB, input 1."}>
            <span>Max Flex No Stack</span>
          </Tooltip>
          <div>
            <StyledInputNumber
              placeholder="0"
              onChange={(e) => onChangeAllTeamStackOption('MaxFlexIfNoStack', Number(e.target.value))}
            ></StyledInputNumber>
          </div>
        </div>
      ),
      key: 'maxFlexIfNoStack',
      render: (_, row) => {
        return (
          <NumberInput row={row} _var={'MaxFlexIfNoStack'} _onChange={onTeamStacksChange} />
        )
      }
    },
    {
      title: (
        <div>
          <Tooltip placement="top" title={"Set teams that can have a QB stacked with teammates and an opposing player"}>
            <span>Game Stack</span>
          </Tooltip>
          <div>
            <StyledCheckboxWhite
              defaultChecked={true}
              onClick={(e) =>{onChangeAllTeamStackOption('AllowGameStack', e.target.checked)}}
            ></StyledCheckboxWhite>
          </div>
        </div>
      ),
      key: 'gameStack',
      render: (_, row) => {
        return (
          <CheckBoxInput row={row} _var={'AllowGameStack'} _onChange={onTeamStacksChange} />
        )
      }
    },
    {
      title: (
        <div>
          <Tooltip placement="top" title={"If this game is selected to be stacked, set the flex posisions from the opposing team that can be included"}>
            <span>Opp. Stack Pos</span>
          </Tooltip>
          <div>
            <StyledCheckboxWhite
              defaultChecked={true}
              onClick={(e) =>{onChangeAllTeamStackOption('AllowRBOppStack', e.target.checked)}}
            >RB</StyledCheckboxWhite>
            <StyledCheckboxWhite
              defaultChecked={true}
              onClick={(e) =>{onChangeAllTeamStackOption('AllowWROppStack', e.target.checked)}}
            >WR</StyledCheckboxWhite>
            <StyledCheckboxWhite
              defaultChecked={true}
              onClick={(e) =>{onChangeAllTeamStackOption('AllowTEOppStack', e.target.checked)}}
            >TE</StyledCheckboxWhite>
          </div>
        </div>
      ),
      key: 'oppStackPos',
      width: 165,
      render: (_, row) => {
        return (
          <div>
            <CheckBoxInput row={row} _var={'AllowRBOppStack'} _onChange={onTeamStacksChange}>RB</CheckBoxInput>
            <CheckBoxInput row={row} _var={'AllowWROppStack'} _onChange={onTeamStacksChange}>WR</CheckBoxInput>
            <CheckBoxInput row={row} _var={'AllowTEOppStack'} _onChange={onTeamStacksChange}>TE</CheckBoxInput>
          </div>
        )
      }
    },
    {
      title: (
        <div>
          <Tooltip placement="top" title={"Allow this team to be used in a secondary game stack."}>
            <span>Secondary Game Stack</span>
          </Tooltip>
          <div>
            <StyledCheckboxWhite
              defaultChecked={true}
              onClick={(e) =>{onChangeAllTeamStackOption('AllowSecondaryStack', e.target.checked)}}
            ></StyledCheckboxWhite>
          </div>
        </div>
      ),
      key: 'allowSecondaryStack',
      render: (_, row) => {
        return (
          <CheckBoxInput row={row} _var={'AllowSecondaryStack'} _onChange={onTeamStacksChange} />
        )
      }
    },
    {
      title: (
        <div>
          <Tooltip placement="top" title={"Allow the teams RB to be stacked with DST."}>
            <span>Allow RB + DST Stack</span>
          </Tooltip>
          <div>
            <StyledCheckboxWhite
              defaultChecked={true}
              onClick={(e) =>{onChangeAllTeamStackOption('AllowRBandDSTStack', e.target.checked)}}
            ></StyledCheckboxWhite>
          </div>
        </div>
      ),
      key: 'allowRBandDSTStack',
      render: (_, row) => {
        return (
          <CheckBoxInput row={row} _var={'AllowRBandDSTStack'} _onChange={onTeamStacksChange} />
        )
      }
    },
  ]

class StackTable extends Component {
  state = {
    toggle: this.props.showdown ? 1 : 0
  }

  render() {
    const extraCols = [
      stackingOptions(this.props.onChangeHandler, this.props.onChangeAllTeamStackOption),
      teamContraints(this.props.onChangeHandler, this.props.onChangeAllTeamStackOption)
    ][this.state.toggle]

    return (
      <TeamStackOptions>
        <ToggleHeader>
          <Row>
            <Col lg={12}>
              <Menu onClick={(e) => this.setState({
                  toggle: e.key
                })} selectedKeys={[String(this.state.toggle)]} mode="horizontal">
                <Menu.Item disabled={this.props.showdown} key={0}><Tooltip placement={'top'} title={'Configure stacking positions given there is a Team Stack or Game Stack'}>Stacking Options</Tooltip></Menu.Item>
                <Menu.Item key={1}><Tooltip placement={'top'} title={'Set global Min/Max settings for an entire team'}>Team Constraints</Tooltip></Menu.Item>
              </Menu>
            </Col>
            <StyledButtonCol lg={12}>
              <div style={{float: 'right'}}>
                <Popconfirm
                  cancelText="No"
                  okText="Yes"
                  onClick={(e) => e.stopPropagation()}
                  onCancel={(e) => e.stopPropagation()}
                  onConfirm={
                    (e) => {
                      e.stopPropagation()
                      this.props.clearStackSettings()
                    }
                  }
                  title={`Permenantly clear all settings for this slate?`}
                >
                  <Button>Reset Team Stacks to Defaults</Button>
                </Popconfirm>
                <Button
                  onClick={this.props.uncheckStackingOptions}
                  style={{marginLeft: '20px', marginRight: '20px'}}
                >Uncheck all Stacking Options</Button>
              </div>
            </StyledButtonCol>
          </Row>
        </ToggleHeader>
        <StyledTable
          columns={teamGameData.concat(extraCols)}
          dataSource={this.props.rows}
          pagination={{ pageSize: 50 }}
          scroll={{ y: 700, x: 1400 }}
          bordered
          loading={this.props.loading}
          size='small'
          rowKey={_row => String(_row.TeamAbbrev)}
        />
    </TeamStackOptions>
    )
  }
}

export default StackTable
