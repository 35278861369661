import { Table, Progress } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { getPositionsClassic, normalizeLineup } from '../../../../utils/normalize-lineups'
import SaveLineupStar from '../../../../components/save-lineup-star'

import {
  HEAT_MAP_POSITIVE_COLOR_SCALE,
  HEAT_MAP_NEGATIVE_COLOR_SCALE,
  getColorForValue
} from './Analysis/utils/color'

const StyledTable = styled(Table)`
  box-shadow: 0px 6px 36px #457b9d;

  .ant-table-tbody > tr > td {
    font-size: 12px;
  }

  td {
    text-align: center;
  }

  tr th {
    text-align: center !important;
  }

  tr:first-child {
    th {
      color: white !important;
      background: #1d3557 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 5px solid #B0B0B0 !important;
  }
`

const fdPositionCols = [
  {
    title: 'PG',
    dataIndex: 'pg1',
    key: 'pg1',
    render: (player) => player.Name
  },
  {
    title: 'PG',
    dataIndex: 'pg2',
    key: 'pg2',
    render: (player) => player.Name
  },
  {
    title: 'SG',
    dataIndex: 'sg1',
    key: 'sg1',
    render: (player) => player.Name
  },
  {
    title: 'SG',
    dataIndex: 'sg2',
    key: 'sg2',
    render: (player) => player.Name
  },
  {
    title: 'SF',
    dataIndex: 'sf1',
    key: 'sf1',
    render: (player) => player.Name
  },
  {
    title: 'SF',
    dataIndex: 'sf2',
    key: 'sf2',
    render: (player) => player.Name
  },
  {
    title: 'PF',
    dataIndex: 'pf1',
    key: 'pf1',
    render: (player) => player.Name
  },
  {
    title: 'PF',
    dataIndex: 'pf2',
    key: 'pf2',
    render: (player) => player.Name
  },
  {
    title: 'C',
    dataIndex: 'c',
    key: 'c',
    render: (player) => player.Name,
    className: 'divide',
  },
]

const dkPositionCols = [
  {
    title: 'PG',
    dataIndex: 'pg1',
    key: 'pg1',
    render: (player) => player.Name
  },
  {
    title: 'SG',
    dataIndex: 'sg1',
    key: 'sg1',
    render: (player) => player.Name
  },
  {
    title: 'SF',
    dataIndex: 'sf1',
    key: 'sf1',
    render: (player) => player.Name
  },
  {
    title: 'PF',
    dataIndex: 'pf1',
    key: 'pf1',
    render: (player) => player.Name
  },
  {
    title: 'C',
    dataIndex: 'c',
    key: 'c',
    render: (player) => player.Name
  },
  {
    title: 'G',
    dataIndex: 'g',
    key: 'g',
    render: (player) => player.Name
  },
  {
    title: 'F',
    dataIndex: 'f',
    key: 'f',
    render: (player) => player.Name
  },
  {
    title: 'UTIL',
    dataIndex: 'util',
    key: 'util',
    render: (player) => player.Name
  }
]

const colLookup = {
  'dk': dkPositionCols,
  'fd': fdPositionCols
}

const LineupCols = (site, slate, showSaved) => ([{
  title: 'Lineups',
  children: [
    {
      title: '',
      render: (_, row) => {
        return (
          ''
        )
      }
    },
    ...colLookup[site],
    {
      title: 'Salary',
      key: 'salary',
      render: (_, row) => {
        let totalSal = 0
        const positions = getPositionsClassic(site, 'nba')
        positions.forEach(_pos => {
          totalSal += Number(row[_pos].Salary)
        })
        return (
          <span>
            {totalSal}
          </span>
        )
      }
    },
    {
      title: 'Proj Own',
      key: 'projOwn',
      sorter: (a, b) => {
        let totalOwnA = 0
        let totalOwnB = 0
        const positions = getPositionsClassic(site, 'nba')
        positions.forEach(_pos => {
          totalOwnA += Number(a[_pos].ProjOwn)
          totalOwnB += Number(b[_pos].ProjOwn)
        })

        return totalOwnB - totalOwnA
      },
      render: (_, row) => {
        let totalProj = 0
        const positions = getPositionsClassic(site, 'nba')
        positions.forEach(_pos => {
          totalProj += Number(row[_pos].ProjOwn)
        })
        return `${Number(totalProj).toFixed(2)}%`
      }
    },
    {
      title: 'Proj Pts',
      key: 'projPoints',
      sorter: (a, b) => {
        let totalProjA = 0
        let totalProjB = 0
        const positions = getPositionsClassic(site, 'nba')
        positions.forEach(_pos => {
          totalProjA += Number(a[_pos].ProjPtsFixed)
          totalProjB += Number(b[_pos].ProjPtsFixed)
        })

        return totalProjB - totalProjA
      },
      render: (_, row) => {
        let totalProj = 0
        const positions = getPositionsClassic(site, 'nba')
        positions.forEach(_pos => {
          totalProj += Number(row[_pos].ProjPtsFixed)
        })
        return `${Number(totalProj).toFixed(2)}`
      }
    },
    {
      title: '<10%',
      key: 'NumUnder10pct',
      render: (_, row) => {
        let totalUnder = 0
        const positions = getPositionsClassic(site, 'nba')
        positions.forEach(_pos => {
          if (Number(row[_pos].ProjOwn) < 10)
            totalUnder++
        })
        return totalUnder
      }
    },
    {
      title: '<5%',
      key: 'NumUnder5pct',
      render: (_, row) => {
        let totalUnder = 0
        const positions = getPositionsClassic(site, 'nba')
        positions.forEach(_pos => {
          if (Number(row[_pos].ProjOwn) < 5)
            totalUnder++
        })
        return totalUnder
      }
    },
    {
      title: '<2.5%',
      key: 'NumUnder2pt5pct',
      render: (_, row) => {
        let totalUnder = 0
        const positions = getPositionsClassic(site, 'nba')
        positions.forEach(_pos => {
          if (Number(row[_pos].ProjOwn) < 2.5)
            totalUnder++
        })
        return totalUnder
      }
    },
  ]
}])

const LineupTable = ({ loading, rows, site, slate, showSaved, currentLineupNumber, totalLineupNumber, filterPlayer }) => {
  let luRows = []
  if (rows && rows.length > 0) {
    rows.forEach((row, i) => {
      luRows[i] = {
        ...normalizeLineup(row, site, false, 'nba'),
        rawTable: row
      }
    })
  }

  return (
    <StyledTable
      columns={LineupCols(site, slate, showSaved, filterPlayer)}
      dataSource={luRows}
      scroll={{ x: '90%', }}
      bordered
      loading={{
        indicator: (
          <Progress type="circle" percent={(Number(currentLineupNumber)/Number(totalLineupNumber)*100).toFixed(0)} />
        ),
        spinning: loading
      }}
      size='small'
      pagination={{ pageSize: 50 }}
      rowKey={_row => {
        let _run = 1
        if (_row.rawTable)
          _run = _row.rawTable[0].Run + 1
        return `lineup-table-${String(_run)}`
      }}
    />
  )
}

export default LineupTable
