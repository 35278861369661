import {
  Row,
  Col,
  Card,
  Radio,
  Button,
  Popconfirm,
  Input,
  Tooltip,
  Upload,
  Switch,
  Checkbox
} from 'antd'
import {
  UploadOutlined,
  DownloadOutlined
} from '@ant-design/icons'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { fuzzyObjSearch } from '../../../../utils/search'

import * as lineupActions from '../../../../actions/lineup'
import * as analysisActions from '../../../../actions/analysis'

import PlayersTable from './players-table'
import LastUpdated from '../../../../components/last-updated'

import { downloadCSV, writeRowCSVFromArray } from '../../../../utils/csv'
import { CPTDisplay } from '../../../../utils/showdown'

import SetPositionBaseExpModal from './modals/set-position-base-exp'
import PlayerStatsModal from './modals/player-stats'

const CSV_HEADINGS = ["Id","Name","Position","Team","ProjPts","ProjOwn","MinExp","MaxExp"]

const StyledSearch = styled.div`
  font-size: 18px;

  input {
    height: 40px;
  }

  .ant-btn {
    height: 40px;
  }
`
const ScrollableContainer = styled.div`
  width: 100%;
`

class Players extends PureComponent {
  state = {
    position: '',
    searchInput: '',
    hideZeroProjPts: true,
    baseExpVisible: false,
    hideZeroMaxExp: false,
    playerStatsVisible: false,
    selectedPlayer: {}
  }

  static propTypes = {
    // Redux State goes here
    updatePlayersTable: PropTypes.func.isRequired
  }

  projPointsValid(rowData) {
    return ((rowData.UserProj ? Number(rowData.UserProj) >= 0 : true) && (rowData.UserOwn ? (Number(rowData.UserOwn) >= 0 && Number(rowData.UserOwn) <= 100) : true))
  }

  onProjectionChange(rowData) {
    const _players = this.props.players.data

    let isValid
    const players = _players.map(_player => {
      if (_player.Id === rowData.Id) {
        // if changes are not valid do not update
        isValid = this.projPointsValid(rowData)
        if (!isValid) return _player

        // return player with updated values
        return rowData
      }

      return _player
    })

    if (isValid)
      this.props.updatePlayersTable({
        payload: players,
        rowData,
        slate: this.props.slate,
        site: this.props.site,
        sport: this.props.sport,
        counter: this.props.counter,
        season: this.props.season
      })
  }

  // direction up or down
  onBoostChange(row, direction) {
    const _players = this.props.players.data
    const rowData = {...row}

    const players = _players.map(_player => {
      if (_player.Id === rowData.Id) {
        // if changes are not valid do not update
        // isValid = this.projPointsValid(rowData)
        let newBoost = Number(rowData.Boost || 0)
        if (direction === 'up') newBoost += 1
        else if (direction === 'down') newBoost -= 1
        
        if (newBoost > 5) newBoost = 5
        else if (newBoost < -5) newBoost = -5

        rowData.Boost = newBoost
        // return player with updated values
        return rowData
      }

      return _player
    })

    this.props.updatePlayersTable({
      payload: players,
      rowData,
      slate: this.props.slate,
      site: this.props.site,
      sport: this.props.sport,
      counter: this.props.counter,
      season: this.props.season
    })
  }

  async _uploadCustomInputs(_projections) {
    // Get Col Indexes
    const headers = _projections[0]
    let idIndex = headers.indexOf('Id')
    // We can't upload if no ID
    // TODO throw error to user
    if (idIndex === -1) {
      idIndex = headers.indexOf('ID')
      if (idIndex === -1) {
        console.error('No ID heading')
        return
      }
    }

    const projPtsIndex = headers.indexOf('ProjPts')
    const minExpIndex = headers.indexOf('MinExp')
    const maxExpIndex = headers.indexOf('MaxExp')
    const projOwnIndex = headers.indexOf('ProjOwn')
    // We must have something to change
    // TODO throw error to user
    if (projPtsIndex === -1 && minExpIndex === -1 && maxExpIndex === -1) {
      console.error('Could not find any expected headings')
      return
    }

    let playerMap = {}
    for (let i = 1; i < _projections.length ; i++) {
      const _pp = ((projPtsIndex > -1 && _projections[i][projPtsIndex] !== '' && typeof(_projections[i][projPtsIndex]) !== "undefined") ?
        Number(_projections[i][projPtsIndex]) :
        undefined
      )
      const _minExp = ((minExpIndex > -1 && _projections[i][minExpIndex] !== '' && typeof(_projections[i][minExpIndex]) !== "undefined") ?
        Number(_projections[i][minExpIndex]) :
        undefined
      )
      const _maxExp = ((maxExpIndex > -1 && _projections[i][maxExpIndex] !== '' && typeof(_projections[i][maxExpIndex]) !== "undefined") ?
        Number(_projections[i][maxExpIndex]) :
        undefined
      )
      const _po = ((projOwnIndex > -1 && _projections[i][projOwnIndex] !== '' && typeof(_projections[i][projOwnIndex]) !== "undefined") ?
        Number(_projections[i][projOwnIndex]) :
        undefined
      )

      // Don't add unless there is some change
      if (typeof(pp) !== "undefined" || typeof(_minExp) !== "undefined" || typeof(_maxExp) !== "undefined" || typeof(_po) !== "undefined") {
        playerMap[_projections[i][idIndex]] = {
          ProjPts: _pp,
          MinExp: _minExp,
          MaxExp: _maxExp,
          ProjOwn: _po
        }
      }
    }

    const rowData = []
    const players = this.props.players.data.map(_player => {
      if (playerMap[_player.Id]) {
        const _newPlayer = { ..._player }

        // Set proj pts
        if (typeof(playerMap[_player.Id].ProjPts) !== "undefined" && (String(playerMap[_player.Id].ProjPts) !== String(_newPlayer.ProjPts))) {
          // Keep track of the original Proj Pts
          _newPlayer.HouseProjPts = _newPlayer.HouseProjPts ? _newPlayer.HouseProjPts : _newPlayer.ProjPts

          _newPlayer.UserProj = playerMap[_player.Id].ProjPts
          _newPlayer.ProjPts = playerMap[_player.Id].ProjPts
        }
        // Set proj own
        if (typeof(playerMap[_player.Id].ProjOwn) !== "undefined" && (String(playerMap[_player.Id].ProjOwn) !== String(_newPlayer.ProjOwn))) {
          // Keep track of the original Proj Pts
          _newPlayer.HouseOwn = _newPlayer.HouseOwn ? _newPlayer.HouseOwn : _newPlayer.ProjOwn

          _newPlayer.UserOwn = playerMap[_player.Id].ProjOwn
          _newPlayer.ProjOwn = playerMap[_player.Id].ProjOwn
        }
        // Set min exp
        if (typeof(playerMap[_player.Id].MinExp) !== "undefined")
          _newPlayer.MinExp = playerMap[_player.Id].MinExp

        // Set min exp
        if (typeof(playerMap[_player.Id].MaxExp) !== "undefined")
          _newPlayer.MaxExp = playerMap[_player.Id].MaxExp

        // return player with updated values
        rowData.push(_newPlayer)
        return _newPlayer
      }

      return _player
    })

    this.props.updatePlayersTable({
      payload: players,
      rowData,
      slate: this.props.slate,
      site: this.props.site,
      sport: this.props.sport,
      season: this.props.season,
      counter: this.props.counter
    })
  }

  async downloadTemplate() {
    const players = this.props.players.data

    let csv = '';
    // Write headings
    csv = writeRowCSVFromArray(csv, CSV_HEADINGS)

    players.forEach(player => {
      csv = writeRowCSVFromArray(csv, [
        player.Id,
        player.Name,
        player.Position,
        player.TeamAbbrev,
        player.UserProj || '',
        player.UserOwn || '',
        player.MinExp || '',
        player.MaxExp || ''
      ])
    })

    downloadCSV(csv, `${this.props.sport}-${this.props.counter}-${this.props.site}-${this.props.slate}-player-inputs.csv`)
  }

  _updatePosition(e) {
    const position = e.target.value
    if (position === 'ALL')
      this.setState({
        position: null
      })
    else
      this.setState({
        position
      })
  }

  _sortPlayersByPosition(data) {
    const { position, hideZeroProjPts, hideZeroMaxExp } = this.state

    // Showdown
    if (this.props.showdown) {
      let _position = position

      return data.filter(player => {
        if (hideZeroProjPts) {
          if (Number(player.ProjPts) === 0) {
            return false
          }
        }
        if (hideZeroMaxExp) {
          if (Number(player.MaxExp) === 0) {
            return false
          }
        }

        return player.RosterPosition === _position
      })
    }

    // Classic OPT
    if (!position) return data.filter(player => {
      if (hideZeroProjPts) {
        if (Number(player.ProjPts) === 0) {
          return false
        }
      }
      if (hideZeroMaxExp) {
        if (Number(player.MaxExp) === 0) {
          return false
        }
      }
      return true
    })

    return data.filter(player => {
      let found = false
      player.Positions.forEach(pos => {
        if (pos === position)
          found = true
      })
      if (hideZeroProjPts) {
        if (Number(player.ProjPts) === 0) {
          found = false
        }
      }
      if (hideZeroMaxExp) {
        if (Number(player.MaxExp) === 0) {
          return false
        }
      }
      return found
    })
  }

  _search(value) {
    this.setState({
      searchInput: value
    })
  }

  _filterDataForSearchTerm = (data, searchTerm) => {
    return data.filter(obj => fuzzyObjSearch(searchTerm, obj))
  }

  _selectPlayerStats(player) {
    this.props.fetchPlayerStats({ name: player.Name, position: player.Position })
    this.setState({playerStatsVisible: true, selectedPlayer: player})
  }

  render() {
    const {
      players
    } = this.props

    const { searchInput } = this.state
    let data = players.data ? this._sortPlayersByPosition(players.data) : []
    if (searchInput)
      data = this._filterDataForSearchTerm(data, searchInput)
    
    return (
      <div className="Players">
        <Card>
          <Row>
            <Col lg={4}>
              <h3>Search:</h3>
              <StyledSearch>
                <Input.Search
                  placeholder="player name"
                  onChange={(e) => this._search(e.target.value)}
                  style={{ width: '100%' }}
                />
              </StyledSearch>
            </Col>
            <Col lg={8} offset={1}>
              <h3>Position:</h3>
              <Radio.Group
                onChange={(e) => this._updatePosition(e)}
                value={this.state.position}
              >
                {
                  this.props.showdown ? (
                    <>
                      <Radio.Button value={''}>FLEX</Radio.Button>
                      <Radio.Button value={"CPT"}>{CPTDisplay[this.props.site]}</Radio.Button>
                    </>
                  ) : (
                    <>
                      <Radio.Button value={''}>ALL</Radio.Button>
                      <Radio.Button value={"C"}>C</Radio.Button>
                      <Radio.Button value={"1B"}>1B</Radio.Button>
                      <Radio.Button value={"2B"}>2B</Radio.Button>
                      <Radio.Button value={"SS"}>SS</Radio.Button>
                      <Radio.Button value={"3B"}>3B</Radio.Button>
                      <Radio.Button value={"OF"}>OF</Radio.Button>
                      <Radio.Button value={"P"}>P</Radio.Button>
                    </>
                  )
                }

              </Radio.Group>
              <div>
                <Checkbox
                  style={{
                    marginTop: '10px'
                  }}
                  checked={this.state.hideZeroProjPts}
                  onChange={(e) => {
                    this.setState({
                      hideZeroProjPts: !this.state.hideZeroProjPts
                    })
                  }}
                >
                    Hide Players with 0 Proj Pts
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  style={{
                    marginTop: '10px'
                  }}
                  onChange={(e) => {
                    this.setState({
                      hideZeroMaxExp: e.target.checked
                    })
                  }}
                >
                    Hide Players with 0 Max Exp
                </Checkbox>
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={24} md={24}>
                  <h3>Upload:</h3>
                  <Upload
                    accept=".csv"
                    showUploadList={false}
                    beforeUpload={file => {
                        const reader = new FileReader()

                        reader.onload = e => {
                            const _csvRows = e.target.result.split(/\r?\n/)
                            const _csvCols = []
                            _csvRows.forEach(row => {
                              _csvCols.push(row.split(','))
                            })
                            this._uploadCustomInputs(_csvCols)
                        }
                        reader.readAsText(file)

                        // Prevent upload
                        return false;
                    }}
                  >
                    <Tooltip
                      title={'Format: ID,Name,Position,ProjPts,ProjOwn,MinExp,MaxExp'}
                      mouseEnterDelay={0.7}
                      >
                      <Button>
                        <UploadOutlined /> Custom Inputs
                      </Button>
                    </Tooltip>
                  </Upload>
                </Col>
                <Col lg={8} style={{marginTop: '5px'}}>
                  <h3>Download:</h3>
                  <Tooltip
                    title={'Exports player table as a CSV for you to work off of'}
                    mouseEnterDelay={0.7}
                    >
                    <Button onClick={this.downloadTemplate.bind(this)}>
                      <DownloadOutlined /> Players Template
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
            <Col lg={4} offset={1}>
              <Popconfirm
                cancelText="No"
                okText="Yes"
                onClick={(e) => e.stopPropagation()}
                onCancel={(e) => e.stopPropagation()}
                onConfirm={
                  (e) => {
                    e.stopPropagation()
                    this.props.clearPlayerData()
                  }
                }
                title={`Permenantly clear all inputted data for the ${this.props.slate} slate?`}
              >
                <Button>Reset Inputs to Defaults</Button>
              </Popconfirm>
              <div style={{marginTop:"12px"}}>
                <Row>
                  <Col lg={12}>
                    <Popconfirm
                      cancelText="No"
                      okText="Yes"
                      onClick={(e) => e.stopPropagation()}
                      onCancel={(e) => e.stopPropagation()}
                      onConfirm={
                        (e) => {
                          e.stopPropagation()
                          this.props.zeroExposures()
                        }
                      }
                      title={`This sets default for exposures to 0. This will erase your existing inputted data if you are not logged in. Proceed?`}
                    >
                      <Button>Set Default Exp to 0</Button>
                    </Popconfirm>
                  </Col>
                </Row>
              </div>
              <div style={{marginTop:"12px"}}>
                <Row>
                  <Col lg={12}>
                    {
                      this.props.showdown ? (
                        ''
                      ) : (
                        <Button onClick={() => {this.setState({baseExpVisible: true})}}>Set Global MaxExp</Button>
                      )
                    }
                    <SetPositionBaseExpModal players={this.props.players.data} onExposureChange={this.props.onExposureChange} visible={this.state.baseExpVisible} setVisible={(v) => {this.setState({baseExpVisible: v})}} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
      <LastUpdated timestamp={players ? players.updatedAt : null} refresh={() => {this.props.fetchPlayers({slate: this.props.slate, site: this.props.site, sport: this.props.sport, shwodwon: this.props.showdown})}}/>
      <ScrollableContainer>
        <PlayersTable
          loading={players.loading}
          rows={data || []}
          expOnChange={this.props.onExposureChange}
          userProjOnChange={this.onProjectionChange.bind(this)}
          permissions={this.props.subscription.permissions}
          site={this.props.site}
          sport={this.props.sport}
          showdown={this.props.showdown}
          selectPlayerStats={this._selectPlayerStats.bind(this)}
          onBoostChange={this.onBoostChange.bind(this)}
        />
      </ScrollableContainer>
      <PlayerStatsModal 
        loading={this.props.analysis.playerStats.loading}
        visible={this.state.playerStatsVisible}
        setVisible={(v) => {this.setState({playerStatsVisible: v})}}
        playerStats={this.props.analysis.playerStats.data}
        player={this.state.selectedPlayer}
        site={this.props.site}
        sport={this.props.sport}
      />
      </div>
    )
  }
}

export default connect(
  state => ({
    players: state.lineup.players,
    loggedin: state.auth.loggedin,
    analysis: state.analysis,
    subscription: state.account.subscription,
    playerStats: state.analysis.playerStats
  }),
  {
    updatePlayersTable: lineupActions.updatePlayersTable,
    fetchPlayers: lineupActions.fetchPlayers,
    fetchPlayerStats: analysisActions.fetchPlayerStats,
  }
)(Players)
