import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'
import onOff from '../containers/analysis/onOff'

const StyledTable = styled(Table)`
  margin-top: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;

  .ant-table-tbody > tr > td {
    font-size: 12px;
  }
  .ant-table-column-title {
    font-size: 14px;
  }

  tr:first-child {
    th {
      color: white !important;
      background-color: #1d3557 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }
`
const RedValue = styled.span`
  color: red;
`

const OnOffGamesCols = (dateKeys) => {
  const baseCols = [{
    title: 'Missed Game Logs',
    key: 'games',
    children: [
      {
        title: 'Name',
        dataIndex: 'PlayerName',
        key: 'player',
        width: '100px'
      },
    ]
  }]

  dateKeys.forEach(k => { baseCols[0].children.push({
    title: k,
    dataIndex: k,
    key: k,
    render: (_, r) => {
      return ( r[k] || '-')
    }
  })})

  return baseCols
}

const OnOffTable = ({ onOffGameData=[], displayKey='Mins', loading }) => {
  
  let _playerData = {}
  let _dates = {}
  // Don't show anything if there are no games
  if (onOffGameData.length) {
    // Only show when player off the court
    onOffGameData.forEach(e => {
      if (e.Status === 'TmNoPlay') {
        if (Object.keys(_dates).length < 6) {
          _dates[e.Date] = true
        }

        if (Object.keys(_dates).indexOf(e.Date) >= 0) {
          // Add new player to data
          if (!_playerData[e.PlayerName]) {
            _playerData[e.PlayerName] = {
              PlayerName: e.PlayerName
            }
          }

          // Add display data under date
          _playerData[e.PlayerName][e.Date] = e[displayKey]
        }
      }
    })
  }

  const cols = OnOffGamesCols(Object.keys(_dates).sort().reverse())
  const _data = Object.values(_playerData)

  return (
    <StyledTable
      columns={cols}
      dataSource={_data}
      loading={loading}
      scroll={{ x: '350px', y: '189px' }}
      bordered
      size='small'
      pagination={false}
      rowKey={(_row, i) => {
        return `on-off-${String(i)}`
      }}
    />
  )
}

export default OnOffTable
