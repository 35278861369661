export default (key, row) => {
  // is key team or opp
  const isTeam = key.indexOf('team') >= 0
  const position = getPositionFromKey(key)
  const isMin = key.indexOf('Min') >= 0

  const checkAgainst = Number(row[key])
  const converseVal = Number(row[getConverseKey(isTeam, position, isMin)])

  // if any min or max is greater than 7
  if (checkAgainst > 7) return true

  // check to make sure val is ok against converse
  if (isMin) {
    if (checkAgainst > converseVal) return true
  } else {
    if (checkAgainst < converseVal) return true
  }

  if (position === 'Flex') {
    // check sum
    const [ min, max ] = getFlexMinMax(row, isTeam)

    if (isMin && checkAgainst > max) return true

    if (!isMin && checkAgainst < min) {
      return true
    }
  }

  return false
}

const getPositionFromKey = (k) => {
  const positions = ['RB', 'WR', 'TE', 'Flex']
  let selectedPos = ''

  positions.forEach(p => {
    if (k.indexOf(p) >= 0) {
      selectedPos = p
      return p
    }
  })

  return selectedPos
}

const getConverseKey = (isTeam, position, isMin) => {
  let k = ''
  if (isTeam)
    k += 'team'
  else
    k += 'opp'

  k += position

  if (isMin)
    k += 'Max'
  else
    k += 'Min'

  return k
}

const getFlexMinMax = (row, isTeam) => {
  let min = 0
  let max = 0
  const positions = ['RB', 'WR', 'TE']
  let prefix = isTeam ? 'team' : 'opp'

  positions.forEach(p => {
    const _min = Number(row[`${prefix}${p}Min`])
    const _max = Number(row[`${prefix}${p}Max`])

    min += _min
    max += _max
  })

  return [ min, max ]
}
