import { Table, Row, Col, Input, Tag } from 'antd'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import {
  DeleteOutlined,
  PlusCircleOutlined
} from '@ant-design/icons'

import { getAdvancedRowsFromBasic } from '../../../../utils/specific-stacks'
import hasErrors from './utils/errors'

const StyledTable = styled(Table)`
  td {
    text-align: center;
    padding: 2px !important;
  }

  tr th {
    text-align: center !important;
    font-size: 13px;
  }

  tr:first-child {
    th {
      color: white !important;
      background: #556672 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 1px solid #556672 !important;
  }

  .error {
    font-weight: 500;
    color: red;
  }

  .freq-divide {
    input {
      border-right: 1px solid #556672 !important;
    }
  }

  input {
    text-align: center;
  }
`
const PlusCircleDiv = styled.div`
  text-align: center;
  width: 100%;
  font-size: 22px;
  margin-top: 15px;
`

const StyledPlusCircle = styled(PlusCircleOutlined)`
  cursor: pointer;
  color: #1890ff !important;
`

const StyledNameInput = styled(Input)`
  display: inline-block;
  width: 70% !important;
  margin-left: 5px !important;
  font-size: 10px;
`

const NoSelect = styled.span`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const rowValsTeamStack = {
  'teamFlexMin': 'Min Flex',
  'teamFlexMax': 'Max Flex',
  'teamRBMin': 'RB Min',
  'teamRBMax': 'RB Max',
  'teamWRMin': 'WR Min',
  'teamWRMax': 'WR Max',
  'teamTEMin': 'TE Min',
  'teamTEMax': 'TE Max'
}
const rowValsGameStack = {
  'oppFlexMin': 'Opp. Min Flex',
  'oppFlexMax': 'Opp. Max Flex',
  'oppRBMin': 'Opp. RB Min',
  'oppRBMax': 'Opp. RB Max',
  'oppWRMin': 'Opp. WR Min',
  'oppWRMax': 'Opp. WR Max',
  'oppTEMin': 'Opp. TE Min',
  'oppTEMax': 'Opp. TE Max',
}

const cols = (updateSpecificStackValue, removeSpecificStackRow, disabled) => {
  const teamStackCols = []
  Object.keys(rowValsTeamStack).forEach(key => {
    teamStackCols.push(
      {
        title: rowValsTeamStack[key],
        dataIndex: key,
        key: key,
        render: (val, row) => {
          let fontWeight = 200

          if (key.indexOf('Min') > 0 && Number(val) > 0)
            fontWeight = 600
          else if (key.indexOf('Max') > 0 && Number(val) !== 7)
            fontWeight = 600

          let className = ''
          if (key.indexOf('Max') > 0)
            className = 'divide'

          if (hasErrors(key, row))
            className = 'error'

          return (
            <Input
              className={className}
              style={{fontWeight}}
              value={Number(val)}
              onChange={(e) => {updateSpecificStackValue(key, Number(e.target.value), row.index)}}
              disabled={row.disabled || disabled}
            />
          )
        }
      },
    )
  })
  const gameStackCols = []
  Object.keys(rowValsGameStack).forEach(key => {
    gameStackCols.push(
      {
        title: rowValsGameStack[key],
        dataIndex: key,
        key: key,
        render: (val, row) => {
          let fontWeight = 200

          if (key.indexOf('Min') > 0 && Number(val) > 0)
            fontWeight = 600
          else if (key.indexOf('Max') > 0 && Number(val) !== 7)
            fontWeight = 600

          let className = ''
          if (key.indexOf('Max') > 0)
            className = 'divide'

          if (hasErrors(key, row))
            className = 'error'

          return (
            <Input
              className={className}
              style={{fontWeight}}
              value={Number(val)}
              onChange={(e) => {updateSpecificStackValue(key, Number(e.target.value), row.index)}}
              disabled={row.disabled || disabled}
            />
          )
        }
      },
    )
  })

  return [
    {
      title: '',
      key: 'freqhead',
      children: [
        {
          title: 'Label',
          dataIndex: 'label',
          key: 'label',
          render: (v, row) => {
            if (row.edit)
              return (
                <StyledNameInput
                  placeholder={'add label...'}
                  type="text"
                  autoFocus
                  onBlur={(e) => {
                    updateSpecificStackValue(['label', 'edit'], [e.target.value || v || '-', false], row.index, true)
                  }}
                />
              )

            return (
              <div
                onDoubleClick={() => {
                  updateSpecificStackValue('edit', true, row.index)
                }}
              >
                <Tag><NoSelect>{v}</NoSelect></Tag>
              </div>
            )
          }
        },
        {
          title: 'Freq. (%)',
          dataIndex: 'freq',
          key: 'freq',
          className: 'freq-divide',
          render: (val, row) => {
            return (
              <Input value={((Number(val) * 100)).toFixed(0)} onChange={(e) => {updateSpecificStackValue('freq', Number((Number(e.target.value || 0) / 100))|| 0, row.index)}} disabled={row.disabled || disabled} />
            )
          },
        }
      ],
    },
    {
      title: 'Team Stacks',
      key: 'teamstacks',
      children: teamStackCols
    },
    {
      title: 'Game Stacks',
      key: 'gamestacks',
      children: gameStackCols
    },
    {
      title: '',
      key: 'deletehead',
      children: [{
        render: (row) => {
            if (!row.disabled && !disabled)
              return (<span><DeleteOutlined onClick={() => {removeSpecificStackRow(row.index)}}/></span>)
        }
      }],
    },

  ]
}

export default ({ settings, addSpecificStackRow, removeSpecificStackRow, updateSpecificStackValue, disabled }) => {
  const sanitizedSpecificStackData = sanitizeSpecificStacks(settings.data.specificStacks)

  return (
    <div>
      <StyledTable
        bordered={true}
        columns={cols(updateSpecificStackValue, removeSpecificStackRow, disabled)}
        dataSource={sanitizedSpecificStackData}
        pagination={false}
        scroll={{ x: 1300 }}
      />
    <PlusCircleDiv>
      <StyledPlusCircle onClick={() => {
        if (!disabled)
          addSpecificStackRow()
        }
      }/>
    </PlusCircleDiv>
    </div>
  )
}

const sanitizeSpecificStacks = (specificStacks) => {
  const _sanitizedStacks = []
  specificStacks.forEach((s, i) => {
    const _s = { ...s }
    _s.index = i
    _sanitizedStacks.push(_s)
  })

  return _sanitizedStacks
}
