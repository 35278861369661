import { Spin } from 'antd'
import React, { useState, useEffect } from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements'
import styled from 'styled-components'

const StyledError = styled.div`
  color: red;
  margin-top: 10px;
`
// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
}
const UpdateBillingLink = styled.div`
  cursor: pointer;
  color: #1890ff;

  &:hover{
    font-weight: 600;
  }
`
const StyledButton = styled.button`
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  cursor: pointer;
  margin-top: 50px;
  width: 30%;
  height: 30px;

  :disabled {
    cursor: auto;
    background-color: #DCDCDC;
    border: none;
  }
`

const CreditCard = ({ subscription, stripe, handleCardToken }) => {
  const [ error, setError ] = useState()
  const [ newCard, setNewCard ] = useState(!subscription.last4)

  const handleChange = ({error}) => {
    if (error) {
      setError(error.message)
    }
  }

  const submitNewCard = (evt) => {
    evt.preventDefault()
    stripe.createToken().then((card) => {handleCardToken(card)})
  }

  useEffect(() => {
    setNewCard(false)
  }, [subscription.last4])

  return (
    <div>
      {
        newCard ? (
          <div>
            <h4 style={{marginBottom: '20px'}}>New Card</h4>
            <form onSubmit={submitNewCard}>
              <CardElement
                onChange={handleChange}
                {...createOptions()}
              />
                <StyledButton disabled={subscription.updating} type="submit">{subscription.updating ? (<Spin />) : 'Submit'}</StyledButton>
            </form>
          </div>
        ) : (
          <div>
            <div>
              **** **** **** { subscription.last4 }
            </div>
            <UpdateBillingLink
              style={{marginTop: '20px'}}
              onClick={() => {
                setNewCard(true)
              }}
            >
              Update Billing
            </UpdateBillingLink>
          </div>
        )
      }
      <StyledError>
        {error}
      </StyledError>
    </div>
  )
}

export default injectStripe(CreditCard)
