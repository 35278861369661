import { Tooltip } from 'antd'
import {
  BookOutlined
} from '@ant-design/icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { calcESTDay, getDayKeyFromDate } from '../sagas/lineup'
import { TAG_KEY } from '../constants/local-storage-keys'

class PlayerTag extends Component {
  state = {
    saved: false,
  }

  componentDidMount() {
    this.setState({
      saved: this.isSaved()
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name) {
      this.setState({
        saved: this.isSaved()
      })
    }
  }

  isSaved() {
    let savedTags = JSON.parse(localStorage.getItem(TAG_KEY))

    return !!savedTags[this.props.name]
  }

  saveTag() {
    this.setState({
      saved: !this.state.saved
    })

    let savedTags = JSON.parse(localStorage.getItem(TAG_KEY))
    savedTags[this.props.name] = !(!!savedTags[this.props.name])
    localStorage.setItem(TAG_KEY, JSON.stringify(savedTags))
  }

  render() {
    const loggedin = this.props.auth.loggedin

    if (loggedin) {
      if (this.props.clickable) {
        return (
          <Tooltip title={'Tag a player to mark it in your Player Table.'} mouseEnterDelay={0.5}>
            <BookOutlined
              onClick={this.saveTag.bind(this)}
              theme={(this.state.saved) ? "twoTone" : "outlined"} twoToneColor="#ffea00"
            />
          </Tooltip>
        )
      } else {
        return (
          <BookOutlined
            theme={(this.state.saved) ? "twoTone" : "outlined"} twoToneColor="#ffea00"
          />
        )
      }
    } else {
      return (
        <Tooltip title={'You must be logged in to tag players'} mouseEnterDelay={0.1}>
          <BookOutlined
            theme={(this.props.saved || this.state.saved) ? "twoTone" : "outlined"} twoToneColor="#ffea00"
          />
        </Tooltip>
      )
    }
  }
}

export default connect(
  state => ({
    auth: state.auth,
    savedLineups: state.lineup.savedLineups
  }),
  {
  }
)(PlayerTag)
