import { Upload, Tooltip, Button, Row, Col, Table, Checkbox, Divider } from 'antd'
import {
    UploadOutlined,
    LockOutlined
  } from '@ant-design/icons'
import { connect } from 'react-redux'
import * as afterStartActions from '../../../../actions/after-start'
import CollapsableCard from '../../../../components/collapsable-card-after-start'
import React from 'react'
import styled from 'styled-components'
import NewBanner from '../../../../components/new-banner'

import fetchExposureStats, { mergeLineupsWithPlayerTable } from '../Lineups/utils/fetch-exposure-stats'

const StyledCheckbox = styled(Checkbox)`
  padding: 15px !important;
`
const AfterStartCheckbox = styled(Checkbox)`
  margin-left: 10px !important;
  font-size: 12px !important;
`


// Justin Herbert (20388489) (LOCKED)
const formatPlayerText = (text) => {
    // split text at spaces
    let _splitText = text.split(' ')
    
    let _text = ''
    let locked = false
    _splitText.forEach(piece => {
        if (piece.indexOf('(') == -1) {
            _text += `${piece} `
        } else {
            if (piece == '(LOCKED)') {
                locked = true
            }
                
        } 
    })

    if (locked) {
        return (
            <span style={{color: '#909090'}}>{_text} <LockOutlined /></span>
        )
    }
    return _text
}

const LineupTable = ({lus}) => {
    const tableColums = [
        {
            title: 'QB',
            dataIndex: 'qb',
            key: 'qb',
        },
        {
            title: 'RB',
            dataIndex: 'rb1',
            key: 'rb1',
        },
        {
            title: 'RB',
            dataIndex: 'rb2',
            key: 'rb2',
        },
        {
            title: 'WR',
            dataIndex: 'wr1',
            key: 'wr1',
        },
        {
            title: 'WR',
            dataIndex: 'wr2',
            key: 'wr2',
        },
        {
            title: 'WR',
            dataIndex: 'wr3',
            key: 'wr3',
        },
        {
            title: 'TE',
            dataIndex: 'te',
            key: 'te',
        },
        {
            title: 'FLEX',
            dataIndex: 'flex',
            key: 'flex',
        },
        {
            title: 'DST',
            dataIndex: 'dst',
            key: 'dst',
        },
    ]

    const mappedLUs = lus.map(lu => {
        return {
            'qb': formatPlayerText(lu[4]),
            'rb1': formatPlayerText(lu[5]),
            'rb2': formatPlayerText(lu[6]),
            'wr1': formatPlayerText(lu[7]),
            'wr2': formatPlayerText(lu[8]),
            'wr3': formatPlayerText(lu[9]),
            'te': formatPlayerText(lu[10]),
            'flex': formatPlayerText(lu[11]),
            'dst': formatPlayerText(lu[12]),

        }
    })

    return (
        <Table
            dataSource={mappedLUs}
            columns={tableColums}
            size={'small'}
            rowKey={(_row, i) => {
                return `after-start-lus-${String(i)}`
            }}
        />
    )
}

const AfterStart = ( {site, receiveCSVUpload, afterStartLineups, activateSlate, activateAfterStart, afterStartActivated }) => {

    const _uploadLineupCSV = (_csvCols) => {
        const headers = _csvCols[0]
        let lineup
        let lineups = []
        for (let i = 1; i < _csvCols.length ; i++) {
            lineup = _csvCols[i].slice(0, 13)
            // console.log(lineup)
            if (lineup[0] == '')
                break
            // TODO format for opt
            lineups.push(lineup)
        }

        // Add to state
        receiveCSVUpload({ lineups })
    }

    const _getCollapsableCards = (sortedLineups) => {
       return Object.keys(sortedLineups).map(k => {
            return (
                <Row>
                    <Col lg={1}>
                        <StyledCheckbox 
                            checked={sortedLineups[k].active} 
                            onClick={(e) => {
                                activateSlate({slateKey: k, active: e.target.checked})
                            }}
                        />
                    </Col>
                    <Col lg={20}>
                        <CollapsableCard title={`${sortedLineups[k].lineups[0][1]} (${sortedLineups[k].lineups.length})`} defaultShow={false}>
                            <LineupTable lus={sortedLineups[k].lineups} />
                        </CollapsableCard>
                    </Col>
                </Row>
                
            )
        })
    }

    const sortedLineupCards = _getCollapsableCards(afterStartLineups || [])

    return (
        <div>
            <Row>
                <Col lg={4}>
                <h3>Upload:</h3>
                    <Upload
                    accept=".csv"
                    showUploadList={false}
                    beforeUpload={file => {
                        const reader = new FileReader()

                        reader.onload = e => {
                            const _csvRows = e.target.result.split(/\r?\n/)
                            const _csvCols = []
                            _csvRows.forEach(row => {
                                _csvCols.push(row.split(','))
                            })
                            _uploadLineupCSV(_csvCols)
                        }
                        reader.readAsText(file)

                        // Prevent upload
                        return false;
                    }}
                    >
                    <Tooltip
                        >
                        <Button>
                            <UploadOutlined /> Upload Lineups
                        </Button>
                    </Tooltip>
                    </Upload>
                    <AfterStartCheckbox
                        style={{marginTop: '20px'}}
                        disabled={sortedLineupCards.length === 0}
                        checked={afterStartActivated}
                        onChange={(e) => {
                            activateAfterStart({activated: e.target.checked})
                        }}
                        >Build With After Start
                    </AfterStartCheckbox>
                </Col>
                <Col lg={4} offset={16}>
                
                </Col>
            </Row>
            <Row>
                <Divider/>
            </Row>
            <Row>
                <Col lg={24}>
                    {
                        sortedLineupCards
                    }
                </Col>
            </Row>
        </div>
    )
}

export default connect(
  state => ({
    afterStartLineups: state.afterStart.afterStartLineups.data,
    afterStartActivated: state.afterStart.activated,
    loggedin: state.auth.loggedin,
    subscription: state.account.subscription,
  }),
  {
    receiveCSVUpload: afterStartActions.receiveCSVUpload,
    activateSlate: afterStartActions.activateSlate,
    activateAfterStart: afterStartActions.activateAfterStart
  }
)(AfterStart)