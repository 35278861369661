import { Card, Row, Col, Input, Button, Form, Alert, Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Elements, StripeProvider, injectStripe } from 'react-stripe-elements'

import * as accountActions from '../../actions/account'
import PlanCard from '../../components/plan-card'
import CreditCard from '../../components/credit-card'
import Months from '../../constants/months'
import { stripe_key } from '../../constants/stripe'
import { NFLPlans, NBAPlans, BundlePlans, LegacyPlans } from '../plans/plan-info'
import SaleBanner from '../../components/sale-banner'

import ResetPassword from './reset-password'

const AccountContainer = styled.div`
  padding: 100px 5% 76px;
  min-height: 100vh;
  border-radius: 10px;
`
const AccountTitle = styled.div`
  font-size: 36px;
  color: #1d3557;
  margin-bottom: 50px;
`
const AccountSection = styled(Card)`
`
const SectionTitle = styled.div`
  font-size: 18px;
  color: #1d3557;
  margin-bottom: 25px;
`
const AccountInfoInput = styled(Input)`
  margin-bottom: 25px;
  width: 80% !important;

  .ant-input-group-addon {
    width: 30%;
  }
`
const AccountInfoPassword = styled(Input.Password)`
  width: 90% !important;

  .ant-input-group-addon {
    width: 40%;
  }
`
const ErrorMessage = styled.div`
  color: red;
`
const CancelLink = styled(Link)`
  padding-top: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

class PasswordForm extends Component {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      this.props.onUpdate({ ...values })
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit} className="new-password-form">
        <Form.Item>
          {getFieldDecorator('current_password', {
            rules: [{ required: true, message: 'Please input your current password' }],
          })(
            <AccountInfoPassword addonBefore="Current Password" />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('new_password', {
            rules: [
              { required: true, message: 'Please input your Password' },
              { validator: (i, value, callback) => {
                if (value.length < 6) callback('Password must be more than 5 characters')
                else callback()
              }}
            ],
          })(
            <AccountInfoPassword addonBefore="New Password" />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('retype_password', {
            rules: [
              { required: true, message: 'Please retype your Password' },
              { validator: (i, value, callback) => {
                const input_pw = this.props.form.getFieldValue('new_password')
                if (value !== input_pw) callback('Passwords do not match')
                else callback()
              }}
            ],
          })(
            <AccountInfoPassword addonBefore="Retype New Pass" />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Save Password
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

// const WrappedPasswordForm = Form.create({ name: 'update_password' })(PasswordForm)

class MyAccount extends Component {
  state = {
    successMessage: false
  }

  componentWillMount() {
    if (!this.props.loggedin)
      window.location = window.location = window.location.protocol + "//" + window.location.host

    this.props.fetchAccountInfo()
    this.props.fetchSubscription()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account.password.updating && !this.props.account.password.updating && !this.props.account.password.failedUpdating)
      this.setState({
        successMessage: true
      })
  }

  handleSubmit = (payload) => {
    this.props.updatePassword({
      oldPassword: payload.current_password,
      newPassword: payload.new_password
    })
  }

  getPlanByName = (planName) => {
    return [...BundlePlans, ...LegacyPlans].filter(p => p.name===planName)[0]
  }

  handleCardToken = (newCard) => {
    this.props.updateCreditCard(newCard.token.id)
  }

  render() {
    if (!this.props.account.data && !this.props.account.data.name) return ''
    let currentPlan
    if (!this.props.account.subscription.loading && this.props.account.subscription.planName)
      currentPlan = this.getPlanByName(this.props.account.subscription.planName)
    let cancelAt
    if (this.props.account.subscription.cancelAt) cancelAt = new Date(this.props.account.subscription.cancelAt * 1000)

    const trialEndDate = this.props.account.subscription.trialEnd && new Date(this.props.account.subscription.trialEnd * 1000)

    return (
      <AccountContainer>
        <AccountTitle>My Account</AccountTitle>
        <Row>
          <Col lg={10}>
            <AccountSection hoverable>
              <SectionTitle>Account Information</SectionTitle>
              <AccountInfoInput addonBefore="First Name" value={this.props.account.data && this.props.account.data.name}/>
              <AccountInfoInput addonBefore="Last Name" value={this.props.account.data && this.props.account.data.last_name}/>
              <AccountInfoInput addonBefore="Email" value={this.props.account.data && this.props.account.data.email} />
              <SectionTitle>Change Password</SectionTitle>
              <ResetPassword success={this.state.successMessage} />
              {
                this.props.account.password.failedUpdating ? (
                  <ErrorMessage>Current password is incorrect</ErrorMessage>
                ) : ''
              }
              {
                this.state.successMessage ? (
                  <div style={{color: '#39DB80'}}>Password updated!</div>
                ) : ''
              }
            </AccountSection>
            <AccountSection>
              <SectionTitle>Billing</SectionTitle>
              <StripeProvider apiKey={stripe_key}>
                <Elements>
                  {
                    this.props.account.loading || this.props.account.subscription.loading ? (
                      <div />
                    ) : (
                      <CreditCard subscription={this.props.account.subscription} handleCardToken={this.handleCardToken} />
                    )
                  }
                </Elements>
              </StripeProvider>
            </AccountSection>
          </Col>
          <Col lg={10} offset={2}>
            <AccountSection hoverable>
              <Skeleton loading={this.props.account.subscription.loading}>
                <Row>
                  <Col lg={12}>
                    <SectionTitle>Current Plan</SectionTitle>
                  </Col>
                  <Col lg={12}>
                    {
                      this.props.account.subscription.trialEnd && trialEndDate.getFullYear() === 2022 ? (
                        <div style={{marginTop: '4px'}}>Free trial ends: {Months[trialEndDate.getMonth()]} {trialEndDate.getDate()} {trialEndDate.getFullYear()}</div>
                      ) : ''
                    }
                  </Col>
                </Row>
                {
                  currentPlan ? (
                    <div>
                      { cancelAt ? (
                          <Alert
                            message={`You have cancelled your current plan. You still have premium features until ${Months[cancelAt.getMonth()]} ${cancelAt.getDate()} ${cancelAt.getFullYear()}`}
                            type="warning"
                            closable
                            style={{marginBottom: '20px'}}
                          />
                        ) : ( <div />)
                      }
                      <PlanCard
                        free={currentPlan.free}
                        title={currentPlan.title}
                        description={currentPlan.description}
                        borderColor={currentPlan.borderColor}
                        sportIcon={currentPlan.sportIcon}
                        icon={currentPlan.icon}
                        details={currentPlan.details}
                        basePrice={this.props.account.subscription.price.toFixed(2)}
                        seasonLongPrice={currentPlan.seasonLongPrice}
                        seasonLongDiscount={currentPlan.seasonLongDiscount}
                        actionTitle={"Change Plan"}
                        onAction={() => {window.location = window.location = window.location.protocol + "//" + window.location.host + "/plans"}}
                        yearly={this.props.account.subscription.yearly || false}
                      />
                  </div>
                  ) : ''
                }
                <Row>
                  <Col>
                    <CancelLink to='/plans?plan=0'>Cancel Plan</CancelLink>
                  </Col>
                </Row>
              </Skeleton>
            </AccountSection>
          </Col>
        </Row>
      </AccountContainer>
    )
  }
}

export default connect(
  state => ({
    account: state.account,
    loggedin: state.auth.loggedin
  }),
  {
    fetchAccountInfo: accountActions.fetchAccountInfo,
    updatePassword: accountActions.updatePassword,
    fetchSubscription: accountActions.fetchSubscription,
    updateCreditCard: accountActions.updateCreditCard
  }
)(MyAccount)
