import { Input } from 'antd'
import React, { Component } from 'react'
import styled from 'styled-components'

const SettingsInputSmall = styled(Input)`
  display: inline-block !important;
  width: 50px !important;
`
const SettingsContainer = styled.div`
  margin-top: 8px;
`
const TextSpan = styled.span`
  color: rgba(0, 0, 0, 0.65);
  background: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 6px 11px;
  margin-left: -5px;
`

const MaxInputForThresholds = [2,4,6]

class ForcePlayers extends Component {
  state = {
    show: 3
  }

  render() {
    return(
      <div>
        <SettingsContainer>
          <SettingsInputSmall
            value={this.props.settings.topN_0force === 0 ? '' : this.props.settings.topN_0force}
            key='topN_0force'
            placeholder={0}
            onChange={evt => {
              if ((evt.target.value === '' ? 0 : Number(evt.target.value)) <= MaxInputForThresholds[0])
                this.props.inputOnChange((evt.target.value === '' ? 0 : Number(evt.target.value)), 'topN_0force')
              else
                this.props.inputOnChange(MaxInputForThresholds[0], 'topN_0force')
            }}
          /> <TextSpan>players in the top {this.props.settings.topN_0thresh}</TextSpan>
        </SettingsContainer>
        {
          this.state.show >= 2 || this.props.settings.topN_1force ? (
            <SettingsContainer>
              <SettingsInputSmall
                value={this.props.settings.topN_1force === 0 ? '' : this.props.settings.topN_1force}
                key='topN_1force'
                placeholder={0}
                onChange={evt => {
                  if ((evt.target.value === '' ? 0 : Number(evt.target.value)) <= MaxInputForThresholds[1])
                    this.props.inputOnChange((evt.target.value === '' ? 0 : Number(evt.target.value)), 'topN_1force')
                  else
                    this.props.inputOnChange(MaxInputForThresholds[1], 'topN_1force')
                }}
              /> <TextSpan>players in the top {this.props.settings.topN_1thresh}</TextSpan>
            </SettingsContainer>
          ) : ''
        }
        {
          this.state.show === 3 || this.props.settings.topN_2force ? (
            <SettingsContainer>
              <SettingsInputSmall
                value={this.props.settings.topN_2force === 0 ? '' : this.props.settings.topN_2force}
                key='topN_2force'
                placeholder={0}
                onChange={evt => {
                  if ((evt.target.value === '' ? 0 : Number(evt.target.value)) <= MaxInputForThresholds[2])
                    this.props.inputOnChange((evt.target.value === '' ? 0 : Number(evt.target.value)), 'topN_2force')
                  else
                    this.props.inputOnChange(MaxInputForThresholds[2], 'topN_2force')
                }}
              /> <TextSpan>players in the top {this.props.settings.topN_2thresh}</TextSpan>
            </SettingsContainer>
          ) : ''
        }
      </div>
    )
  }
}

export default ForcePlayers
