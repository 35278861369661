import { useEffect, useState } from "react"
import { Row, Col, Card, Checkbox, Radio, Input, Button } from 'antd'
import styled from "styled-components"

import { fuzzyObjSearch } from "../../../../../utils/search"
import { CPTDisplay } from '../../../../../utils/showdown'

import SingleLineupTable from "./single-lineup-table"
import MiniPlayerTable from "./mini-player-table"

const StyledSearch = styled.div`
  font-size: 18px;

  input {
    height: 40px;
  }

  .ant-btn {
    height: 40px;
  }
`

const getTotalSalary = (record, positions) => {
  let sum = 0
  positions.forEach(p => {
    const r = record[p]
    if (r) {
      sum += Number(r.Salary || 0)
    }
  })

  return sum
}

const filterPlayers = ({players, position, hideOverSalary, hideZeroProjPts, remainingSalary, showdown, currentLU}) => {
  // Showdown
  if (showdown) {
    let _position = position
    if (!position)
      _position = 'FLEX'

    return players.filter(player => {
      if (hideZeroProjPts) {
        if (Number(player.ProjPts) === 0) {
          return false
        }
      }
      if (hideOverSalary) {
        if (Number(player.Salary) > remainingSalary) {
          return false
        }
      }
      // remove players already in LU
      const matches = currentLU.filter(p => {
        // Can't rely on ID in showdown -- need CPT to take out same player in FLEX
        // WARNING this could cause issues if two players on the same team share a name 
        return p.Name === player.Name && player.TeamAbbrev === p.TeamAbbrev
      })
      if (matches.length > 0) {
        return false
      }

      return player.RosterPosition === _position
    })
  }

  // Classic OPT
  if (!position) return players.filter(player => {
    if (hideZeroProjPts) {
      if (Number(player.ProjPts) === 0) {
        return false
      }
    }
    if (hideOverSalary) {
      if (Number(player.Salary) > remainingSalary) {
        return false
      }
    }
    // remove players already in LU
    const matches = currentLU.filter(p => {
      return p.Id === player.Id
    })
    if (matches.length > 0) {
      return false
    }
    return true
  })

  if (position === 'FLEX') return players.filter(player => {
    let found = false
    player.Positions.forEach(pos => {
      if (['RB', 'WR', 'TE'].indexOf(pos) >= 0)
        found = true
    })
    if (hideZeroProjPts) {
      if (Number(player.ProjPts) === 0) {
        return false
      }
    }
    if (hideOverSalary) {
      if (Number(player.Salary) > remainingSalary) {
        return false
      }
    }
    // remove players already in LU
    const matches = currentLU.filter(p => {
      return p.Id === player.Id
    })
    if (matches.length > 0) {
      return false
    }
    return found
  })

  return players.filter(player => {
    let found = false
    player.Positions.forEach(pos => {
      if (pos === position)
        found = true
    })
    if (hideZeroProjPts) {
      if (Number(player.ProjPts) === 0) {
        found = false
      }
    }
    if (hideOverSalary) {
      if (Number(player.Salary) > remainingSalary) {
        return false
      }
    }
    // remove players already in LU
    const matches = currentLU.filter(p => {
      return p.Id === player.Id
    })
    if (matches.length > 0) {
      return false
    }
    return found
  })
}

const filterDataForSearchTerm = (players, searchTerm) => {
  return players.filter(obj => fuzzyObjSearch(searchTerm, obj))
}

const positionAllowanceClassic = {
  'QB': ['QB'],
  'RB': ['RB1', 'RB2', 'FLEX'],
  'WR': ['WR1', 'WR2', 'WR3', 'FLEX'],
  'TE': ['TE', 'FLEX'],
  'DST': ['DST'],
  'DEF': ['DEF'],
  'K': ['K'],
}
const rosterPositionShowdown = {
  'CPT': ['CPT'],
  'FLEX': ['FLEX1', 'FLEX2', 'FLEX3', 'FLEX4', 'FLEX5', 'FLEX6', 'FLEX7', 'FLEX8', 'FLEX9']
}

const LineupModalBody = ({ record, positions, players, replaceLineup, totalSalary = 50000, site='dk', showdown=false }) => {
  const [ _record, _setRecord ] = useState({...record})
  const [ _selectedPosition, _setSelectedPosition ] = useState()
  const [ excludeNoProj, setExcludeNoProj ] = useState(true)
  const [ excludeOverRemSal, setExcludeOverRemSal ] = useState(false)
  const [ filteredPosition, setFilteredPosition ] = useState('')
  const [ searchTerm, setSearchTerm ] = useState('')

  useEffect(() => {
    _setRecord({...record})
  }, [record])


  const removePlayer = (pos) => {
    const newRecord = { ..._record }
    newRecord[pos] = {}

    _setRecord(newRecord)
  }

  const isUpdateValid = () => {
    let valid = true
    positions.forEach(pos => {
      // if lineup is missing a player
      if (Object.keys(_record[pos]).length === 0) valid = false
      // if salary is not under max
      if (Number(totalSalary) - Number(getTotalSalary(_record, positions)) < 0) valid = false
    })

    return valid
  }

  const addPlayer = (player) => {
    // Add in missing fields and make sure that we have correct types
    const playerCopy = {
      ...player,
      Salary: Number(player.Salary),
      TimeRank: Number(player.TimeRank),
      ProjPts: Number(player.ProjPts),
      ProjOwn: Number(player.ProjOwn),
      ProjPtsFixed: Number(player.ProjPts),
      ProjOwnFixed: Number(player.ProjOwn),
      Run: record["rawTable"][0]["Run"]
    }

    let insertPos = null
    positions.forEach(pos => {
      // if we already set insertPos get out of loop
      if (insertPos) return 

      // if the player matches that position and it is missing in the record add it
      if (showdown) {
        if (rosterPositionShowdown[playerCopy.RosterPosition].indexOf(pos) >= 0 && Object.keys(_record[pos]).length === 0) {
          insertPos = pos
        }
      } else {
        if (positionAllowanceClassic[playerCopy.Position].indexOf(pos) >= 0 && Object.keys(_record[pos]).length === 0) {
          insertPos = pos
        }
      }
      return
    })

    if (insertPos) {
      const newRecord = {..._record}
      newRecord[insertPos] = playerCopy
      _setRecord(newRecord)
    }
  }

  // flatten record to make it easier to search for matches
  let openPositions = 0
  const currentLU = positions.map(p => {
    if (Object.keys(_record[p]).length === 0) {
      openPositions += 1
    }
    return _record[p]
  })

  const remainingSalary = Number(totalSalary) - Number(getTotalSalary(_record, positions))
  let filteredPlayers = filterPlayers(
    {
      players, 
      position: filteredPosition, 
      hideOverSalary: excludeOverRemSal, 
      hideZeroProjPts: excludeNoProj,
      currentLU, 
      remainingSalary, 
      showdown
    }
  )
  if (searchTerm) {
    filteredPlayers = filterDataForSearchTerm(filteredPlayers, searchTerm)
  }

  return (
    <div>
      <h3>View/Edit Lineup</h3>
      <Row style={{padding: '10px'}}>
        <Col lg={12}>
          <div style={{fontWeight: 'bold'}}>Current Lineup</div>
          <div style={{marginTop: '10px', fontSize: '16px', color: remainingSalary < 0 ? 'red' : 'black'}}>Remaining Salary: ${remainingSalary} | Avg. Rem./Player ${openPositions > 0 ? (remainingSalary / openPositions).toFixed(0) : '0'}</div>
        </Col>
        <Col lg={12}>
          <Radio.Group
              onChange={(e) => {setFilteredPosition(e.target.value)}}
              value={filteredPosition}
              style={{margin: '5px'}}
            >
            {
              showdown ? (
                <>
                  <Radio.Button value={''}>FLEX</Radio.Button>
                  <Radio.Button value={"CPT"}>{CPTDisplay[site]}</Radio.Button>
                </>
              ) : (
                <>
                  <Radio.Button value={''}>ALL</Radio.Button>
                  <Radio.Button value={"QB"}>QB</Radio.Button>
                  <Radio.Button value={"RB"}>RB</Radio.Button>
                  <Radio.Button value={"WR"}>WR</Radio.Button>
                  <Radio.Button value={"TE"}>TE</Radio.Button>
                  <Radio.Button value={"FLEX"}>FLEX</Radio.Button>
                  <Radio.Button value={"DST"}>DST</Radio.Button>
                </>
              )
            }
          </Radio.Group>
          <StyledSearch>
            <Input.Search
              placeholder="player name"
              onChange={(e) => {setSearchTerm(e.target.value)}}
              style={{ width: '80%', margin: '5px' }}
            />
          </StyledSearch>
          <div style={{ margin: '5px' }}>
            <span>Exclude Players with 0 ProjPts </span><Checkbox checked={excludeNoProj} onClick={() => {setExcludeNoProj(!excludeNoProj)}} />
            <span style={{marginLeft: '20px'}}>Exclude Players over Rem. Salary </span><Checkbox checked={excludeOverRemSal} onClick={() => {setExcludeOverRemSal(!excludeOverRemSal)}} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={24} sm={24}>
          <Card>
            <SingleLineupTable record={_record} positions={positions} removePlayer={removePlayer} />
          </Card>
        </Col>
        <Col lg={12} md={24} sm={24}>
          <Card>
            <MiniPlayerTable 
              players={filteredPlayers} 
              addPlayer={addPlayer} 
              remainingSalary={remainingSalary} 
              showdown={showdown}
            />
          </Card>
        </Col>
      </Row>
      <Row style={{margin: '20px'}}>
        <Col lg={24}>
          <Button 
            style={{float: 'right'}} 
            disabled={!isUpdateValid()} 
            type="primary"
            onClick={(e) => {
              e.preventDefault()
              replaceLineup(_record)
            }}
          >
            Submit Lineup
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default LineupModalBody