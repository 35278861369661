import { createActions } from 'lessdux'

export const groups = {
  ...createActions('GROUPS', { withUpdate: true }),
  REMOVE: 'REMOVE_GROUP'
}

// Groups
export const fetchGroups = params => ({
  type: groups.FETCH,
  ...params
})
export const updateGroup = params => ({
  type: groups.UPDATE,
  ...params
})
export const removeGroup = params => ({
  type: groups.REMOVE,
  ...params
})
