import { Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'

const NewBanner = styled.div`
  color: #fff;
  background-color: #69B2FF;
  padding: 2px;
  font-size: 8px;
  border-radius: 2px;
`
const NewBannerContainer = styled.div`
  display: inline-block;
  margin-left: 4px;
  line-height: 10px;
`

export default ({helpText}) => (
  <NewBannerContainer>
    <Tooltip title={helpText}>
      <NewBanner>BETA</NewBanner>
    </Tooltip>
  </NewBannerContainer>
)
