import { Tooltip } from 'antd'
import {
  StarOutlined,
  StarTwoTone
} from '@ant-design/icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { lineupHash } from '../utils/simple-hash'
import * as lineupActions from '../actions/lineup'

class SaveLineupStar extends Component {
  state = {
    saved: false,
  }

  componentDidMount() {
    if (!this.props.saved && this.props.auth.loggedin && !this.props.savedLineups.loading) {
      this.setState({
        saved: this.isSaved()
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.savedLineups.loading) {
      return false
    }
    return true
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.savedLineups.data ? prevProps.savedLineups.data.length : 0) !== this.props.savedLineups.data.length &&
      !this.props.saved && this.props.auth.loggedin
    ) {
      this.setState({
        saved: this.isSaved()
      })
    }
    if (lineupHash(this.props.lineup, this.props.positions) !== lineupHash(prevProps.lineup, prevProps.positions)) {
      this.setState({
        saved: this.isSaved()
      })
    }
  }

  isSaved() {
    const _lineupHash = lineupHash(this.props.lineup, this.props.positions)
    // console.log(_lineupHash)
    // console.log(this.props.savedLineups.lineupHashes)
    return this.props.savedLineups.lineupHashes[_lineupHash]
  }

  saveLineup(_saved) {
    const { site, slate, lineup, saved, sport, counter, season } = this.props

    this.setState({
      saved: _saved
    })

    // Save/Unsave in DB
    this.props.saveLineups(
      {
        lineups: [{
          ...lineup,
          lineupHash: lineupHash(lineup, this.props.positions), // only need hash to update existing lineup
          saved: _saved,
        }],
        site,
        slate,
        sport,
        counter,
        season
      },
    )
  }

  render() {
    const loggedin = this.props.auth.loggedin

    if (loggedin && !this.props.afterStartActivated) {
      if (this.state.saved)
        return (
          <StarTwoTone
            onClick={() => {this.saveLineup(!this.state.saved)}}
          />
        )

      return (
        <StarOutlined
          onClick={() => {this.saveLineup(!this.state.saved)}}
        />
      )
    } else {
      return (
        <Tooltip title={"Unable to Save Lineups"}>
          <StarOutlined />
        </Tooltip>
      )
    }
  }
}

export default connect(
  state => ({
    auth: state.auth,
    savedLineups: state.lineup.savedLineups,
    afterStartActivated: state.afterStart.activated
  }),
  {
    saveLineups: lineupActions.saveLineups
  }
)(SaveLineupStar)
