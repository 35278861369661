import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Table, Row, Col, Select, Tooltip } from 'antd'
import { MinusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { defaultDate } from '../nba/util/nba-date'

const StyledTable = styled(Table)`
  margin-top: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  max-height: 300px;
  height: 300px;
  width: 90%;

  .ant-table-tbody > tr > td {
    font-size: 12px;
  }
  .ant-table-column-title {
    font-size: 14px;
  }

  tr:first-child {
    th {
      color: 1d3557 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }
`
const LinkSpan = styled.span`
  font-size: 10px;
  cursor: pointer;
  float: right;
  margin-right: 20px;
  color: #1890ff;
`
const OnOffSelect = styled(Select)`
  margin-left: 15px;
`

const IGNORE_LIST = 'IgnoreList'
const OFF_LIST = 'offList'

const getList = (l) => {
  let s = localStorage.getItem(l)
  const d = defaultDate()
  if (s) {
    s = JSON.parse(s)
    // if list current return
    if (s.date === d) {
      return s.list
    }
  }
  // set new list
  localStorage.setItem(l, JSON.stringify({
    date: d,
    list: []
  }))
  return []
}

const setList = (l, v) => {
  const d = defaultDate()

  localStorage.setItem(l, JSON.stringify({
    date: d,
    list: v
  }))
  
}

const offList = ({ fetchOffList, subscription, offList, offListLoading, playerTable, refreshPlayerTable, site, playerTableLoading, lastUpdate, setMasterOffList }) => {
  const [ customIgnoreList, setCustomIgnoreList ] = useState(getList(IGNORE_LIST))
  const [ customOffList, setCustomOffList ] = useState(getList(OFF_LIST))

  const updateMasterOffList = () => {
    let baseList = [...(offList || [])]
    // add off list
    const _offList = [...customOffList]
    _offList.forEach(name => {
      if (!baseList.includes(name)) {
        baseList.push(name)
      }
    })
    // remove ignored names
    const _ignoreList = [...customIgnoreList]
    _ignoreList.forEach(name => {
      const i = baseList.indexOf(name)
      if (i >= 0) {
        baseList.splice(i, 1)
      }
    })

    setMasterOffList(baseList)
  }

  // fetch off list on load
  useEffect(() => {
    if (playerTable && playerTable.length) {
      let pt = playerTable
      if (!subscription.permissions || !subscription.permissions.opt.nfl.projections) {
        pt = null
      }
      fetchOffList({ playerTable: pt })
    }
  }, [playerTable])

  useEffect(() => {
    updateMasterOffList()
  }, [offList, customIgnoreList, customOffList])

  return (
    <Row>
      <Col lg={8}>
        <BaseOffListTable offList={offList || []} loading={offListLoading || playerTableLoading} refreshPlayerTable={refreshPlayerTable} site={site} />
      </Col>
      <Col lg={8}>
        <CustomOffListTable customOffList={customOffList} playerTable={playerTable} customIgnoreList={customIgnoreList} offList={offList || []} setCustomOffList={setCustomOffList} />
      </Col>
      <Col lg={8}>
        <CustomIgnoreListTable customIgnoreList={customIgnoreList} offList={offList || []} setCustomIgnoreList={setCustomIgnoreList} />
      </Col>
    </Row>
  )
}

export default offList

const BaseOffListTable = ({ offList, loading, refreshPlayerTable, site }) => {
  const rows = [
    {
      title: (
        <span>
          <Tooltip title="Today's inactive players. If entire slate is listed here there may be no minute projections available for the day yet. Please try again closer to slate lock.">
            <QuestionCircleOutlined style={{marginRight: '5px', color: '#ffa500'}} />
          </Tooltip>
          Inactive Players <LinkSpan onClick={() => {refreshPlayerTable(site)}}>refresh</LinkSpan>
        </span>
      ),
      dataIndex: 'Name',
      key: 'player'    }
  ]

  const data = offList ? offList.map(n => ({'Name': n})) : []

  return (
    <StyledTable 
      columns={rows}
      dataSource={data}
      loading={loading}
      scroll={{ x: '90%', y: '250px' }}
      bordered
      size='small'
      pagination={false}
      rowKey={(_row, i) => {
        return `off-base-${String(i)}`
      }}
    />
  )
}

const CustomIgnoreListTable = ({ customIgnoreList, offList, setCustomIgnoreList }) => {
  const rows = [
    {
      title: (
        <Row>
          <Col lg={24} style={{marginBottom: '5px'}}>
            <Tooltip title="Inactive players that shouldn't effect the report. Sometimes a low consequence player can effect output. Add them here to ignore them.">
              <QuestionCircleOutlined style={{marginRight: '5px', color: '#ffa500'}} />
            </Tooltip>
            Remove Players from Report
          </Col>
          <Col lg={24}>
            <OnOffSelect 
                showSearch={true}
                mode="single"
                style={{ width: '70%' }}
                placeholder="Select Active Player"
                onChange={(v) => {
                  const newIgnoreList = [...customIgnoreList]
                  newIgnoreList.push(v)
                  setCustomIgnoreList(newIgnoreList)
                  setList(IGNORE_LIST, newIgnoreList)
                }}
                value={null}
                optionLabelProp="label"
              >
                {
                  offList.filter(v => {return !customIgnoreList.includes(v)}).map((name, i) => {
                    return (
                      <Select.Option value={name} label={name} key={`${i}-ignore-list-option`}>
                        {name}
                      </Select.Option>
                    )
                  })
                }
              </OnOffSelect>
          </Col>
        </Row>
      ),
      dataIndex: 'Name',
      key: 'player',
      render: (v, row) => {
        return (
          <span>
            {v}
            <span 
              style={{float: 'right', marginRight: '10px', cursor: 'pointer'}}
              onClick={() => {
                const newIgnoreList = [...customIgnoreList]
                const i = newIgnoreList.indexOf(v)
                if (i > -1) {
                  newIgnoreList.splice(i, 1)
                  setCustomIgnoreList(newIgnoreList)
                  setList(IGNORE_LIST, newIgnoreList)
                }
              }}
            >
              <MinusCircleOutlined />
            </span>
          </span>
        )
      }
    }
  ]

  const data = customIgnoreList ? customIgnoreList.map(n => ({'Name': n})) : []

  return (
    <StyledTable 
      columns={rows}
      dataSource={data}
      scroll={{ x: '90%', y: '250px' }}
      bordered
      size='small'
      pagination={false}
      rowKey={(_row, i) => {
        return `on-list-${String(i)}`
      }}
    />
  )
}

const CustomOffListTable = ({ customOffList, offList, customIgnoreList, playerTable, setCustomOffList }) => {
  const offOptions = playerTable.filter(
    p => { 
      return (!offList.includes(p.Name) && !customIgnoreList.includes(p.Name))
    }
  ).map(p => {
    return p.Name
  })

  const rows = [
    {
      title: (
        <Row>
          <Col lg={24} style={{marginBottom: '5px'}}>
            <Tooltip title="Add extra players to the inactive list. Sometimes players are declared out last minute or don't carry official designations even though we know they likely won't play. Add them here so the report considers them out.">
              <QuestionCircleOutlined style={{marginRight: '5px', color: '#ffa500'}} />
            </Tooltip>
            Add Inactives
          </Col>
          <Col lg={24}>
            <OnOffSelect 
              showSearch={true}
              mode="single"
              style={{ width: '70%' }}
              placeholder="Select Inactive Player"
              onChange={(v) => {
                const newOffList = [...customOffList]
                newOffList.push(v)
                setCustomOffList(newOffList)
                setList(OFF_LIST, newOffList)
              }}
              value={null}
              optionLabelProp="label"
            >
              {
                offOptions.map((name, i) => {
                  return (
                    <Select.Option value={name} label={name} key={`${i}-off-list-option`}>
                      {name}
                    </Select.Option>
                  )
                })
              }
            </OnOffSelect>
          </Col>
        </Row>
      ),
      dataIndex: 'Name',
      key: 'player',
      render: (v, row) => {
        return (
          <span>
            {v}
            <span 
              style={{float: 'right', marginRight: '10px', cursor: 'pointer'}}
              onClick={() => {
                const newOffList = [...customOffList]
                const i = newOffList.indexOf(v)
                if (i > -1) {
                  newOffList.splice(i, 1)
                  setCustomOffList(newOffList)
                  setList(OFF_LIST, newOffList)
                }
              }}
            >
              <MinusCircleOutlined />
            </span>
          </span>
        )
      }
    }
  ]

  const data = customOffList ? customOffList.map(n => ({'Name': n})) : []

  return (
    <StyledTable 
      columns={rows}
      dataSource={data}
      scroll={{ x: '90%', y: '250px' }}
      bordered
      size='small'
      pagination={false}
      rowKey={(_row, i) => {
        return `off-list-${String(i)}`
      }}
    />
  )
}