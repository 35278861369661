import {
  Table
} from 'antd'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  td, th {
    font-size: 11px;
  }
`

export default StyledTable