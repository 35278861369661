import { Card, Row, Col, Alert, Menu, Skeleton } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import PlanCard from '../../components/plan-card'
import * as accountActions from '../../actions/account'
import Months from '../../constants/months'
import { stripe_key } from '../../constants/stripe'
import { Elements, StripeProvider } from 'react-stripe-elements'
import Checkout from './checkout'
import { NFLPlans, NBAPlans, BundlePlans, LegacyPlans } from './plan-info'

import { PLANS_ENABLED } from '../../constants/emergency'

const BackingCard = styled(Card)`
  min-height: 600px;
  width: 90%;
  box-shadow: 2px 2px 40px #e6e6e6;
`
const PlansContainer = styled.div`
  padding: 100px 5% 76px;
  min-height: 100vh;
  border-radius: 10px;
`

const planOrdering = {
  "Free": 0,
  "Gold": 2,
  "Royal": 3
}

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}

const SportKeyToPlanInfoMap = {
  'nfl': NFLPlans,
  'nba': NBAPlans,
  'bundle': BundlePlans,
  'legacy': LegacyPlans
}

// const stripe_key = 'pk_test_DRwR1dWFkuYCfQA6KxecpfuZ00Pmg7Zwxo'
class PlansPage extends Component {
  state = {
    checkout: false,
    plan: null,
    sport: 'bundle'
  }

  componentWillMount() {
    this.props.fetchSubscription()
    const plan = getQueryVariable('plan')
    const sport = getQueryVariable('sport')
    if (plan) {
      this.setState({
        checkout: true,
        plan,
        sport: sport || 'bundle'
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account.subscription.updating && !this.props.account.subscription.updating && !this.props.account.subscription.failedUpdating)
      this.setState({
        checkout: false
      })
  }

  choosePlan(plan_id) {
    this.setState({
      checkout: true,
      plan: plan_id
    })
  }

  handleResult(submission, yearly, promo) {
    const PlanInfo = SportKeyToPlanInfoMap[this.state.sport]

    if (!submission) {
      this.props.updateSubscription({ planName: PlanInfo[this.state.plan].name, yearly, promo })
    } else {
      this.props.updateSubscription({ token: submission.token.id,  planName: PlanInfo[this.state.plan].name, yearly, promo })
    }
  }

  render() {
    let planName
    let cancelAt
    let error
    if (this.props.account.subscription.planName) planName = this.props.account.subscription.planName
    if (!planName) return (
      <PlansContainer>
        <Skeleton style={{marginTop: '100px'}}/>
      </PlansContainer>
    )

    const planYearly = this.props.account.subscription.yearly
    if (this.props.account.subscription.cancelAt) cancelAt = new Date(this.props.account.subscription.cancelAt * 1000)
    if (this.props.account.subscription.error) error = this.props.account.subscription.error

    if (this.state.checkout && !this.props.loggedin) {
      this.props.history.push(`/signup?redirectTo=plans&plan=${this.state.plan}&sport=${this.state.sport}`)
    }

    let PlanInfo = SportKeyToPlanInfoMap[this.state.sport]

    return (
      <PlansContainer>
         <Alert
            message={`DFSForecast is shutting down. You can find the same optimizer on ftndaily.com. See you there!`}
            type="warning"
            closable
            style={{marginBottom: '20px', textAlign: 'center'}}
          />
        { cancelAt ? (
          <Alert
            message={`You have cancelled your current plan. You still have premium features until ${Months[cancelAt.getMonth()]} ${cancelAt.getDate()} ${cancelAt.getFullYear()}`}
            type="warning"
            closable
            style={{marginBottom: '20px', textAlign: 'center'}}
          />
        ) : ( <div />)
        }

        <Menu style={{marginBottom: '50px'}} mode="horizontal" defaultSelectedKeys={[this.state.sport]} onSelect={(item) => {
          this.setState({
            sport: item.key
          })
        }}>
          <Menu.Item key='bundle'>All Sports</Menu.Item>
        </Menu>

        <Row>
        { this.state.checkout ? (
          <StripeProvider apiKey={stripe_key}>
            <Elements>
              <Checkout
                plan={ PlanInfo[this.state.plan] }
                handleResult={this.handleResult.bind(this)}
                account={this.props.account} error={error}
                loading={this.props.account.subscription.updating}
                promoCode={this.props.promoCode}
                checkPromoCode={this.props.checkPromoCode}
              />
            </Elements>
          </StripeProvider>
        ) : (
          PlanInfo.map((p, i) => (
            <Col lg={8} md={24} sm={24} xs={24} key={`plan-info-${i}`}>
              <BackingCard>
                <PlanCard
                  free={p.free}
                  title={p.title}
                  description={p.description}
                  icon={p.icon}
                  borderColor={p.borderColor}
                  sportIcon={p.sportIcon}
                  details={p.details}
                  basePrice={p.basePrice}
                  baseDiscount={p.baseDiscount}
                  seasonLongPrice={p.seasonLongPrice}
                  seasonLongDiscount={p.seasonLongDiscount}
                  actionTitle={(planName === p.name && this.props.loggedin) ? 'Current Plan' : 'Choose Plan'}
                  onAction={(planName === p.name && this.props.loggedin) ? ()=>{} : () => this.choosePlan(i)}
                  disabled={true}
                />
                  {/*
                    
                  */}
              </BackingCard>
            </Col>
          ))
        )}
        </Row>
      </PlansContainer>
    )
  }
}

export default connect(
  state => ({
    account: state.account,
    loggedin: state.auth.loggedin,
    promoCode: state.account.promoCode
  }),
  {
    fetchSubscription: accountActions.fetchSubscription,
    updateSubscription: accountActions.updateSubscription,
    checkPromoCode: accountActions.checkPromoCode
  }
)(PlansPage)
