import { Radio, Select } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { LockOutlined } from '@ant-design/icons'

import ExposureTable from './exposure-table'
import { CPTDisplay } from '../../../../../utils/showdown'
import { nflTeamNames as TeamToAbbrev } from '../../../../../utils/team-name-to-abbrev'

const StyledSelect = styled(Select)`
  display: inline-block
`

class ExposureStats extends Component {
  state = {
    position: null,
    filteredPlayer: undefined
  }

  _updatePosition(e) {
    const position = e.target.value
    if (position === 'ALL')
      this.setState({
        position: null
      })
    else
      this.setState({
        position
      })
  }

  _sortPlayersByPosition(data) {
    const { position, filteredPlayer } = this.state
    // If search
    if (filteredPlayer) {
      return data.filter(p => (p.Id === filteredPlayer))
    }

    // Base case
    let _positionRanks = this.props.positionRanks.filter((posRank) => {
      return (posRank[1] === (position ? position : 'All'))
    })

    const mergePositionRanksWithTableData = (data) => {
      let _data = [...data].sort((a,b) => {
        return b.Exp - a.Exp
      })
     
      _data = _data.map((item, i) => {
        const _item = {...item}
        _item.userRankPos = i + 1
        
        if (_positionRanks.length) {
          let _index = i
          if (i >= _positionRanks.length) {
            _index = _positionRanks.length - 1
          }

          try {
            _item.posRankAvg = `${_positionRanks[_index][3]} - ${_positionRanks[_index][6]}`
          } catch (e) {
            console.log(_index)
          }
        } else {
          _item.posRankAvg = (<LockOutlined />)
        }
        
        return _item
      })

      return _data
    }

    if (!position) {
      return mergePositionRanksWithTableData(data)
    }

    if (this.props.showdown) {
      if (position === 'CPT') return data.filter(player => player.RosterPosition === 'CPT')
      else if (position === 'FLEX') return data.filter(player => player.RosterPosition === 'FLEX')
      else return data.filter(player => player.Position === position)
    } else {
      if (position === 'FLEX') return data.filter(player => {
        return (
          ['RB', 'WR', 'TE'].indexOf(player.Position) >= 0
        )
      })

      let _data = [...data.filter(player => player.Position === position)].sort((a,b) => {
        return b.Exp - a.Exp
      })

      return mergePositionRanksWithTableData(_data)
    }
  }

  render() {
    const exposureStats = this.props.exposureStats

    let data = exposureStats ? this._sortPlayersByPosition(exposureStats) : []

    return (
      <div>
        <Radio.Group
          onChange={(e) => this._updatePosition(e)}
          value={this.state.position}
        >
        {
          this.props.showdown ? (
            <>
              <Radio.Button value={''}>ALL</Radio.Button>
              <Radio.Button value={"CPT"}>{CPTDisplay[this.props.site]}</Radio.Button>
              <Radio.Button value={"FLEX"}>FLEX</Radio.Button>
              <Radio.Button value={"QB"}>QB</Radio.Button>
              <Radio.Button value={"RB"}>RB</Radio.Button>
              <Radio.Button value={"WR"}>WR</Radio.Button>
              <Radio.Button value={"TE"}>TE</Radio.Button>
              <Radio.Button value={"DST"}>DST</Radio.Button>
              <Radio.Button value={"K"}>K</Radio.Button>
            </>
          ) : (
            <>
              <Radio.Button value={''}>ALL</Radio.Button>
              <Radio.Button value={"QB"}>QB</Radio.Button>
              <Radio.Button value={"RB"}>RB</Radio.Button>
              <Radio.Button value={"WR"}>WR</Radio.Button>
              <Radio.Button value={"TE"}>TE</Radio.Button>
              <Radio.Button value={"FLEX"}>FLEX</Radio.Button>
              <Radio.Button value={"DST"}>DST</Radio.Button>
              <StyledSelect
                showSearch
                mode="single"
                size="medium"
                allowClear="true"
                style={{ width: '300px' }}
                placeholder="Search For Player"
                optionFilterProp="children"
                value={this.state.filteredPlayer}
                onChange={(v) => {
                  this.setState({
                    filteredPlayer: v
                  })
                }}
                filterOption={(input, option) => {
                  return option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                filterSort={(optionA, optionB) => {
                  return optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                }}
              >
                { this.props.players.data.map((p, i) => {
                  return (
                    <Select.Option key={i} value={p.Id}>{p.Name} - {this.props.showdown ? p.RosterPosition : p.Position} | {TeamToAbbrev[p.TeamAbbrev]}</Select.Option>
                  )
                })}
              </StyledSelect>
            </>
          )
        }

        </Radio.Group>
        <ExposureTable
          permissioned={this.props.permissioned}
          data={data}
          numLUs={this.props.lineups.length / this.props.lineupSize}
          loading={(!this.props.lineups.length && this.props.loading)}
          onExposureChange={this.props.onExposureChange}
        />
    </div>
    )
  }
}

export default connect(
  state => ({
    players: state.lineup.players,
  }),
  {}
)(ExposureStats)
