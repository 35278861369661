import { Table, Progress, Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'

import { normalizeLineup, getPositionsClassic } from '../../../../utils/normalize-lineups'
import SaveLineupStar from '../../../../components/save-lineup-star'

const StyledTable = styled(Table)`
  box-shadow: 0px 6px 36px #457b9d;

  .ant-table-tbody > tr > td {
    font-size: 12px;
  }

  td {
    text-align: center;
  }
  tr {
    font-weight: 400;
  }

  tr th {
    text-align: center !important;
  }

  tr:first-child {
    th {
      color: white !important;
      background: #1d3557 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 5px solid #B0B0B0 !important;
  }
`
const StyledInitialize = styled.div`
  position: absolute;
`
const StyledProgress = styled.div`
  position: absolute;
`

const dkPositionCols = [
  {
    title: 'P1',
    dataIndex: 'p1',
    key: 'p1',
    render: (player) => player.Name
  },
  {
    title: 'P2',
    dataIndex: 'p2',
    key: 'p2',
    render: (player) => player.Name
  },
  {
    title: 'C',
    dataIndex: 'c',
    key: 'c',
    render: (player) => player.Name
  },
  {
    title: '1B',
    dataIndex: '1b',
    key: '1b',
    render: (player) => player.Name
  },
  {
    title: '2B',
    dataIndex: '2b',
    key: '2b',
    render: (player) => player.Name
  },
  {
    title: 'SS',
    dataIndex: 'ss',
    key: 'ss',
    render: (player) => player.Name
  },
  {
    title: '3B',
    dataIndex: '3b',
    key: '3b',
    render: (player) => player.Name
  },
  {
    title: 'OF1',
    dataIndex: 'of1',
    key: 'of1',
    render: (player) => player.Name
  },
  {
    title: 'OF2',
    dataIndex: 'of2',
    key: 'of2',
    render: (player) => player.Name
  },
  {
    title: 'OF3',
    dataIndex: 'of3',
    key: 'of3',
    render: (player) => player.Name,
    className: 'divide',
  },
]

const fdPositionCols = [
]

const colLookup = {
  'dk': dkPositionCols,
  'fd': fdPositionCols
}

const getPrimaryStackNum = (row, positions) => {
  const stacks = {}
  positions.forEach(pos => {
    const p = row[pos]
    stacks[p.TeamAbbrev] = (stacks[p.TeamAbbrev] || 0) + 1
  })

  let h = 0
  Object.keys(stacks).forEach(k => {
    if (stacks[k] > h) h = stacks[k]
  })

  return h
}

const getSecondaryStackNum = (row, positions) => {
  const stacks = {}
  positions.forEach(pos => {
    const p = row[pos]
    stacks[p.TeamAbbrev] = (stacks[p.TeamAbbrev] || 0) + 1
  })

  let h = 0
  let s = 0
  Object.keys(stacks).forEach(k => {
    if (stacks[k] > h) {
      s = h
      h = stacks[k]
    }
    else if (stacks[k] > s) s = stacks[k]
  })

  return s
}

const LineupCols = (site, slate, showSaved, counter, season, selectExpandedLineup, positions) => ([{
  title: 'Lineups',
  children: [
    {
      title: '',
      render: (_, row) => {
        return (
          <SaveLineupStar
            lineup={row}
            site={site}
            slate={slate}
            saved={showSaved}
            sport={'mlb'}
            positions={positions}
            counter={counter}
            season={season}
          />
        )
      }
    },
    ...colLookup[site],
    {
      title: 'Salary',
      key: 'salary',
      render: (_, row) => {
        let totalSal = 0
        positions.forEach(_pos => {
          totalSal += Number(row[_pos].Salary)
        })
        return (
          <span>
            {totalSal}
          </span>
        )
      }
    },
    {
      title: 'Pri. Stk',
      key: 'numPriStack',
      render: (_, row) => {
        return getPrimaryStackNum(row, positions)
      }
    },
    {
      title: 'Sec. Stk',
      key: 'numSecStack',
      render: (_, row) => {
        return getSecondaryStackNum(row, positions)
      }
    },
    {
      title: 'Proj Own',
      key: 'projOwn',
      sorter: (a, b) => {
        let totalOwnA = 0
        let totalOwnB = 0
        positions.forEach(_pos => {
          totalOwnA += Number(a[_pos].ProjOwn)
          totalOwnB += Number(b[_pos].ProjOwn)
        })

        return totalOwnB - totalOwnA
      },
      render: (_, row) => {
        let totalProj = 0
        positions.forEach(_pos => {
          totalProj += Number(row[_pos].ProjOwn)
        })
        return `${Number(totalProj).toFixed(2)}%`
      }
    },
    {
      title: 'Proj Pts',
      key: 'projPoints',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        let totalProjA = 0
        let totalProjB = 0
        positions.forEach(_pos => {
          totalProjA += Number(a[_pos].ProjPtsFixed)
          totalProjB += Number(b[_pos].ProjPtsFixed)
        })

        return totalProjB - totalProjA
      },
      render: (_, row) => {
        let totalProj = 0
        positions.forEach(_pos => {
          totalProj += Number(row[_pos].ProjPtsFixed)
        })
        return `${Number(totalProj).toFixed(2)}`
      }
    },
    {
      title: '<10%',
      key: 'NumUnder10pct',
      render: (_, row) => {
        let totalUnder = 0
        positions.forEach(_pos => {
          if (Number(row[_pos].ProjOwn) < 10)
            totalUnder++
        })
        return totalUnder
      }
    },
    {
      title: '<5%',
      key: 'NumUnder5pct',
      render: (_, row) => {
        let totalUnder = 0
        positions.forEach(_pos => {
          if (Number(row[_pos].ProjOwn) < 5)
            totalUnder++
        })
        return totalUnder
      }
    },
    {
      title: '<2.5%',
      key: 'NumUnder2pt5pct',
      render: (_, row) => {
        let totalUnder = 0
        positions.forEach(_pos => {
          if (Number(row[_pos].ProjOwn) < 2.5)
            totalUnder++
        })
        return totalUnder
      }
    },
    {
      title: 'Edit',
      render: (_, record) => {
        return (
          <div><EditOutlined style={{color: '#1890ff'}} onClick={() => {selectExpandedLineup(record)}}/></div>
        )
      }
    }
  ]
}])

const LineupTable = ({ loading, rows, site, slate, showSaved, currentLineupNumber, totalLineupNumber, filterPlayer, counter, season, selectExpandedLineup }) => {
  let luRows = []
  if (rows && rows.length > 0) {
    rows.forEach((row, i) => {
      luRows[i] = {
        ...normalizeLineup(row, site, false, 'mlb'),
        rawTable: row
      }
    })
  }

  const positions = getPositionsClassic(site, 'mlb')

  return (
    <StyledTable
      columns={LineupCols(site, slate, showSaved, counter, season, selectExpandedLineup, positions)}
      dataSource={luRows}
      scroll={{ x: '90%', }}
      bordered
      loading={{
        indicator: (
          <div>
            {Number(currentLineupNumber) === 0 ? (
              <StyledInitialize>
                <Spin/>
                <div style={{marginTop: '20px'}}>Initializing...</div>
              </StyledInitialize>
            ) : (
              <StyledProgress>
                <Progress type="circle" percent={(Number(currentLineupNumber)/Number(totalLineupNumber)*100).toFixed(0)}/>
              </StyledProgress>
            )}
          </div>
          
        ),
        spinning: loading
      }}
      size='small'
      pagination={{ pageSize: 50 }}
      rowKey={_row => {
        let _run = 1
        if (_row.rawTable)
          _run = _row.rawTable[0].Run + 1
        return `lineup-table-${String(_run)}`
      }}
    />
  )
}

export default LineupTable
