export const getAfterStartCSVHeadingsForSite = (site) => {
    switch(site) {
        case 'dk':
            return "EntryId, ContestName, ContestsId, EntryFee, QB, RB, RB, WR, WR, WR, TE, FLEX, DST\n"
        default:
            return  ""
    }
}

export const getPlayerStartIndexForSite = (site) => {
    switch(site) {
        case 'dk':
            return 4
        default:
            return  0
    }
}