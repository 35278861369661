export const baseURIui = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://localhost:5002' : 'https://ui.dfsforecast.com'
export const JWT_LOOKUP = 'jwt'

export const playersURI = (site='fd') => {
  if (process.env.REACT_APP_ENVIRONMENT === 'development')
    return 'http://localhost:5003'

  return `https://${site}-players.dfsforecast.com`
  // return `https://ui.dfsforecast.com`
}
