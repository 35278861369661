import React, { Component } from 'react'
import { Table } from 'antd'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { nbaTeamNames as TeamToAbbrev } from '../../../../../utils/team-name-to-abbrev'

const HoverableDiv = styled.div`
  text-align: center;

  #default {
    display: inline;
  }
  #onHover {
    display: none;
  }

  &:hover {
    #default {
      display: none;
    }
    #onHover {
      display: inline;
    }
  }
`
const StyledTable = styled(Table)`
  border: 1px solid #e8e8e8;
  &.blurry-text {
    tr:not(:first-child) {
      color: transparent;
      text-shadow: 0 0 10px rgba(0,0,0,0.5);

      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;

      /*
       Introduced in IE 10.
       See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
      */
      -ms-user-select: none;
      user-select: none;
    }
  }
`

class StacksBreakdown extends Component {
  render() {
    const stacks = getStacks(this.props.rawLineups)

    return (
      <div>
        <StyledTable
          bordered
          columns={cols()}
          dataSource={stacks}
          size={'small'}
          className={ (!this.props.permissioned) ? 'blurry-text' : '' }
          pagination={false}
          scroll={{ y: '322px' }}
          rowKey={(_row, i) => {
            return `stacks-breakdown-${String(i)}`
          }}
        />
      </div>
    )
  }
}

const getStacks = (rawLineups) => {
  const stacks = {}

  const lineups = []
  for (let i=0; i< Math.ceil(rawLineups.length / 9); i++) {
    lineups.push(rawLineups.slice((i*9), ((i+1)*9)))
  }

  lineups.forEach(lu => {
    const qb = lu.filter(p => p.Position === 'QB')[0]

    // Filter and sort flexes by Id
    const flexes = lu.filter(p => {
      try {
        return (p.TeamAbbrev === qb.TeamAbbrev && p.Position !== 'QB' && ["DST", "DEF"].indexOf(p.Position) === -1)
      } catch (e) {
        console.log(p)
      }
      
    }).sort((a, b) => a.Id - b.Id)

    if (flexes.length) {
      let stacksKey = `${qb.Id}`
      flexes.forEach(p => {
        stacksKey = stacksKey + `-${p.Id}`
      })

      if (!stacks[stacksKey])
        stacks[stacksKey] = {
          qb,
          flexes,
          count: 0
        }

      stacks[stacksKey].count = stacks[stacksKey].count + 1
    }
  })

  return flattenStacksObject(stacks)
}

const flattenStacksObject = (stacks) => {
  const flattenedStacks = []
  Object.keys(stacks).forEach(k => {
    flattenedStacks.push(stacks[k])
  })

  return flattenedStacks
}

const cols = () => ([
  {
    'title': 'Team',
    'key': 'team',
    render: (s) => (
      <div>
        <img src={require(`../../../../../assets/images/nfl/teamlogos/${s.qb.TeamAbbrev}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[s.qb.TeamAbbrev]}/>
        <span style={{marginLeft: '10px'}}>{s.qb.Name}</span>
      </div>
    ),
  },
  {
    'title': 'Count',
    'key': 'count',
    defaultSortOrder: 'ascend',
    width: '15%',
    sorter: (a, b) => Number(b.count - a.count),
    render: (s) => s.count,
  },
  {
    'title': 'Stacked Flex',
    'key': 'exposure',
    width: '50%',
    render: (s) => {
      let _flex = ''
      s.flexes.forEach((p, i) => {
        _flex = _flex + p.Name
        if (i !== s.flexes.length - 1)
          _flex = _flex + ', '
      })

      return _flex
    }
  },
])

export default connect(
  state => ({}),
  {}
)(StacksBreakdown)
