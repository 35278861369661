import { Card, Row, Col, Button } from 'antd'
import React from 'react'
import styled from 'styled-components'
import SaleBanner from './sale-banner'

const StyledPlanCard = styled(Card)`
  border-radius: 10px !important;
`
const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
`
const StyledIconRow = styled(Row)`
  color: #ffa500;
  font-size: 70px;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px 0px 10px 0px;
`
const Strikethough = styled.span`
  text-decoration: line-through;
  font-weight: 300;
`
const StyledHeading = styled.div`
  margin: -24px -24px 0px -24px;
  padding: 20px;
`
const TopButton = styled(Button)`
  @media (max-width: 1360px) {
    margin-left: -50px;
  }
`

const PlanCard = ({ title, description, icon, details, actionTitle, onAction, basePrice, baseDiscount, borderColor, sportIcon, seasonLongPrice, seasonLongDiscount, free=false, disabled=false, yearly=false }) => {
  return (
    <StyledPlanCard style={{border: `3px solid ${borderColor || '#fff'}`}}>
      <StyledHeading style={{backgroundColor: borderColor}}>
        <Row>
            <Col lg={4}>
              {sportIcon}
            </Col>
            <Col lg={8} offset={12}>
              <TopButton style={{textAlign: 'center'}} className="ant-btn" onClick={onAction} disabled={disabled}>{actionTitle}</TopButton>
            </Col>
          </Row>
      </StyledHeading>
      <StyledTitle>
        <div style={{textAlign: "center"}}>
          <div style={{fontSize: '20px', marginBottom: '5px'}}>{title}</div>
          {
            free ? (
              <div style={{fontWeight: '500', marginBottom: '5px'}}>
                Free
              </div>
            ) : (
              <div style={{fontWeight: '500'}}>
                {
                  yearly ? (
                    (<span>Yearly: </span>)
                  ) : (
                    (<span>Monthly: </span>)
                  )
                }
               {
                  baseDiscount ? (
                    <span>
                      <Strikethough>${basePrice}
                      {
                        yearly ? (
                          (<span>/yr</span>)
                        ) : (
                          (<span>/mo</span>)
                        )
                      }
                      </Strikethough> ${(basePrice - (basePrice * baseDiscount)).toFixed(2)}/mo
                      <SaleBanner saleText={`${baseDiscount * 100}% off`} />
                    </span>
                  ) : (
                    <span>
                      ${basePrice}
                      {
                        yearly ? (
                          (<span>/yr</span>)
                        ) : (
                          (<span>/mo</span>)
                        )
                      }
                    </span>
                  )
                }
              </div>
            )
          }
        </div>
      </StyledTitle>
      <StyledIconRow>
        <Col lg={24} style={{textAlign: 'center'}}>
          {icon}
        </Col>
      </StyledIconRow>
      <div style={{padding: '10px', fontWeight: '600', height: '200px'}}>{description}</div>
      <Row>
        { details }
      </Row>
      {
        actionTitle ? (
          <Button style={{marginBottom: '35px', marginTop: '35px'}} className="ant-btn-primary" onClick={onAction} disabled={disabled}>{actionTitle}</Button>
        ) : ''
      }
    </StyledPlanCard>
  )
}

export default PlanCard
