import { Card, Row, Col, Input, Slider, Checkbox, Tooltip, Divider, Radio } from 'antd'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ForcePlayers from './force-players'
import BracketOwnershipSliders from './inputs/BracketOwnershipSliders'
import {
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons'

import { CPTDisplay } from '../../../../utils/showdown'

const hideTestFeatures = false

const InputContainer = styled.div`
  padding: 20px;
`
const InputLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const SettingsInput = styled(Input)`
  .ant-input-group-addon {
    @media (max-width: 1280px) {
      font-size: 12px;
    }
  }
`
const SettingsSlider = styled(Slider)`
  width: 80%;
  display: inline-block;
`
const SliderValueLeft = styled.div`
  color: rgb(0,0,0,0.4);
  float: left;
`
const SliderValueRight = styled.div`
  color: rgb(0,0,0,0.4);
  float: right;
`
const InlineColPct = styled(Col)`
  position: relative;
  top: 7px;
`
const BlueHeadingCard = styled(Card)`
  .ant-card-head {
    background-color: #d9e3f2;
    color: black;
  }
`
const StyledRandDiv = styled.div`
  margin: 15px;
`
const RandHeading = styled.div`
  font-weight: 400;
  margin: 15px;
  font-size: 18px;
`
const RandSubheading = styled.div`
  display: inline-block;
  font-weight: 600;
  margin: 10px;
`

const AmountToIndexMap = {
  'none': 0,
  'low': 1,
  'med': 2,
  'high': 3,
  'very_high': 4
}
const IndexToAmountMap = {
  0: 'none',
  1: 'low',
  2: 'med',
  3: 'high',
  4: 'very_high',
}
const AmountToIndexMapOverMax = {
  'None': 0,
  'Low': 1,
  'Medium': 2,
  'High': 3,
}
const IndexToAmountMapOverMax = {
  0: 'None',
  1: 'Low',
  2: 'Medium',
  3: 'High',
}
const MAX_LINEUPS = 300
const ClickableDiv = styled.div`
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
`
const StyledTotal = styled.div`
  margin-top: 10px;
  float: right;

  span {
    margin-right: 5px;
    font-size: 16px;
  }

  h3 {
    display: inline-block;
  }
`

const maxOwnSite = {
  'dk': 600,
  'ya': 500,
  'fd': 500
}

const ControlledSlider = ({ defaultValue, sliderMin, sliderMax, step, onAfterChange, disabled }) => {
  const [ values, setValues ] = useState(defaultValue)

  useEffect(() => {
    setValues(defaultValue)
  }, [ defaultValue ])

  return (
    <SettingsSlider
      range
      disabled={disabled}
      step={step}
      min={sliderMin}
      max={sliderMax}
      value={values}
      onAfterChange={onAfterChange}
      onChange={(value) => {
        // console.log(value)
        setValues(value)
      }}
      style={{width: '100%', marginBottom: '5px'}}
    />
  )
}

const GeneralSettingsCard = ({ settings, inputOnChange, loggedin, savedLineupsNumber, permissions, site, noProjOwn, showdown }) => {
  let salarySliderMinOffset = 5000
  let step = 100
  let maxOverride = null

  if (site === 'ya') {
    salarySliderMinOffset = 50
    step = 1

    // if (showdown)
    //   maxOverride = 125
  }

  let maxOwn = 900
  if (showdown)
    maxOwn = maxOwnSite[site]
  // console.log(settings)
  return (
    <div>
      <BlueHeadingCard>
        <Row>
          <Col lg={12} className="Settings-options-general">
            <Card hoverable>
              <InputContainer>
                <BracketOwnershipSliders
                  minUnderOwn_1={settings["minUnderOwn_1"]}
                  minUnderOwn_2={settings["minUnderOwn_2"]}
                  minUnderOwn_3={settings["minUnderOwn_3"]}
                  minUnderOwn_4={settings["minUnderOwn_4"]}
                  minUnderOwn_5={settings["minUnderOwn_5"]}
                  maxUnderOwn_1={settings["maxUnderOwn_1"]}
                  maxUnderOwn_2={settings["maxUnderOwn_2"]}
                  maxUnderOwn_3={settings["maxUnderOwn_3"]}
                  maxUnderOwn_4={settings["maxUnderOwn_4"]}
                  maxUnderOwn_5={settings["maxUnderOwn_5"]}
                  inputOnChange={inputOnChange}
                  noProjOwn={noProjOwn}
                  showdown={showdown}
                  site={site}
                />
              </InputContainer>
            </Card>
            <Card hoverable>
              <InputContainer>
                <h2 style={{marginBottom: '20px'}}>Lineup Ranges</h2>
                <InputLabel><Tooltip title={'Lineups will be in this salary range'}>Salary Range</Tooltip></InputLabel>
                <ControlledSlider
                  step={step}
                  sliderMin={showdown ? 0 : settings.maxSalDefault - salarySliderMinOffset}
                  sliderMax={maxOverride ? maxOverride : settings.maxSalDefault}
                  defaultValue={[settings.minSal, settings.maxSal]}
                  onAfterChange={(evt) => {
                    inputOnChange(evt[0], 'minSal')
                    inputOnChange(evt[1], 'maxSal')
                  }}
                />
                <div>
                  <SliderValueLeft>${settings.minSal}</SliderValueLeft>
                  <SliderValueRight>${settings.maxSal}</SliderValueRight>
                </div>
              </InputContainer>

              {
                /*
                  <InputContainer>
                    <InputLabel><Tooltip title={'Force a certain number of your highest exposure players into the same lineups.'}>Force Top Players <a href={"https://blog.dfsforecast.com/force-top-players/"}>(What is this?)</a></Tooltip></InputLabel>
                      <div style={{fontWeight: 400, marginTop: '6px', fontSize: '10px'}}><i>Warning: Using these settings can cause builds to exceed your exposure thresholds.</i></div>
                      <ForcePlayers settings={settings} inputOnChange={inputOnChange}/>
                    </InputContainer>
                */
              }
              <InputContainer>
                <InputLabel><Tooltip placement={'top'} title={'Each lineups total projected ownership will be between these values.'}>Total Ownership</Tooltip></InputLabel>
                <ControlledSlider
                  range
                  step={1}
                  sliderMin={0}
                  sliderMax={maxOwn}
                  disabled={noProjOwn}
                  defaultValue={[settings.minTotalOwn,settings.maxTotalOwn]}
                  onAfterChange={evt => {
                    inputOnChange(evt[0], 'minTotalOwn')
                    inputOnChange(evt[1], 'maxTotalOwn')
                  }}
                  style={{width: '100%', marginBottom: '5px'}}
                />
                <div>
                  <SliderValueLeft>{settings.minTotalOwn}%</SliderValueLeft>
                  <SliderValueRight>{settings.maxTotalOwn}%</SliderValueRight>
                </div>
              </InputContainer>
              <InputContainer>
                <SettingsInput
                  type="number"
                  addonBefore={(
                    <Tooltip placement={'top'} title={'Each lineup will be at least this many players different from all other lineups. Note: time of build increases considerably as this increases. Max of 3.'}>
                      Min. Unique Players
                    </Tooltip>
                  )}
                  value={settings.minUnique}
                  key='minUnique'
                  onChange={evt => {
                    let _val = Number(evt.target.value)
                    if (_val < 0)
                      evt.target.value = 0
                    else if (_val > 3)
                      evt.target.value = 3

                    inputOnChange(evt.target.value, 'minUnique')
                  }}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel><Tooltip placement={'top'} title={'The percent to blend ceiling projections with standard projections'}>Ceiling Projections Blend (%)</Tooltip></InputLabel>
                <SettingsSlider
                  style={{width: '100%'}}
                  min={0}
                  max={100}
                  defaultValue={settings.ceilingPct || 0}
                  onAfterChange={evt => {
                    inputOnChange(Number(evt), 'ceilingPct')
                  }}
                />
              </InputContainer>
            </Card>
          </Col>

          <Col lg={12} className="Settings-options-general">
            <Card hoverable>
              <InputContainer>
                {
                  showdown ? (
                    <div>
                      <h2 style={{marginBottom: '20px'}}>{CPTDisplay[site]} Parameters</h2>
                      <InputLabel><Tooltip placement={'top'} title={'Each position will occupy the Captain spot no more than X% of the lineups. The sum of percentages must be greater than 100%.'}>Limit {CPTDisplay[site]} Pct.</Tooltip></InputLabel>
                      <Input
                        type='number'
                        addonBefore={'QB'}
                        addonAfter={'%'}
                        value={(settings.qbCptPct * 100).toFixed(0)}
                        style={{margin: '7px 0'}}
                        onChange={evt => {inputOnChange((Number(evt.target.value) / 100), 'qbCptPct')}}
                      />
                      <Input
                        type='number'
                        addonBefore={'RB'}
                        addonAfter={'%'}
                        value={(settings.rbCptPct * 100).toFixed(0)}
                        style={{margin: '7px 0'}}
                        onChange={evt => {inputOnChange((Number(evt.target.value) / 100), 'rbCptPct')}}
                      />
                      <Input
                        type='number'
                        addonBefore={'WR'}
                        addonAfter={'%'}
                        value={(settings.wrCptPct * 100).toFixed(0)}
                        style={{margin: '7px 0'}}
                        onChange={evt => {inputOnChange((Number(evt.target.value) / 100), 'wrCptPct')}}
                      />
                      <Input
                        type='number'
                        addonBefore={'TE'}
                        addonAfter={'%'}
                        style={{margin: '7px 0'}}
                        value={(settings.teCptPct * 100).toFixed(0)}
                        onChange={evt => {inputOnChange((Number(evt.target.value) / 100), 'teCptPct')}}
                      />
                      <Input
                        type='number'
                        addonBefore={'DST'}
                        addonAfter={'%'}
                        value={(settings.dstCptPct * 100).toFixed(0)}
                        style={{margin: '7px 0'}}
                        onChange={evt => {inputOnChange((Number(evt.target.value) / 100), 'dstCptPct')}}
                      />
                      <Input
                        type='number'
                        addonBefore={'K'}
                        addonAfter={'%'}
                        style={{margin: '7px 0'}}
                        value={(settings.kCptPct * 100).toFixed(0)}
                        onChange={evt => {inputOnChange((Number(evt.target.value) / 100), 'kCptPct')}}
                      />
                      <Row>
                        <Col lg={24}>
                          <StyledTotal>
                            {
                              (settings.qbCptPct * 100 + settings.rbCptPct * 100 + settings.wrCptPct * 100 + settings.teCptPct * 100 + settings.dstCptPct * 100 + settings.kCptPct * 100) >= 100 ? (
                                <span style={{color: '#00e600'}}><CheckOutlined /></span>
                              ) : (
                                <Tooltip title="The total flex pct must be >= 100%"><span style={{color: '#ff1a1a'}}><CloseOutlined /></span></Tooltip>
                              )
                            }
                            <h3>Total</h3> <h3>{(settings.qbCptPct * 100 + settings.rbCptPct * 100 + settings.wrCptPct * 100 + settings.teCptPct * 100 + settings.dstCptPct * 100 + settings.kCptPct * 100).toFixed(0)}%</h3>
                          </StyledTotal>
                          </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      <h2 style={{marginBottom: '20px'}}>Flex Parameters</h2>
                      <InputLabel><Tooltip placement={'top'} title={'Each position will occupy the flex-spot no more than X% of the lineups. The sum of percentages must be greater than 100%.'}>Limit Flex Pct.</Tooltip></InputLabel>
                      <Input
                        type='number'
                        addonBefore={'RB'}
                        addonAfter={'%'}
                        value={(settings.rbFlexPct * 100).toFixed(0)}
                        style={{margin: '7px 0'}}
                        onChange={evt => {inputOnChange((Number(evt.target.value) / 100), 'rbFlexPct')}}
                      />
                      <Input
                        type='number'
                        addonBefore={'WR'}
                        addonAfter={'%'}
                        value={(settings.wrFlexPct * 100).toFixed(0)}
                        style={{marginBottom: '7px'}}
                        onChange={evt => {inputOnChange((Number(evt.target.value) / 100), 'wrFlexPct')}}
                      />
                      <Input
                        type='number'
                        addonBefore={'TE'}
                        addonAfter={'%'}
                        value={(settings.teFlexPct * 100).toFixed(0)}
                        onChange={evt => {inputOnChange((Number(evt.target.value) / 100), 'teFlexPct')}}
                      />
                      <Row>
                        <Col lg={24}>
                          <StyledTotal>
                            {
                              (settings.teFlexPct * 100 + settings.wrFlexPct * 100 + settings.rbFlexPct * 100) >= 100 ? (
                                <span style={{color: '#00e600'}}><CheckOutlined /></span>
                              ) : (
                                <Tooltip title="The total flex pct must be >= 100%"><span style={{color: '#ff1a1a'}}><CloseOutlined /></span></Tooltip>
                              )
                            }
                            <h3>Total</h3> <h3>{(settings.teFlexPct * 100 + settings.wrFlexPct * 100 + settings.rbFlexPct * 100).toFixed(0)}%</h3>
                          </StyledTotal>
                          </Col>
                      </Row>
                    </div>
                  )
                }


              </InputContainer>
            </Card>
            <Card hoverable>
              <InputContainer>
                <InputLabel><Tooltip placement={'top'} title={'In order to produce unique lineups and to account for imperfect estimations we allow randomness in the optimizer. By default we allow for moderate variations in the given projections and ownership. You can adjust the amounts below.'}><h2>Randomness</h2></Tooltip></InputLabel>  
                <RandHeading>
                  Proj Pts
                </RandHeading>
                <div style={{marginLeft: '20px'}}>
                {
                  showdown ? (
                    <StyledRandDiv>
                        <RandSubheading>All</RandSubheading>
                        <SettingsSlider
                          marks={{
                            0: 'None',
                            1: 'Low',
                            2: 'Med',
                            3: 'High',
                            4: 'Very High',
                          }}
                          tooltipVisible={false}
                          min={0}
                          max={4}
                          defaultValue={AmountToIndexMap[settings[`projPtsRandInput`]]}
                          onAfterChange={evt => {
                            inputOnChange(IndexToAmountMap[evt], 'projPtsRandInput')
                          }}
                          style={{marginLeft: '50px', marginBottom: '10px'}}
                        />
                      </StyledRandDiv>
                  ) : (
                    <div>
                      <StyledRandDiv>
                        <RandSubheading>QB</RandSubheading>
                        <SettingsSlider
                          marks={{
                            0: 'None',
                            1: 'Low',
                            2: 'Med',
                            3: 'High',
                            4: 'Very High',
                          }}
                          tooltipVisible={false}
                          min={0}
                          max={4}
                          defaultValue={AmountToIndexMap[settings[`projPtsRandQB`]]}
                          onAfterChange={evt => {
                            inputOnChange(IndexToAmountMap[evt], 'projPtsRandQB')
                          }}
                          style={{marginLeft: '50px', marginBottom: '10px'}}
                        />
                      </StyledRandDiv>
                      <StyledRandDiv>
                      <RandSubheading>RB</RandSubheading>
                        <SettingsSlider
                          marks={{
                            0: 'None',
                            1: 'Low',
                            2: 'Med',
                            3: 'High',
                            4: 'Very High',
                          }}
                          tooltipVisible={false}
                          min={0}
                          max={4}
                          defaultValue={AmountToIndexMap[settings[`projPtsRandRB`]]}
                          onAfterChange={evt => {
                            inputOnChange(IndexToAmountMap[evt], 'projPtsRandRB')
                          }}
                          style={{marginLeft: '50px', marginBottom: '10px'}}
                        />
                      </StyledRandDiv>
                      <StyledRandDiv>
                        <RandSubheading>WR</RandSubheading>
                        <SettingsSlider
                          marks={{
                            0: 'None',
                            1: 'Low',
                            2: 'Med',
                            3: 'High',
                            4: 'Very High',
                          }}
                          tooltipVisible={false}
                          min={0}
                          max={4}
                          defaultValue={AmountToIndexMap[settings[`projPtsRandWR`]]}
                          onAfterChange={evt => {
                            inputOnChange(IndexToAmountMap[evt], 'projPtsRandWR')
                          }}
                          style={{marginLeft: '50px', marginBottom: '10px'}}
                        />
                      </StyledRandDiv>
                      <StyledRandDiv>
                        <RandSubheading>TE</RandSubheading>
                        <SettingsSlider
                          marks={{
                            0: 'None',
                            1: 'Low',
                            2: 'Med',
                            3: 'High',
                            4: 'Very High',
                          }}
                          tooltipVisible={false}
                          min={0}
                          max={4}
                          defaultValue={AmountToIndexMap[settings[`projPtsRandTE`]]}
                          onAfterChange={evt => {
                            inputOnChange(IndexToAmountMap[evt], 'projPtsRandTE')
                          }}
                          style={{marginLeft: '50px', marginBottom: '10px'}}
                        />
                      </StyledRandDiv>
                      <StyledRandDiv>
                        <RandSubheading>DST</RandSubheading>
                        <SettingsSlider
                          marks={{
                            0: 'None',
                            1: 'Low',
                            2: 'Med',
                            3: 'High',
                            4: 'Very High',
                          }}
                          tooltipVisible={false}
                          min={0}
                          max={4}
                          defaultValue={AmountToIndexMap[settings[`projPtsRandDST`]]}
                          onAfterChange={evt => {
                            inputOnChange(IndexToAmountMap[evt], 'projPtsRandDST')
                          }}
                          style={{marginLeft: '50px', marginBottom: '10px'}}
                        />
                      </StyledRandDiv>
                    </div>
                  )
                }
                
                </div>
                <div style={{margin: '50px 10px 50px 10px'}}>
                  <RandHeading>Proj Own</RandHeading>
                  <div style={{marginLeft: '30px'}}>
                    <RandSubheading>ALL</RandSubheading>
                    <SettingsSlider
                      marks={{
                        0: 'None',
                        1: 'Low',
                        2: 'Med',
                        3: 'High',
                        4: 'Very High',
                      }}
                      tooltipVisible={false}
                      min={0}
                      max={4}
                      defaultValue={AmountToIndexMap[settings[`projOwnRandInput`]]}
                      onAfterChange={evt => {
                        inputOnChange(IndexToAmountMap[evt], 'projOwnRandInput')
                      }}
                      style={{marginLeft: '50px', marginBottom: '10px'}}
                    />
                  </div>
                </div>
              </InputContainer>
              <InputContainer>
                <div style={{margin: '0px 10px 18px 10px'}}>
                  <Tooltip title={"Specify how sensitive you want the optimizer to be to going over max exposure on players."}><InputLabel>Over Max Exposure Sensitivity</InputLabel></Tooltip>
                  <Radio.Group
                    style={{marginTop: '15px'}}
                    value={settings['OverMax_Sensitivity']}
                    onChange={(e) => {
                      inputOnChange(e.target.value, 'OverMax_Sensitivity')
                    }}
                  >
                    <Tooltip title="Try to keep exposures within bounds, but always complete the build."><Radio value="Loose">Loose</Radio></Tooltip>
                    <Tooltip title="Usually keeps players within exposure bounds, but allows for some flexibility to complete a build."><Radio value="Balanced">Balanced</Radio></Tooltip>
                    <Tooltip title="Players must be within exposure bounds or fail the build."><Radio value="Strict">Strict</Radio></Tooltip>
                  </Radio.Group>
                </div>
              </InputContainer>
            </Card>
          </Col>
        </Row>
      </BlueHeadingCard>
    </div>
  )
}

/*

</Col>
<Col lg={8}>
<SettingsInput
  type="number"
  value={settings.data.luWithStackPct}
  key='luWithStackPct'
  title='Lineup Percentage with Stacks'
  onChange={evt => inputOnChange(evt, 'luWithStackPct')}
/>
</Col>
*/

export default GeneralSettingsCard
