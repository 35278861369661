import { GENERAL_SETTINGS_KEY } from '../constants/local-storage-keys'
import { getDefaultSpecificStacks, getDefaultSpecificStacksMLB } from './specific-stacks'
import { counter } from '../sagas/lineup'

const salarySettings = {
  'fd': {
    minSal: 0,
    maxSal: 0,
    maxSalDefault: 0
  },
  'dk': {
    minSal: 0,
    maxSal: 0,
    maxSalDefault: 0
  },
  'ya': {
    minSal: 0,
    maxSal: 0,
    maxSalDefault: 0
  }
}

export default (sport, site, slate="Main", withCache=false) => {
  let settings = {}
  if (withCache) {
    const period = counter(sport)
    const cached_settings = JSON.parse(localStorage.getItem(GENERAL_SETTINGS_KEY(sport, site, slate))) || {}

    if (cached_settings.period && cached_settings.period === period)
      settings = cached_settings.settings
  }

  switch (sport) {
    case 'nfl':
      return {
        minSal: salarySettings[site].minSal,
        maxSal: salarySettings[site].maxSal,
        maxSalDefault: salarySettings[site].maxSalDefault,
        minUnique: 1,
        numLUs: 3,
        allowDefenseVsQB: false,
        allowDefenseVsFlex: true,
        allowDefenseVsKicker: true,
        rbFlexPct: 1.0,
        teFlexPct: 1.0,
        wrFlexPct: 1.0,
        minTotalOwn: 0,
        maxTotalOwn: 900,
        minUnderOwn_1: 0,
        minUnderOwn_2: 0,
        minUnderOwn_3: 0,
        minUnderOwn_4: 0,
        minUnderOwn_5: 0,
        maxUnderOwn_1: 9,
        maxUnderOwn_2: 9,
        maxUnderOwn_3: 9,
        maxUnderOwn_4: 9,
        maxUnderOwn_5: 9,
        includeSaved: false,
        projPtsRandInput: 'med',
        projOwnRandInput: 'med',
        allowRBStackPct: 1,
        teamStackPct: 0.9,
        gameStackPct: 0.5,
        force2FlexStck: 0.4,
        noStackPct: 0,
        forceSecondGameStack: 0,
        topN_0thresh: 4,
        topN_1thresh: 8,
        topN_2thresh: 16,
        topN_0force: 0,
        topN_1force: 0,
        topN_2force: 0,
        OverMax_Sensitivity: "Balanced",
        qbCptPct: 1.0,
        rbCptPct: 1.0,
        wrCptPct: 1.0,
        teCptPct: 1.0,
        dstCptPct: 1.0,
        kCptPct: 1.0,
        stackSettingMode: 'basic',
        forceRBandDSTStack: 0,
        projPtsRandQB: 'med',
        projPtsRandRB: 'med',
        projPtsRandWR: 'med',
        projPtsRandTE: 'med',
        projPtsRandDST: 'med',
        specificStacks: getDefaultSpecificStacks(),
        ceilingPct: 0,
        ...settings
      }
    case 'nba':
      return {
        numLUs: 3,
        minSal: 49000,
        maxSal: 50000,
        minUnique: 1,
        teamStackPct: .5,
        gameStackPct: .5,
        minTotalOwn: 0,
        maxTotalOwn: 800,
        minUnderOwn_1: 0,
        minUnderOwn_2: 0,
        minUnderOwn_3: 0,
        minUnderOwn_4: 0,
        minUnderOwn_5: 0,
        maxUnderOwn_1: 8,
        maxUnderOwn_2: 8,
        maxUnderOwn_3: 8,
        maxUnderOwn_4: 8,
        maxUnderOwn_5: 8,
        ...settings
      }
    case 'mlb':
      return {
        numLUs: 3,
        minSal: 49000,
        maxSal: 50000,
        minUnique: 1,
        minTotalOwn: 0,
        maxTotalOwn: 1000,
        minUnderOwn_1: 0,
        minUnderOwn_2: 0,
        minUnderOwn_3: 0,
        minUnderOwn_4: 0,
        minUnderOwn_5: 0,
        maxUnderOwn_1: 10,
        maxUnderOwn_2: 10,
        maxUnderOwn_3: 10,
        maxUnderOwn_4: 10,
        maxUnderOwn_5: 10,
        projPtsRandHitter: 'med',
        projPtsRandPitcher: 'med',
        specificStacks: getDefaultSpecificStacksMLB(site),
        stackSettingMode: 'advanced'
      }
    default:
      return {}
  }
}

export const cache_settings = (sport, site, slate="Main", settings) => {
  const period = counter(sport)
  const _key = GENERAL_SETTINGS_KEY(sport, site, slate)
  const _savedSettings = JSON.parse(localStorage.getItem(_key)) || {}

  if (_savedSettings.period && _savedSettings.period === period) {
    localStorage.setItem(_key, JSON.stringify({
      period,
      settings: {
        ..._savedSettings.settings,
        ...settings
      }
    }))
  } else {
    localStorage.setItem(_key, JSON.stringify({
      period,
      settings
    }))
  }
}
