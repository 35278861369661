import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  border: 2px solid #e8e8e8;
  border-radius: 3px;

  .ant-table-tbody > tr > td {
    font-size: 14px;
    text-align: center;
  }

  .ant-table-body {
    margin: 0 !important;
  }

  tr:first-child {
    th {
      text-align: center;
      color: white !important;
      background: #1d3557 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #B9B9B9 !important;
  }
`
const RedValue = styled.span`
  color: red;
`
const RightBorder = styled.span`
  border-right: 2px solid black;
`

const TopStacksCols = [
    {
      title: 'Team',
      dataIndex: 'Team',
      key: 'Team',
      className: 'divide',
      width: '20%',
      render: (r) => (
        <span><b>{r}</b></span>
      )
    },
    {
      title: 'Highest Total',
      dataIndex: 'isTopTotal',
      key: 'isTopTotal',
      width: '20%',
      sorter: (a, b) => Number(a.isTopTotal - b.isTopTotal),
      render: (r) => (
        <span>{Number(r).toFixed(1)}%</span>
      )
    },
    {
      title: 'Best Value',
      dataIndex: 'isTopValue',
      key: 'isTopValue',
      width: '20%',
      sorter: (a, b) => Number(a.isTopValue - b.isTopValue),
      render: (r) => (
        <span>{Number(r).toFixed(1)}%</span>
      )
    },
    {
      title: 'Milly Maker Winner',
      dataIndex: 'isTopGPPVal',
      key: 'isTopGPPVal',
      className: 'divide',
      defaultSortOrder: 'descend',
      width: '20%',
      sorter: (a, b) => Number(a.isTopGPPVal - b.isTopGPPVal),
      render: (r) => (
        <span>{Number(r).toFixed(1)}%</span>
      )
    },
    {
      title: 'QB Ownership',
      dataIndex: 'ProjOwn',
      key: 'ProjOwn',
      width: '20%',
      sorter: (a, b) => Number(a.ProjOwn - b.ProjOwn),
      render: (r) => (
        <span>{Number(r).toFixed(1)}%</span>
      )
    },
  ]

const TopStacksTable = ({ loading, data }) => {
  return (
    <StyledTable
      columns={TopStacksCols}
      dataSource={data}
      loading={loading}
      bordered
      size='small'
      pagination={false}
      rowKey={(_row, i) => {
        return `top-stacks-table-${String(i)}`
      }}
    />
  )
}

export default TopStacksTable
