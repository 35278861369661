export const HEAT_MAP_POSITIVE_COLOR_SCALE = [
  '#F3FFEB',
  '#E4FFD1',
  '#D1FFB0',
  '#BEFF8F',
  '#ABFF6E',
]
export const HEAT_MAP_NEGATIVE_COLOR_SCALE = [
  '#FFEFEF',
  '#FEE0E0',
  '#FFCDCD',
  '#FFB9B9',
  '#FE9F9F',
]

export const getColorForValue = (v) => {
  const _value = Number(v)
  if (_value >= 0) {
    if (_value <= 0.5) return '#ffffff'
    else if (_value <= 1) return HEAT_MAP_POSITIVE_COLOR_SCALE[0]
    else if (_value <= 3) return HEAT_MAP_POSITIVE_COLOR_SCALE[1]
    else if (_value <= 5) return HEAT_MAP_POSITIVE_COLOR_SCALE[2]
    else if (_value <= 7) return HEAT_MAP_POSITIVE_COLOR_SCALE[3]
    else return HEAT_MAP_POSITIVE_COLOR_SCALE[4]
  } else {
    const _positiveV = _value * -1
    if (_positiveV <= 0.5) return '#ffffff'
    else if (_positiveV <= 1) return HEAT_MAP_NEGATIVE_COLOR_SCALE[0]
    else if (_positiveV <= 3) return HEAT_MAP_NEGATIVE_COLOR_SCALE[1]
    else if (_positiveV <= 5) return HEAT_MAP_NEGATIVE_COLOR_SCALE[2]
    else if (_positiveV <= 7) return HEAT_MAP_NEGATIVE_COLOR_SCALE[3]
    else return HEAT_MAP_NEGATIVE_COLOR_SCALE[4]
  }
}
