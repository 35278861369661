export const HEAT_MAP_POSITIVE_COLOR_SCALE = [
  '#e6effc',
  '#d2e3fa',
  '#c2dbfc',
  '#a1c5f7',
  '#88b6f7',
]
export const HEAT_MAP_NEGATIVE_COLOR_SCALE = [
  '#FFEFEF',
  '#FEE0E0',
  '#FFCDCD',
  '#FFB9B9',
  '#FE9F9F',
]

export const getColorForValueForOU = (v) => {
  let _value = Number(v)
  if (_value >= 0) {
    if (_value <= 36) return HEAT_MAP_NEGATIVE_COLOR_SCALE[4]
    else if (_value <= 38) return HEAT_MAP_NEGATIVE_COLOR_SCALE[2]
    else if (_value <= 42) return HEAT_MAP_NEGATIVE_COLOR_SCALE[0]
    else if (_value <= 46.5) return '#fff'
    else if (_value <= 50) return HEAT_MAP_POSITIVE_COLOR_SCALE[0]
    else if (_value <= 53) return HEAT_MAP_POSITIVE_COLOR_SCALE[2]
    else return HEAT_MAP_POSITIVE_COLOR_SCALE[4]
  }
}

export const getColorForValueForImpPts = (v) => {
  let _value = Number(v)
  if (_value >= 0) {
    if (_value <= 25 &&  _value >= 22) return '#ffffff'
    else if (_value <= 18) return HEAT_MAP_NEGATIVE_COLOR_SCALE[4]
    else if (_value <= 20) return HEAT_MAP_NEGATIVE_COLOR_SCALE[2]
    else if (_value <= 22) return HEAT_MAP_NEGATIVE_COLOR_SCALE[0]
    else if (_value <= 27) return HEAT_MAP_POSITIVE_COLOR_SCALE[0]
    else if (_value <= 30) return HEAT_MAP_POSITIVE_COLOR_SCALE[2]
    else return HEAT_MAP_POSITIVE_COLOR_SCALE[4]
  }
}

export const getColorForValueForGPPValue = (v) => {
  let _value = Number(v)
  const MAP = _value >= 0 ? HEAT_MAP_POSITIVE_COLOR_SCALE : HEAT_MAP_NEGATIVE_COLOR_SCALE

  if (_value < 0) _value = _value * -1
  if (_value <= 1.5) return '#ffffff'
  else if (_value <= 2.5) return MAP[0]
  else if (_value <= 4) return MAP[2]
  else return MAP[4]
}