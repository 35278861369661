import { Table, Progress, Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'

import { normalizeLineup, positions } from '../../../../utils/normalize-lineups'
import SaveLineupStar from '../../../../components/save-lineup-star'

const StyledTable = styled(Table)`
  box-shadow: 0px 6px 36px #457b9d;

  .ant-table-tbody > tr > td {
    font-size: 12px;
  }

  td {
    text-align: center;
  }
  tr {
    font-weight: 400;
  }

  tr th {
    text-align: center !important;
  }

  tr:first-child {
    th {
      color: white !important;
      background: #1d3557 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 5px solid #B0B0B0 !important;
  }
`
const StyledInitialize = styled.div`
  position: absolute;
`
const StyledProgress = styled.div`
  position: absolute;
`

const LineupCols = (site, slate, showSaved, counter, season, selectExpandedLineup) => ([{
  title: 'Lineups',
  children: [
    {
      title: '',
      render: (_, row) => {
        console.log(row)
        return (
          <SaveLineupStar
            lineup={row}
            site={site}
            slate={slate}
            saved={showSaved}
            sport={'nfl'}
            positions={positions}
            counter={counter}
            season={season}
          />
        )
      }
    },
    {
      title: 'QB',
      dataIndex: 'QB',
      key: 'qb',
      render: (player) => player.Name
    },
    {
      title: 'RB',
      dataIndex: 'RB1',
      key: 'rb1',
      render: (player) => player.Name
    },
    {
      title: 'RB',
      dataIndex: 'RB2',
      key: 'rb2',
      render: (player) => player.Name
    },
    {
      title: 'WR',
      dataIndex: 'WR1',
      key: 'wr1',
      render: (player) => player.Name
    },
    {
      title: 'WR',
      dataIndex: 'WR2',
      key: 'wr2',
      render: (player) => player.Name
    },
    {
      title: 'WR',
      dataIndex: 'WR3',
      key: 'wr3',
      render: (player) => player.Name
    },
    {
      title: 'TE',
      dataIndex: 'TE',
      key: 'te',
      render: (player) => player.Name
    },
    {
      title: 'FLEX',
      dataIndex: 'FLEX',
      key: 'flex',
      render: (player) => player.Name
    },
    {
      title: 'DST',
      dataIndex: 'DST',
      key: 'dst',
      render: (player) => player.Name,
      className: 'divide',
    },
    {
      title: 'Salary',
      key: 'salary',
      render: (_, row) => {
        let totalSal = 0
        positions.forEach(_pos => {
          totalSal += Number(row[_pos].Salary)
        })
        return (
          <span>
            {totalSal}
          </span>
        )
      }
    },
    {
      title: 'Tm. Stk',
      key: 'numTeamStack',
      render: (_, row) => {
        const stackingTeam = row.QB.TeamAbbrev
        let stacks = 0
        positions.forEach((_pos, i) => {
          if (i > 0 && i < positions.length - 1) {
            if (row[_pos].TeamAbbrev === stackingTeam)
              stacks++
          }
        })
        return stacks
      }
    },
    {
      title: 'Gm. Stk',
      key: 'numGameStack',
      render: (_, row) => {
        const teamStack = row.QB.TeamAbbrev
        let hasTeamStack = false
        positions.forEach((_pos, i) => {
          if (i > 0 && i < positions.length - 1) {
            if (row[_pos].TeamAbbrev === teamStack)
              hasTeamStack = true
              return
          }
        })
        if (!hasTeamStack) return 0

        const stackingTeam = row.QB.Opp
        let stacks = 0
        positions.forEach((_pos, i) => {
          if (i > 0 && i < positions.length - 1) {
            if (row[_pos].TeamAbbrev === stackingTeam)
              stacks++
          }
        })
        return stacks
      }
    },
    {
      title: 'Proj Own',
      key: 'projOwn',
      sorter: (a, b) => {
        let totalOwnA = 0
        let totalOwnB = 0
        positions.forEach(_pos => {
          totalOwnA += Number(a[_pos].ProjOwn)
          totalOwnB += Number(b[_pos].ProjOwn)
        })

        return totalOwnB - totalOwnA
      },
      render: (_, row) => {
        let totalProj = 0
        positions.forEach(_pos => {
          totalProj += Number(row[_pos].ProjOwn)
        })
        return `${Number(totalProj).toFixed(2)}%`
      }
    },
    {
      title: 'Proj Pts',
      key: 'projPoints',
      sorter: (a, b) => {
        let totalProjA = 0
        let totalProjB = 0
        positions.forEach(_pos => {
          totalProjA += Number(a[_pos].ProjPtsFixed)
          totalProjB += Number(b[_pos].ProjPtsFixed)
        })

        return totalProjB - totalProjA
      },
      render: (_, row) => {
        let totalProj = 0
        positions.forEach(_pos => {
          totalProj += Number(row[_pos].ProjPtsFixed)
        })
        return `${Number(totalProj).toFixed(2)}`
      }
    },
    {
      title: '<10%',
      key: 'NumUnder10pct',
      render: (_, row) => {
        let totalUnder = 0
        positions.forEach(_pos => {
          if (Number(row[_pos].ProjOwn) < 10)
            totalUnder++
        })
        return totalUnder
      }
    },
    {
      title: '<5%',
      key: 'NumUnder5pct',
      render: (_, row) => {
        let totalUnder = 0
        positions.forEach(_pos => {
          if (Number(row[_pos].ProjOwn) < 5)
            totalUnder++
        })
        return totalUnder
      }
    },
    {
      title: '<2.5%',
      key: 'NumUnder2pt5pct',
      render: (_, row) => {
        let totalUnder = 0
        positions.forEach(_pos => {
          if (Number(row[_pos].ProjOwn) < 2.5)
            totalUnder++
        })
        return totalUnder
      }
    },
    {
      title: 'Edit',
      render: (_, record) => {
        return (showSaved ? "" : <div><EditOutlined style={{color: '#1890ff'}} onClick={() => {selectExpandedLineup(record)}}/></div>)
      }
    }
  ]
}])

const LineupTable = ({ loading, rows, site, slate, showSaved, currentLineupNumber, totalLineupNumber, filterPlayer, counter, season, selectExpandedLineup }) => {
  let luRows = []
  if (rows && rows.length > 0) {
    rows.forEach((row, i) => {
      luRows[i] = {
        ...normalizeLineup(row),
        rawTable: row
      }
    })
  }

  return (
    <StyledTable
      columns={LineupCols(site, slate, showSaved, counter, season, selectExpandedLineup)}
      dataSource={luRows}
      scroll={{ x: '90%', }}
      bordered
      loading={{
        indicator: (
          <div>
            {Number(currentLineupNumber) === 0 ? (
              <StyledInitialize>
                <Spin/>
                <div style={{marginTop: '20px'}}>Initializing...</div>
              </StyledInitialize>
            ) : (
              <StyledProgress>
                <Progress type="circle" percent={(Number(currentLineupNumber)/Number(totalLineupNumber)*100).toFixed(0)}/>
              </StyledProgress>
            )}
          </div>
          
        ),
        spinning: loading
      }}
      size='small'
      pagination={{ pageSize: 50 }}
      rowKey={_row => {
        let _run = 1
        if (_row.rawTable)
          _run = _row.rawTable[0].Run + 1
        return `lineup-table-${String(_run)}`
      }}
    />
  )
}

export default LineupTable
