import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as analysisActions from '../actions/analysis'
import { getAuth } from '../reducers/auth'
import { playersURI, JWT_LOOKUP } from '../constants/api'
import { week, season } from '../utils/nfl-week'
import {
  sanitizePlayerTable
 } from '../utils/sanitize-data'

const baseURIui = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://localhost:5002' : 'https://ui.dfsforecast.com'
const dataURI = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://localhost:6001' : 'https://data.dfsforecast.com'

function* fetchOnOff({ players, team, playerTable, sport='nba' }) {
  // console.log(player)
  let responseBody
  let uri = `${dataURI}/analysis/nba/on-off/game-stats/${team}`
  try {
    const response = yield call(fetch, uri, {
      method: 'POST',
      body: JSON.stringify({
        offPlayers: players,
        playerTable
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    responseBody = yield call([response, response.json])

    yield put({ type: analysisActions.onOff.RECEIVE, payload: responseBody })
  } catch (e) {
    console.log(e)
  }
}

function* fetchOnOffValues({ playerTable, updatedAt, offList, site, minMinutes }) {
  let responseBody
  let uri = `${dataURI}/analysis/nba/on-off/values`

  try {
    const response = yield call(fetch, uri, {
      method: 'POST',
      body: JSON.stringify({
        playerTable,
        updatedAt,
        offList,
        site,
        minMinutes
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    responseBody = yield call([response, response.json])

    yield put({ type: analysisActions.onOffValues.RECEIVE, payload: responseBody })
  } catch (e) {
    console.log(e)
  }
}

function* fetchOffList({ playerTable }) {
  let responseBody
  let uri = `${dataURI}/analysis/nba/on-off/off-list`

  try {
    const response = yield call(fetch, uri, {
      method: 'POST',
      body: JSON.stringify({
        playerTable
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    responseBody = yield call([response, response.json])

    yield put({ type: analysisActions.offList.RECEIVE, payload: responseBody.off_list })
  } catch (e) {
    console.log(e)
  }
}

function* fetchTopStacks({ site, season, week }) {
  let responseBody
  let uri = `${baseURIui}/nfl/analysis/top-stacks/${season}/${week}/${site}`
  const token = localStorage.getItem(JWT_LOOKUP)
  try {
    const response = yield call(fetch, uri, {
      method: 'GET',
      headers: {
        authorization: token
      }
    })
    responseBody = yield call([response, response.json])
    yield put({ type: analysisActions.topStacks.RECEIVE, payload: responseBody })
  } catch (e) {}
}

function* fetchPositionCounts({ numTeams, numLUs }) {
  let responseBody
  const token = localStorage.getItem(JWT_LOOKUP)
  let uri = `${baseURIui}/nfl/analysis/build-analysis/player-count/${numTeams}/${numLUs}`
  try {
    const response = yield call(fetch, uri, {
      method: 'GET',
      headers: {
        authorization: token
      }
    })
    responseBody = yield call([response, response.json])
    yield put({ type: analysisActions.positionCounts.RECEIVE, payload: responseBody })
  } catch (e) {}
}

function* fetchPlayerProps() {
  let responseBody
  let uri = `${playersURI()}/nfl/analysis/${season}/${week}/props`
  try {
    const response = yield call(fetch, uri, {
      method: 'GET',
    })
    responseBody = yield call([response, response.json])
    yield put({ type: analysisActions.playerProps.RECEIVE, payload: responseBody })
  } catch (e) {
    console.log(e)
  }
}

function* fetchPlayerStats({ name, position }) {
  let responseBody
  let uri = `${playersURI()}/nfl/analysis/player/${name}/${position}/stats`
  try {
    const response = yield call(fetch, uri, {
      method: 'GET',
    })
    responseBody = yield call([response, response.json])
    yield put({ type: analysisActions.playerStats.RECEIVE, payload: responseBody })
  } catch (e) {
    console.log(e)
  }
}

function* fetchPositionRanks({slateSize, numLUs}) {
  let responseBody
  const token = localStorage.getItem(JWT_LOOKUP)
  let uri = `${baseURIui}/nfl/analysis/build-analysis/position-ranks/${slateSize}/${numLUs}`
  try {
    const response = yield call(fetch, uri, {
      method: 'GET',
      headers: {
        authorization: token
      }
    })
    responseBody = yield call([response, response.json])
    if (responseBody.error) {
      yield put({ type: analysisActions.playerStats.RECEIVE, payload: [] })
    } else {
      yield put({ type: analysisActions.positionRanks.RECEIVE, payload: responseBody })
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * The root of the analysis saga.
 */
export default function* analysisSaga() {
  // On Off
  yield takeLatest(
    analysisActions.onOff.FETCH,
    fetchOnOff
  )
  yield takeLatest(
    analysisActions.onOffValues.FETCH,
    fetchOnOffValues
  )
  yield takeLatest(
    analysisActions.offList.FETCH,
    fetchOffList
  )
  // Top Stacks
  yield takeLatest(
    analysisActions.topStacks.FETCH,
    fetchTopStacks
  )
  // Player Stats
  yield takeLatest(
    analysisActions.playerStats.FETCH,
    fetchPlayerStats
  )
  // Position Counts
  yield takeLatest(
    analysisActions.positionCounts.FETCH,
    fetchPositionCounts
  )
  yield takeLatest(
    analysisActions.playerProps.FETCH,
    fetchPlayerProps
  )
  yield takeLatest(
    analysisActions.positionRanks.FETCH,
    fetchPositionRanks
  )
}
