import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  margin-top: 10px;

  .ant-table-tbody > tr > td {
    font-size: 11px;
  }
  .ant-table-column-title {
    font-size: 14px;
  }

  tr:first-child {
    th {
      color: white !important;
      background-color: #1d3557 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #1d3557 !important;
  }
`
const RedValue = styled.span`
  color: red;
`

const OnOffSummaryCols = (playerName) => ([{
  title: `${playerName} Summary`,
  children: [
    {
      title: 'Player',
      dataIndex: 'Name',
      key: 'player',
      width: '100px',
      className: 'divide',
    },
    {
      title: 'Inactive',
      className: 'divide',
      children: [{
        title: 'Avg Pts',
        dataIndex: 'Off_AvgDKPts',
        key: 'Off_AvgDKPts',
        width: '70px',
      }, {
        title: 'Avg Val',
        dataIndex: 'Off_AvgVal',
        key: 'Off_AvgVal',
        width: '70px',
      },{
        title: 'Proj Pts',
        dataIndex: 'Off_ProjDKPts',
        key: 'Off_ProjDKPts',
        width: '70px',
      },{
        title: 'Proj Val',
        dataIndex: 'Off_ProjVal',
        key: 'Off_ProjVal',
        className: 'divide',
        width: '70px',
      }]
    },
    {
      title: 'Active',
      children: [{
        title: 'Avg Pts',
        dataIndex: 'On_AvgDKPts',
        key: 'On_AvgDKPts',
        width: '70px',
      },{
        title: 'Avg Val',
        dataIndex: 'On_AvgVal',
        key: 'On_AvgVal',
        width: '70px',
      },{
        title: 'Proj Pts',
        dataIndex: 'On_ProjDKPts',
        key: 'On_ProjDKPts',
        width: '70px',
      },{
        title: 'Proj Val',
        dataIndex: 'On_ProjVal',
        key: 'On_ProjVal',
        width: '70px',
      }]
    },
  ]
}])

const formatData = (onOffGameData, players, site='DK') => {
  const active = {}
  const inactive = {}
  const _listedPlayers = {}
  const rows = []
  if (onOffGameData.length) {
    onOffGameData.forEach(e => {
      // Add to listed players
      if (!_listedPlayers[e.PlayerName]) {
        _listedPlayers[e.PlayerName] = e.PlayerTeam
      }

      // If player was out
      if (e.Status === 'TmNoPlay') {
        if (!inactive[e.PlayerName]) {
          inactive[e.PlayerName] = {
            pts: [],
            ptsPer: [],
            mins: []
          }
        }

        inactive[e.PlayerName].pts.push(e[`${site}Pts`])
        inactive[e.PlayerName].ptsPer.push(e[`${site}PtsPer`])
        inactive[e.PlayerName].mins.push(e.Mins)
      } else {
        if (!active[e.PlayerName]) {
          active[e.PlayerName] = {
            pts: [],
            ptsPer: [],
            mins: []
          }
        }

        active[e.PlayerName].pts.push(e[`${site}Pts`])
        active[e.PlayerName].ptsPer.push(e[`${site}PtsPer`])
        active[e.PlayerName].mins.push(e.Mins)
      }
    })

    // get formatted rows
    Object.keys(_listedPlayers).forEach((name, i) => {
      const _player = players.filter((p) => (p.TeamAbbrev === _listedPlayers[name] && p.Name === name))
      const _p = _player[0]
      if (!_p) {
        console.log(`Not able to find player ${name}`)
        return
      }

      // sum if inactive
      let off_pts
      let off_mins
      let Off_AvgDKPts
      try {
        off_pts = inactive[name].pts.reduce((partialSum, a) => partialSum + a, 0)
        off_mins = inactive[name].mins.reduce((partialSum, a) => partialSum + a, 0)
        Off_AvgDKPts = (off_pts / inactive[name].pts.length)
      } catch (e) {
        off_pts = 0
        off_mins = 0
        Off_AvgDKPts = 0
      }
      
      // sum if active
      let on_pts
      let on_mins
      let On_AvgDKPts
      try {
        on_pts = active[name].pts.reduce((partialSum, a) => partialSum + a, 0)
        on_mins = active[name].mins.reduce((partialSum, a) => partialSum + a, 0)
        On_AvgDKPts = (on_pts / active[name].pts.length)
      } catch (e) {
        on_pts = 0
        on_mins = 0
        On_AvgDKPts = 0
        if (i ==0){
          console.log(active[name])
        }
      }
      
      /**
       * Avg Pts = mean(Pts)
       * Avg Val = mean(Pts) / current Salary * proj mins
       * Proj Pts = sum(DKPts)/sum(Mins) * current-day-projected-minutes
       * Proj Val = sum(DKPts)/Sum(Min)/current-day-salary * current-day-projected-minutes
       * 
       */
      rows.push({
        Name: name,
        Off_AvgDKPts: Off_AvgDKPts.toFixed(2),
        Off_AvgVal: (Number(Off_AvgDKPts) / Number(_p.Salary) * Number(_p.ProjMins)).toFixed(2),
        Off_ProjDKPts: off_mins === 0 ? '0.00' : ((Number(off_pts)/Number(off_mins)) * Number(_p.ProjMins)).toFixed(2),
        Off_ProjVal: off_mins === 0 ? '0.00' : (off_pts/off_mins/Number(_p.Salary) * Number(_p.ProjMins) * 1000).toFixed(2),
        On_AvgDKPts: On_AvgDKPts.toFixed(2),
        On_AvgVal: (On_AvgDKPts / Number(_p.Salary) * Number(_p.ProjMins)).toFixed(2),
        On_ProjDKPts: on_mins === 0 ? '0.00' : ((Number(on_pts)/Number(on_mins)) * Number(_p.ProjMins)).toFixed(2),
        On_ProjVal: on_mins === 0 ? '0.00' : (on_pts/on_mins/Number(_p.Salary) * Number(_p.ProjMins) * 1000).toFixed(2),
      })
      
    })
  }

  return rows
}

const OnOffTable = ({ onOffSummaryData, loading, playerName, players, site='DK' }) => {
  const _data = formatData(onOffSummaryData, players, site)

  return (
    <StyledTable
      columns={OnOffSummaryCols(playerName)}
      dataSource={_data}
      loading={loading}
      scroll={{ x: '250px', y: '150px' }}
      bordered
      size='small'
      pagination={false}
      rowKey={(_row, i) => {
        return `on-off-summary-${String(i)}`
      }}
    />
  )
}

export default OnOffTable
