import { Tooltip, Row, Col, Input, Checkbox } from 'antd'
import styled from 'styled-components'

import getBasicStacksFromAdvanced from '../../../../utils/get-basic-stacks-from-advanced'

const InputLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const SettingsInput = styled(Input)`
  .ant-input-group-addon {
    @media (max-width: 1280px) {
      font-size: 12px;
    }
  }
`
const InputContainer = styled.div`
  padding: 20px;
`

export default ({ updateInputValue, settings, showdown, disabled }) => {
  return (
    <Row>
      <Col lg={10}>
        {
          !showdown ? (
            <div>
              <SettingsInput
                disabled={disabled}
                style={{marginTop: '10px'}}
                addonBefore={(
                  <Tooltip placement={'top'} title={'This sets the target percentage of lineups that will have a QB with at least 1 desired flex position from the same team (configure in Team Stack Table below)'}>
                    Team Stack Percentage
                  </Tooltip>
                )}
                addonAfter="%"
                value={(settings.data.teamStackPct * 100).toFixed(0)}
                key='teamStackPct'
                onChange={evt => updateInputValue('teamStackPct', Number(Number(evt.target.value) / 100))}
              />
              <SettingsInput
                disabled={disabled}
                style={{margin: '10px 0px 0px 10%', width: '90%'}}
                addonBefore={(
                  <Tooltip placement={'top'} title={'Percentage of Lineups that will have a Game Stack (opposing players will be part of the stack)'}>
                    Game Stack Percentage
                  </Tooltip>
                )}
                addonAfter="%"
                value={(settings.data.gameStackPct * 100).toFixed(0)}
                key='gameStackPct'
                onChange={evt => updateInputValue('gameStackPct', Number(Number(evt.target.value) / 100))}
              />
              <SettingsInput
                disabled={disabled}
                style={{margin: '10px 0px 0px 10%', width: '90%'}}
                addonBefore={(
                  <Tooltip placement={'top'} title={'This sets the target percentage of lineups that will have a QB with at least 2 desired flex position from the same team (configure in Team Stack Table below)'}>
                    Force 2 Flex Percentage
                  </Tooltip>
                )}
                addonAfter="%"
                value={(settings.data.force2FlexStck * 100).toFixed(0)}
                key='force2FlexStck'
                onChange={evt => updateInputValue('force2FlexStck', Number(Number(evt.target.value) / 100))}
              />
          </div>
          ) : ''
        }
      </Col>
      <Col lg={10} offset={4}>
        {
          !showdown ? (
            <div>
              <SettingsInput
                disabled={disabled}
                style={{marginTop: '10px'}}
                addonBefore={(
                  <Tooltip placement={'top'} title={'Percentage of Lineups that will be forced to have no stack. Note: if Team Stack + No Stack is less than 100%, the optimizer will choose whichever setting is more optimal.'}>
                    Forced No Stack Percentage
                  </Tooltip>
                )}
                addonAfter="%"
                value={(settings.data.noStackPct * 100).toFixed(0)}
                key='noStackPct'
                onChange={evt => updateInputValue('noStackPct', Number(Number(evt.target.value) / 100))}
              />
            </div>
          ) : (
            ''
          )
        }
      </Col>
    </Row>
  )
}
