import React from 'react'

import NFLLogo from '../assets/images/nfllogo.png'
import NBALogo from '../assets/images/nba-logo.png'
import MLBLogo from '../assets/images/mlb-logo.png'
import DKLogo from '../assets/images/logos/dk.png'
import FDLogo from '../assets/images/logos/fd.png'
import YALogo from '../assets/images/logos/ya.png'
import NFLWeekPicker from './nfl-week-picker'
import DatePicker from './date-picker'

const siteToLogoMap = {
  'dk': (
    <img src={DKLogo} style={{height: '30px', marginRight: '10px'}} />
  ),
  'fd': (
    <img src={FDLogo} style={{height: '30px', marginRight: '10px'}} />
  ),
  'ya': (
    <img src={YALogo} style={{height: '30px', marginRight: '10px'}} />
  )
}

const sportToLogoMap = {
  'nfl': (
    <img src={NFLLogo} style={{height: '30px', marginRight: '10px'}} />
  ),
  'nba': (
    <img src={NBALogo} style={{height: '32px', marginRight: '10px'}} />
  ),
  'mlb': (
    <img src={MLBLogo} style={{height: '22px', marginRight: '10px'}} />
  ),
}

export const SectionHeaderTitle = ({sport, site, counter, season, changeDateInfo}) => {
  return (
    <h3>{sportToLogoMap[sport]}{siteToLogoMap[site]} 
    <span style={{padding: '5px', marginTop: '5px'}}>
      {
        sport === 'nba' || sport === 'mlb' ? (
          counter
        ) : (
          <NFLWeekPicker selectedWeek={counter} selectedSeason={season} changeDateInfo={changeDateInfo} />
        )
      }  
    </span></h3>
  )
}