import { takeLatest, put, call } from 'redux-saga/effects'
import { baseURIui } from '../constants/api'

import * as authActions from '../actions/auth'

// const baseURIui = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'https://ui.dfsforecast.com' : 'https://ui.dfsforecast.com'
const JWT_LOOKUP = 'jwt'

function* startLogin({ payload }) {
  const { email, password } = payload
  const uri = `${baseURIui}/login`
  const _fetch = () => fetch(
   uri,
   {
     method: 'POST',
     body: JSON.stringify({ email, password }),
     headers: {
       'Content-Type': 'application/json'
     }
   }
  )

  let responseBody
  try {
   const response = yield call(_fetch)
   responseBody = yield call([response, response.json])
   if (responseBody.success) {
     // Store JWT token
     localStorage.setItem(JWT_LOOKUP, responseBody.access_token)

     // set as logged in
     yield put({type: authActions.login.RECEIVE, payload: { name: responseBody.first_name }})
   } else {
     yield put({type: authActions.login.FAIL_FETCH, payload: { error: responseBody.error }})
   }

  } catch (e) {
   throw new Error('unable to login')
  }
}

function* startSignup({ payload }) {
  const { email, password, first_name, last_name } = payload
  const uri = `${baseURIui}/sign-up`
  const _fetch = () => fetch(
   uri,
   {
     method: 'POST',
     body: JSON.stringify({ email, password, first_name, last_name }),
     headers: {
       'Content-Type': 'application/json'
     }
   }
  )

  let responseBody
  try {
   const response = yield call(_fetch)
   responseBody = yield call([response, response.json])
   if (responseBody.success) {
     // Store JWT token
     localStorage.setItem(JWT_LOOKUP, responseBody.access_token)

     // set as logged in
     yield put({type: authActions.signup.RECEIVE, payload: {}})
   } else {
     yield put({type: authActions.signup.FAIL_FETCH, payload: { error: responseBody.error }})
   }

  } catch (e) {
   throw new Error('unable to login')
  }
}

function* logout() {
  // Remove JWT
  localStorage.removeItem(JWT_LOOKUP)

  // Reset store to default
  yield put({type: authActions.login.LOGOUT_COMPLETE})
}

function* checkLogin() {
  const _jwt = localStorage.getItem(JWT_LOOKUP)
  if (_jwt) {
    // yield put({type: authActions.login.RECEIVE, payload: {}})
    const uri = `${baseURIui}/validate-jwt`
    const response = yield call(fetch, uri, {
      method: 'GET',
      headers: {
        authorization: _jwt
      }
    })
    let responseBody = yield call([response, response.json])

    if (responseBody.success) {
      // update token if necessary
      if (responseBody.access_token)
        localStorage.setItem(JWT_LOOKUP, responseBody.access_token)

      yield put({type: authActions.login.RECEIVE, payload: {}})
    } else {
      yield put({type: authActions.login.LOGOUT})
    }
  } else {
    yield put({type: authActions.login.LOGOUT_COMPLETE})
  }
}

/**
 * The root of the wallet saga.
 */
export default function* lineupSaga() {
  yield takeLatest(
    authActions.login.FETCH,
    startLogin
  )
  yield takeLatest(
    authActions.login.LOGOUT,
    logout
  )
  yield takeLatest(
    authActions.signup.FETCH,
    startSignup
  )
  yield takeLatest(
    authActions.login.CHECK,
    checkLogin
  )
}
