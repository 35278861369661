import React, { Component } from 'react'
import { Row, Col, Card, Input, Tooltip, Checkbox, Switch, Button, Popconfirm } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  CheckOutlined
} from '@ant-design/icons'

import * as lineupActions from '../../../../actions/lineup'

import TeamStacksTable from './stack-table'
import mergeOrderTeamStacks from '../../../../utils/merge-order-team-stacks'
import { initialRow, getEmptyStack, getDefaultSpecificStacks, getMaxFreq } from '../../../../utils/specific-stacks'
import { cache_settings } from '../../../../utils/sport-settings'
import FakurianDesign from '../../../../assets/images/backgrounds/fakurian-design-light.png'
import NewBanner from '../../../../components/new-banner'

import GeneralStackSettings from './general-stack-settings'
import BasicStackSettings from './basic-stack-settings'
import AdvancedStackSettings from './advanced-stack-settings'

import './Settings.css'


const StyledSwitch = styled(Switch)`
  margin-top: 20px !important;
`
const SwitchCol = styled(Col)`
  .ant-switch {
    background-color: #1890ff;
    background-image: none;
  }
  .ant-switch-checked {
    background-color: #ffa500;
  }
`
const SettingsContainer = styled.div`
`
const HeadingCard = styled(Card)`
  &.ant-card {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }
`
const InputLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const SettingsInput = styled(Input)`
  .ant-input-group-addon {
    @media (max-width: 1280px) {
      font-size: 12px;
    }
  }
`
const InputContainer = styled.div`
  padding: 20px;
`
const StyledEnableButton = styled(Button)`
  float: right;
`
const EnableDiv = styled.div`
  float: right;
`

class Settings extends Component {
  static propTypes = {
    // Redux State goes here
    fetchOpt: PropTypes.func.isRequired,
    updateSettings: PropTypes.func.isRequired,
    updateTeamStacks: PropTypes.func.isRequired
  }

  state = {
    stackSettingMode: this.props.settings.data.stackSettingMode,
    showPresetsActive: false
  }

  onTeamStacksChange(rowData) {
    const teamStacks = this.props.teamStacks.data

    const _teamStacks = teamStacks.map(stack => {
      if (stack.TeamAbbrev === rowData.TeamAbbrev) {
        return rowData
      }
      if (stack.TeamAbbrev === rowData.Opp) {
        if (stack.AllowSecondaryStack !== rowData.AllowSecondaryStack) {
          const _stack = {
            ...stack
          }
          _stack.AllowSecondaryStack = rowData.AllowSecondaryStack
          return _stack
        }
      }
      return stack
    })

    this.props.updateTeamStacks({
      _teamStacks,
      rowData,
      slate: this.props.slate,
      site: this.props.site,
      sport: this.props.sport
    })
  }

  async onChangeAllTeamStackOption(rowKey, val) {
    const teamStacks = this.props.teamStacks.data

    const _teamStacks = await Promise.all(teamStacks.map(stack => {
      return {
        ...stack,
        [rowKey]: val
      }
    }))

    this.props.updateTeamStacks({
      _teamStacks,
      rowData: [],
      slate: this.props.slate,
      site: this.props.site,
      sport: this.props.sport,
      counter: this.props.counter
    })
  }

  uncheckStackingOptions() {
    const booleanKeys = ['AllowPrimaryStack', 'AllowSecondaryStack']

    const _teamStacks = this.props.teamStacks.data
    const teamStacks = _teamStacks.map(stack => {
      const _newStack = { ...stack }
      booleanKeys.forEach(_key => {
        _newStack[_key] = false
      })

      return _newStack
    })

    this.props.updateTeamStacks({
      _teamStacks: teamStacks,
      rowData: teamStacks, // Batch update
      slate: this.props.slate,
      site: this.props.site,
      sport: this.props.sport,
      counter: this.props.counter
    })
  }

  updateInputValue = (key, val) => {
    this.props.updateSettings(
      {
        site: this.props.site, 
        slate: this.props.slate, 
        [key]: val, 
        sport: this.props.sport, 
        counter: this.props.counter, 
        season: this.props.season 
      }
    )
  }

  updateSpecificStackValue = (key, val, rowIndex, bulk=false) => {
    // deep clone
    const _specificStacks = JSON.parse(JSON.stringify(this.props.settings.data.specificStacks))
    if (bulk) {
      key.forEach((k, i) => {
        _specificStacks[rowIndex][k] = val[i]
      })
    } else {
      _specificStacks[rowIndex][key] = val
    }

    if (key === 'freq') {
      const maxFreq = getMaxFreq(this.props.settings.data.specificStacks, rowIndex)

      if (Number(val) > maxFreq) {
        _specificStacks[rowIndex][key] = maxFreq
      }
    }

    // update empty stack
    _specificStacks[0] = getEmptyStack(_specificStacks)

    this.props.updateSettings( { site: this.props.site, slate: this.props.slate, specificStacks: _specificStacks, sport: this.props.sport, counter: this.props.counter, season: this.props.season } )
  }

  removeSpecificStackRow = (index) => {
    const _specificStacks = JSON.parse(JSON.stringify(this.props.settings.data.specificStacks))
    _specificStacks.splice(index, 1)

    // update empty stack
    _specificStacks[0] = getEmptyStack(_specificStacks)

    this.props.updateSettings( { site: this.props.site, slate: this.props.slate, specificStacks: _specificStacks, sport: this.props.sport, counter: this.props.counter, season: this.props.season } )
  }

  addSpecificStackRow = () => {
    const _specificStacks = [...this.props.settings.data.specificStacks]
    const _newStack = {...initialRow}
    _newStack.edit = true
    _specificStacks.push(_newStack)

    this.props.updateSettings( { site: this.props.site, slate: this.props.slate, specificStacks: _specificStacks, sport: this.props.sport, counter: this.props.counter, season: this.props.season } )
  }

  updateSpecificStacksFromBasic = (basicSettings) => {
    const _specificStacks = getAdvancedRowsFromBasic(basicSettings)

    this.props.updateSettings( { site: this.props.site, slate: this.props.slate, specificStacks: _specificStacks, sport: this.props.sport, counter: this.props.counter, season: this.props.season } )
  }

  resetStackSettings = () => {
    const _specificStacks = getDefaultSpecificStacks()

    this.props.updateSettings( { site: this.props.site, slate: this.props.slate, specificStacks: _specificStacks, sport: this.props.sport, counter: this.props.counter, season: this.props.season } )
  }

  setPreset = () => {
    const _settings = this.props.presets.data

    this.props.updateSettings( { sport: this.props.sport, site: this.props.site, slate: this.props.slate, counter: this.props.counter, season: this.props.season, ..._settings } )
    this.setState({
      showPresetsActive: true
    })

    setTimeout(() => {
      this.setState({
        showPresetsActive: false
      })
    }, 1000)
  }

  render() {
    const { settings, teamStacks, players, showdown } = this.props
    let _teamStacksSettings = []
    if (teamStacks.data && players.data && !players.loading && !teamStacks.loading)
      _teamStacksSettings = mergeOrderTeamStacks(teamStacks.data, players.data, {}, false)

    return (
      <div className="Settings">
        <SettingsContainer>
          <HeadingCard style={{backgroundImage: `url(${FakurianDesign})`, backgroundSize: '100% auto'}}>
            <Row>
              <Col lg={5}>
                <h1 style={{paddingTop: '15px'}}>Stack Settings</h1>
              </Col>
              <Col lg={7}>
                <Button
                    style={{marginTop: '8px'}}
                    onClick={() => this.setPreset()}
                    disabled={(this.props.showdown && !this.props.site === 'dk') || (this.props.subscription.permissions && !this.props.subscription.permissions.opt.nfl.historical_data)}
                  >
                    Use Presets
                    <span style={{fontSize: '12px', marginLeft: '4px', color: '#ffa500'}}></span>
                </Button>
                {
                  this.state.showPresetsActive ? (
                    <div style={{margin: '5px 10px 10px 30px'}}>
                      Active <CheckOutlined style={{color: '#50b547'}} />
                    </div>
                  ) : ""
                }
              </Col>
              <Col lg={4} offset={5}>
                 
              </Col>
               
            </Row>
              <div>
                  <BasicStackSettings
                    updateInputValue={this.updateInputValue.bind(this)}
                    showdown={this.props.showdown}
                    settings={this.props.settings}
                    disabled={ this.props.settings.data.stackSettingMode !== this.state.stackSettingMode }
                    site={this.props.site}
                  />
                  <GeneralStackSettings
                    updateInputValue={this.updateInputValue.bind(this)}
                    showdown={this.props.showdown}
                    settings={this.props.settings}
                    disabled={ this.props.settings.data.stackSettingMode !== this.state.stackSettingMode }
                  />
                </div>
          </HeadingCard>
          <TeamStacksTable
            rows={_teamStacksSettings}
            className={'team-stacks-table'}
            onChangeHandler={this.onTeamStacksChange.bind(this)}
            loading={teamStacks.loading}
            clearStackSettings={this.props.clearStackSettings}
            uncheckStackingOptions={this.uncheckStackingOptions.bind(this)}
            onChangeAllTeamStackOption={this.onChangeAllTeamStackOption.bind(this)}
            showdown={this.props.showdown}
            rowKey={(_row, i) => {
              return `team-stacks-custom-${String(i)}`
            }}
          />
        </SettingsContainer>
      </div>
    )
  }
}

export default connect(
  state => ({
    settings: state.lineup.settings,
    teamStacks: state.lineup.teamStacks,
    players: state.lineup.players,
    loggedin: state.auth.loggedin,
    subscription: state.account.subscription,
    savedLineups: state.lineup.savedLineups,
    presets: state.lineup.presets,
  }),
  {
    fetchOpt: lineupActions.fetchOpt,
    updateSettings: lineupActions.updateSettings,
    updateTeamStacks: lineupActions.updateTeamStacks
  }
)(Settings)
