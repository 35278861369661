import React, { useState } from 'react'
import { Modal, Row, Col, Table, Input } from 'antd'
import styled from 'styled-components'
import { nbaTeamNames as TeamToAbbrev } from '../../../../../../utils/team-name-to-abbrev'

export default ({ onExposureChange, players, selectedMatchup, visible, setVisible }) => {
  const [ playerUpdates, setPlayerUpdates ] = useState({})
  const teamPlayers = players.filter(p => (p.TeamAbbrev === selectedMatchup && ["DST", "DEF"].indexOf(p.Position) === -1))
  const oppTeam = teamPlayers.length > 0 ? teamPlayers[0].Opp : null
  const oppPlayers = players.filter(p => (p.TeamAbbrev === oppTeam && ["DST", "DEF"].indexOf(p.Position) === -1))

  return (
    <Modal
      title={`${teamPlayers[0] && teamPlayers[0].TeamAbbrev} Breakdown`}
      visible={visible}
      onOk={()=>{
        const changedRows = []
        Object.keys(playerUpdates).forEach(k => {
          changedRows.push(playerUpdates[k])
        })

        onExposureChange(changedRows)
        setPlayerUpdates({})
        setVisible(false)
      }}
      onCancel={() => {
        setVisible(false)
        setPlayerUpdates({})
      }}
      width={'70%'}
      style={{top: '50px'}}
    >
      <Row style={{margin: '20px'}}>
        <MatchupTable player={teamPlayers[0]} />
      </Row>
      <Row>
        <h3 style={{marginBottom: '10px'}}>{teamPlayers[0] && teamPlayers[0].TeamAbbrev}</h3>
        <TeamTable players={teamPlayers} playerUpdates={playerUpdates} setPlayerUpdates={setPlayerUpdates}/>
      </Row>
      <Row style={{marginTop: '30px'}}>
        <h3 style={{marginBottom: '10px'}}>{oppTeam}</h3>
        <TeamTable players={oppPlayers} playerUpdates={playerUpdates} setPlayerUpdates={setPlayerUpdates}/>
      </Row>
    </Modal>
  )
}

const TeamTable = ({players, playerUpdates, setPlayerUpdates}) => {

  const _cols = [
    {
      title: 'Player',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Position',
      dataIndex: 'Position',
      key: 'Position',
    },
    {
      title: 'Proj. Own',
      dataIndex: 'ProjOwn',
      key: 'ProjOwn',
      sorter: (a, b) => (Number(a.ProjOwn) - Number(b.ProjOwn))
    },
    {
      title: 'Min. Exp',
      key: 'MinExp',
      dataIndex: 'MinExp',
      sorter: (a, b) => {
        const aVal = (playerUpdates[a.Name] && playerUpdates[a.Name].MinExp) || a.MinExp
        const bVal = (playerUpdates[b.Name] && playerUpdates[b.Name].MinExp) || b.MinExp

        return (aVal - bVal)
      },
      render: (v, r) => {
        return (
          <Input style={{width: '80px'}} value={playerUpdates[r.Name] ? playerUpdates[r.Name].MinExp : v} onChange={(_v) => {
              const _updates = {
                ...playerUpdates
              }
              if (!_updates[r.Name])
                _updates[r.Name] = {
                  ...r
                }

              _updates[r.Name].MinExp = Number(_v.target.value)
              setPlayerUpdates(_updates)
            }}
          />
        )
      }
    },
    {
      title: 'Max. Exp',
      dataIndex: 'MaxExp',
      sorter: (a, b) => {
        const aVal = (playerUpdates[a.Name] && playerUpdates[a.Name].MaxExp) || a.MaxExp
        const bVal = (playerUpdates[b.Name] && playerUpdates[b.Name].MaxExp) || b.MaxExp

        return (aVal - bVal)
      },
      defaultSortOrder: 'descend',
      render: (v, r) => {
        return (
          <Input style={{width: '80px'}} value={playerUpdates[r.Name] ? playerUpdates[r.Name].MaxExp : v} onChange={(_v) => {
              const _updates = {
                ...playerUpdates
              }
              if (!_updates[r.Name])
                _updates[r.Name] = {
                  ...r
                }

              _updates[r.Name].MaxExp = Number(_v.target.value)
              setPlayerUpdates(_updates)
            }}
          />
        )
      }
    }
  ]

  return (
    <Table
      columns={_cols}
      dataSource={players}
      size={'small'}
      scroll={{ y: 200 }}
      pagination={false}
    />
  )
}

const MatchupTable = ({player}) => {
  const _cols = [
    {
      title: 'Home',
      key: 'home',
      render: (row) => {
        let homeTeamAbbrev = row.TeamAbbrev
        if (row.Venue === 'R')
          homeTeamAbbrev = row.Opp

        return (
          <img src={require(`../../../../../../assets/images/nba/teamlogos/${homeTeamAbbrev}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[homeTeamAbbrev]}/>
        )
      },
    },
    {
      title: 'Away',
      key: 'away',
      render: (row) => {
        let awayTeamAbbrev = row.TeamAbbrev
        if (row.Venue === 'H')
          awayTeamAbbrev = row.Opp

        return (
          <img src={require(`../../../../../../assets/images/nba/teamlogos/${awayTeamAbbrev}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[awayTeamAbbrev]}/>
        )
      },
    },
    {
      title: "OU",
      dataIndex: 'OU',
      key: 'ou',
    },
    {
      title: "Spread",
      dataIndex: 'Spread',
      key: 'spread',
    },
    {
      title: "Imp. Pts",
      dataIndex: 'ImpPts',
      key: 'currImpPt',
    },
  ]

  return (
    <Table
      columns={_cols}
      dataSource={[player]}
      size={'small'}
      pagination={false}
      bordered={true}
    />
  )
}
