import { Tooltip, Row, Col, Input, Checkbox } from 'antd'
import styled from 'styled-components'

import getBasicStacksFromAdvanced from '../../../../utils/get-basic-stacks-from-advanced'

const InputLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const SettingsInput = styled(Input)`
  .ant-input-group-addon {
    @media (max-width: 1280px) {
      font-size: 12px;
    }
  }

  text-align: center;
`
const InputContainer = styled.div`
  padding: 20px;
`
const StyledAddOn = styled.div`
  padding: 0px 40px 0px 40px;
`
const TotalStack = styled.div`
  float: right;
  margin-top: 10px;
  font-size: 14px;
`

export default ({ updateInputValue, settings, showdown, disabled, site}) => {
  let totalStacks = 0

  if (settings.data.specificStacks) {
    settings.data.specificStacks.forEach(stack => {
      totalStacks += (stack.freq * 100)
    })
  }

  const updateSpecificStack = (evt, index) => {
    const pct = (Number(evt.target.value))
    // Brute force deep clone
    let newSpecificStacks = JSON.parse(JSON.stringify(settings.data.specificStacks))

    newSpecificStacks[index]['freq'] = pct / 100

    updateInputValue('specificStacks', newSpecificStacks)
  }

  return (
    <Row>
      <Col lg={6}>
        {
          !showdown && settings.data.specificStacks ? (
            <>
            {
              settings.data.specificStacks.map((stack, i) => {
                return (
                  <SettingsInput 
                    addonBefore={(<StyledAddOn>{stack.PrimaryStack}-{stack.SecondaryStack || 'X'}</StyledAddOn>)}
                    addonAfter={'%'}
                    value={(stack.freq * 100).toFixed(0)}
                    onChange={evt => {updateSpecificStack(evt, i)}}
                  />
                )
              })
            }
            <TotalStack style={{color: totalStacks > 100 ? 'red' : 'black'}}>
              Total: {totalStacks.toFixed(0)}%
            </TotalStack>
            </>
          ) : ''
        }
      </Col>
      <Col lg={8} offset={4}>
        {
          !showdown ? (
            <div>
              
            </div>
          ) : (
            ''
          )
        }
      </Col>
    </Row>
  )
}
