import { Table } from "antd"
import { nflTeamNames as TeamToAbbrev } from '../../../../../utils/team-name-to-abbrev'
import { PlusCircleOutlined } from "@ant-design/icons"
import StyledTable from "./player-tables"

const cols = (addPlayer, remainingSalary, showdown) => ([
  {
    title: 'Pos.',
    dataIndex: 'Position',
    key: 'position',
    width: '45px'
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'name'
  },
  {
    title: 'Salary',
    dataIndex: 'Salary',
    width: 70,
    defaultSortOrder: 'ascend',
    sorter: (a, b) => {
      return Number(b.Salary) - Number(a.Salary)
    },
    key: 'salary',
    render: (val) => {
      return (
        <span style={{color: remainingSalary >= Number(val) ? 'black' : 'red'}}>{val}</span>
      )
    }
  },
  {
    title: 'Team',
    dataIndex: 'TeamAbbrev',
    key: 'teamAbbrev',
    width: 50,
    render: (text, row) => {
      if (text)
        return (
          <img src={require(`../../../../../assets/images/nfl/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
        )
    }
  },
  {
    title: 'Opp',
    dataIndex: 'Opp',
    key: 'opp',
    width: 50,
    render: (text, row) => {
      if (text)
        return (
          <img src={require(`../../../../../assets/images/nfl/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
        )
    }
  },
  {
    title: 'ProjPts',
    dataIndex: 'ProjPts',
    key: 'ProjPts',
    sorter: (a, b) => {
      return b.ProjPts - a.ProjPts
    },
    render: (v) => {
      return (v && Number(v).toFixed(2))
    }
  },
  {
    title: 'ProjOwn',
    dataIndex: 'ProjOwn',
    sorter: (a, b) => {
      return b.ProjOwn - a.ProjOwn
    },
    key: 'ProjOwn',
    render: (v) => {
      return (v && Number(v).toFixed(2))
    }
  },
  {
    title: 'Add',
    width: 45,
    key: 'add',
    render: (_, row) => {
      if (!row.Name) return ''
      return (
        <div style={{cursor: 'pointer', color: 'green', textAlign: 'center'}}>
          <PlusCircleOutlined onClick={() => {addPlayer(row)}} />
        </div>
      )
    }
  }
])

const MiniPlayerTable = ({players, addPlayer, remainingSalary, showdown}) => {
  return (
    <StyledTable 
      columns={cols(addPlayer, remainingSalary, showdown)}
      dataSource={players}
      size="small"
      bordered={true}
      pagination={false}
      scroll={{y: 500}}
      rowKey={(r) => `mini-pt-${r.Id}`}
    />
  )
}

export default MiniPlayerTable