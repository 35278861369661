import { takeLatest, put, call, select } from 'redux-saga/effects'
import { localStorageKeyGroups } from '../constants/local-storage-keys'
import { JWT_LOOKUP, baseURIui } from '../constants/api'
import * as groupsActions from '../actions/groups'
import { getPlayerGroups } from '../reducers/groups'
import { getDayKeyFromDate, counter } from './lineup'

/*
Local Storage Schema for Groups
{
  [groupID] : {
    "players": [id<string>, ...],
    "min": int,
    "max": int,
    "active": bool,
    "keyPlayer": Id<string>,
    "name": string
  }
}

Pass to server in flattened format for each player:

'Name', 'Position', 'Team', 'Group', 'minGroup', 'maxGroup'
[
  {
    "Id": string,
    "Name": string,
    "Position": string,
    "Team": string,
    "Group": int,
    "minGroup": int,
    "maxGroup": int
  }
]
*/

export function calcESTDay() {
    const d = new Date()
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000)
    const nd = new Date(utc + (3600000*-5)) // Timezone EST is -5 (or not depending on daylight savings but close enough)
    return nd
}

const getLocalStorageKeyForSport = (keyFunction, slate, site, sport, season, week) => {
  let localStorageKey
  switch (sport) {
    case 'nfl':
      localStorageKey = keyFunction(slate, site, sport, `${season}-${week}`)
      break
    case 'nba':
      const estDay = calcESTDay()
      localStorageKey = keyFunction(slate, site, sport, getDayKeyFromDate(estDay))
      break
    default:
      break
  }

  return localStorageKey
}

export function* fetchPlayerGroup({ slate, site, counter, season, sport='nfl' }) {
  const localStorageKey = getLocalStorageKeyForSport(
    localStorageKeyGroups,
    slate,
    site,
    sport,
    season,
    counter
  )
  const _groups = JSON.parse(localStorage.getItem(localStorageKey)) || []

  const token = localStorage.getItem(JWT_LOOKUP)
  const settings_uri = `${baseURIui}/${sport}/${site}/${slate}/${sport === 'nfl' ? `${season}/` : ''}${counter}/user-groups`

  let responseGroups
  try {
    const response = yield call(fetch, settings_uri, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    })
    responseGroups = yield call([response, response.json])

    if (response.status !== 200)
      responseGroups = []
  } catch(e) {
    console.error("unable to save groups")
  }

  // if we ended up with nulls in our array, remove them.
  if (responseGroups && responseGroups.length) {
    let i = responseGroups.length
    while (i--) {
      if (!responseGroups[i]) {
        responseGroups.splice(i, 1)
      }
    }
  }

  yield put({type: groupsActions.groups.RECEIVE, payload: responseGroups || _groups})
}
/**
players: [],
name: `Group ${this.props.groups.playerGroups.length}`,
minPlayers: 1,
maxPlayers: 2,
keyPlayer: null,
active: true,
ID: this.props.groups.playerGroups.length
*/
function* updatePlayerGroup({ group, site, slate, counter, season, sport='nfl' }) {
  const groups = yield select(getPlayerGroups)

  let _groups = JSON.parse(JSON.stringify(groups))

  const newGroup = {
    players: group.players,
    minGroup: group.minGroup,
    maxGroup: group.maxGroup,
    pctGroup: group.pctGroup || 1,
    active: group.active,
    name: group.name,
    anchorPlayerID: group.anchorPlayerID || null,
    ID: group.ID
  }

  const index = _groups.findIndex((element) => {
    if (element.ID === group.ID) return true
  })
  if (index >= 0)
    _groups[index] = newGroup
  else
    _groups.push(newGroup)
  // update front end first
  yield put({type: groupsActions.groups.RECEIVE, payload: _groups})

  const token = localStorage.getItem(JWT_LOOKUP)
  const settings_uri = `${baseURIui}/${sport}/${site}/${slate}/${sport === 'nfl' ? `${season}/` : ''}${counter}/user-groups`

  try {
    yield call(fetch, settings_uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({
        groups: _groups
      })
    })
  } catch(e) {
    console.error("unable to save groups")
  }
}

function* removePlayerGroup({ groupID, site, slate, counter, season, sport='nfl' }) {
  const groups = yield select(getPlayerGroups)

  // deep copy
  let _groups = JSON.parse(JSON.stringify(groups))
  // get index of group
  const index = _groups.findIndex((element) => {
    if (element.ID === groupID) return true
  })

  _groups.splice(index, 1)

  const token = localStorage.getItem(JWT_LOOKUP)
  const settings_uri = `${baseURIui}/${sport}/${site}/${slate}/${sport === 'nfl' ? `${season}/` : ''}${counter}/user-groups`
  try {
    yield call(fetch, settings_uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({
        groups: _groups
      })
    })
  } catch(e) {
    console.error("unable to save groups")
  }

  yield put({type: groupsActions.groups.RECEIVE, payload: _groups})
}

export default function* groupSagas() {
  yield takeLatest(
    groupsActions.groups.FETCH,
    fetchPlayerGroup
  )
  yield takeLatest(
    groupsActions.groups.UPDATE,
    updatePlayerGroup
  )
  yield takeLatest(
    groupsActions.groups.REMOVE,
    removePlayerGroup
  )
}
