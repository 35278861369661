import {
    TrophyOutlined,
    ExperimentOutlined,
    CrownOutlined,
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons'
import styled from 'styled-components'

import SaleBanner from '../../components/sale-banner'
import NFLLogo from '../../assets/images/nfllogo.png'
import NBALogo from '../../assets/images/nba-logo.png'

const Strikethough = styled.span`
  text-decoration: line-through;
  font-weight: 300;
`

const BUNDLE_COLOR = '#ffdda7'
const NFL_COLOR = "#ffdda7"
const NBA_COLOR = '#bbd6e6'

/*******************************************************
 * ******** NFL ****************************************
 * *****************************************************
 */
export const NFLPlans = [
    {
      title: "Free",
      free: true,
      borderColor: NFL_COLOR,
      sportIcon: (<img src={NFLLogo} style={{height: '30px'}} />),
      description: "Basic optimizer. Upgrade for more features.",
      icon: (<ExperimentOutlined style={{color: NFL_COLOR}}/>),
      details: (
        <div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Full NFL Optimizer</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>20 Lineups/Build</b></div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections <i>(Not Downloadable)</i></div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
          <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Ownership</div>
          <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Saved Lineups</div>
          <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Pre and Post Build Analysis</div>
          <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Historical Winner Data</div>
        </div>
      ),
      cost: 'Free',
      name: "Free"
    },
    {
      title: <span>Gold</span>,
      basePrice: 50,
      baseDiscount: 0.1,
      borderColor: NFL_COLOR,
      seasonLongPrice: 300,
      seasonLongDiscount: 0,
      sportIcon: (<img src={NFLLogo} style={{height: '30px'}} />),
      icon: (<TrophyOutlined style={{color: NFL_COLOR}}/>),
      description: "Gives access to DFSForecast's projections, ownership and all of the tools for building lineups and max entering contests.",
      details: (
        <div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Full NFL Optimizer</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>150 Lineups/Build</b></div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Ownership</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Saved Lineups</div>
          <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Pre and Post Build Analysis</div>
          <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Historical Winner Data</div>
        </div>
      ),
      cost: (
        <div style={{textAlign: "center"}}>
          <div style={{fontSize: '20px', marginBottom: '5px'}}>Gold</div>
          <div style={{fontWeight: '500'}}>
            Monthly: <Strikethough>$35</Strikethough> $28/mo
            <SaleBanner saleText={'20% off'} />
            <div style={{"textAlign": 'center', fontSize: '14px', fontWeight: "400"}}><i>OR</i></div>
            <div>
              Season Pass: <Strikethough>$210</Strikethough> $157
              <SaleBanner saleText={'25% off'} />
            </div>
          </div>
        </div>
      ),
      name: "NFL-Gold"
    },
    {
      title: "Royal",
      basePrice: 80,
      baseDiscount: 0.1,
      borderColor: NFL_COLOR,
      seasonLongPrice: 480,
      seasonLongDiscount: 0,
      sportIcon: (<img src={NFLLogo} style={{height: '30px'}} />),
      icon: (<CrownOutlined style={{color: NFL_COLOR}}/>),
      description: "All of DFSForecast's analysis, a max of 300 lineups per run and data of historical winners to compare your lineups to every run.",
      details: (
        <div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Full NFL Optimizer</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>300 Lineups/Build</b></div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Ownership</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Saved Lineups</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Pre and Post Build Analysis</div>
          <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Historical Winner Data</div>
        </div>
      ),
      cost: (
        <div style={{textAlign: "center"}}>
          Royal | <Strikethough>$80</Strikethough> $64/mo
          <SaleBanner saleText={'20% off'} />
          <div style={{"textAlign": 'center', fontSize: '14px', fontWeight: "400"}}><i>OR</i></div>
          <div>
            Season Pass: <Strikethough>$480</Strikethough> $336
            <SaleBanner saleText={'25% off'} />
          </div>
        </div>
      ),
      name: "NFL-Royal"
    },
]

/*******************************************************
 * ******** NBA ****************************************
 * *****************************************************
 */
export const NBAPlans = [
    {
        title: "Free",
        free: true,
        borderColor: NBA_COLOR,
        sportIcon: (<img src={NBALogo} style={{height: '30px'}} />),
        description: "Basic optimizer. Upgrade for more features.",
        icon: (<ExperimentOutlined style={{color: NBA_COLOR}}/>),
        details: (
        <div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Full NBA Optimizer</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>20 Lineups/Build</b></div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections <i>(Not Downloadable)</i></div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Ownership</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Saved Lineups</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Pre and Post Build Analysis</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Historical Winner Data</div>
        </div>
        ),
        cost: 'Free',
        name: "Free"
    },
    {
        title: <span>Gold</span>,
        basePrice: 50,
        baseDiscount: 0.1,
        borderColor: NBA_COLOR,
        seasonLongPrice: 300,
        seasonLongDiscount: 0,
        sportIcon: (<img src={NBALogo} style={{height: '30px'}} />),
        icon: (<TrophyOutlined style={{color: NBA_COLOR}}/>),
        description: "Gives access to DFSForecast's projections, ownership and all of the tools for building lineups and max entering contests.",
        details: (
        <div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Full NBA Optimizer</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>150 Lineups/Build</b></div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Ownership</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Saved Lineups</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Pre and Post Build Analysis</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Historical Winner Data</div>
        </div>
        ),
        cost: (
        <div style={{textAlign: "center"}}>
            <div style={{fontSize: '20px', marginBottom: '5px'}}>Gold</div>
            <div style={{fontWeight: '500'}}>
            Monthly: <Strikethough>$35</Strikethough> $28/mo
            <SaleBanner saleText={'20% off'} />
            <div style={{"textAlign": 'center', fontSize: '14px', fontWeight: "400"}}><i>OR</i></div>
            <div>
                Season Pass: <Strikethough>$210</Strikethough> $157
                <SaleBanner saleText={'25% off'} />
            </div>
            </div>
        </div>
        ),
        name: "NBA-Gold"
    },
    {
        title: "Royal",
        basePrice: 80,
        baseDiscount: 0.1,
        borderColor: NBA_COLOR,
        seasonLongPrice: 480,
        seasonLongDiscount: 0,
        sportIcon: (<img src={NBALogo} style={{height: '30px'}} />),
        icon: (<CrownOutlined style={{color: NBA_COLOR}}/>),
        description: "All of DFSForecast's analysis, a max of 300 lineups per run and data of historical winners to compare your lineups to every run.",
        details: (
        <div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Full NBA Optimizer</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>300 Lineups/Build</b></div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Ownership</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Saved Lineups</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Pre and Post Build Analysis</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Historical Winner Data</div>
        </div>
        ),
        cost: (
        <div style={{textAlign: "center"}}>
            Royal | <Strikethough>$80</Strikethough> $64/mo
            <SaleBanner saleText={'20% off'} />
            <div style={{"textAlign": 'center', fontSize: '14px', fontWeight: "400"}}><i>OR</i></div>
            <div>
            Season Pass: <Strikethough>$480</Strikethough> $336
            <SaleBanner saleText={'25% off'} />
            </div>
        </div>
        ),
        name: "NBA-Royal"
    },
]

/*******************************************************
 * ******** BUNDLE *************************************
 * *****************************************************
 */
export const BundlePlans = [
    {
        title: "Free",
        free: true,
        borderColor: BUNDLE_COLOR,
        sportIcon: (
        <span>
            <img src={NFLLogo} style={{height: '30px'}} />
            <img src={NBALogo} style={{height: '30px', marginLeft: '5px'}} />
        </span>
        ),
        description: "Basic optimizer. Upgrade for more features.",
        icon: (<ExperimentOutlined style={{color: BUNDLE_COLOR}}/>),
        details: (
        <div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Limited NFL + NBA Optimizer</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>10 Lineups/Build</b></div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections <i>(Not Downloadable)</i></div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Ownership</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Saved Lineups</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> After Start Optimizer</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Pre and Post Build Analysis</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Historical Winner Data</div>
        </div>
        ),
        cost: 'Free',
        name: "Free"
    },
    {
        title: <span>Gold</span>,
        basePrice: 49.99,
        borderColor: BUNDLE_COLOR,
        sportIcon: (
        <span>
            <img src={NFLLogo} style={{height: '30px'}} />
            <img src={NBALogo} style={{height: '30px', marginLeft: '5px'}} />
        </span>
        ),
        icon: (<TrophyOutlined style={{color: BUNDLE_COLOR}}/>),
        description: "Gives access to DFSForecast's projections, ownership and all of the tools for building lineups and max entering contests.",
        details: (
        <div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Full NFL + NBA Optimizer</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>150 Lineups/Build</b></div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Ownership</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Saved Lineups</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> After Start Optimizer</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Pre and Post Build Analysis</div>
            <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Historical Winner Data</div>
        </div>
        ),
        cost: (
        <div style={{textAlign: "center"}}>
            <div style={{fontSize: '20px', marginBottom: '5px'}}>Gold</div>
            <div style={{fontWeight: '500'}}>
            Monthly: <Strikethough>$35</Strikethough> $28/mo
            <SaleBanner saleText={'20% off'} />
            <div style={{"textAlign": 'center', fontSize: '14px', fontWeight: "400"}}><i>OR</i></div>
            <div>
                Season Pass: <Strikethough>$210</Strikethough> $157
                <SaleBanner saleText={'25% off'} />
            </div>
            </div>
        </div>
        ),
        name: "Bundle-Gold"
    },
    {
        title: "Royal",
        basePrice: 79.99,
        borderColor: BUNDLE_COLOR,
        sportIcon: (
        <span>
            <img src={NFLLogo} style={{height: '30px'}} />
            <img src={NBALogo} style={{height: '30px', marginLeft: '5px'}} />
        </span>
        ),
        icon: (<CrownOutlined style={{color: BUNDLE_COLOR}}/>),
        description: "All of DFSForecast's analysis, a max of 300 lineups per run and data of historical winners to compare your lineups to every run.",
        details: (
        <div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Full NFL + NBA Optimizer</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>300 Lineups/Build</b></div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Ownership</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Saved Lineups</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> After Start Optimizer</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Pre and Post Build Analysis</div>
            <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Historical Winner Data</div>
        </div>
        ),
        cost: (
        <div style={{textAlign: "center"}}>
            Royal | <Strikethough>$80</Strikethough> $64/mo
            <SaleBanner saleText={'20% off'} />
            <div style={{"textAlign": 'center', fontSize: '14px', fontWeight: "400"}}><i>OR</i></div>
            <div>
            Season Pass: <Strikethough>$480</Strikethough> $336
            <SaleBanner saleText={'25% off'} />
            </div>
        </div>
        ),
        name: "Bundle-Royal"
    },
]

export const LegacyPlans = [
  {
    title: <span>Self Starter (Legacy)</span>,
    basePrice: 10,
    borderColor: NFL_COLOR,
    sportIcon: (
    <span>
        <img src={NFLLogo} style={{height: '30px'}} />
        <img src={NBALogo} style={{height: '30px', marginLeft: '5px'}} />
    </span>
    ),
    icon: (<TrophyOutlined style={{color: NFL_COLOR}}/>),
    description: "Gives access to DFSForecast's projections, ownership and all of the tools for building lineups and max entering contests.",
    details: (
      <div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Full NFL Optimizer</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>20 Lineups/Build</b></div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Ownership</div>
        <div><span style={{color: '#ff1a1a'}}><CheckOutlined /></span> Saved Lineups</div>
        <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Pre and Post Build Analysis</div>
        <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Historical Winner Data</div>
      </div>
    ),
    cost: (
      <div style={{textAlign: "center"}}>
        <div style={{fontSize: '20px', marginBottom: '5px'}}>Gold</div>
        <div style={{fontWeight: '500'}}>
          Monthly: <Strikethough>$15</Strikethough> $12/mo
          <SaleBanner saleText={'20% off'} />
          <div style={{"textAlign": 'center', fontSize: '14px', fontWeight: "400"}}><i>OR</i></div>
          <div>
            Season Pass: <Strikethough>$210</Strikethough> $157
            <SaleBanner saleText={'25% off'} />
          </div>
        </div>
      </div>
    ),
    name: "SelfStarter"
  },
  {
    title: <span>Gold (Legacy)</span>,
    basePrice: 35,
    borderColor: NFL_COLOR,
    sportIcon: (
    <span>
        <img src={NFLLogo} style={{height: '30px'}} />
        <img src={NBALogo} style={{height: '30px', marginLeft: '5px'}} />
    </span>
    ),
    icon: (<TrophyOutlined style={{color: NFL_COLOR}}/>),
    description: "Gives access to DFSForecast's projections, ownership and all of the tools for building lineups and max entering contests.",
    details: (
      <div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Full NFL Optimizer</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>150 Lineups/Build</b></div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Ownership</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Saved Lineups</div>
        <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Pre and Post Build Analysis</div>
        <div><span style={{color: '#ff1a1a'}}><CloseOutlined /></span> Historical Winner Data</div>
      </div>
    ),
    cost: (
      <div style={{textAlign: "center"}}>
        <div style={{fontSize: '20px', marginBottom: '5px'}}>Gold</div>
        <div style={{fontWeight: '500'}}>
          Monthly: <Strikethough>$35</Strikethough> $28/mo
          <SaleBanner saleText={'20% off'} />
          <div style={{"textAlign": 'center', fontSize: '14px', fontWeight: "400"}}><i>OR</i></div>
          <div>
            Season Pass: <Strikethough>$210</Strikethough> $157
            <SaleBanner saleText={'25% off'} />
          </div>
        </div>
      </div>
    ),
    name: "Gold"
  },
  {
    title: "Royal (Legacy)",
    basePrice: 80,
    borderColor: NFL_COLOR,
    sportIcon: (
      <span>
          <img src={NFLLogo} style={{height: '30px'}} />
          <img src={NBALogo} style={{height: '30px', marginLeft: '5px'}} />
      </span>
    ),
    icon: (<CrownOutlined style={{color: NFL_COLOR}}/>),
    description: "All of DFSForecast's analysis, a max of 300 lineups per run and data of historical winners to compare your lineups to every run.",
    details: (
      <div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Full NFL Optimizer</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Bracket Ownership</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Max <b>300 Lineups/Build</b></div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Lineup Download</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Projections</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Showdown Slates</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Ownership</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Saved Lineups</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Pre and Post Build Analysis</div>
        <div><span style={{color: '#00e600'}}><CheckOutlined /></span> Historical Winner Data</div>
      </div>
    ),
    cost: (
      <div style={{textAlign: "center"}}>
        Royal | <Strikethough>$80</Strikethough> $64/mo
        <SaleBanner saleText={'20% off'} />
        <div style={{"textAlign": 'center', fontSize: '14px', fontWeight: "400"}}><i>OR</i></div>
        <div>
          Season Pass: <Strikethough>$480</Strikethough> $336
          <SaleBanner saleText={'25% off'} />
        </div>
      </div>
    ),
    name: "Royal"
  },
]