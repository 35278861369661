import { Row, Col, Card, Divider, Switch, Alert } from 'antd'
import {
  SaveOutlined,
  CloudDownloadOutlined,
  RightSquareOutlined,
  CrownOutlined
} from '@ant-design/icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import * as lineupActions from '../../../../actions/lineup'
import * as analysisActions from '../../../../actions/analysis'
import ExposureStats from './Analysis/exposure-stats'
import LineupComparisonChart from './Analysis/lineup-comparison-chart'
import OwnershipBreakdown from './Analysis/ownership-breakdown'
import StacksBreakdown from './Analysis/stacks-breakdown'
import { getTeamsInSlate } from '../../../../utils/slate'

import fetchExposureStats, { mergeLineupsWithPlayerTable } from './utils/fetch-exposure-stats'

import Overview from './Analysis/overview'

import './Lineups.css'

const StyledAlert = styled(Alert)`
  margin-bottom: 10px;
  &:hover {
    font-weight: 600;
  }
`
const StyledLineups = styled.div`
  margin-top: 25px;
`
const StatsCard = styled(Card)`
  min-height: 380px;
`
const StyledSwitch = styled(Switch)`
  margin-top: 20px !important;
  padding: 5px;
`

class LineupAnalysis extends Component {

  async componentDidMount() {
    const _data = await this.props.rawLineups
    if (!this.props.showdown && _data && _data.length) {
      const slateSize = getTeamsInSlate(this.props.players.data)
      this.props.fetchLineupStats({
        lineups: _data,
        slateSize
      })
      this.props.fetchPositionRanks({
        numLUs: (this.props.rawLineups.length / this.props.lineupSize),
        slateSize: getTeamsInSlate(this.props.players.data)
      })
    }
  }

  componentDidUpdate(prevProps) {
    // Update stats if we switch between saved and current run
    if (!this.props.showdown && JSON.stringify(prevProps.rawLineups) !== JSON.stringify(this.props.rawLineups)) {
      const slateSize = getTeamsInSlate(this.props.players.data)
      this.props.fetchLineupStats({
        lineups: this.props.rawLineups,
        slateSize
      })
    }
  }

  getPlayersActualExp(rawLineups) {

    const _players = {}
    rawLineups.forEach(p => {
      if (["DST", "DEF"].indexOf(p.Position) == -1) {
        if (!_players[p.Id])
        _players[p.Id] = {
          ...p,
          Count: 0
        }

        _players[p.Id].Count = _players[p.Id].Count + 1
      }
    })

    const numLUs = Math.ceil(rawLineups.length / 9)
    const _playersCombined = []
    Object.keys(_players).forEach(pId => {
      const p = _players[pId]
      const _p = {
        ...p,
        ActualExp: (p.Count / numLUs) * 100
      }
      _playersCombined.push(_p)
    })

    return _playersCombined
  }

  render() {
    const _playersCombined = this.getPlayersActualExp(this.props.rawLineups || [])

    const exposureStats = mergeLineupsWithPlayerTable(
      fetchExposureStats(this.props.rawLineups, (this.props.rawLineups.length / this.props.lineupSize)),
      this.props.players.data
    )

    return (
      <div className="Lineups">
        <Overview
          players={_playersCombined}
          permissioned={this.props.permissions.analytics.nfl.post_build}
          exposureStats={ exposureStats }
          showdown={ this.props.showdown }
        />
        {
          !this.props.permissions.analytics.nfl.post_build ? (
            <Link to="/plans">
              <StyledAlert
                message={(<span>Upgrade to one of our premium plans to use Post-Build Analysis <RightSquareOutlined /></span>)}
                type="info"
                style={{textAlign: 'center'}}
              />
            </Link>
          ) : ''
        }
        <StyledLineups>
          <StatsCard hoverable>
            <Row>
              <Col lg={14}>
                <h3>Player Breakdown</h3>
                <ExposureStats
                  lineups={ this.props.rawLineups || [] }
                  players={ this.props.players.data }
                  lineupSize={ this.props.lineupSize }
                  loading={ this.props.loading }
                  site={this.props.site}
                  permissioned={ this.props.permissions.analytics.nfl.post_build }
                  exposureStats={ exposureStats }
                  showdown={ this.props.showdown }
                  onExposureChange={this.props.onExposureChange}
                  positionRanks={this.props.positionRanks.data}
                />
              </Col>
              <Col lg={9} offset={1}>
                <h3 style={{marginBottom: '40px'}}>Stacks Breakdown</h3>
                <StacksBreakdown
                  rawLineups={this.props.rawLineups}
                  permissioned={this.props.permissions.analytics.nfl.post_build}
                />
              </Col>
            </Row>
            {
              !this.props.showdown && (
                <Row style={{marginTop: '50px'}}>
                  <Col lg={11}>
                    <h3><span style={{fontSize: '12px', marginRight: '2px', color: '#ffa500'}}><CrownOutlined /></span>Lineup Comparison</h3>
                    <LineupComparisonChart
                      loading={this.props.lineupStats.loading}
                      lineupComparisonData={
                        this.props.lineupStats.data ?
                        this.props.lineupStats.data.lineup_comparison :
                        null
                      }
                      permissioned={this.props.permissions.analytics.nfl.historical}
                    />
                  </Col>
                  <Col lg={2}></Col>
                  <Col lg={11}>
                    
                  </Col>
                </Row>
              )
            }
          </StatsCard>
        </StyledLineups>
      </div>
    )
  }
}

export default connect(
  state => ({
    lineupStats: state.lineup.lineupStats,
    loggedin: state.auth.loggedin,
    players: state.lineup.players,
    settings: state.lineup.settings,
    opt: state.lineup.opt,
    positionRanks: state.analysis.positionRanks
  }),
  {
    fetchLineupStats: lineupActions.fetchLineupStats,
    fetchPositionRanks: analysisActions.fetchPositionRanks
  }
)(LineupAnalysis)
