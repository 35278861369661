import { Row, Col, Button, Card, Modal, Switch, Select } from 'antd'
import {
  SaveOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as lineupActions from '../../../../actions/lineup'
import LineupTable from './lineup-table'
import ShowdownLineupTable from './showdown-lineup-table'
import { getLineupSize, formatNameIDForUpload } from '../../../../utils/contests'
import { lineupHash } from '../../../../utils/simple-hash'
import { downloadCSV } from '../../../../utils/csv'
import { getCSVHeadingsForSite } from '../../../../utils/showdown'
import { 
  getAfterStartCSVHeadingsForSite,
  getPlayerStartIndexForSite
 } from './utils/after-start'
import { normalizeLineup, getPositionsClassic, getPositionsShowdown } from '../../../../utils/normalize-lineups'
import NewBanner from '../../../../components/new-banner'

import './Lineups.css'
import LineupModalBody from './lineup-modal/lineup-modal-body'

const StyledLineups = styled.div`
  margin-top: 25px;
`
const FailedContainer = styled.div`
  margin-top: 15vh;
  text-align: center;
`
const StatsCard = styled(Card)`
  min-height: 380px;
`
const StyledSwitch = styled(Switch)`
  margin-top: 20px !important;
  padding: 5px;
`
const PlayerEditModal = styled(Modal)`

`

const csvHeadings = {
  'dk': 'QB,RB,RB,WR,WR,WR,TE,FLEX,DST\n',
  'fd': 'QB,RB,RB,WR,WR,WR,TE,FLEX,DEF\n',
}

const baseURIui = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://localhost:5002' : 'https://ui.dfsforecast.com'
// const baseURIui = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'https://ui.dfsforecast.com' : 'https://ui.dfsforecast.com'

class Lineups extends Component {
  state = {
    heatmap: false,
    heatmapStat: 'ProjOwn',
    rerender: false,
    filteredPlayers: [],
    showLineupModal: false,
    selectedRecord: null
  }

  async downloadResults() {
    // Clone raw lineups
    let lineups
    let _data = []
    const luSize = getLineupSize(this.props.site, this.props.showdown ? 'showdown' : 'classic', this.props.sport)

    if (this.props.showSaved) {
      // flatten raw table
      lineups = this.props.savedLineups.data.map(entry => entry.rawTable).flat()
    } else { 
      lineups = [ ...this.props.rawLineups.slice(0) ]
    }
    if (lineups) {
      for (let i=0; i< Math.ceil(lineups.length / luSize); i++) {
        _data.push(lineups.slice((i*luSize), ((i+1)*luSize)))
      }
    }

    
    if (this.props.showdown) {
      let csv = getCSVHeadingsForSite(this.props.site)

      _data.forEach((lu) => {
        lu.forEach((p, i) => {
          csv += formatNameIDForUpload(this.props.site, p, true)

          if (i === lu.length - 1) {
            csv += '\n'
          } else {
            csv += ','
          }
        })
      })

      downloadCSV(csv, `dfsforecast-lineups-${this.props.site}-${this.props.slate}.csv`)
    } else {
      const positions = getPositionsClassic(this.props.site, this.props.sport)
      let headings = ''
      positions.forEach((p, i) => {
        headings += p.toUpperCase()
        if (positions.length - 1 === i) {
          headings += '\n'
        }
        else
          headings += ','
      })
      let csv = headings

      _data.forEach((lu) => {
        lu.forEach((p, i) => {
          csv += formatNameIDForUpload(this.props.site, p, false)

          if (i === lu.length - 1) {
            csv += '\n'
          } else {
            csv += ','
          }
        })
      })
      downloadCSV(csv, `dfsforecast-lineups-${this.props.sport}-${this.props.counter}-${this.props.site}-${this.props.slate}.csv`)
    }
  }

  saveAllLineups() {
    const lineups = this.props.opt.data

    const _data = []
    if (lineups) {
      for (let i=0; i< Math.ceil(lineups.length / this.props.lineupSize); i++) {
        _data.push(lineups.slice((i*this.props.lineupSize), ((i+1)*this.props.lineupSize)))
      }
    }

    const dbLineups = []
    _data.forEach(_lu => {
      const _normalizedLU = normalizeLineup(_lu, this.props.site, this.props.showdown)
      const lu = {
        ..._normalizedLU,
        saved: true,
        lineupHash: lineupHash(_normalizedLU, getPositionsClassic(this.props.site, this.props.sport)),
        rawTable: _lu
      }
      dbLineups.push(lu)
    })

    this.props.saveLineups({
      lineups: dbLineups,
      site: this.props.site,
      slate: this.props.slate
    })
  }

  unsaveAllLineups() {
    const lineups = this.props.savedLineups.data
    const _data = []

    lineups.forEach(_lu => {
      const lu = {
        ..._lu,
        saved: false,
      }
      _data.push(lu)
    })

    this.props.saveLineups({
      lineups: _data,
      site: this.props.site,
      slate: this.props.slate
    })

    this.setState({
      showSaved: false
    })
  }

  async changeTab(tab) {
    this.setState({
      showSaved: (tab === 'saved')
    })
  }

  filterLineups(lineups) {
    const _filteredLineups = []
    // If there is nothing to filter, return all lineups
    if (!this.state.filteredPlayers.length)
      return lineups

    lineups.forEach((lu, i) => {
      let add = true
      this.state.filteredPlayers.forEach((playerID) => {
        if (!lu.filter(p => String(p.Id) === String(playerID)).length) {
          add = false
        }
      })

      if (add)
        _filteredLineups.push(lu)
    })

    return _filteredLineups
  }

  filterPlayer(playerId) {
    if (this.state.filteredPlayers.indexOf(playerId) === -1) {
      const _filteredPlayers = [ ...this.state.filteredPlayers ]
      _filteredPlayers.push(playerId)
      this.setState({
        filteredPlayers: _filteredPlayers
      })
    }
  }

  selectExpandedLineup(record) {
    this.setState({
      selectedRecord: record,
      showLineupModal: true
    })
  }

  replaceLineup(record) {
    // get positions for format
    const _positions = this.props.showdown ? getPositionsShowdown(this.props.site, this.props.sport) : getPositionsClassic(this.props.site, this.props.sport)
    // flatten record
    let flattenedRecord = []
    _positions.forEach(p => {
      flattenedRecord.push(record[p])
    })

    const optLUs = [...this.props.rawLineups]

    const runID = record["rawTable"][0]["Run"]
    // Find start index of run
    let startIndex = -1
    for (let i = 0; i < optLUs.length; i++) {
      if (optLUs[i]["Run"] === runID && startIndex === -1) {
        startIndex = i
        break
      }
    }
    // copy over LU
    for (let j=0; j < flattenedRecord.length; j++) {
      optLUs[startIndex + j] = flattenedRecord[j]
    }

    this.props.replaceLineups({payload: optLUs, site: this.props.site, slate: this.props.slate})

    this.setState({
      showLineupModal: false
    })
  }

  render() {
    let _loading = false
    if (this.props.showSaved) {
      if (!this.props.savedLineups.loading) {
        _loading = false
      } else {
        _loading = true
      }
    } else {
      if (!this.props.opt.loading) {
        _loading = false
      } else {
        _loading = true
      }
    }

    const lineupSize = this.props.lineupSize

    let _data = []
    if (!_loading && this.props.showSaved) {
      _data = this.props.savedLineups.data.map(entry => entry.rawTable)
    } else if (!_loading && this.props.rawLineups.length) {
      for (let i=0; i< Math.ceil(this.props.rawLineups.length / lineupSize); i++) {
        _data.push(this.props.rawLineups.slice((i*lineupSize), ((i+1)*lineupSize)))
      }
    }

    _data = this.filterLineups(_data)

    const failedBuilding = this.props.opt.failedLoading

    const getNumLUsFromAfterStart = () => {
      let numLUs = 0
      Object.keys(this.props.afterStartLineups).forEach(k => {
        const d = this.props.afterStartLineups[k]
        if (d.active)
          numLUs += d.lineups.length
      })

      return numLUs
    }
    const totalLineups = this.props.afterStartActivated ? getNumLUsFromAfterStart() : this.props.settings.data.numLUs

    return (
      <div className="Lineups">
        <PlayerEditModal
          open={this.state.showLineupModal}
          onCancel={() => {this.setState({showLineupModal: false})}}
          width={'90%'}
          footer={null}
        >
          <LineupModalBody 
            record={this.state.selectedRecord} 
            positions={this.props.showdown ? getPositionsShowdown(this.props.site, this.props.sport) : getPositionsClassic(this.props.site, this.props.sport)}
            players={this.props.players.data} 
            replaceLineup={this.replaceLineup.bind(this)}
            totalSalary={this.props.settings.data.maxSal}
            showdown={this.props.showdown}
          />
        </PlayerEditModal>
        <StyledLineups>
          <Row>
            <Col lg={8}>
              <Button
                style={{marginBottom: '25px'}}
                onClick={this.downloadResults.bind(this)}
                disabled={!this.props.rawLineups || this.props.rawLineups.length === 0}
              >
                <CloudDownloadOutlined />Download Lineups
              </Button>
              { this.props.showSaved ? (
                <Button
                  style={{marginBottom: '25px', marginLeft: '25px'}}
                  onClick={this.unsaveAllLineups.bind(this)}
                  disabled={!this.props.loggedin}
                >
                  <SaveOutlined />Unsave All
                </Button>
              ) : (
                <Button
                  style={{marginBottom: '25px', marginLeft: '25px'}}
                  onClick={this.saveAllLineups.bind(this)}
                  disabled={!this.props.loggedin || this.props.showdown}
                >
                  <SaveOutlined />Save All
                </Button>
              )}
            </Col>
            <Col lg={8}>
              <Select
                showSearch
                mode="multiple"
                size="medium"
                style={{ width: '80%', marginBottom: '25px' }}
                placeholder="Filter Lineups by Player"
                optionFilterProp="children"
                value={this.state.filteredPlayers}
                onChange={(v) => {
                  this.setState({
                    filteredPlayers: v
                  })
                }}
                filterOption={(input, option) => {
                  return option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                filterSort={(optionA, optionB) => {
                  return optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                }}
              >
                { this.props.players.data.map((p, i) => {
                  return (
                    <Select.Option key={i} value={p.Id}>{p.Name} - {this.props.showdown ? p.RosterPosition : p.Position} | {p.TeamAbbrev}</Select.Option>
                  )
                })}
              </Select>
            </Col>

          </Row>
          <Row>
            <Col lg={24}> {
                failedBuilding ? (
                  <FailedContainer>
                    <b>Uh Oh! The Server was unable to complete your build.</b>
                    <br />
                    <br />
                    Please carefully check your <b>build settings</b>. This often happens because the optimizer is passed settings where it is impossible to build the requested lineups.
                    <br />
                    <br />
                    <b>Troubleshooting:</b>
                    <br />
                    - Consider the Warnings in the Player Analysis tab.
                    <br />
                    - Make sure your stacks have a sufficient number of position players to stack with your QB.
                    <br />
                    - Forced player ownership too tight or player pool too low in those ownership ranges. Try loosening Number of Players Under N% Owned settings or add more players for the tightest ownerships to your player pool
                    <br />
                    - Check to see if you have active Groups that clash with your stack settings, or contain players outside of your player pool.
                    <br />
                    - Player Pool too tight. If there are not enough players in different price ranges for the optimizer to choose from it may be impossible to make the requested number of lineups. Try adding more players or building less lineups.
                  </FailedContainer>
                ) : (
                  <div>
                    {
                      this.props.showdown ? (
                        <ShowdownLineupTable
                          rows={_data || []}
                          lineupSize={lineupSize}
                          site={this.props.site}
                          slate={this.props.slate}
                          loading={_loading}
                          showSaved={this.props.showSaved}
                          currentLineupNumber={this.props.opt.data ? this.props.opt.data.length / lineupSize : 0}
                          totalLineupNumber={this.props.settings.data.numLUs}
                          filterPlayer={this.filterPlayer.bind(this)}
                          counter={this.props.week}
                          season={this.props.season}
                          selectExpandedLineup={this.selectExpandedLineup.bind(this)}
                        />
                      ) : (
                        <LineupTable
                          rows={_data || []}
                          heatmap={this.state.heatmap}
                          heatmapStat={this.state.heatmapStat}
                          site={this.props.site}
                          slate={this.props.slate}
                          loading={_loading}
                          showSaved={this.props.showSaved}
                          currentLineupNumber={this.props.opt.data ? this.props.opt.data.length / lineupSize : 0}
                          totalLineupNumber={totalLineups}
                          filterPlayer={this.filterPlayer.bind(this)}
                          counter={this.props.week}
                          season={this.props.season}
                          selectExpandedLineup={this.selectExpandedLineup.bind(this)}
                        />
                      )
                    }
                  </div>
                )
              }
            </Col>
          </Row>
        </StyledLineups>
      </div>
    )
  }
}

export default connect(
  state => ({
    lineupStats: state.lineup.lineupStats,
    opt: state.lineup.opt,
    savedLineups: state.lineup.savedLineups,
    loggedin: state.auth.loggedin,
    players: state.lineup.players,
    settings: state.lineup.settings,
    afterStartActivated: state.afterStart.activated,
    afterStartLineups: state.afterStart.afterStartLineups.data
  }),
  {
    fetchLineupStats: lineupActions.fetchLineupStats,
    saveLineups: lineupActions.saveLineups,
    fetchSavedLineups: lineupActions.fetchSavedLineups,
    fetchExposureStats: lineupActions.fetchExposureStats,
    replaceLineups: lineupActions.replaceLineups
  }
)(Lineups)
