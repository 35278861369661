import { Table, Progress } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'

import { normalizeLineup, positions } from '../../../../utils/normalize-lineups'
import { CPTDisplay } from '../../../../utils/showdown'
import SaveLineupStar from '../../../../components/save-lineup-star'

import {
  HEAT_MAP_POSITIVE_COLOR_SCALE,
  HEAT_MAP_NEGATIVE_COLOR_SCALE,
  getColorForValue
} from './Analysis/utils/color'

const StyledTable = styled(Table)`
  box-shadow: 0px 6px 36px #457b9d;

  .ant-table-tbody > tr > td {
    font-size: 12px;
  }

  td {
    text-align: center;
  }

  tr th {
    text-align: center !important;
  }

  tr:first-child {
    th {
      color: white !important;
      background: #1d3557 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 5px solid #B0B0B0 !important;
  }
`

const LineupCols = (site, slate, showSaved, numFlex, selectExpandedLineup) => {
  let children = [
    {
      title: CPTDisplay[site],
      dataIndex: 'CPT',
      key: 'cpt',
      render: (player) => player.Name
    }
  ]

  const flexCols = []
  for (let i=1; i <= numFlex; i++) {
    flexCols.push({
      title: `FLEX${i}`,
      dataIndex: `FLEX${i}`,
      key: `FLEX${i}`,
      render: (player) => player.Name
    })
  }

  // Add flex cols to table
  children = children.concat(flexCols)

  // Stat cols
  children = children.concat([
    {
      title: 'Salary',
      key: 'salary',
      render: (_, row) => {
        let totalSal = 0
        const keys = Object.keys(row)
        keys.forEach(k => {
          if (k !== 'rawTable')
            totalSal += Number(row[k].Salary)
        })
        return (
          <span>
            {totalSal}
          </span>
        )
      }
    },
    {
      title: 'Proj Own',
      key: 'projPoints',
      render: (_, row) => {
        let totalProj = 0
        const keys = Object.keys(row)
        keys.forEach(k => {
          if (k !== 'rawTable')
            totalProj += Number(row[k].ProjOwn)
        })
        return `${Number(totalProj).toFixed(2)}%`
      }
    },
    {
      title: 'Proj Pts',
      key: 'projPoints',
      render: (_, row) => {
        let totalProj = 0
        const keys = Object.keys(row)
        keys.forEach(k => {
          if (k !== 'rawTable')
            totalProj += Number(row[k].ProjPts)
        })
        return `${Number(totalProj).toFixed(2)}`
      }
    },
    {
      title: '<10%',
      key: 'NumUnder10pct',
      render: (_, row) => {
        let totalUnder = 0
        const keys = Object.keys(row)
        keys.forEach(k => {
          if (k !== 'rawTable')
            if (Number(row[k].ProjOwn) < 10)
              totalUnder++
        })
        return totalUnder
      }
    },
    {
      title: '<5%',
      key: 'NumUnder5pct',
      render: (_, row) => {
        let totalUnder = 0
        const keys = Object.keys(row)
        keys.forEach(k => {
          if (k !== 'rawTable')
            if (Number(row[k].ProjOwn) < 5)
              totalUnder++
        })
        return totalUnder
      }
    },
    {
      title: '<2.5%',
      key: 'NumUnder2pt5pct',
      render: (_, row) => {
        let totalUnder = 0
        const keys = Object.keys(row)
        keys.forEach(k => {
          if (k !== 'rawTable')
            if (Number(row[k].ProjOwn) < 2.5)
              totalUnder++
        })
        return totalUnder
      }
    },
    {
      title: 'Edit',
      render: (_, record) => {
        return (
          <div><EditOutlined style={{color: '#1890ff'}} onClick={() => {selectExpandedLineup(record)}}/></div>
        )
      }
    }
  ])

  return ([{
    title: 'Lineups',
    children
  }])
}


const ShowdownLineupTable = ({ loading, rows, site, slate, showSaved, currentLineupNumber, totalLineupNumber, lineupSize, selectExpandedLineup }) => {
  let luRows = []
  if (rows && rows.length > 0) {
    rows.forEach((row, i) => {
      luRows[i] = {
        ...normalizeLineup(row, site, true),
        rawTable: row
      }
    })
  }

  return (
    <StyledTable
      columns={LineupCols(site, slate, showSaved, lineupSize - 1, selectExpandedLineup)}
      dataSource={luRows}
      scroll={{ x: '90%', }}
      bordered
      loading={{
        indicator: (
          <Progress type="circle" percent={(Number(currentLineupNumber)/Number(totalLineupNumber)*100).toFixed(0)} />
        ),
        spinning: loading
      }}
      size='small'
      pagination={{ pageSize: 50 }}
      rowKey={_row => {
        let _run = 1
        if (_row.rawTable)
          _run = _row.rawTable[0].Run + 1
        return String(_run)
      }}
    />
  )
}

export default ShowdownLineupTable
