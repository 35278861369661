import { Row, Col, Button, Card, Divider, Menu, Switch } from 'antd'
import {
  SaveOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as lineupActions from '../../../../actions/lineup'

import LineupAnalysis from './analysis'
import LineupDisplay from './lineups-display'
import { getLineupSize } from '../../../../utils/contests'
import { SectionHeaderTitle } from '../../../../components/section-header-title'
import { defaultDate } from '../../util/nba-date'

import NewBanner from '../../../../components/new-banner'

import './Lineups.css'

const StyledLineups = styled.div`
  margin-top: 25px;
`
const FailedContainer = styled.div`
  margin-top: 15vh;
  text-align: center;
`
const StatsCard = styled(Card)`
  min-height: 380px;
`
const StyledSwitch = styled(Switch)`
  margin-top: 20px !important;
`
const SwitchCol = styled(Col)`
  .ant-switch {
    background-color: #1890ff;
    background-image: none;
  }
  .ant-switch-checked {
    background-color: #ffa500;
  }
`

const baseURIui = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://localhost:5002' : 'https://ui.dfsforecast.com'
// const baseURIui = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'https://ui.dfsforecast.com' : 'https://ui.dfsforecast.com'

class Lineups extends Component {
  state = {
    heatmap: false,
    heatmapStat: 'ProjOwn',
    showSaved: false,
    rawLineups: [],
    rerender: false,
    toggle: 'lineups'
  }

  async changeTab(tab) {
    this.setState({
      showSaved: (tab === 'saved')
    })
  }

  exposureChangeValid(rowData) {
    // must be inbetween 0-100
    if (rowData.MaxExp > 100 || rowData.MaxExp < 0 || rowData.MinExp > 100 || rowData.MinExp < 0) return false

    return true
  }

  onExposureChange(rowData) {
    // turn into array
    if (!Array.isArray(rowData))
      rowData = [rowData]

    const _players = [
      ...this.props.players.data
    ]

    rowData.forEach(r => {
      // Skip rows that have invalid changes
      if (!this.exposureChangeValid(r)) return

      const _playerIndex = _players.findIndex(p => p.Id === r.Id)
      if (_playerIndex < 0) {
        throw Error('No player with id ' + r.Id)
      }

      _players[_playerIndex] = {
        ...r
      }
    })

    this.props.updatePlayersTable({
      payload: _players,
      rowData,
      slate: this.props.slate,
      site: this.props.site,
      sport: 'nba'
    })
  }

  render() {
    let _loading = false
    if (this.state.showSaved) {
      if (!this.props.savedLineups.loading) {
        _loading = false
      } else {
        _loading = true
      }
    } else {
      if (!this.props.opt.loading) {
        _loading = false
      } else {
        _loading = true
      }
    }

    const failedBuilding = this.props.opt.failedLoading
    const _rawLineups = this.state.showSaved ? this.props.savedLineups.data : this.props.opt.data

    const lineupSize = getLineupSize(this.props.site, this.props.showdown ? 'showdown' : 'classic', 'nba')

    return (
      <div className="Lineups">
        <SectionHeaderTitle sport={'nba'} site={this.props.site} period={defaultDate()} />
        <StyledLineups>
          <Row>
            <Col lg={12} >
              <Menu onClick={(e) => this.changeTab(e.key)} selectedKeys={this.state.showSaved ? ['saved'] : ['current']} mode="horizontal">
                <Menu.Item key={'current'}>Current Build ({this.props.opt.data ? this.props.opt.data.length / lineupSize : 0})</Menu.Item>
                <Menu.Item key={'saved'} disabled={
                    true || 
                    !this.props.loggedin ||
                    (this.props.savedLineups.data && this.props.savedLineups.data.length === 0) ||
                    this.props.afterStartActivated
                  }>Saved Lineups ({this.props.savedLineups.data ? this.props.savedLineups.data.length / lineupSize || 0 : 0})</Menu.Item>
              </Menu>
            </Col>
            <SwitchCol lg={2} offset={10}>
              <div style={{display: 'inline-block'}}>
                <StyledSwitch
                  disabled={_loading}
                  checkedChildren="Analysis"
                  unCheckedChildren="Lineups"
                  onClick={(e) => {this.setState({
                    toggle: e ? 'analysis' : 'lineups'
                  })}}
                />
              </div>
            </SwitchCol>
          </Row>
          <Divider />
          {
            this.state.toggle === 'lineups' ? (
              <LineupDisplay
                rawLineups={_rawLineups || []}
                showSaved={this.state.showSaved}
                site={this.props.site}
                slate={this.props.slate}
                showdown={this.props.showdown}
                lineupSize={lineupSize}
              />
            ) : (
              <LineupAnalysis
                rawLineups={_rawLineups || []}
                loading={_loading}
                permissions={this.props.subscription.permissions}
                lineupSize={lineupSize}
                showdown={this.props.showdown}
                site={this.props.site}
                onExposureChange={this.onExposureChange.bind(this)}
              />
            )
          }
        </StyledLineups>
      </div>
    )
  }
}

export default connect(
  state => ({
    lineupStats: state.lineup.lineupStats,
    opt: state.lineup.opt,
    savedLineups: state.lineup.savedLineups,
    loggedin: state.auth.loggedin,
    players: state.lineup.players,
    settings: state.lineup.settings,
    subscription: state.account.subscription,
    afterStartActivated: state.afterStart.activated,
  }),
  {
    fetchLineupStats: lineupActions.fetchLineupStats,
    saveLineups: lineupActions.saveLineups,
    fetchSavedLineups: lineupActions.fetchSavedLineups,
    fetchExposureStats: lineupActions.fetchExposureStats,
    updatePlayersTable: lineupActions.updatePlayersTable
  }
)(Lineups)
