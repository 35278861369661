import { Card, Col, Row, Alert, Spin } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  RightSquareOutlined
} from '@ant-design/icons'

import PositionBreakdownTable from './Analysis/position-breakdown'
import TopOwnedPlayers from './Analysis/player-breakdown'
import MatchupBreakdownTable from './Analysis/matchup-breakdown'
import OwnershipBreakdown from './Analysis/ownership-breakdown'
import Overview from './Analysis/overview'
import {
  validateBuild,
  validateSettings,
  validateTeamStacks
} from './Analysis/utils/validate'

import FakurianDesign from '../../../../assets/images/backgrounds/fakurian-design-light.png'

const PlayersAnalysisContainer = styled.div`
  margin-top: 30px;
`
const StyledAlert = styled(Alert)`
  margin-bottom: 10px;
  &:hover {
    font-weight: 600;
  }
`

const PlayersAnalysis = ({ players, teamStacks, settings, onExposureChange, permissions, positionCounts, showdown, site }) => {
  // Show spinning if still loading
  if (positionCounts && Object.keys(positionCounts).length === 0 && positionCounts.constructor === Object) {
    return (
      <PlayersAnalysisContainer>
        <Card style={{textAlign: 'center'}}>
          <Spin />
        </Card>
      </PlayersAnalysisContainer>
    )
  }

  const [ hasErrors, setHasErrors ] = useState(false)
  let warnings = []
  if (!showdown)
    warnings = generateWarnings(players, teamStacks, settings, hasErrors, setHasErrors)

  return (
    <PlayersAnalysisContainer>
      {
        !permissions.analytics.nfl.pre_build ? (
          <Link to="/plans">
            <StyledAlert message={(<span>Upgrade to one of our premium plans to use Pre-Build Analysis <RightSquareOutlined /></span>)} type="info" style={{textAlign: 'center'}} />
          </Link>
        ) : ''
      }
      <Overview players={players} warnings={warnings} permissioned={permissions.analytics.nfl.pre_build} />
      {
        hasErrors ? (
          <Alert message={'The current build has issues that may cause unexpected results or could cause the build to fail in some circumstances. See Warnings for details.'} type="error" style={{textAlign: 'center'}} />
        ) : ''
      }
      <Card>
        <Row>
          <Col lg={10}>
            <h3>Positional Breakdown</h3>
            <div style={{margin: '12px 0px'}} />
            <PositionBreakdownTable players={players} permissioned={permissions.analytics.nfl.historical} positionCounts={positionCounts} showdown={showdown} site={site} />
          </Col>
          <Col lg={10} offset={2}>
            <h3>Ownership Breakdown</h3>
            <div style={{margin: '12px 0px'}} />
            <OwnershipBreakdown players={players} permissioned={permissions.analytics.nfl.pre_build} />
          </Col>
        </Row>
      </Card>
      <Card>
        <Row>
          <Col lg={10}>
            <h3>Player Breakdown</h3>
            <TopOwnedPlayers players={players} onExposureChange={onExposureChange} permissioned={permissions.analytics.nfl.pre_build} showdown={showdown} site={site} />
          </Col>
          <Col lg={10} offset={2}>
            <h3>Team Breakdown</h3>
            <div style={{marginTop: '8px'}} />
            <MatchupBreakdownTable players={players} onExposureChange={onExposureChange} settings={settings} permissioned={permissions.analytics.nfl.pre_build} />
          </Col>
        </Row>
      </Card>
    </PlayersAnalysisContainer>
  )
}

const generateWarnings = (players, teamStacks, settings, hasErrors, setHasErrors) => {
  let warnings = []
  // validate build for errors
  const [ valid, errors ] = validateBuild(players)
  if (!valid) {
    warnings = warnings.concat(errors)
    if (!hasErrors)
      setHasErrors(true)
  }
  const [ settingsValid, settingsErrors ] = validateSettings(players, settings)
  if (!settingsValid) {
    warnings = warnings.concat(settingsErrors)
    if (!hasErrors)
      setHasErrors(true)
  }

  const [ teamStacksValid, teamStacksErrors ] = validateTeamStacks(players, settings, teamStacks)
  if (!teamStacksValid) {
    warnings = warnings.concat(teamStacksErrors)
    if (!hasErrors)
      setHasErrors(true)
  }

  return warnings
}

export default PlayersAnalysis
