import React, { useState, Component } from 'react'
import { Row, Col, Card, Input, Button, Form } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import * as authActions from '../../actions/auth'
import * as accountActions from '../../actions/account'
import Logo from '../../assets/images/logo-filled.png'

const ResetPasswordContainer = styled.div`
  background: #eaecef;
`
const LoginCard = styled(Card)`
  border: 2px solid #cccccc !important;
  border-radius: 12px !important;
  margin: 100px 25% 0px 25% !important;
  box-shadow: 2px 2px 40px #e6e6e6;
`
const HeadingText = styled.div`
  width: 100%;
  font-size: 30px;
  font-weight: 600;
`
const StyledInput = styled(Input)`
  border-radius: 0px !important;
  font-size: 20px !important;
  line-height: 44px;

  .ant-input {
    border-radius: 0px !important;
    font-size: 20px !important;
    line-height: 44px;
  }

  .ant-input-prefix {
    margin-right: 7px;
  }

  .error {
    .ant-input {
      border: 1px solid red !important;
    }
  }
`
const LinkText = styled.div`
  font-size: 12px;
  color: blue;
`
const LogoImg = styled.img`
  height: 70px;
`
const Underline = styled.div`
  border-top: 1px solid #bfbfbf;

  &.error {
    border-top: 1px solid #ff6666;
  }
`
const ClickableText = styled.div`
  cursor: pointer;
  margin: 0px 2px;
`
const ErrorText = styled.div`
  text-align: center;
  color: #ff4d4d;
  font-size: 12px;
  margin: 10px 0px 10px 0px;
`
const StyledForm = styled(Form)`
  .ant-form-item-explain-error {
    text-align: center;
  }
  .ant-form-item-explain {
    text-align: center;
  }

  input {
    border: none !important;
  }
`
class ResetPassword extends Component {
  render() {
    return (
      <Login
        updatePassword={this.props.updatePassword}
        error={this.props.auth.failedLogin ? (this.props.auth.errorMessage) : ''}
        success={this.props.success}
      />
    )
  }
}

const Login = ( { updatePassword, error, success } ) => {
  const [ errors, setErrors ] = useState([])

  const initiateUpdatePassword = (values) => {
    if (values["new-password"] !== values["reenter-new-password"])
      setErrors(["New Passwords do not match"])
    else {
      updatePassword({
        oldPassword: values["current-password"],
        newPassword: values["new-password"]
      })
    }
  }

  return (
    <div>
        <ErrorText>{error}</ErrorText>
        {
          errors.length ? (
            <ErrorText>{errors[0]}</ErrorText>
          ) : ''
        }
        <StyledForm onFinish={initiateUpdatePassword} className="login-form">
          <Form.Item
            name="current-password"
            rules={[
              {
                required: true,
                message: 'Please enter your current password'
              }
            ]}
          >
            <Row style={{marginTop: '10px'}}>
              <Col lg={12} offset={6}>
                <StyledInput.Password
                  bordered={false}
                  size="large"
                  placeholder={'Current Password'}
                />
                <Underline />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="new-password"
            rules={[
              {
                required: true,
                message: 'Please enter your password'
              }
            ]}
          >
            <Row style={{marginTop: '10px'}}>
              <Col lg={12} offset={6}>
                <StyledInput.Password
                  bordered={false}
                  size="large"
                  placeholder={'New Password'}
                />
                <Underline />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="reenter-new-password"
            rules={[
              {
                required: true,
                message: 'Please enter your password'
              }
            ]}
          >
            <Row style={{marginTop: '10px'}}>
              <Col lg={12} offset={6}>
                <StyledInput.Password
                  bordered={false}
                  size="large"
                  placeholder={'Re-Enter New Password'}
                />
                <Underline />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <div style={{marginTop: '40px'}}>
              <div style={{marginLeft: '40%'}}>
                <Button
                  size={'large'}
                  shape='round'
                  type='primary'
                  style={{width: '160px'}}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form.Item>
        </StyledForm>
    </div>
  )
}

export default connect(
  state => ({
    auth: state.auth,
    account: state.account
  }),
  {
    updatePassword: accountActions.updatePassword
  }
)(ResetPassword)
