import { Menu, Row, Col, Button, Tooltip, Progress } from 'antd'
import {
  CrownOutlined
} from '@ant-design/icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as lineupActions from '../../actions/lineup'
import * as afterStartActions from '../../actions/after-start'

import Players from './components/Players'
import Settings from './components/Settings'
import TeamStacks from './components/TeamStacks'
import Lineups from './components/Lineups'
import Groups from './components/Groups'
import AfterStart from './components/AfterStart'

import NumberOfLineupsBar from './components/NumberOfLineupsBar'
import Checkbox from 'antd/lib/checkbox/Checkbox'

const BuildButton = styled(Button)`
  background-color: #1890ff !important;
  color: white !important;
  margin-left: 25px;
  width: 140px;
  display: inline-block;

  &:disabled {
    background-color: #eaecef !important;
    color: gray !important;
  }
`

const AfterStartCheckbox = styled(Checkbox)`
  margin-left: 10px !important;
  font-size: 12px !important;
`

class MLBLineupGenerator extends Component {
  state = {
    afterStartChecked: false
  }

  validateBuild = () => {
    const { players, showdown } = this.props
    if (!players.data || players.data.error) return false

    let valid = true
    const errors = []

    if (!showdown) {
      players.data.forEach(_player => {
        // if any player has a greater MinExp than MaxExp not valid
        if (Number(_player.MinExp) > Number(_player.MaxExp)) {
          errors.push(
            `${_player.Name} has a greater Min Exp than Max Exp`
          )
          valid = false
        }
      })
    }

    return {
      valid,
      errors
    }
  }

  render() {
    const {
      opt,
      players,
      settings,
      site,
      slate,
      tab,
      updateSlate,
      clearStackSettings,
      clearPlayerData,
      zeroExposures,
      showdown
    } = this.props

    let body
    const sport = 'mlb'
    switch (tab) {
      case 'players':
        body = (
          <Players
            players={players}
            settings={settings}
            clearPlayerData={clearPlayerData.bind(this)}
            zeroExposures={zeroExposures.bind(this)}
            updateSlate={updateSlate.bind(this)}
            slate={slate}
            site={site}
            showdown={showdown}
            counter={this.props.counter}
            season={this.props.season}
            changeDateInfo={this.props.changeDateInfo}
            sport={sport}
          />
        )
        break
      case 'settings':
        body = (
          <Settings
            settings={settings}
            updateSlate={updateSlate.bind(this)}
            clearStackSettings={clearStackSettings.bind(this)}
            slate={slate}
            site={site}
            players={players}
            showdown={showdown}
            counter={this.props.counter}
            season={this.props.season}
            changeDateInfo={this.props.changeDateInfo}
            sport={sport}
          />
        )
        break
      case 'team-stacks':
        body = (
          <TeamStacks
            settings={settings}
            updateSlate={updateSlate.bind(this)}
            clearStackSettings={clearStackSettings.bind(this)}
            slate={slate}
            site={site}
            showdown={showdown}
            counter={this.props.counter}
            season={this.props.season}
            changeDateInfo={this.props.changeDateInfo}
            sport={sport}
          />
        )
        break
      case 'groups':
        body = (
          <Groups
            players={players}
            updateSlate={updateSlate.bind(this)}
            slate={slate}
            site={site}
            showdown={showdown}
            counter={this.props.counter}
            season={this.props.season}
            changeDateInfo={this.props.changeDateInfo}
            sport={sport}
          />
        )
        break
      case 'lineups':
        body = (
          <Lineups
            opt={opt}
            numLUs={settings.data.numLUs}
            site={site}
            slate={slate}
            showdown={showdown}
            counter={this.props.counter}
            season={this.props.season}
            sport={sport}
          />
        )
        break
      case 'after-start':
        body = (
          <AfterStart
            site={site}
            slate={slate}
          />
        )
        break
      default:
        body = false
    }

    const { valid, errors } = this.validateBuild()
    return (
      <div>
        <Row>
          <Col lg={16} md={24} sm={24} xs={24} style={{padding: '15px 0px'}}>
            <Menu onClick={(e) => this.props.changeTab(e.key)} selectedKeys={[this.props.tab]} mode="horizontal">
              {
                showdown ? (
                  <>
                    <Menu.Item key={'players'}>Players</Menu.Item>
                    <Menu.Item key={'settings'}
                      disabled={
                          this.props.players.loading || this.props.settings.loading
                      }
                    >
                      Build Settings
                    </Menu.Item>
                    <Menu.Item key={'team-stacks'} disabled={
                        this.props.players.loading || this.props.settings.loading
                    }>
                      Team Stacks
                    </Menu.Item>
                    <Menu.Item key={'groups'} disabled={
                        this.props.players.loading || this.props.settings.loading
                    }>
                     Player Groups
                    </Menu.Item>
                    <Menu.Item
                      disabled={
                        !(
                          (this.props.opt.data && this.props.opt.data.length) ||
                          this.props.opt.loading ||
                          this.props.opt.failedLoading ||
                          (this.props.savedLineups.data && this.props.savedLineups.data.length > 0)
                        )
                      }
                      key={'lineups'}
                    >
                      Lineups
                      {
                        this.props.opt.loading ? (
                          <Progress
                            style={{marginLeft: '5px'}}
                            percent={(Number(this.props.opt.data ? this.props.opt.data.length / 9 : 0)/Number(this.props.settings.data.numLUs)*100).toFixed(0)}
                            steps={5}
                            size="small"
                            strokeColor="#1890ff"
                            showInfo={false}
                          />
                        ) : ''
                      }
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item key={'players'}>Players</Menu.Item>
                    <Menu.Item key={'settings'}
                      disabled={
                          this.props.players.loading || this.props.settings.loading
                      }
                    >
                      Build Settings
                    </Menu.Item>
                    <Menu.Item key={'team-stacks'} disabled={
                        this.props.players.loading || this.props.settings.loading
                    }>
                      Team Stacks
                    </Menu.Item>
                    <Menu.Item key={'groups'} disabled={
                        this.props.players.loading || this.props.settings.loading
                    }>
                      Player Groups
                    </Menu.Item>
                    <Menu.Item
                      disabled={
                        !(
                          (this.props.opt.data && this.props.opt.data.length) ||
                          this.props.opt.loading ||
                          this.props.opt.failedLoading ||
                          (this.props.savedLineups.data && this.props.savedLineups.data.length > 0)
                        )
                      }
                      key={'lineups'}
                    >
                      Lineups
                      {
                        this.props.opt.loading ? (
                          <Progress
                            style={{marginLeft: '5px'}}
                            percent={(Number(this.props.opt.data ? this.props.opt.data.length / 9 : 0)/Number(this.props.settings.data.numLUs)*100).toFixed(0)}
                            steps={5}
                            size="small"
                            strokeColor="#1890ff"
                            showInfo={false}
                          />
                        ) : ''
                      }
                    </Menu.Item>
                    {
                      this.props.sport == 'nfl' ? (
                        <Menu.Item 
                          key={'after-start'}
                          disabled={this.props.showdown || this.props.site !== 'dk' || this.props.subscription.loading || (this.props.subscription.permissions && !this.props.subscription.permissions.opt.nfl.after_start)}
                        >
                          After Start
                          <span style={{fontSize: '12px', marginLeft: '4px', color: '#ffa500'}}><CrownOutlined /></span>
                        </Menu.Item>
                      ) : ''
                    }
                  </> 
                )
              }
            </Menu>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} style={{padding: '15px 0px'}}>
            <NumberOfLineupsBar site={this.props.site} slate={this.props.slate} week={this.props.week} season={this.props.season} />
            { this.props.opt.loading ? (
                <Button onClick={this.props.cancelOpt} disabled={!this.props.opt.loading}>
                  Cancel
                </Button>
              ) : (
                valid ? ( this.props.loggedin ? (
                  <BuildButton onClick={this.props.getLineups} disabled={false}>
                    {
                        this.props.afterStartActivated ? (
                          <>Build After Start</>
                        ) : (
                          <>Build</>
                        )
                      }
                  </BuildButton>
                ) : (
                  <Tooltip title={'You must be logged in to create lineups.'}>
                    <BuildButton disabled={true}>
                      Build
                    </BuildButton>
                  </Tooltip>
                )

                ) : (
                  <Tooltip placement="bottom" title=
                    {
                      errors.map(_error => (
                        <div>
                          - {_error}
                        </div>
                      ))
                    }
                  >
                    <BuildButton disabled={true}>
                      {
                        this.props.afterStartActivated ? (
                          <>Build After Start</>
                        ) : (
                          <>Build</>
                        )
                      }
                    </BuildButton>
                  </Tooltip>
                )
              )
            }
          </Col>
      </Row>
      <Row>
      </Row>
      <div>
        { body }
      </div>
  </div>
    )
  }
}

export default connect(
  state => ({
    savedLineups: state.lineup.savedLineups,
    loggedin: state.auth.loggedin,
    settings: state.lineup.settings,
    opt: state.lineup.opt,
    afterStartActivated: state.afterStart.activated,
    subscription: state.account.subscription,
  }),
  {
    cancelOpt: lineupActions.cancelOpt,
    activateAfterStart: afterStartActions.activateAfterStart
  }
)(MLBLineupGenerator)
