import { Table, Row, Col, Tooltip } from 'antd'
import {
  MinusCircleOutlined
} from '@ant-design/icons'
import styled from 'styled-components'
import { ReactComponent as Anchor } from '../../../../assets/images/anchor.svg'

const MinusIcon = styled(MinusCircleOutlined)`
  cursor: pointer;
  padding-top: 8px;
`

const StyledTable = styled(Table)`
  td {
    text-align: center;
    padding: 2px !important;
  }

  tr th {
    text-align: center !important;
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }
`
const StyledAnchor = styled(Anchor)`
  width: 18px;
  cursor: pointer;
`
const SelectedAnchor = styled(StyledAnchor)`
  path {
    fill: #1890ff;
  }
`

const anchorCol = (setAnchor, anchorPlayerID=null) => [
  {
    title: (<Tooltip title="If the group has an Anchor player, the constraints will only apply when the Anchor player is in a lineup.">Anchor</Tooltip>),
    width: '50px',
    render: (_, row) => {
      if (row.Id === anchorPlayerID) {
        return (
          <SelectedAnchor onClick={() => setAnchor(row.Id)}/>
        )
      }

      return (
        <StyledAnchor onClick={() => setAnchor(row.Id)}/>
      )
    }
  }
]
const removeCol = (onRemove) => [
  {
    title: 'Remove',
    width: '80px',
    render: (_, row) => {
      return (
        <MinusIcon onClick={() => onRemove(row.Id)}/>
      )
    }
  }
]

const playerDetailsCols =  (setWidths, showdown) => {
  const cols = []
  cols.push(
    {
      title: 'Player Name',
      dataIndex: 'Name',
      key: 'playerName',
      width: setWidths ? 160 : undefined,
    },
    {
      title: 'Salary',
      dataIndex: 'Salary',
      key: 'salary',
      defaultSortOrder: 'descend',
      width: setWidths ? 120 : undefined,
      sorter: (a, b) => Number(a.Salary - b.Salary),
    },
    {
      title: 'Pos',
      dataIndex: 'Position',
      key: 'position',
      width: setWidths ? 60 : undefined,
    }
  )

  if (showdown)
    cols.push(
      {
        title: 'Roster Pos',
        dataIndex: 'RosterPosition',
        key: 'rosterPositio',
        width: setWidths ? 120 : undefined,
      }
    )

  return cols
}

const SelectedPlayersTable = ({loading, onRemove, rows = [], showdown=false, setAnchor, anchorPlayerID=null}) => {
  const allCols = [
    ...anchorCol(setAnchor, anchorPlayerID),
    ...removeCol(onRemove),
    ...playerDetailsCols(true, showdown),
  ]

  return (
    <StyledTable
      columns={allCols}
      dataSource={rows}
      pagination={false}
      scroll={{ x: '90%'}}
      bordered
      loading={loading}
      size='small'
      rowKey={_row => `unselected-${String(_row.Id)}`}
    />
  )
}

export default SelectedPlayersTable
