import { Table, Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  margin-top: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;

  .ant-table-tbody > tr > td {
    font-size: 12px;
  }
  .ant-table-column-title {
    font-size: 14px;
  }

  tr:first-child {
    th {
      color: white !important;
      background-color: #1d3557 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  tr {
    text-align: center;
  }
  th {
    text-align: center !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }
`
const RedValue = styled.span`
  color: red;
`

const OnOffGamesCols = (site) => {
  const baseCols = [
    {
      title: 'Player',
      dataIndex: 'Name',
      key: 'player',
      width: '200px'
    },
    {
      title: 'Team',
      dataIndex: 'Team',
      key: 'teamAbbrev',
      width: 80,
      render: (text, row) => {
        try {
          return (
            <img src={require(`../assets/images/nba/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={text}/>
          )
        } catch (e) {
          console.log(e)
        }
      }
    },
    {
      title: 'Salary',
      dataIndex: 'Salary',
      key: 'Salary',
      sorter: (a, b) => Number(b.Salary - a.Salary),
    },
    {
      title: 'Mins.',
      dataIndex: 'ProjMins',
      key: 'ProjMins',
      sorter: (a, b) => Number(b.ProjMins - a.ProjMins),
    },
    {
      title: (
        <Tooltip title="Total number of minutes played without any of the Inactive Players on the court.">
          Total Mins.
        </Tooltip>
      ),
      dataIndex: 'MinsOnly',
      key: 'MinsOnly',
      sorter: (a, b) => Number(b.MinsOnly - a.MinsOnly),
      render: (v) => {
        if (v && v !== '-')
          return v.toFixed(1)
        return '-'
      }
    },
    {
      title: (
        <Tooltip title="Total number of points scored without any of the Inactive Players on the court.">
          {site} Total Pts.
        </Tooltip>
      ),
      dataIndex: `${site}PtsOnly`,
      key: `${site}PtsOnly`,
      sorter: (a, b) => Number(b[`${site}PtsOnly`] - a[`${site}PtsOnly`]),
      render: (v) => {
        if (v && v !== '-')
          return v.toFixed(1)
        return '-'
      }
    },
    {
      title: (
        <Tooltip title="Total points per minute without any of the Inactive Players on the court.">
          {site} PPM
        </Tooltip>
      ),
      dataIndex: `${site}PtsPerOnly`,
      key: `${site}PtsPerOnly`,
      sorter: (a, b) => Number(b[`${site}PtsPerOnly`] - a[`${site}PtsPerOnly`]),
      render: (v) => {
        if (v && v !== '-')
          return v.toFixed(2)
        return '-'
      }
    },
    {
      title: (
        <Tooltip title={(
          <span>This metric measures the players performance without Inactive Players and answers the question: If a player performs as they have in the past with their respective teammates out, how good of a play are they on today’s slate?</span>
        )}>
          Value
        </Tooltip>
      ),
      dataIndex: 'OffGPPVal',
      key: 'OffGPPVal',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => Number(b.OffGPPVal - a.OffGPPVal),
      render: (v) => {
        if (v && v !== '-')
          return (<b>{v.toFixed(1)}</b>)
        return '-'
      }
    },
    {
      title: (
        <Tooltip title="The player’s usage percetage without any of the Inactive Players on the court.">
          Usage Pct.
        </Tooltip>
      ),
      dataIndex: 'UsgPct',
      key: 'UsgPct',
      sorter: (a, b) => Number(b.UsgPct - a.UsgPct),
      render: (v) => {
        try {
          if (v && v !== '-')
            return (<span>{v.toFixed(1)}%</span>)
          return '-'
        }
        catch (e) {
          console.log(v)
          return '-'
        }
      }
    },
    {
      title: (
        <Tooltip title="The player’s rebound percentage without any of the Inactive Players on the court.">
          Reb Pct.
        </Tooltip>
      ),
      dataIndex: 'RebPct',
      key: 'RebPct',
      sorter: (a, b) => Number(b.RebPct - a.RebPct),
      render: (v) => {
        try {
          if (v && v !== '-')
            return (<span>{(Number(v)*100).toFixed(1)}%</span>)
          return '-'
        }
        catch (e) {
          console.log(v)
          return '-'
        }
      }
    },
    {
      title: (
      <Tooltip title="The player’s assist percetage without any of the Inactive Players on the court.">
        Ast. Pct.
      </Tooltip>
      ),
      dataIndex: 'AstPct',
      key: 'AstPct',
      sorter: (a, b) => Number(b.AstPct - a.AstPct),
      render: (v) => {
        try {
          if (v && v !== '-')
            return (<span>{(Number(v)*100).toFixed(1)}%</span>)
          return '-'
        }
        catch (e) {
          console.log(v)
          return '-'
        }
      }
    },
  ]

  return baseCols
}

const OnOffValuesTable = ({ onOffValues=[], loading=false, site='DK', players=[] }) => {
  const cols = OnOffGamesCols(site)
  // const _data = Object.values(_playerData)

  let data = onOffValues
  if (!onOffValues.length || !onOffValues[0][`${site}PtsOnly`]) {
    data = []
  }
  data = data.map(p => {
    let _player = players.filter(_p => _p.Name === p.Name)[0]
    if (!_player)
      _player = {}
    
    return {
      ...p,
      ProjMins: _player.ProjMins
    }
  })

  return (
    <StyledTable
      columns={cols}
      dataSource={data}
      loading={loading}
      scroll={{ x: '1000px', y: '70vh' }}
      bordered
      size='small'
      pagination={false}
      rowKey={(_row, i) => {
        return `on-off-values-${String(i)}`
      }}
    />
  )
}

export default OnOffValuesTable
