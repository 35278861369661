import React, { useState } from 'react'
import { Table, Checkbox, Row, Col, Button, Tooltip } from 'antd'
import styled from 'styled-components'
import { nflTeamNames as TeamToAbbrev } from '../../../../../utils/team-name-to-abbrev'
import MatchupModal from './modals/edit-matchup'

import {
  HEAT_MAP_NEGATIVE_COLOR_SCALE
} from './utils/color'

const MenuText = styled.span`
  font-size: 14px;
  margin-right: 5px;
`
const StyledTable = styled(Table)`
  &.blurry-text {
    tr:not(:first-child) {
      color: transparent;
      text-shadow: 0 0 10px rgba(0,0,0,0.5);

      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;

      /*
       Introduced in IE 10.
       See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
      */
      -ms-user-select: none;
      user-select: none;
    }
  }
`
const Errorable = styled.div`
  &.under-exposed {
    color: #ff5252;
  }
`
const initTeam = {
  totalOwn: 0,
  qbOwn: 0,
  qbMinOwn: 0,
  flexOwn: 0,
  oppFlexOwn: 0,
  minExp: 0,
  maxExp: 0,
  oppQbMinOwn: 0
}

const MatchupBreakdownTable = ({ players, onExposureChange, permissioned, settings }) => {
  const [ stacksChecked, setStacksChecked ] = useState(false)
  const [ selectedMatchup, setSelectedMatchup ] = useState()
  const [ showEditMatchup, setShowEditMatchup ] = useState(false)
  const matchupStats = getMatchupStats(players, stacksChecked)

  return (
    <div>
      <Row style={{padding: '5px'}}>
        <Col lg={8}>
          <MenuText>Only Stacks</MenuText>
          <Checkbox checked={stacksChecked} onClick={() => setStacksChecked(!stacksChecked)} />
        </Col>
      </Row>
      <StyledTable
        bordered
        columns={cols(setSelectedMatchup, setShowEditMatchup, permissioned, settings)}
        dataSource={matchupStats}
        size={'small'}
        className={ (!permissioned) ? 'blurry-text' : '' }
        scroll={{x: '150px'}}
        rowKey ={(_row, i) => {
          return `pre-analysis-matchup-breakdown-${i}`
        }}
      />
      <MatchupModal players={players} onExposureChange={onExposureChange} selectedMatchup={selectedMatchup} visible={showEditMatchup} setVisible={setShowEditMatchup} />
    </div>
  )
}

const getMatchupStats = (players, onlyStack) => {
  const teamStats = {}

  players.forEach((p, i) => {
    if (Number(p.MaxExp) !== 0 && (p.Position !== "DST" && p.Position !== "DEF")) {
      // Initialize Team and Opp
      if (!teamStats[p.TeamAbbrev])
        teamStats[p.TeamAbbrev] = {
          TeamAbbrev: p.TeamAbbrev,
          ...initTeam
        }
      if (!teamStats[p.Opp])
        teamStats[p.Opp] = {
          TeamAbbrev: p.Opp,
          ...initTeam
        }

      const _isQB = !isFlex(p)

      const _minExp = Number(p.MinExp)
      const _maxExp = Number(p.MaxExp)

      if (_isQB) {
        teamStats[p.TeamAbbrev].qbOwn = (teamStats[p.TeamAbbrev].qbOwn || 0) + ((_maxExp + _minExp) / 2)
        // Add min exp for analysis
        teamStats[p.TeamAbbrev].qbMinOwn = (teamStats[p.TeamAbbrev].qbMinOwn || 0) + _minExp
        teamStats[p.Opp].oppQbMinOwn = (teamStats[p.Opp].oppQbMinOwn || 0) + _minExp
      } else {
        teamStats[p.TeamAbbrev].flexOwn = (teamStats[p.TeamAbbrev].flexOwn || 0) + ((_maxExp + _minExp) / 2)
        teamStats[p.Opp].oppFlexOwn = (teamStats[p.Opp].oppFlexOwn || 0) + ((_maxExp + _minExp) / 2)
        teamStats[p.TeamAbbrev].minExp = (teamStats[p.TeamAbbrev].minExp || 0) + _minExp
        teamStats[p.TeamAbbrev].maxExp = (teamStats[p.TeamAbbrev].maxExp || 0) + _maxExp
        teamStats[p.Opp].oppMaxExp = (teamStats[p.Opp].oppMaxExp || 0) + _maxExp
      }

      // Always add a total own count
      teamStats[p.TeamAbbrev].totalOwn = (teamStats[p.TeamAbbrev].totalOwn || 0) + ((_maxExp + _minExp) / 2)
    }
  })

  // turn into array
  const r = []
  Object.keys(teamStats).forEach(k => {
    if (onlyStack) {
      if (teamStats[k].qbOwn > 0) {
        r.push(teamStats[k])
      }
    } else {
      r.push(teamStats[k])
    }
  })

  return r
}

const isFlex = (player) => {
  const flexPos = ['RB', 'WR', 'TE']

  return flexPos.indexOf(player.Position) >= 0
}

const cols = (setSelectedMatchup, setShowEditMatchup, permissioned, settings) => ([
  {
    'title': 'Team',
    'dataIndex': 'TeamAbbrev',
    'key': 'team',
    render: (text) => (
      <img src={require(`../../../../../assets/images/nfl/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
    ),
  },
  {
    'title': 'Total Own (no def)',
    'dataIndex': 'totalOwn',
    'key': 'totalOwn',
    sorter: (a, b) => Number(a.totalOwn - b.totalOwn),
    defaultSortOrder: 'descend',
    render: (v) => `${v.toFixed(1)}%`
  },
  {
    'title': 'QB Own',
    'dataIndex': 'qbOwn',
    'key': 'qbOwn',
    render: (v) => `${v.toFixed(1)}%`
  },
  {
    'title': 'Flex Own',
    'dataIndex': 'flexOwn',
    sorter: (a, b) => (Number(b.flexOwn) - Number(a.flexOwn)),
    'key': 'flexOwn',
    render: (v, row) => {
      const minFlexExposure = getMinExposure(row, settings, false)

      const underExposed = Number(row.maxExp) < minFlexExposure && row.qbOwn > 0
      let d
      if (underExposed && permissioned) {
        d = (<Tooltip title={`Under-exposed to flex. Need an additional ${(minFlexExposure - Number(v)).toFixed(1)}% of exposure.`}><Errorable className={'under-exposed'}>{v}%</Errorable></Tooltip>)
      } else {
        d = (<div>{v.toFixed(1)}%</div>)
      }
      return d
    }
  },
  {
    'title': 'Opp. Flex Own',
    'dataIndex': 'oppFlexOwn',
    sorter: (a, b) => (Number(b.oppFlexOwn) - Number(a.oppFlexOwn)),
    'key': 'oppFlexOwn',
    render: (v, row) => {
      const minFlexExposure = getMinExposure(row, settings, true)

      const underExposed = Number(row.oppMaxExp || 0) < minFlexExposure && row.qbOwn > 0
      let d
      if (underExposed && permissioned) {
        d = (<Tooltip title={`Under-exposed to opponents flex. Need an additional ${(minFlexExposure - Number(v)).toFixed(1)}% of exposure.`}><Errorable className={'under-exposed'}>{v}%</Errorable></Tooltip>)
      } else {
        d = (<div>{v.toFixed(1)}%</div>)
      }
      return d
    }
  },
  {
    'title': 'Game Flex Total',
    'key': 'gameFlexTotal',
    sorter: (a, b) => ((Number(b.flexOwn) + Number(b.oppFlexOwn)) - (Number(a.flexOwn) + Number(a.oppFlexOwn))),
    render: (v) => {
      return (
        `${(Number(v.flexOwn) + Number(v.oppFlexOwn)).toFixed(1)}%`
      )
    }
  },
  {
    render: (v) => {
      return (
        <Button
          size="small"
          disabled={!permissioned}
          onClick={() => {
            setSelectedMatchup(v.TeamAbbrev)
            setShowEditMatchup(true)
          }}
        >Edit</Button>
      )
    }
  }
])

const getMinExposure = (team, settings, oppStack=false) => {
  if (oppStack)
    return (Number(team.qbMinOwn) * Number(settings.teamStackPct) * Number(settings.gameStackPct)) + (Number(team.oppQbMinOwn) * Number(settings.teamStackPct))

  return (Number(team.oppQbMinOwn) * Number(settings.teamStackPct) * Number(settings.gameStackPct)) + (Number(team.qbMinOwn) * Number(settings.teamStackPct))
}

export default MatchupBreakdownTable
