import React from 'react'
import { Table, Row, Col, Tooltip } from 'antd'
import {
  LockOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'

import { nbaTeamNames as TeamToAbbrev } from '../../../../utils/team-name-to-abbrev'
import isNull from '../../../../utils/isNull'
import { getNBAGPPValue, getShowdownGPPValue } from '../../../../utils/gpp-value'
import { select } from 'redux-saga/effects'

const StyledTable = styled(Table)`
  td {
    text-align: center;
    padding: 2px !important;
  }

  tr th {
    text-align: center !important;
  }

  tr:first-child {
    th {
      color: white !important;
      background: #1d3557 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }
`
const PlayerText = styled.span`
  cursor: pointer;
  font-weight: 600;
  color: #1d3557;
  &:hover {
    color: #1890ff;
  }
`

const Lock = () => (
  <Tooltip title="Upgrade for this feature">
    <LockOutlined />
  </Tooltip>
)

const playerDetailsCols =  (setWidths, selectPlayerStats) => ({
  title: 'Player Details',
  key: 'playerDetails',
  children: [
    {
      title: 'Player Name',
      dataIndex: 'Name',
      key: 'playerName',
      width: setWidths ? 125 : undefined,
      render: (text, row) => {
        return (
          <PlayerText onClick={() => {}}>
            {text}
          </PlayerText>
        )
      }
    },
    {
      title: 'Salary',
      dataIndex: 'Salary',
      key: 'salary',
      defaultSortOrder: 'descend',
      sorter: (a, b) => Number(a.Salary - b.Salary),
    },
    {
      title: 'Pos',
      dataIndex: 'Position',
      key: 'position',
      className: 'divide',
      width: setWidths ? 60 : undefined,
    },
  ]
})

const teamAndGameInfoCols = (setWidths) => ({
  title: 'Team & Game Info',
  key: 'teamGameInfo',
  children: [
    {
      title: 'Team',
      dataIndex: 'TeamAbbrev',
      key: 'teamAbbrev',
      width: setWidths ? 80 : undefined,
      render: (text, row) => {
        try {
          return (
            <img src={require(`../../../../assets/images/nba/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
          )
        } catch (e) {
          console.log(text)
          console.log(row)
        }
      }
    },
    {
      title: 'Opp',
      dataIndex: 'Opp',
      key: 'opp',
      width: setWidths ? 80 : undefined,
      render: (text, row) => {
        try {
          return (
            <img src={require(`../../../../assets/images/nba/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
          )
        } catch (e) {
          console.log(text)
          console.log(row)
        }
      }
    },
    {
      title: 'Ven.',
      dataIndex: 'Venue',
      key: 'venue',
      width: setWidths ? 60 : undefined,
    },
    {
      title: (
        <Tooltip placement="top" title={"Projected total points for the game"}>
          <span>OU</span>
        </Tooltip>
      ),
      dataIndex: 'OU',
      width: setWidths ? 80 : undefined,
      key: 'ou',
      sorter: (a, b) => Number(a.OU) - Number(b.OU),
    },
    {
      title: (
        <Tooltip placement="top" title={"Projected amount team will win or lose by (favored team is negative)"}>
          <span>Spread</span>
        </Tooltip>
      ),
      dataIndex: 'Spread',
      key: 'spread',
      sorter: (a, b) => Number(a.Spread) - Number(b.Spread)
    },
    {
      title: (
        <Tooltip placement="top" title={"Projected total points for the team. For DST, it shows the total for the opposing team (lower Imp Pts is better for a DST)"}>
          <span>Imp Pts</span>
        </Tooltip>
      ),
      dataIndex: 'ImpPts',
      key: 'currImpPt',
      sorter: (a, b) => Number(a.ImpPts) - Number(b.ImpPts),
      className: 'divide'
    },
  ]
})

const UserProjInput = styled.input`
  width: 50px;
`

const _onUserProjChange = (row, e, _onChange) => {
  let projPts = e.target.value
  if (projPts === ".")
    projPts = "0."
  // Keep track of the original Proj Pts
  const HouseProjPts = row.HouseProjPts ? row.HouseProjPts : row.ProjPts

  let _row
  if (projPts === '') {
    if (HouseProjPts) {
      _row = {
        ...row,
        UserProj: '',
        ProjPts: HouseProjPts,
        HouseProjPts
      }
    } else {
      _row = row
    }
  } else {
    _row = {
      ...row,
      UserProj: projPts,
      ProjPts: projPts,
      HouseProjPts
    }
  }

  _onChange(_row)
}

const _onUserOwnChange = (row, e, _onChange) => {
  let projOwn = e.target.value
  if (projOwn === ".")
    projOwn = "0."
  // Keep track of the original Ownership
  const HouseOwn = row.HouseOwn ? row.HouseOwn : row.ProjOwn

  let _row
  if (projOwn === '') {
    if (HouseOwn) {
      _row = {
        ...row,
        UserOwn: '',
        ProjOwn: HouseOwn,
        HouseOwn
      }
    } else {
      _row = row
    }
  } else {
    _row = {
      ...row,
      UserOwn: projOwn,
      ProjOwn: projOwn,
      HouseOwn
    }
  }

  _onChange(_row)
}

const playerProjectionCols = (userProjOnChange, permissions, site, showdown=false) => ({
  title: 'Player Projections',
  key: 'playerProjections',
  children: [
    {
      title: (
        <Tooltip placement="top" title={"Projected minutes played"}>
          <span>Min</span>
        </Tooltip>
      ),
      key: 'projMins',
      sorter: (a, b) => Number(a.ProjMins) - Number(b.ProjMins),
      render: (_, row) => {
        if (permissions && permissions.opt.nfl.projections) {
          return (
            row.ProjMins
          )
        } else {
          return <Lock />
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Player's projected point total"}>
          <span>Proj Pts</span>
        </Tooltip>
      ),
      key: 'dKPts',
      sorter: (a, b) => Number(a.HouseProjPts || a.ProjPts) - Number(b.HouseProjPts || b.ProjPts),
      render: (_, row) => {
        if (permissions && permissions.opt.nfl.projections) {
          return (
            row.HouseProjPts || row.ProjPts
          )
        } else {
          return <Lock />
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Overwrite our projected points here"}>
          <span>User Proj</span>
        </Tooltip>
      ),
      dataIndex: 'UserProj',
      key: 'userProj',
      render: (text, row) => {
        return (<UserProjInput onChange={(e) => _onUserProjChange(row, e, userProjOnChange)} value={isNull(text) ? '' : text} />)
      }

    },
    {
      title: (
        <Tooltip placement="top" title={"Projected points / Salary * 1000"}>
          <span>Proj Value</span>
        </Tooltip>
      ),
      key: 'projval',
      sorter: (a, b) => (Number(a.ProjPts) / Number(a.Salary) * 1000) - (Number(b.ProjPts) / Number(b.Salary) * 1000),
      render: (_, row) => {
        let multiplier = 1000
        if (site === 'ya')
          multiplier = 10

        if (permissions && permissions.opt.nfl.projections) {
          return (
            (Number(row.ProjPts) / Number(row.Salary) * multiplier).toFixed(2)
          )
        } else {
          return (Number(row.UserProj || 0) / Number(row.Salary) * multiplier).toFixed(2)
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Combined GPP value metric"}>
          <span>GPP Value</span>
        </Tooltip>
      ),
      sorter: (a, b) => {
        if (showdown)
          return getShowdownGPPValue(b.RosterPosition, b.Salary, b.ProjPts, site) - getShowdownGPPValue(a.RosterPosition, a.Salary, a.ProjPts, site)
        else
          return getNBAGPPValue(b.Salary, b.ProjPts) - getNBAGPPValue(a.Salary, a.ProjPts)
      },
      key: 'gppVal',
      render: (row) => {
        if (permissions && permissions.opt.nfl.projections) {
          if (showdown) {
            return (
              getShowdownGPPValue(row.RosterPosition, row.Salary, row.ProjPts, site).toFixed(2)
            )
          } else {
            return (
              getNBAGPPValue(row.Salary, row.ProjPts).toFixed(2)
            )
          }
        } else {
          return <Lock />
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Ceiling Projection"}>
          <span>Ceil.</span>
        </Tooltip>
      ),
      sorter: (a, b) => {
        return b.Ceiling-a.Ceiling
      },
      key: 'Ceiling',
      render: (row) => {
        if (permissions && permissions.opt.nfl.projections) {
          return (
            Number(row[`Ceiling`]).toFixed(2)
          )
        } else {
          return <Lock />
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Overwrite our projected ownership here"}>
          <span>User Own</span>
        </Tooltip>
      ),
      dataIndex: 'UserOwn',
      key: 'userOwn',
      render: (text, row) => {
        return (
          <UserProjInput
            onChange={(e) => {
              _onUserOwnChange(row, e, userProjOnChange)
            }}
            value={isNull(text) ? '' : text}
          />
        )
      }

    },
    {
      title: 'Proj Own',
      dataIndex: 'ProjOwn',
      key: 'projOwn',
      sorter: (a, b) => Number(a.ProjOwn) - Number(b.ProjOwn),
      render: (_, row) => {
        if (permissions && permissions.opt.nfl.ownership) {
          return row.HouseOwn && row.UserOwn ? (<span style={{color: "#9a9a9a"}}>{row.HouseOwn}</span>) : row.ProjOwn
        } else {
          return <Lock />
        }
      },
      className: 'divide'
    }
  ]
})

const _onChangeMin = (row, e, _onChange) => {
  let MinExp = e.target.value
  if (MinExp === ".")
    MinExp = "0."
  const _row = {
    ...row,
    MinExp
  }
  _onChange(_row)
}

const _onChangeMax = (row, e, _onChange) => {
  let MaxExp = e.target.value
  if (MaxExp === ".")
    MaxExp = "0."
  const _row = {
    ...row,
    MaxExp
  }
  _onChange(_row)
}

const _lockRow = (row, _onChange) => {
  const _row = {
    ...row,
    MinExp: 100,
    MaxExp: 100
  }
  _onChange(_row)
}

const _clearRow = (row, _onChange) => {
  const _row = {
    ...row,
    MinExp: 0,
    MaxExp: 0
  }
  _onChange(_row)
}

const ExpInput = styled.input`
  width: 50px;
`

const exposureCols = (expOnChange, setWidths) => ({
  title: 'Exposure',
  className: 'exposure-cols',
  key: 'exposures',
  children: [
    {
      title: (
        <Tooltip placement="top" title={"Player will be in at least this percentage of lineups"}>
          <span>Min Exp</span>
        </Tooltip>
      ),
      dataIndex: 'MinExp',
      key: 'minExp',
      sorter: (a, b) => Number(a.MinExp) - Number(b.MinExp),
      render: (text, row) => {
        return (
          <ExpInput onChange={(e) => {_onChangeMin(row, e, expOnChange)}} value={Number(text) > 0 ? text : ''} placeholder={0} />
        )
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Player will be in at most this percentage of lineups"}>
          <span>Max Exp</span>
        </Tooltip>
      ),
      dataIndex: 'MaxExp',
      key: 'maxExp',
      sorter: (a, b) => Number(a.MaxExp) - Number(b.MaxExp),
      render: (text, row) => {
        return (
          <ExpInput onChange={(e) => _onChangeMax(row, e, expOnChange)} value={Number(text) > 0 ? text : ''} placeholder={0} />
        )
      }
    },
    {
      title: '',
      key: 'lockUnlock',
      width: 40,
      render: (_, row) => {
        return (
          <Row>
            <Col>
              <LockOutlined onClick={() => _lockRow(row, expOnChange)} />
            </Col>
            <Col>
              <CloseOutlined onClick={() => _clearRow(row, expOnChange)}/>
            </Col>
          </Row>
        )
      }
    }
  ]
})

const MinWidth = 1526

const PlayersTable = ({ loading, rows = [], userProjOnChange, expOnChange, permissions, site, showdown, selectPlayerStats }) => {
  const _setWidths = window.innerWidth > MinWidth

  const allCols = [
    playerDetailsCols(_setWidths, selectPlayerStats),
    teamAndGameInfoCols(_setWidths),
    playerProjectionCols(userProjOnChange, permissions, site, showdown),
    exposureCols(expOnChange, _setWidths)
  ]

  return (
    <StyledTable
      columns={allCols}
      dataSource={rows}
      pagination={{ pageSize: 100 }}
      bordered
      loading={loading}
      size='small'
      rowKey={_row => String(_row.Id)}
      scroll={{ y: 700, x: 1300 }}
    />
  )
}

export default PlayersTable
