import { Tooltip, Row, Col, Input, Checkbox } from 'antd'
import styled from 'styled-components'

const InputLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const SettingsInput = styled(Input)`
  .ant-input-group-addon {
    @media (max-width: 1280px) {
      font-size: 12px;
    }
  }
`
const InputContainer = styled.div`
  padding: 20px;
`
// warning div red text
const WarningDiv = styled.div`
  color: red;
  font-size: 12px;
  font-weight: 300;
  margin-lef: 5px;
`

export default ({ updateInputValue, settings, showdown, disabled }) => {
  return (
    <Row>
      <Col lg={10}>
        {
          !showdown ? (
            <div>
              <SettingsInput
                disabled={disabled}
                style={{margin: '10px 0px'}}
                addonBefore={(
                  <Tooltip placement={'top'} title={'This sets the percentage of lineups allowed to have an RB in the QB stack. Does not force RB in stacks, but rather gives a way to set a limit on RB stacks.'}>
                    Allow RB Stack Percentage
                  </Tooltip>
                )}
                addonAfter="%"
                value={(settings.data.allowRBStackPct * 100).toFixed(0)}
                key='allowRBStackPct'
                onChange={evt => updateInputValue('allowRBStackPct', Number(Number(evt.target.value) / 100))}
              />
              <SettingsInput
                disabled={disabled}
                style={{marginBottom: '10px'}}
                addonBefore={(
                  <Tooltip placement={'top'} title={'Percentage of Lineups that will have a second game stack of two players who aren\'t in the same game as your QB.'}>
                    Forced Second Game Stack
                  </Tooltip>
                )}
                addonAfter="%"
                value={(settings.data.forceSecondGameStack * 100).toFixed(0)}
                key='forceSecondGameStack'
                onChange={evt => updateInputValue('forceSecondGameStack', Number(Number(evt.target.value) / 100))}
              />
              {
                showdown ? '' : (
                  <div>
                    <SettingsInput
                      disabled={disabled}
                      style={{marginBottom: '10px'}}
                      addonBefore={(
                        <Tooltip placement={'top'} title={'Percentage of Lineups that will have a second game stack of two players who aren\'t in the same game as your QB.'}>
                          Forced RB + DST Stack
                        </Tooltip>
                      )}
                      addonAfter="%"
                      value={(settings.data.forceRBandDSTStack * 100).toFixed(0)}
                      key='forceRBandDSTStack'
                      onChange={evt => updateInputValue('forceRBandDSTStack', Number(Number(evt.target.value) / 100))}
                    />
                    {
                      settings.data.forceRBandDSTStack * 100 > 25 ? (
                        <div>
                          <WarningDiv>Warning: Forcing >25% RB/DST stack could significantly increase build time.</WarningDiv>
                        </div>
                      ) : ''
                    } 
                  </div>
                )
              }
              
          </div>
          ) : ''
        }
      </Col>
      <Col lg={10} offset={4}>
        {
          !showdown ? (
            <div>
              <InputContainer>
                <Row>
                  <Col lg={12}>
                    <InputLabel style={{display: 'inline-block', marginRight: '10px'}}>Allow Defense vs QB</InputLabel>
                    <Checkbox
                      disabled={disabled}
                      checked={settings.data.allowDefenseVsQB}
                      onClick={(e) => {
                        updateInputValue('allowDefenseVsQB', e.target.checked)}
                      }
                    />
                  </Col>
                  <Col lg={12}>
                    <InputLabel style={{display: 'inline-block', marginRight: '10px'}}>Allow Defense vs Flex</InputLabel>
                    <Checkbox
                      disabled={disabled}
                      checked={settings.data.allowDefenseVsFlex}
                      onClick={(e) => {
                        updateInputValue('allowDefenseVsFlex', e.target.checked)}
                      }
                    />
                  </Col>
                </Row>
              </InputContainer>
            </div>
          ) : (
            <div>
              <InputContainer>
                <Row>
                  <Col lg={8}>
                    <InputLabel style={{display: 'inline-block', marginRight: '10px'}}>Allow Defense vs QB</InputLabel>
                    <Checkbox
                      disabled={disabled}
                      checked={settings.data.allowDefenseVsQB}
                      onClick={(e) => {
                        updateInputValue('allowDefenseVsQB', e.target.checked)}
                      }
                    />
                  </Col>
                  <Col lg={8}>
                    <InputLabel style={{display: 'inline-block', marginRight: '10px'}}>Allow Defense vs Flex</InputLabel>
                    <Checkbox
                      disabled={disabled}
                      checked={settings.data.allowDefenseVsFlex}
                      onClick={(e) => {
                        updateInputValue('allowDefenseVsFlex', e.target.checked)}
                      }
                    />
                  </Col>
                  <Col lg={8}>
                    <InputLabel style={{display: 'inline-block', marginRight: '10px'}}>Allow Defense vs Kicker</InputLabel>
                    <Checkbox
                      disabled={disabled}
                      checked={settings.data.allowDefenseVsKicker}
                      onClick={(e) => {
                        updateInputValue('allowDefenseVsKicker', e.target.checked)}
                      }
                    />
                  </Col>
                </Row>
              </InputContainer>
            </div>
          )
        }
      </Col>
    </Row>
  )
}
