import React from 'react'
import { Table, Row, Col, Tooltip } from 'antd'
import {
  PlusCircleOutlined,
  LockOutlined
} from '@ant-design/icons'
import styled from 'styled-components'

import { nflTeamNames as TeamToAbbrev } from '../../../../utils/team-name-to-abbrev'
import isNull from '../../../../utils/isNull'
import { getGPPValue, getShowdownGPPValue } from '../../../../utils/gpp-value'

const StyledTable = styled(Table)`
  td {
    text-align: center;
    padding: 2px !important;
  }

  tr th {
    text-align: center !important;
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }
`

const Lock = () => (
  <Tooltip title="Upgrade for this feature">
    <LockOutlined />
  </Tooltip>
)

const PlusIcon = styled(PlusCircleOutlined)`
  cursor: pointer;
  padding-top: 8px;
`

const addCol = (onAdd, selectedPlayers) => [
  {
    title: 'Add',
    width: '50px',
    render: (_, row) => {
      return (
        <PlusIcon onClick={() => onAdd(row.Id)}/>
      )
    }
  }
]

const playerDetailsCols =  (setWidths, showdown) => {
  const cols = [
    {
      title: 'Player Name',
      dataIndex: 'Name',
      key: 'playerName',
      width: setWidths ? 120 : undefined,
    },
    {
      title: 'Salary',
      dataIndex: 'Salary',
      key: 'salary',
      defaultSortOrder: 'descend',
      sorter: (a, b) => Number(a.Salary - b.Salary),
    },
    {
      title: 'Pos',
      dataIndex: 'Position',
      key: 'position',
      className: showdown ? '' : 'divide',
      width: setWidths ? 60 : undefined,
    },
  ]

  if (showdown) {
    cols.push({
      title: 'Roster Pos',
      dataIndex: 'RosterPosition',
      key: 'rosterPosition',
      className: 'divide',
      width: setWidths ? 80 : undefined,
    })
  }

  return cols
}

const teamAndGameInfoCols = (setWidths) => [
    {
      title: 'Team',
      dataIndex: 'TeamAbbrev',
      key: 'teamAbbrev',
      width: setWidths ? 80 : undefined,
      render: (text) => {
        try {
          return (
            <img src={require(`../../../../assets/images/nfl/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
          )
        } catch (e) {
          return (
            <img style={{width: '28px'}} alt={TeamToAbbrev[text]} />
          )
        }
      },
    },
    {
      title: 'Opp',
      dataIndex: 'Opp',
      key: 'opp',
      width: setWidths ? 80 : undefined,
      render: (text) => {
        try {
          return (
            <img src={require(`../../../../assets/images/nfl/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
          )
        } catch (e) {
          return (
            <img style={{width: '28px'}} alt={TeamToAbbrev[text]} />
          )
        }
      },
    },
    {
      title: 'Ven.',
      dataIndex: 'Venue',
      key: 'venue',
      width: setWidths ? 60 : undefined,
    },
    {
      title: (
        <Tooltip placement="top" title={"Projected total points for the game"}>
          <span>OU</span>
        </Tooltip>
      ),
      dataIndex: 'OU',
      width: setWidths ? 80 : undefined,
      key: 'ou',
      sorter: (a, b) => Number(a.OU) - Number(b.OU),
    },
    {
      title: (
        <Tooltip placement="top" title={"Projected amount team will win or lose by (favored team is negative)"}>
          <span>Spread</span>
        </Tooltip>
      ),
      dataIndex: 'Spread',
      key: 'spread',
      sorter: (a, b) => Number(a.Spread) - Number(b.Spread)
    },
    {
      title: (
        <Tooltip placement="top" title={"Projected total points for the team. For DST, it shows the total for the opposing team (lower Imp Pts is better for a DST)"}>
          <span>Imp Pts</span>
        </Tooltip>
      ),
      dataIndex: 'ImpPts',
      key: 'currImpPt',
      sorter: (a, b) => Number(a.ImpPts) - Number(b.ImpPts),
      className: 'divide'
    }
]

const playerProjectionCols = (permissions, site, showdown) => [
    {
      title: (
        <Tooltip placement="top" title={"DFSForecast’s projected point total"}>
          <span>Proj Pts</span>
        </Tooltip>
      ),
      key: 'dKPts',
      sorter: (a, b) => Number(a.HouseProjPts || a.ProjPts) - Number(b.HouseProjPts || b.ProjPts),
      render: (_, row) => {
        if (permissions.opt.nfl.projections) {
          return (
            row.HouseProjPts || row.ProjPts
          )
        } else {
          return <Lock />
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Projected points / Salary * 1000"}>
          <span>Proj Value</span>
        </Tooltip>
      ),
      key: 'projval',
      sorter: (a, b) => (Number(a.ProjPts) / Number(a.Salary) * 1000) - (Number(b.ProjPts) / Number(b.Salary) * 1000),
      render: (_, row) => {
        let multiplier = 1000
        if (site === 'ya')
          multiplier = 10
        if (permissions.opt.nfl.projections) {
          return (
            (Number(row.ProjPts) / Number(row.Salary) * multiplier).toFixed(3)
          )
        } else {
          return (Number(row.UserProj || 0) / Number(row.Salary) * multiplier).toFixed(3)
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Combined GPP value metric"}>
          <span>GPP Value</span>
        </Tooltip>
      ),
      sorter: (a, b) => {
        if (showdown)
          return getShowdownGPPValue(b.RosterPosition, b.Salary, b.ProjPts, site) - getShowdownGPPValue(a.RosterPosition, a.Salary, a.ProjPts, site)
        else
          return getGPPValue(b.Position, b.Salary, b.ProjPts, site) - getGPPValue(a.Position, a.Salary, a.ProjPts, site)
      },
      key: 'gppVal',
      render: (row) => {
        if (permissions && permissions.opt.nfl.projections) {
          if (showdown) {
            return (
              getShowdownGPPValue(row.RosterPosition, row.Salary, row.ProjPts, site).toFixed(2)
            )
          } else {
            return (
              getGPPValue(row.Position, row.Salary, row.ProjPts, site).toFixed(2)
            )
          }
        } else {
          return <Lock />
        }
      }
    },
    {
      title: 'Proj Own',
      dataIndex: 'ProjOwn',
      key: 'projOwn',
      sorter: (a, b) => Number(a.ProjOwn) - Number(b.ProjOwn),
      render: (_, row) => {
        if (permissions.opt.nfl.ownership) {
          return row.HouseOwn && row.UserOwn ? (<span style={{color: "#9a9a9a"}}>{row.HouseOwn}</span>) : row.ProjOwn
        } else {
          return <Lock />
        }
      },
    }
  ]

const MinWidth = 1526

const PlayersTable = ({ loading, onAdd, rows = [], permissions, site, showdown}) => {
  const _setWidths = window.innerWidth > MinWidth
  const allCols = [
    ...addCol(onAdd),
    ...playerDetailsCols(_setWidths, showdown),
    ...teamAndGameInfoCols(_setWidths),
    ...playerProjectionCols(permissions, site, showdown)
  ]

  return (
    <StyledTable
      columns={allCols}
      dataSource={rows}
      pagination={{pageSize: 50}}
      bordered
      loading={loading}
      size='small'
      rowKey={_row => `selected-${String(_row.Id)}`}
      scroll={{ y: 300, x: 800 }}
    />
  )
}

export default PlayersTable
