import { HEAT_MAP_NEGATIVE_COLOR_SCALE, HEAT_MAP_POSITIVE_COLOR_SCALE } from "../../../../../../utils/color"

export const getColorForValue = (v) => {
  const _value = Number(v)
  if (_value >= 0) {
    if (_value <= 1.5) return '#ffffff'
    else if (_value <= 3) return HEAT_MAP_POSITIVE_COLOR_SCALE[0]
    else if (_value <= 5) return HEAT_MAP_POSITIVE_COLOR_SCALE[1]
    else if (_value <= 10) return HEAT_MAP_POSITIVE_COLOR_SCALE[2]
    else return HEAT_MAP_POSITIVE_COLOR_SCALE[3]
  } else {
    const _positiveV = _value * -1
    if (_positiveV <= 1.5) return '#ffffff'
    else if (_positiveV <= 3) return HEAT_MAP_NEGATIVE_COLOR_SCALE[0]
    else if (_positiveV <= 5) return HEAT_MAP_NEGATIVE_COLOR_SCALE[1]
    else if (_positiveV <= 10) return HEAT_MAP_NEGATIVE_COLOR_SCALE[2]
    else return HEAT_MAP_NEGATIVE_COLOR_SCALE[3]
  }
}
