import { Modal, Checkbox, Input, Row, Col } from 'antd'
import React, { useState } from 'react'

const initalPositionUpdates = {
  "P": {
    MinExp: 0,
    MaxExp: 100,
    ProjPtsMin: 3,
  },
  "C": {
    MinExp: 0,
    MaxExp: 100,
    ProjPtsMin: 1,
  },
  "1B": {
    MinExp: 0,
    MaxExp: 100,
    ProjPtsMin: 1,
  },
  "2B": {
    MinExp: 0,
    MaxExp: 100,
    ProjPtsMin: 1,
  },
  "SS": {
    MinExp: 0,
    MaxExp: 100,
    ProjPtsMin: 1,
  },
  "3B": {
    MinExp: 0,
    MaxExp: 100,
    ProjPtsMin: 1,
  },
  "OF": {
    MinExp: 0,
    MaxExp: 100,
    ProjPtsMin: 1,
  },
}

const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export default ({players, onExposureChange, visible, setVisible}) => {
  const [ positionUpdates, setPositionUpdates ] = useState(deepCopy(initalPositionUpdates))
  const [ override, setOverride ] = useState(false)

  return (
    <Modal
      title={`Set Base Exposures`}
      open={visible}
      onOk={()=>{
        const changedRows = []
        players.forEach((p) => {
          const _player = { ...p }
          const _Position = p.Position.split("/")[0]

          if (!override) {
            if (Number(p.MinExp) !== 0 || (Number(p.MaxExp) !== 0 && Number(p.MaxExp) !== 100)) {
              return
            }
          }

          if (
            Number(_player.ProjPts) >= Number(positionUpdates[_Position].ProjPtsMin)
          ) {
            _player.MaxExp = positionUpdates[_Position].MaxExp
          } else {
            _player.MinExp = 0
            _player.MaxExp = 0
          }

          changedRows.push(_player)
        })

        onExposureChange(changedRows)
        setPositionUpdates(deepCopy(initalPositionUpdates))
        setOverride(false)
        setVisible(false)
      }}
      onCancel={() => {
        setVisible(false)
        setOverride(false)
        setPositionUpdates(deepCopy(initalPositionUpdates))
      }}
      width={'70%'}
      style={{top: '100px'}}
    >
      <h4>
        Change the default exposure for each position. By default this will ignore any player whose exposure has already been adjusted.
        <br/>
        <br/>
        <span style={{fontWeight: 400, marginTop: '15px'}}><i>E.g. <b>QB MaxExp=20</b> will set all QBs to have Max Exp of 20. <b>QB Proj Pts. Threshold=12.</b> will set all QBs under 12 Proj Pts to have Map Exp of 0.</i></span>
      </h4>
      <Row style={{margin: '40px 0px 40px 0px'}}>
        <Col lg={8}>
          <Checkbox onChange={(e) => {setOverride(e.target.checked)}} checked={override}>Override All Existing Exposures</Checkbox>
        </Col>
      </Row>
      <Row style={{fontWeight: 600, marginBottom: '10px'}}>
        <Col lg={4}>Position</Col>
        <Col lg={6}>
          MaxExp
        </Col>
        <Col lg={6}>
          Proj Pts. Threshold
        </Col>
      </Row>
      {
        Object.keys(initalPositionUpdates).map((position, i) => {
          return (
            <Row key={`position-base-exp-${i}`}>
              <Col lg={4}>{position}</Col>
              <Col lg={6}>
                <Input style={{width: '40%'}} value={positionUpdates[position].MaxExp} type="number" min={0} max={100} onChange={(e) => {
                  const _positionUpdates = { ...positionUpdates }
                  _positionUpdates[position].MaxExp = Number(e.target.value)
                  if (_positionUpdates[position].MaxExp > 100)
                    _positionUpdates[position].MaxExp = 100

                  setPositionUpdates(_positionUpdates)
                }}/>
              </Col>
              <Col lg={6}>
                <Input style={{width: '40%'}} value={positionUpdates[position].ProjPtsMin} type="number" min={0} max={100} onChange={(e) => {
                  const _positionUpdates = { ...positionUpdates }
                  _positionUpdates[position].ProjPtsMin = Number(e.target.value)
                  if (_positionUpdates[position].ProjPtsMin < 0)
                    _positionUpdates[position].ProjPtsMin = 0

                  setPositionUpdates(_positionUpdates)
                }}/>
              </Col>
            </Row>
          )
        })
      }
    </Modal>
  )
}
